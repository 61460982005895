import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { PutEnvironmentBodyContract } from '../../../data/contracts/environment';
import {
    CreateOrReplaceEnvironmentResponse,
    GetEnvironmentResponse,
    createEnvironment,
} from '../../../data/services/data-plane-api/environment';
import { ClientError, FailureOperation, FailureResponse, isFailureResponse } from '../../../models/common';
import { ResourceProvisioningState } from '../../../models/resource-manager';
import { createFailureResponseFromCloudErrorBodyOrDefault } from '../../../utilities/failure';
import { has } from '../../../utilities/serializable-set';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    addEnvironment,
    addEnvironmentAccepted,
    addEnvironmentError,
    addEnvironmentFailed,
    addEnvironmentOperationError,
    addEnvironmentOperationFailed,
    addEnvironmentOperationSuccess,
    pollNonTerminalEnvironment,
} from '../../actions/environment/environment-action-creators';
import { AddEnvironmentAcceptedAction, AddEnvironmentAction } from '../../actions/environment/environment-actions';
import { getGraphDirectoryObject } from '../../actions/graph/directory-objects-action-creators';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { getIdsOfOrphanedObjects } from '../../selector/graph-selectors';
import { AsyncOutcome } from '../../store/common-state';

export function* addEnvironmentSaga(action: AddEnvironmentAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { catalog, environmentDefinition, environmentType, expirationDate, id, name, parameters } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const body: PutEnvironmentBodyContract = {
            environmentType,
            catalogName: catalog,
            environmentDefinitionName: environmentDefinition,
            parameters,
            expirationDate,
            uri: id,
            name,
        };

        const response: CreateOrReplaceEnvironmentResponse = yield call(
            createEnvironment,
            id,
            name,
            body,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            // Note: the data layer will call the operation CreateOrReplace, which doesn't differentiate between
            // create and redeploy. Overwriting the operation here to add that clarity.
            const failure = FailureResponse({ ...response, operation: FailureOperation.AddEnvironment });
            const { code } = failure;
            yield put(addEnvironmentFailed({ failure, id }, meta));
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.AddEnvironment,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, code)
            );

            return;
        }

        const { data } = response;
        yield put(addEnvironmentAccepted({ id, result: data }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.AddEnvironment,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.AddEnvironment);
        yield put(addEnvironmentError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.AddEnvironment,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* addEnvironmentAcceptedSaga(action: AddEnvironmentAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetEnvironmentResponse = yield putAndAwait(pollNonTerminalEnvironment({ id }, meta));

        if (isFailureResponse(response)) {
            // Note: the data layer will call the operation GetEnvironment. Adjust that here to make it consistent with
            // the other failures that get reported in this saga.
            const failure = FailureResponse({ ...response, operation: FailureOperation.AddEnvironment });
            yield put(addEnvironmentOperationFailed({ failure, id }, meta));
            return;
        }

        const { data } = response;
        const { provisioningState, error: environmentResourceError } = data;

        switch (provisioningState) {
            case ResourceProvisioningState.Succeeded:
                yield put(addEnvironmentOperationSuccess({ id, result: data }, meta));
                break;
            case ResourceProvisioningState.Failed:
            default:
                // Unexpected state: log cases where we're falling back on the default failure message. This means we're
                // in a failed state, but there are no error details.
                if (!environmentResourceError) {
                    trackEvent(EventName.EnvironmentInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState,
                        },
                    });
                }

                const failure = createFailureResponseFromCloudErrorBodyOrDefault(
                    environmentResourceError,
                    FailureOperation.AddEnvironment
                );

                yield put(addEnvironmentOperationFailed({ failure, id, result: data }, meta));
                break;
        }

        // Load owner data for environments with any provisioning state (Succeeded or Failed)
        const { user } = data;
        const orphanedObjectIds = yield select(getIdsOfOrphanedObjects);

        if (user && !has(orphanedObjectIds, user)) {
            yield put(getGraphDirectoryObject({ id: user }));
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.AddEnvironment);
        yield put(addEnvironmentOperationError({ error, id }));
    }
}

export function* addEnvironmentListenerSaga(): SagaIterator {
    yield takeLeading(addEnvironment, addEnvironmentSaga);
}

export function* addEnvironmentAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(addEnvironmentAccepted, addEnvironmentAcceptedSaga);
}
