import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Banner from '../../banner';

type PlannedMaintenanceBannerProps = {
    dateTime: Date;
    isDismissed: boolean;
    isExpired: boolean;
    onDismiss: () => void;
    timeSpanInMinutes: number;
};

export const PlannedMaintenanceBanner: React.FC<PlannedMaintenanceBannerProps> = React.memo((props) => {
    const { dateTime, isExpired, isDismissed, onDismiss, timeSpanInMinutes } = props;

    // Intl hooks
    const { formatDate, formatNumber } = useIntl();

    // Memoization hooks
    const values = React.useMemo(
        () => ({
            date: formatDate(dateTime, { day: 'numeric', month: 'long', weekday: 'long' }),
            time: formatDate(dateTime, { hour: 'numeric', hour12: true, minute: 'numeric', timeZoneName: 'short' }),
            timeSpan: formatNumber(timeSpanInMinutes, { style: 'unit', unit: 'minute', unitDisplay: 'long' }),
        }),
        [dateTime, formatDate, formatNumber, timeSpanInMinutes]
    );

    // User has closed the banner we will not reshow the banner
    if (isDismissed) {
        return <></>;
    }

    // Message is now past the expiration date
    if (isExpired) {
        return <></>;
    }

    return (
        <Banner
            intent="warning"
            onDismiss={onDismiss}
            title={
                <FormattedMessage
                    id="PlannedMaintenanceBanner_Message_Title"
                    defaultMessage="Planned maintenance"
                    description="Title of the 'Planned maintenance' banner."
                />
            }
        >
            <FormattedMessage
                id="PlannedMaintenanceBanner_Message_Text"
                defaultMessage="The developer portal will be unavailable for {timeSpan} starting at {time} on {date}."
                description="Content of the 'Planned maintenance' banner."
                values={values}
            />
        </Banner>
    );
});

export default PlannedMaintenanceBanner;
