import {
    FontWeights,
    IGroupHeaderProps,
    Icon,
    Spinner,
    SpinnerSize,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getTokensFromCustomizationGroupDataPlaneUri } from '../../ids/customization-group';
import { CustomizationTask, CustomizationTaskStatus } from '../../models/customization';
import { getCustomizationTaskLogs } from '../../redux/selector/customization-selectors';
import { SerializableMap } from '../../types/serializable-map';
import { get } from '../../utilities/serializable-map';
import { getSemanticColor } from '../../utilities/styles';
import { FluentIconNames } from '../common/fluent-icon-names';
import { CustomizedGroupListHeader } from '../common/customized-group-list-header';

interface CustomizationTaskDetailsContainerProps {
    groupHeaderProps?: IGroupHeaderProps;
    task?: CustomizationTask;
    customizationGroupUri: string;
}

interface CustomizationTaskDetailsProps extends CustomizationTaskDetailsContainerProps {
    taskLogs: SerializableMap<string>;
}

interface TaskIconProps {
    status?: CustomizationTaskStatus;
}

const messages = defineMessages({
    customizationTaskLogFileName: {
        id: 'CustomizationTaskDetails_CustomizationTaskLogFile_Name',
        defaultMessage: '{devBoxName}_{customizationGroupName}_{taskName}',
        description:
            'Name for the downloaded customization task log file. {devBoxName}, {customizationGroupName} and {taskName} should not be localized.',
    },
});

/**
 * Styles
 */

const useSuccessIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'successMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useErrorIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'errorMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useQueuedIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'queuedIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useSkippedIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'skippedIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

/**
 * End Styles
 */

const TaskIcon: React.FC<TaskIconProps> = React.memo((props: TaskIconProps) => {
    const { status } = props;

    const successIconStyles = useSuccessIconStyles();
    const errorIconStyles = useErrorIconStyles();
    const queuedIconStyles = useQueuedIconStyles();
    const skippedIconStyles = useSkippedIconStyles();

    switch (status) {
        case CustomizationTaskStatus.Running:
            return <Spinner ariaLabel={'spinner'} size={SpinnerSize.small} />;
        case CustomizationTaskStatus.Succeeded:
            return <Icon iconName={FluentIconNames.Accept} styles={successIconStyles} />;
        case CustomizationTaskStatus.FailedValidation:
        case CustomizationTaskStatus.TimedOut:
        case CustomizationTaskStatus.Failed:
            return <Icon iconName={FluentIconNames.Cancel} styles={errorIconStyles} />;
        case CustomizationTaskStatus.Skipped:
            return <Icon iconName={FluentIconNames.Blocked} styles={skippedIconStyles} />;
        case CustomizationTaskStatus.NotStarted:
        default:
            return <Icon iconName={FluentIconNames.CircleRing} styles={queuedIconStyles} />;
    }
});

export const CustomizationTaskDetails: React.FC<CustomizationTaskDetailsProps> = React.memo(
    (props: CustomizationTaskDetailsProps) => {
        const { groupHeaderProps, task, customizationGroupUri, taskLogs } = props;
        const { status, endTime, startTime, logUri, parameters } = task ?? {};
        const { devBoxName, customizationGroupName } =
            getTokensFromCustomizationGroupDataPlaneUri(customizationGroupUri);

        // Intl hooks
        const { formatMessage } = useIntl();

        const hasParameters = React.useMemo(() => {
            if (parameters) {
                return Object.keys(parameters).length > 0;
            }

            return false;
        }, [parameters]);

        const taskLogUrl = React.useMemo(() => get(taskLogs, logUri), [taskLogs, logUri]);

        const getTaskLogFileName = React.useMemo(
            () =>
                formatMessage(messages.customizationTaskLogFileName, {
                    devBoxName,
                    customizationGroupName,
                    taskName: task?.name,
                }),
            [task, formatMessage]
        );

        const getStatusIcon = React.useMemo(() => {
            return <TaskIcon status={status} />;
        }, [status]);

        return (
            <CustomizedGroupListHeader
                statusIcon={getStatusIcon}
                groupHeaderProps={groupHeaderProps}
                hasParameters={hasParameters}
                startTime={startTime}
                endTime={endTime}
                logUrl={taskLogUrl}
                logFileName={getTaskLogFileName}
            />
        );
    }
);

export const CustomizationTaskDetailsContainer: React.FC<CustomizationTaskDetailsContainerProps> = (
    props: CustomizationTaskDetailsContainerProps
) => {
    const { task, customizationGroupUri, groupHeaderProps } = props;

    const taskLogs = useSelector(getCustomizationTaskLogs);

    return (
        <CustomizationTaskDetails
            taskLogs={taskLogs}
            task={task}
            customizationGroupUri={customizationGroupUri}
            groupHeaderProps={groupHeaderProps}
        />
    );
};

export default CustomizationTaskDetailsContainer;
