import { failedCustomizationGroupStatuses } from '../constants/customization';
import { CustomizationGroup, CustomizationGroupStatus, OverallCustomizationGroup } from '../models/customization';
import { terminalCustomizationGroupStates } from '../redux/store/customization-state';
import { sortBy } from './array';
import { compareDates } from './date';

export const isFailedCustomizationGroupStatus = (status: string | undefined): status is CustomizationGroupStatus =>
    !!status && failedCustomizationGroupStatuses.indexOf(status as CustomizationGroupStatus) !== -1;

export const isCustomizationGroupInTerminalState = (state: CustomizationGroupStatus | undefined): boolean =>
    !!state && terminalCustomizationGroupStates.indexOf(state) !== -1;

export const getEarliestStartTimeFromCustomizationGroups = (
    customizationGroups: CustomizationGroup[]
): Date | undefined => {
    return customizationGroups.reduce((earliest: Date | undefined, group) => {
        if (group.startTime && (!earliest || group.startTime.getTime() < earliest.getTime())) {
            return group.startTime;
        }

        return earliest;
    }, undefined);
};

export const getLatestEndTimeFromCustomizationGroups = (
    customizationGroups: CustomizationGroup[]
): Date | undefined => {
    return customizationGroups.reduce((latest: Date | undefined, group) => {
        if (group.endTime && (!latest || group.endTime.getTime() > latest.getTime())) {
            return group.endTime;
        }

        return latest;
    }, undefined);
};

export const getOverallCustomizationGroup = (customizationGroups: CustomizationGroup[]): OverallCustomizationGroup => {
    const unsortedTasks = customizationGroups.flatMap((group) => group.tasks ?? []);
    const tasks = sortBy(
        unsortedTasks,
        (task) => task,
        (a, b) => compareDates(a.startTime, b.startTime)
    );

    const status = getOverallCustomizationGroupStatus(customizationGroups);

    const startTime = getEarliestStartTimeFromCustomizationGroups(customizationGroups);

    const endTime = getLatestEndTimeFromCustomizationGroups(customizationGroups);

    return {
        tasks, // The flat map of the tasks from each group in CustomizationGroups
        status,
        startTime, // The earliest start time of all groups
        endTime, // The latest end time of all groups
    };
};

export const getOverallCustomizationGroupStatus = (
    customizationGroups: CustomizationGroup[] | undefined
): CustomizationGroupStatus | undefined => {
    if (!customizationGroups) {
        return undefined;
    }

    if (
        customizationGroups.some(
            (group) =>
                group.status === CustomizationGroupStatus.Failed ||
                group.status === CustomizationGroupStatus.ValidationFailed
        )
    ) {
        // Terminal state - at least one group is failed or validation failed
        return CustomizationGroupStatus.Failed;
    } else if (customizationGroups.every((group) => group.status === CustomizationGroupStatus.Succeeded)) {
        // Terminal state - all groups are succeeded
        return CustomizationGroupStatus.Succeeded;
    } else if (customizationGroups.every((group) => group.status === CustomizationGroupStatus.NotStarted)) {
        return CustomizationGroupStatus.NotStarted;
        // Initial state - all groups are not started
    } else {
        // Intermediate state
        return CustomizationGroupStatus.Running;
    }
};

export const getLatestCustomizationGroupFailureEndTime = (
    customizationGroups: CustomizationGroup[] | undefined
): Date | undefined => {
    if (!customizationGroups) {
        return undefined;
    }

    const failedGroups = customizationGroups.filter((group) => isFailedCustomizationGroupStatus(group.status));

    if (failedGroups.length === 0) {
        return undefined;
    }

    return getLatestEndTimeFromCustomizationGroups(failedGroups);
};
