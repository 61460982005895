import { makeStyles as legacyMakeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AppSemanticColor } from '../../../../../themes/app-semantic-colors';
import { getSemanticColor } from '../../../../../utilities/styles';
import { ImageViewModel } from '../../../../add-dev-box-panel/models';
import { DropdownDividerOption } from '../divider';
import { DropdownHeaderOption } from '../header';
import { ValueDropdown, ValueDropdownOption, ValueDropdownWrapperProps } from '../value-dropdown';
import { getOptionKeyForIndexedModel } from './selectors';

type DropdownProps = ValueDropdownWrapperProps<ImageViewModel>;

const messages = defineMessages({
    imageDropdownRecentImagesText: {
        id: 'AddDevBoxFormImageControls_RecentImages_Text',
        defaultMessage: 'Recent images',
        description: 'Text for the recent images header in the image dropdown in the add dev box panel label',
    },
    imageDropdownOtherImagesText: {
        id: 'AddDevBoxFormImageControls_OtherImages_Text',
        defaultMessage: 'Other images',
        description: 'Text for the other images header in the image dropdown in the add dev box panel label',
    },
});

/**
 * Styles
 */

const useLinkStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, AppSemanticColor.seeAllLink),
    },
}));

/**
 * END Styles
 */

export const ImageDropdown: React.FC<DropdownProps> = (props) => {
    const { options } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const linkStyles = useLinkStyles();

    const onRenderOption = React.useCallback(
        (value: ImageViewModel): JSX.Element => {
            if (value === undefined) {
                return <></>;
            }

            if (value.id === 'see-all-images') {
                return (
                    <div className={linkStyles.root}>
                        <FormattedMessage
                            id="AddDevBoxFormImageControls_SeeAllImagesLink_Text"
                            defaultMessage="See all images"
                            description="Text for the see all images link for the add dev box image form field"
                        />
                    </div>
                );
            }

            return <div>{value.name}</div>;
        },
        [linkStyles]
    );

    const optionsWithDivider: ValueDropdownOption<ImageViewModel>[] = React.useMemo(() => {
        //If there is only option, don't insert divider.
        if (options.length > 1 && options[options.length - 1]) {
            const optionsWithDivider: ValueDropdownOption<ImageViewModel>[] = options.slice(0, options.length - 1);
            optionsWithDivider.push(DropdownDividerOption('image-divider'), options[options.length - 1]);

            const recentlyUsed = options.filter((option) => option.recentlyUsed === true);

            if (recentlyUsed.length > 0) {
                optionsWithDivider.splice(
                    0,
                    0,
                    DropdownHeaderOption('recentimages', formatMessage(messages.imageDropdownRecentImagesText))
                );

                if (recentlyUsed.length + 1 < options.length) {
                    optionsWithDivider.splice(
                        recentlyUsed.length + 1,
                        0,
                        DropdownHeaderOption('otherimages', formatMessage(messages.imageDropdownOtherImagesText))
                    );
                }
            }

            return optionsWithDivider;
        }

        return options;
    }, [options, formatMessage]);

    return (
        <ValueDropdown<ImageViewModel>
            {...props}
            options={optionsWithDivider}
            onRenderOption={onRenderOption}
            getOptionKey={getOptionKeyForIndexedModel}
        />
    );
};

export default ImageDropdown;
