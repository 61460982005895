import { CheckboxOnChangeData } from '@fluentui/react-components';
import { ChangeEvent, useCallback } from 'react';

// Sidesteps react-final-form's goofy behavior around checkbox's onChange handler
// NOTE: this function assumes that the checkbox won't be in the 'mixed' state
export const useOnChangeCallbackForCheckbox = (
    onChange: (checked: boolean) => void
): ((ev: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => void) => {
    return useCallback(
        (_ev: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
            //The checkboxes have a mixed state which is basically halfway checked. We shouldn't be putting it in that state, so I'm mapping it to false.
            const checked: boolean = data.checked === 'mixed' ? false : data.checked;
            onChange(checked);
        },
        [onChange]
    );
};
