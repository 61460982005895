import {
    TeachingPopover,
    TeachingPopoverBody,
    TeachingPopoverFooter,
    TeachingPopoverHeader,
    TeachingPopoverSurface,
    TeachingPopoverTitle,
} from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { setWelcomeTourStatus } from '../../redux/actions/application/application-action-creators';
import { ReturnVoid } from '../../types/return-void';
import {
    getIsCreateNewTeachingPopoverForWelcomeTourDismissed,
    getIsCreateNewTeachingPopoverForWelcomeTourSkipped,
    getIsHelpMenuTeachingPopoverForWelcomeTourDismissed,
    getIsSettingsMenuTeachingPopoverForWelcomeTourDismissed,
    getIsSettingsMenuTeachingPopoverForWelcomeTourSkipped,
    getIsWelcomeTourSkippedWithNoShowAgainDismissed,
} from './selectors';

interface WelcomeTourDialogTeachingPopoversProps {
    dialogDismissed: boolean;
    dialogSkipped: boolean;
    createNewTeachingPopoverTargetElement: Element | null;
    settingsMenuTeachingPopoverTargetElement: Element | null;
    helpMenuTeachingPopoverTargetElement: Element | null;
    onCreateNewTeachingPopoverForWelcomeTourDismiss: () => void;
    onCreateNewTeachingPopoverForWelcomeTourSkip: () => void;
    onSettingsMenuTeachingPopoverForWelcomeTourDismiss: () => void;
    onSettingsMenuTeachingPopoverForWelcomeTourSkip: () => void;
    onHelpMenuTeachingPopoverForWelcomeTourDismiss: () => void;
    onWelcomeTourSeenOrSkipped: ReturnVoid<typeof setWelcomeTourStatus>;
}

const messages = defineMessages({
    teachingPopoversWelcomeTourPrimaryButton: {
        id: 'TeachingPopovers_WelcomeTour_PrimaryButton',
        defaultMessage: 'Next tip',
        description: 'Text for the new create and settings teaching popover footer primary button',
    },
    teachingPopoversWelcomeTourSecondaryButton: {
        id: 'TeachingPopovers_WelcomeTour_SecondaryButton',
        defaultMessage: 'Skip',
        description: 'Text for the new create and settings teaching popover footer secondary button',
    },
    teachingPopoversWelcomeTourFooterButton: {
        id: 'TeachingPopovers_WelcomeTour_FooterButton',
        defaultMessage: 'Done',
        description: 'Text terminal teaching popover footer button',
    },
});

/**
 * END Styles
 */

export const WelcomeTourDialogTeachingPopovers: React.FC<WelcomeTourDialogTeachingPopoversProps> = React.memo(
    (props: WelcomeTourDialogTeachingPopoversProps) => {
        const {
            dialogDismissed,
            dialogSkipped,
            onCreateNewTeachingPopoverForWelcomeTourDismiss,
            onCreateNewTeachingPopoverForWelcomeTourSkip,
            createNewTeachingPopoverTargetElement,
            settingsMenuTeachingPopoverTargetElement,
            helpMenuTeachingPopoverTargetElement,
            onSettingsMenuTeachingPopoverForWelcomeTourDismiss,
            onSettingsMenuTeachingPopoverForWelcomeTourSkip,
            onHelpMenuTeachingPopoverForWelcomeTourDismiss,
            onWelcomeTourSeenOrSkipped,
        } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // State hooks
        const [popoverSkipped, setPopoverSkipped] = React.useState(false);

        // Selector hooks
        const isFirstPopoverDismissed = useSelector(getIsCreateNewTeachingPopoverForWelcomeTourDismissed);
        const isFirstPopoverSkipped = useSelector(getIsCreateNewTeachingPopoverForWelcomeTourSkipped);
        const isSecondPopoverDismissed = useSelector(getIsSettingsMenuTeachingPopoverForWelcomeTourDismissed);
        const isSecondPopoverSkipped = useSelector(getIsSettingsMenuTeachingPopoverForWelcomeTourSkipped);
        const isThirdPopoverDismissed = useSelector(getIsHelpMenuTeachingPopoverForWelcomeTourDismissed);
        const dialogSkippedWithNoShowAgain = useSelector(getIsWelcomeTourSkippedWithNoShowAgainDismissed);

        const shouldShowFirstTeachingPopoverForWelcomeTour =
            createNewTeachingPopoverTargetElement &&
            !isFirstPopoverDismissed &&
            !isFirstPopoverSkipped &&
            dialogDismissed &&
            !dialogSkipped &&
            !dialogSkippedWithNoShowAgain;

        const shouldShowSecondTeachingPopoverForWelcomeTour =
            (isFirstPopoverDismissed || isFirstPopoverSkipped) &&
            !popoverSkipped &&
            settingsMenuTeachingPopoverTargetElement &&
            !isSecondPopoverDismissed &&
            !isSecondPopoverSkipped &&
            dialogDismissed &&
            !dialogSkipped &&
            !dialogSkippedWithNoShowAgain;

        const shouldShowThirdTeachingPopoverForWelcomeTour =
            (isSecondPopoverDismissed || isSecondPopoverSkipped) &&
            !popoverSkipped &&
            helpMenuTeachingPopoverTargetElement &&
            !isThirdPopoverDismissed &&
            dialogDismissed &&
            !dialogSkipped &&
            !dialogSkippedWithNoShowAgain;

        // Callback hooks
        const onCreateNewTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
            onCreateNewTeachingPopoverForWelcomeTourDismiss();
        }, [onCreateNewTeachingPopoverForWelcomeTourDismiss]);

        const onCreateNewTeachingPopoverForWelcomeTourSkipped = React.useCallback(() => {
            onCreateNewTeachingPopoverForWelcomeTourSkip();
            setPopoverSkipped(true); // To not show skipped popover unless refreshed
        }, [onCreateNewTeachingPopoverForWelcomeTourSkip]);

        const onSettingsMenuTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
            onSettingsMenuTeachingPopoverForWelcomeTourDismiss();
        }, [onSettingsMenuTeachingPopoverForWelcomeTourDismiss]);

        const onSettingsMenuTeachingPopoverForWelcomeTourSkipped = React.useCallback(() => {
            onSettingsMenuTeachingPopoverForWelcomeTourSkip();
            setPopoverSkipped(true); // To not show skipped popover unless refreshed
        }, [onSettingsMenuTeachingPopoverForWelcomeTourSkip]);

        const onHelpMenuTeachingPopoverForWelcomeTourDismissed = React.useCallback(() => {
            onHelpMenuTeachingPopoverForWelcomeTourDismiss();
            onWelcomeTourSeenOrSkipped({ seenOrSkippedWelcomeTour: true }); // Welcome tour complete, set to true
        }, [onWelcomeTourSeenOrSkipped, onHelpMenuTeachingPopoverForWelcomeTourDismiss]);
        return (
            <>
                {shouldShowFirstTeachingPopoverForWelcomeTour && (
                    <TeachingPopover
                        onOpenChange={onCreateNewTeachingPopoverForWelcomeTourSkipped}
                        defaultOpen
                        appearance="brand"
                        positioning={{ target: createNewTeachingPopoverTargetElement, position: 'after' }}
                    >
                        <TeachingPopoverSurface>
                            <TeachingPopoverHeader>
                                <FormattedMessage
                                    id="Welcome_Tour_NewButtonTeachingPopoverForWelcomeTour_Header"
                                    defaultMessage="Tip 1 of 3"
                                    description="Header for the new button teaching popover for welcome tour"
                                />
                            </TeachingPopoverHeader>
                            <TeachingPopoverBody>
                                <TeachingPopoverTitle>
                                    <FormattedMessage
                                        id="Welcome_Tour_NewButtonTeachingPopoverForWelcomeTour_Title"
                                        defaultMessage="Create Resources"
                                        description="Title for the connect via app teaching popover for welcome tour"
                                    />
                                </TeachingPopoverTitle>
                                <div>
                                    <FormattedMessage
                                        id="Welcome_Tour_NewButtonTeachingPopoverForWelcomeTour_Body"
                                        defaultMessage="The developer portal allows you to rapidly create as many resources as you need for your development work. These are configured by your organization to meet the needs of you and your team."
                                        description="Body for the connect via app teaching popover for welcome tour"
                                    />
                                </div>
                            </TeachingPopoverBody>

                            <TeachingPopoverFooter
                                primary={{
                                    children: formatMessage(messages.teachingPopoversWelcomeTourPrimaryButton),
                                    onClick: onCreateNewTeachingPopoverForWelcomeTourDismissed,
                                }}
                                secondary={{
                                    children: formatMessage(messages.teachingPopoversWelcomeTourSecondaryButton),
                                    onClick: onCreateNewTeachingPopoverForWelcomeTourSkipped,
                                }}
                            />
                        </TeachingPopoverSurface>
                    </TeachingPopover>
                )}
                {shouldShowSecondTeachingPopoverForWelcomeTour && (
                    <TeachingPopover
                        onOpenChange={onSettingsMenuTeachingPopoverForWelcomeTourSkipped}
                        defaultOpen
                        appearance="brand"
                        positioning={{ target: settingsMenuTeachingPopoverTargetElement, position: 'after' }}
                    >
                        <TeachingPopoverSurface>
                            <TeachingPopoverHeader>
                                <FormattedMessage
                                    id="Welcome_Tour_SettingsTeachingPopoverForWelcomeTour_Header"
                                    defaultMessage="Tip 2 of 3"
                                    description="Header for settings button teaching popover for welcome tour"
                                />
                            </TeachingPopoverHeader>
                            <TeachingPopoverBody>
                                <TeachingPopoverTitle>
                                    <FormattedMessage
                                        id="Welcome_Tour_SettingsTeachingPopoverForWelcomeTour_Title"
                                        defaultMessage="Settings"
                                        description="Title for the settings button teaching popover for welcome tour"
                                    />
                                </TeachingPopoverTitle>
                                <div>
                                    <FormattedMessage
                                        id="DevBoxCard_SettingsTeachingPopoverForWelcomeTour_Body"
                                        defaultMessage="Access settings to change dev box connection settings, and more."
                                        description="Body for the settings menu button teaching popover for welcome tour"
                                    />
                                </div>
                            </TeachingPopoverBody>

                            <TeachingPopoverFooter
                                primary={{
                                    children: formatMessage(messages.teachingPopoversWelcomeTourPrimaryButton),
                                    onClick: onSettingsMenuTeachingPopoverForWelcomeTourDismissed,
                                }}
                                secondary={{
                                    children: formatMessage(messages.teachingPopoversWelcomeTourSecondaryButton),
                                    onClick: onSettingsMenuTeachingPopoverForWelcomeTourSkipped,
                                }}
                            />
                        </TeachingPopoverSurface>
                    </TeachingPopover>
                )}
                {shouldShowThirdTeachingPopoverForWelcomeTour && (
                    <TeachingPopover
                        onOpenChange={onHelpMenuTeachingPopoverForWelcomeTourDismissed}
                        defaultOpen
                        appearance="brand"
                        positioning={{ target: helpMenuTeachingPopoverTargetElement, position: 'after' }}
                    >
                        <TeachingPopoverSurface>
                            <TeachingPopoverHeader>
                                <FormattedMessage
                                    id="Welcome_Tour_HelpTeachingPopoverForWelcomeTour_Header"
                                    defaultMessage="Tip 3 of 3"
                                    description="Header for the help menu button teaching popover for welcome tour"
                                />
                            </TeachingPopoverHeader>
                            <TeachingPopoverBody>
                                <TeachingPopoverTitle>
                                    <FormattedMessage
                                        id="Welcome_Tour_HelpTeachingPopoverForWelcomeTour_Title"
                                        defaultMessage="Help & support"
                                        description="Title for the help menu button teaching popover for welcome tour"
                                    />
                                </TeachingPopoverTitle>
                                <div>
                                    <FormattedMessage
                                        id="DevBoxCard_HelpTeachingPopoverForWelcomeTour_Body"
                                        defaultMessage="If you encounter any technical issues while using the developer portal or would like to view documentation, the help and support panel includes information and resources."
                                        description="Body for the help menu button teaching popover for welcome tour"
                                    />
                                </div>
                            </TeachingPopoverBody>

                            <TeachingPopoverFooter
                                primary={{
                                    children: formatMessage(messages.teachingPopoversWelcomeTourFooterButton),
                                    onClick: onHelpMenuTeachingPopoverForWelcomeTourDismissed,
                                }}
                            />
                        </TeachingPopoverSurface>
                    </TeachingPopover>
                )}
            </>
        );
    }
);

export default WelcomeTourDialogTeachingPopovers;
