import {
    Button,
    FluentProvider,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarIntent,
    MessageBarTitle,
} from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useCurrentFluent2Theme } from '../hooks/styling';

type BannerProps = React.PropsWithChildren<{
    intent: MessageBarIntent;
    onDismiss?: () => void;
    title?: React.ReactNode;
}>;

const messages = defineMessages({
    dismissButtonAriaLabel: {
        id: 'Banner_DismissButton_AriaLabel',
        defaultMessage: 'Dismiss',
        description: 'Aria label for banner close button',
    },
});

export const Banner: React.FC<BannerProps> = React.memo((props) => {
    const { children, intent, onDismiss, title } = props;

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoization hooks
    const dismissButtonAriaLabel = React.useMemo(() => formatMessage(messages.dismissButtonAriaLabel), [formatMessage]);

    // TODO #2085494: FluentProvider should ideally be called in app.tsx. However, this caused unexpected changes to
    // components still using Fluent 1. Rather than attempt to dig into that issue in this targeted change, I went with
    // adding FluentProvider only where strictly needed for now.
    return (
        <FluentProvider theme={theme}>
            <MessageBar intent={intent}>
                <MessageBarBody>
                    {title && <MessageBarTitle>{title}</MessageBarTitle>}
                    {children}
                </MessageBarBody>

                {onDismiss && (
                    <MessageBarActions
                        containerAction={
                            <Button
                                appearance="transparent"
                                aria-label={dismissButtonAriaLabel}
                                icon={<DismissRegular />}
                                onClick={onDismiss}
                            />
                        }
                    />
                )}
            </MessageBar>
        </FluentProvider>
    );
});

export default Banner;
