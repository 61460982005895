import { defineMessages } from 'react-intl';

export const selectDevBoxRegionDetailsListMessages = defineMessages({
    filterInputPlaceholder: {
        id: 'SelectDevBoxRegionDetailsList_FilterInput_Placeholder',
        defaultMessage: 'Filter for any field...',
        description: 'Text for the select dev box region details list filter input placeholder',
    },
    filterInputAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_FilterInput_AriaLabel',
        defaultMessage: 'This is the input to filter out the dev box regions for any field',
        description: 'Aria label for the select dev box region details list filter input',
    },
    regionColumnHeaderText: {
        id: 'SelectDevBoxRegionDetailsList_RegionColumnHeader_Text',
        defaultMessage: 'Region',
        description: 'Text for the select dev box region details list region column',
    },
    regionColumnHeaderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_RegionColumnHeader_AriaLabel',
        defaultMessage: 'Region',
        description: 'Aria label for the select dev box region details list region column',
    },
    latencyColumnHeaderText: {
        id: 'SelectDevBoxRegionDetailsList_LatencyColumnHeader_Text',
        defaultMessage: 'Latency',
        description: 'Text for the "select dev box region" details list source region latency column',
    },
    latencyColumnHeaderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_LatencyColumnHeader_AriaLabel',
        defaultMessage: 'Latency',
        description: 'Aria label for the "select dev box region" details list source region latency column',
    },
    roundTripTimeColumnHeaderText: {
        id: 'SelectDevBoxRegionDetailsList_RoundTripTimeColumnHeader_Text',
        defaultMessage: 'Round trip time',
        description: 'Text for the select dev box region details list round trip time column',
    },
    roundTripTimeColumnHeaderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_RoundTripTimeColumnHeader_AriaLabel',
        defaultMessage: 'Round trip time',
        description: 'Aria label for the select dev box region details list round trip time column',
    },
    roundTripTimeFormattedText: {
        id: 'SelectDevBoxRegionDetailsList_RoundTripTimeFormattedValue_Text',
        defaultMessage: '{roundTripTime} ms',
        description:
            'Text informing user of the round trip time of the region. {roundTripTime} should not be localized.',
    },
    virtualNetworkColumnHeaderText: {
        id: 'SelectDevBoxRegionDetailsList_VirtualNetworkColumnHeader_Text',
        defaultMessage: 'Virtual network',
        description: 'Text for the select dev box region details list virtual network name column',
    },
    virtualNetworkColumnHeaderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_VirtualNetworkColumnHeader_AriaLabel',
        defaultMessage: 'Virtual network',
        description: 'Aria label for the select dev box region details list virtual network name column',
    },
    poolNameColumnHeaderText: {
        id: 'SelectDevBoxRegionDetailsList_PoolColumnHeader_Text',
        defaultMessage: 'Pool',
        description: 'Text for the select dev box region details list pool name column',
    },
    poolNameColumnHeaderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_PoolColumnHeader_AriaLabel',
        defaultMessage: 'Pool',
        description: 'Aria label for the select dev box region details list pool name column',
    },
    sortAscendingOrderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_SortAscendingOrder_AriaLabel',
        defaultMessage: 'Sorted from A to Z',
        description: 'Aria label for the select dev box region details list sorting ascending order',
    },
    sortDescendingOrderAriaLabel: {
        id: 'SelectDevBoxRegionDetailsList_SortDescendingOrder_AriaLabel',
        defaultMessage: 'Sorted from Z to A',
        description: 'Aria label for the select dev box region details list sorting descending order',
    },
});
