import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../../redux/selector/localization-selectors';
import LegacyExpirationTimePicker from '../legacy-add-or-update-expiration-form-field-group/legacy-time-picker';
import { ExpirationTimePicker } from '../time-picker';

interface AddOrUpdateExpirationFormTimeControlContainerProps {
    value?: Date;
    onChange: (time: Date | undefined) => void;
    isSubmitting: boolean;
    isLegacy?: boolean;
}

interface AddOrUpdateExpirationFormTimeControlProps extends AddOrUpdateExpirationFormTimeControlContainerProps {
    locale: string;
}

const messages = defineMessages({
    selectDeletionTime: {
        id: 'AddOrUpdateExpirationFormTimeControl_Label',
        defaultMessage: 'Deletion time',
        description: 'Label for the time picker',
    },
    selectDeletionTimeAriaLabel: {
        id: 'AddOrUpdateExpirationFormTimeControl_AriaLabel',
        defaultMessage: 'Deletion time',
        description: 'Aria label for the time picker',
    },
    selectDeletionTimePlaceholder: {
        id: 'AddOrUpdateExpirationFormTimeControl_Placeholder',
        defaultMessage: 'Select time',
        description: 'Placeholder for the time picker',
    },
});

export const AddOrUpdateExpirationFormTimeControl: React.FC<AddOrUpdateExpirationFormTimeControlProps> = (
    props: AddOrUpdateExpirationFormTimeControlProps
) => {
    const { value, onChange, isSubmitting, locale, isLegacy } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    return isLegacy ? (
        <LegacyExpirationTimePicker
            label={formatMessage(messages.selectDeletionTime)}
            ariaLabel={formatMessage(messages.selectDeletionTimeAriaLabel)}
            onChange={onChange}
            value={value}
            placeholder={formatMessage(messages.selectDeletionTimePlaceholder)}
            locale={locale}
            disabled={isSubmitting}
        />
    ) : (
        <ExpirationTimePicker
            label={formatMessage(messages.selectDeletionTime)}
            ariaLabel={formatMessage(messages.selectDeletionTimeAriaLabel)}
            onChange={onChange}
            value={value}
            placeholder={formatMessage(messages.selectDeletionTimePlaceholder)}
            locale={locale}
            disabled={isSubmitting}
        />
    );
};

export const AddOrUpdateExpirationFormTimeControlContainer: React.FC<
    AddOrUpdateExpirationFormTimeControlContainerProps
> = (props: AddOrUpdateExpirationFormTimeControlContainerProps) => {
    //Application state hooks
    const locale = useSelector(getLocale);

    return <AddOrUpdateExpirationFormTimeControl {...props} locale={locale} />;
};

export default AddOrUpdateExpirationFormTimeControlContainer;
