import {
    ActivityItem,
    CommandBarButton,
    DirectionalHint,
    FontSizes,
    FontWeights,
    IContextualMenuProps,
    makeStyles as legacyMakeStyles,
    Link,
    Persona,
    PersonaSize,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/action-creator';
import { signOut } from '../../redux/actions/identity/identity-action-creators';
import { getUserDisplayName, getUserPhotoUrl } from '../../redux/selector/graph-selectors';
import { getUsername } from '../../redux/selector/identity-selectors';
import { useStackStyles } from '../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../utilities/styles';

interface MeControlComponentProps {
    displayName: string;
    ariaLabel?: string;
    onSignOutSubmitted: () => void;
    username: string;
    userPhotoUrl?: string;
}

interface MeControlSubmenuProps {
    displayName: string;
    onSignOutSubmitted: () => void;
    username: string;
    userPhotoUrl?: string;
}

/**
 * Styles
 */

const useMeControlContainerStyles = makeStyles({
    root: {
        height: '48px',
        justifyContent: 'center',
        padding: '0 10px',
    },
});

const useMeControlButtonStyles = makeStyles({
    root: {
        padding: '0',
        width: '48px',
    },
    menuIcon: {
        display: 'none',
    },
});

const useMeControlSubmenuStyles = makeStyles({
    name: {
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.bold,
        marginLeft: '31px',
        marginTop: '39px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '170px',
    },
    signOut: {
        fontSize: FontSizes.size14,
        marginLeft: '31px',
        marginTop: '5px',
    },
    username: {
        fontSize: FontSizes.size14,
        marginLeft: '31px',
        marginTop: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '170px',
    },
});

const usePersonaCoinStyles = makeStyles({
    root: {
        marginLeft: '43px',
        marginTop: '39px',
    },
});

const useMeControlRootStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'meControlSubmenuText'),
        height: '184px',
        width: '364px',
    },
}));

/**
 * End Styles
 */

const MeControlComponent: React.FC<MeControlComponentProps> = React.memo((props: MeControlComponentProps) => {
    const { displayName, onSignOutSubmitted, username, userPhotoUrl, ariaLabel } = props;

    // Style hooks
    const meControlButtonStyles = useMeControlButtonStyles();
    const meControlContainerStyles = useMeControlContainerStyles();
    const stackStyles = useStackStyles();

    // Memoized data
    const menuProps = React.useMemo<IContextualMenuProps>(
        () => ({
            directionalHint: DirectionalHint.bottomRightEdge,
            items: [
                {
                    key: 'me-control-submenu',
                    onRender: () => (
                        <MeControlSubmenu
                            displayName={displayName}
                            onSignOutSubmitted={onSignOutSubmitted}
                            username={username}
                            userPhotoUrl={userPhotoUrl}
                        />
                    ),
                },
            ],
            calloutProps: {
                minPagePadding: 0,
            },
        }),
        [displayName, onSignOutSubmitted, username, userPhotoUrl, ariaLabel]
    );

    return (
        <CommandBarButton
            id="TitleBar_MeControl"
            menuProps={menuProps}
            styles={meControlButtonStyles}
            aria-label={ariaLabel}
        >
            <div className={mergeClasses(stackStyles.root, meControlContainerStyles.root)}>
                <div className={stackStyles.item}>
                    <Persona
                        hidePersonaDetails
                        imageAlt={displayName}
                        imageUrl={userPhotoUrl}
                        size={PersonaSize.size32}
                        text={displayName}
                    />
                </div>
            </div>
        </CommandBarButton>
    );
});

interface MeControlContainerProps {
    ariaLabel: string;
}

const MeControlContainer: React.FC<MeControlContainerProps> = (props: MeControlContainerProps) => {
    const { ariaLabel } = props;

    // Action hooks
    const onSignOutSubmitted = useActionCreator(signOut);

    // Application state hooks
    const displayName = useSelector(getUserDisplayName);
    const username = useSelector(getUsername);
    const userPhotoUrl = useSelector(getUserPhotoUrl);

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: displayName and username should always be defined if MeControl is rendered, as isSignedIn should be true
    return (
        <MeControlComponent
            ariaLabel={ariaLabel}
            displayName={displayName!}
            onSignOutSubmitted={onSignOutSubmitted}
            username={username!}
            userPhotoUrl={userPhotoUrl}
        />
    );
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

const MeControlSubmenu: React.FC<MeControlSubmenuProps> = (props: MeControlSubmenuProps) => {
    const { displayName, onSignOutSubmitted, username, userPhotoUrl } = props;

    // Style hooks
    const meControlRootStyles = useMeControlRootStyles();
    const meControlSubmenuStyles = useMeControlSubmenuStyles();
    const personaCoinStyles = usePersonaCoinStyles();

    // Memoized data
    const signOutMessageValues = React.useMemo(
        () => ({
            Link: (chunks: string) => <Link onClick={onSignOutSubmitted}>{chunks}</Link>,
        }),
        []
    );

    // TODO: add link to profile, a la Managed Labs? Does this exist for dev center?
    const activityDescription = React.useMemo(
        () => [
            <div className={meControlSubmenuStyles.name} key="name">
                {displayName}
            </div>,
            <div className={meControlSubmenuStyles.username} key="username">
                {username}
            </div>,
            <div className={meControlSubmenuStyles.signOut} id="TitleBar_MeControlSubmenu_SignOut" key="signOut">
                <FormattedMessage
                    id="TitleBar_MeControlSubmenu_SignOut"
                    defaultMessage="<Link>Sign out</Link>"
                    description="Link to sign out of the portal. <Link> and </Link> should not be localized, but text between them should."
                    values={signOutMessageValues}
                />
            </div>,
        ],
        [displayName, meControlSubmenuStyles]
    );

    const onRenderIcon = React.useCallback(
        () => (
            <Persona
                hidePersonaDetails
                imageAlt={displayName}
                imageUrl={userPhotoUrl}
                size={PersonaSize.size100}
                styles={personaCoinStyles}
                text={displayName}
            />
        ),
        [displayName, personaCoinStyles, userPhotoUrl]
    );

    return (
        <ActivityItem
            activityDescription={activityDescription}
            onRenderIcon={onRenderIcon}
            styles={meControlRootStyles}
        />
    );
};

export default MeControlContainer;
