import {
    FontSizes,
    FontWeights,
    GroupedList,
    IGroup,
    IGroupHeaderProps,
    makeStyles as legacyMakeStyles,
    Panel,
    PanelType,
    SelectionMode,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { Buffer } from 'buffer';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useCustomizationDetailsPanelContext } from '../../hooks/context/panels';
import { getCustomizationGroupUriFromTaskLogDataPlaneUri } from '../../ids/customization-task-log';
import {
    CustomizationGroup,
    CustomizationGroupStatus,
    CustomizationTask,
    CustomizationTaskStatus,
} from '../../models/customization';
import { useStackWithFullWidthItemStyles } from '../../themes/styles/flexbox-styles';
import { getOverallCustomizationGroup } from '../../utilities/customization-group';
import { entries } from '../../utilities/serializable-map';
import { getSemanticColor } from '../../utilities/styles';
import { getMillisecondsBetween } from '../../utilities/time';
import { wingetBase64ParameterName } from '../common/winget-configuration/models';
import { isWingetConfiguration } from '../common/winget-configuration/selectors';
import { WingetConfigurationDetails } from '../common/winget-configuration/winget-configuration-details';
import CustomizationTaskDetailsContainer from './customization-task-details';

export interface CustomizationDetailsPanelProps {
    devBoxName?: string;
    customizationGroup: CustomizationGroup;
    customizationGroups?: CustomizationGroup[];
    isOpen: boolean;
    onDismiss: () => void;
}

const customizationDetailsPanelMessages = defineMessages({
    closeButtonAriaLabel: {
        id: 'CustomizationDetailsPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the close button in the "Customization details" panel',
    },
    titleText: {
        id: 'CustomizationDetailsPanel_Title_Text',
        defaultMessage: 'Customization details',
        description: 'Title of the "Customization details" panel',
    },
});

/**
 * Styles
 */

const usePanelStyles = legacyMakeStyles((theme) => ({
    header: {
        marginBottom: '33px',
    },
    titleName: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
    },
    infoText: {
        color: getSemanticColor(theme, 'customizationDetailsPanelInfoText'),
    },
}));

const useGroupListStyles = legacyMakeStyles((theme) => ({
    expandedTask: {
        backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
        color: getSemanticColor(theme, 'expandedCustomizationTaskText'),
        padding: '4px 8px 10px 32px',
        fontFamily: 'Lucida Console',
        fontSize: FontSizes.size12,
    },
    taskDetails: {
        fontSize: FontSizes.size12,
        wordBreak: 'break-all',
    },
    wingetDetails: {
        backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
        color: getSemanticColor(theme, 'expandedCustomizationTaskText'),
        fontFamily: 'Lucida Console',
        fontSize: FontSizes.size12,
    },
    parameterName: {
        fontWeight: FontWeights.bold,
    },
}));

const useBodyStyles = makeStyles({
    root: {
        gap: '20px',
    },
});

/**
 * END Styles
 */

const CustomizationDetailsPanelComponent: React.FC<CustomizationDetailsPanelProps> = (
    props: CustomizationDetailsPanelProps
) => {
    const { devBoxName, isOpen, onDismiss, customizationGroup, customizationGroups } = props;
    const { uri } = customizationGroup;

    const group = getOverallCustomizationGroup(customizationGroups ?? []);
    const { tasks, status, startTime, endTime } = group;

    // Intl hooks
    const { formatMessage, formatNumber } = useIntl();

    // Style hooks
    const panelStyles = usePanelStyles();
    const groupListStyles = useGroupListStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();

    const taskList: IGroup[] = React.useMemo(() => {
        return tasks
            ? tasks.map((task, index) => ({
                  count: 1,
                  /* eslint-disable @typescript-eslint/no-non-null-assertion */
                  // Justification: task.id should always be defined, this is just a spec artifact
                  key: task.id!,
                  /* eslint-enable @typescript-eslint/no-non-null-assertion */
                  name: task.displayName ?? task.name,
                  startIndex: index,
                  isCollapsed: true,
              }))
            : [];
    }, [tasks]);

    const tasksCompleted = React.useMemo(
        () => (tasks ? tasks.filter((task) => task.status === CustomizationTaskStatus.Succeeded) : []),
        [tasks]
    );

    const tasksFailed = React.useMemo(
        () =>
            tasks
                ? tasks.filter(
                      (task) =>
                          task.status === CustomizationTaskStatus.Failed ||
                          task.status === CustomizationTaskStatus.FailedValidation
                  )
                : [],
        [tasks]
    );

    const completionTime = React.useMemo(
        () => (endTime && startTime ? getMillisecondsBetween(startTime, endTime) / 1000 : 0),
        [endTime, startTime]
    );

    const tasksCompleteValues = React.useMemo(
        () => ({
            tasksCompleted: formatNumber(tasksCompleted.length),
            tasksFailed: formatNumber(tasksFailed.length),
            completionTime: formatNumber(completionTime, { style: 'unit', unit: 'second', unitDisplay: 'narrow' }),
        }),
        [tasksFailed, tasksCompleted, completionTime]
    );

    const infoText = React.useMemo(() => {
        if (status === CustomizationGroupStatus.NotStarted) {
            return (
                <FormattedMessage
                    id="CustomizationDetailsPanel_PreparingToApplyCustomizations_Text"
                    defaultMessage="Preparing to apply the following customizations:"
                />
            );
        }

        if (status === CustomizationGroupStatus.Running) {
            return (
                <FormattedMessage
                    id="CustomizationDetailsPanel_ApplyingCustomizations_Text"
                    defaultMessage="We're applying the following customizations:"
                    description='Info message for applying customizations in "Customization details" panel'
                />
            );
        }

        if (tasksCompleted.length === 1) {
            return (
                <FormattedMessage
                    id="CustomizationDetailsPanel_SingleCustomizationTaskComplete_Text"
                    defaultMessage="{tasksCompleted} task completed and {tasksFailed} failed in {completionTime}"
                    description="Message informing that one task completed and how many tasks failed in what amount of time"
                    values={tasksCompleteValues}
                />
            );
        }

        return (
            <FormattedMessage
                id="CustomizationDetailsPanel_MultipleCustomizationTasksComplete_Text"
                defaultMessage="{tasksCompleted} tasks completed and {tasksFailed} failed in {completionTime}"
                description="Message informing how many tasks completed and how many tasks failed in what amount of time"
                values={tasksCompleteValues}
            />
        );
    }, [formatMessage, status, tasksCompleteValues, tasksCompleted]);

    const onRenderParameters = React.useCallback(
        (parameters) => {
            const parametersEntries = entries(parameters);

            if (parametersEntries.length === 1 && parametersEntries[0][0] === wingetBase64ParameterName) {
                const base64String = String(parametersEntries[0][1]);
                const decodedString = Buffer.from(base64String, 'base64').toString('utf8');

                if (isWingetConfiguration(decodedString)) {
                    return <WingetConfigurationDetails style={groupListStyles.wingetDetails} content={decodedString} />;
                }
            }

            return parametersEntries.map((param, index) => (
                <div key={index} className={groupListStyles.taskDetails}>
                    <span className={groupListStyles.parameterName}>
                        <FormattedMessage
                            id="CustomizationDetailsPanel_CustomizationTaskParameterName_Text"
                            defaultMessage="{parameterName}:"
                            description="Parameter name for a customization task with parameters. {parameterName} should not be localized."
                            values={{ parameterName: param[0] }}
                        />
                    </span>{' '}
                    <FormattedMessage
                        id="CustomizationDetailsPanel_CustomizationTaskParameterValue_Text"
                        defaultMessage="{parameterValue}"
                        description="Parameter value for a customization task with parameters. {parameterValue} should not be localized."
                        values={{ parameterValue: param[1] }}
                    />
                </div>
            ));
        },
        [formatMessage, groupListStyles]
    );

    const onRenderCell = React.useCallback(
        (_nestingDepth?: number, item?: CustomizationTask, itemIndex?: number) => {
            return item?.parameters ? (
                <div className={groupListStyles.expandedTask} data-selection-index={itemIndex}>
                    {onRenderParameters(item.parameters)}
                </div>
            ) : null;
        },
        [onRenderParameters, groupListStyles]
    );

    const findTask = React.useCallback((group: IGroup) => tasks?.find((task) => task.id === group.key), [tasks]);

    const getCustomizationGroupUriForTask = React.useCallback(
        (group: IGroup | undefined) => {
            const task = tasks?.find((task) => task.id === group?.key);
            const taskLogUri = task?.logUri;

            // TODO: Update the store to fetch all the customizationGroups instead of just the "initial" after creating a devbox so that taskLogUri is available
            if (!taskLogUri) {
                // This will happen if the customizationGroup has just been created and the taskLogUri hasn't been created yet.
                // Fallback to uri
                return uri;
            }

            return getCustomizationGroupUriFromTaskLogDataPlaneUri(taskLogUri);
        },
        [uri, tasks]
    );

    const onRenderHeader = React.useCallback(
        (props?: IGroupHeaderProps): JSX.Element => (
            <CustomizationTaskDetailsContainer
                groupHeaderProps={props}
                task={props?.group ? findTask(props?.group) : undefined}
                customizationGroupUri={getCustomizationGroupUriForTask(props?.group)}
            />
        ),
        [findTask, getCustomizationGroupUriForTask]
    );

    const groupProps = React.useMemo(() => {
        return { onRenderHeader };
    }, []);

    return (
        <Panel
            closeButtonAriaLabel={formatMessage(customizationDetailsPanelMessages.closeButtonAriaLabel)}
            customWidth="456px"
            headerText={formatMessage(customizationDetailsPanelMessages.titleText)}
            isLightDismiss
            isOpen={isOpen}
            onDismiss={onDismiss}
            styles={panelStyles}
            type={PanelType.custom}
        >
            <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                <div className={stackStyles.item}>
                    <div className={panelStyles.titleName}>{devBoxName}</div>
                    <div className={panelStyles.infoText}>{infoText}</div>
                </div>
                <div className={stackStyles.item}>
                    {tasks && (
                        <GroupedList
                            items={tasks}
                            onRenderCell={onRenderCell}
                            groups={taskList}
                            compact
                            selectionMode={SelectionMode.none}
                            groupProps={groupProps}
                        />
                    )}
                </div>
            </div>
        </Panel>
    );
};

const CustomizationDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useCustomizationDetailsPanelContext();

    return <CustomizationDetailsPanelComponent isOpen={isOpen} onDismiss={closePanel} {...properties} />;
};

export const CustomizationDetailsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useCustomizationDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <CustomizationDetailsPanelContainer />;
};

export default CustomizationDetailsPanelContextWrapper;
