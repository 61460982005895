import { createDevBoxDataPlaneUri } from '../../ids/dev-box';
import { getTokensFromSnapshotDataPlaneUri } from '../../ids/snapshot';
import { Status } from '../../models/common';
import { snapshotAdapter } from '../adapters/snapshot-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createArrayFromEntitiesSelector, createGroupedMapFromEntitiesSelector } from './common';

/**
 * Basic selectors
 */

export const getStatusForListSnapshots: StoreStateSelector<Status> = (store) =>
    store.snapshotStore.status.listSnapshots;

/**
 * Entity state selectors
 */

const snapshotSelectors = snapshotAdapter.getSelectors<StoreState>((store) => store.snapshotStore.data.snapshots);

/**
 * Composed selectors
 */

export const getSnapshots = createArrayFromEntitiesSelector(snapshotSelectors.selectAll);

export const getSnapshotsByDevBox = createGroupedMapFromEntitiesSelector(snapshotSelectors.selectAll, (entity) => {
    const { id } = entity;
    const { devBoxName, devCenter, projectName, user } = getTokensFromSnapshotDataPlaneUri(id);

    return createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user });
});
