import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Checkbox } from '../../../common/form/checkbox';

interface AddOrUpdateExpirationFormEnableControlProps {
    expirationEnabled: boolean;
    onChange: (expirationEnabled: boolean | 'mixed') => void;
    isSubmitting: boolean;
}

const messages = defineMessages({
    expirationEnabledLabel: {
        id: 'AddOrUpdateExpirationFormEnableControl_Checkbox_Title',
        defaultMessage: 'Expiration',
        description: 'Title of the expiration checkbox',
    },
    expirationEnabledCheckboxLabel: {
        id: 'AddOrUpdateExpirationFormEnableControl_Checkbox_Label',
        defaultMessage: 'Enable scheduled deletion',
        description: 'Label for the expiration checkbox',
    },
    expirationEnabledCheckboxAriaLabel: {
        id: 'AddOrUpdateExpirationFormEnableControl_Checkbox_AriaLabel',
        defaultMessage: 'Enable scheduled deletion for environment',
        description: 'Aria label for the expiration checkbox',
    },
});

export const AddOrUpdateExpirationFormEnableControl: React.FC<AddOrUpdateExpirationFormEnableControlProps> = (
    props: AddOrUpdateExpirationFormEnableControlProps
) => {
    const { expirationEnabled, onChange, isSubmitting } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <Checkbox
            label={formatMessage(messages.expirationEnabledLabel)}
            checkboxLabel={formatMessage(messages.expirationEnabledCheckboxLabel)}
            checkboxAriaLabel={formatMessage(messages.expirationEnabledCheckboxAriaLabel)}
            onChangeHandler={onChange}
            value={expirationEnabled}
            disabled={isSubmitting}
        />
    );
};

export default AddOrUpdateExpirationFormEnableControl;
