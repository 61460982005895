import { defineMessages } from 'react-intl';

export const selectDevBoxImageDetailsListMessages = defineMessages({
    filterInputPlaceholder: {
        id: 'SelectDevBoxImageDetailsList_FilterInput_Placeholder',
        defaultMessage: 'Filter for any field...',
        description: 'Text for the select dev box image details list filter input placeholder',
    },
    filterInputAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_FilterInput_AriaLabel',
        defaultMessage: 'Filter for any field...',
        description: 'Aria label for the select dev box image details list filter input',
    },
    nameColumnHeaderText: {
        id: 'SelectDevBoxImageDetailsList_NameColumnHeader_Text',
        defaultMessage: 'Name',
        description: 'Text for the select dev box image details list name column',
    },
    nameColumnHeaderAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_NameColumnHeader_AriaLabel',
        defaultMessage: 'Name',
        description: 'Aria label for the select dev box image details list name column',
    },
    sourceImageColumnHeaderText: {
        id: 'SelectDevBoxImageDetailsList_SourceImageColumnHeader_Text',
        defaultMessage: 'Source image',
        description: 'Text for the select dev box image details list source image column',
    },
    sourceImageColumnHeaderAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_SourceImageColumnHeader_AriaLabel',
        defaultMessage: 'Source image',
        description: 'Aria Label for the select dev box image details list source image column',
    },
    versionColumnHeaderText: {
        id: 'SelectDevBoxImageDetailsList_VersionColumnHeader_Text',
        defaultMessage: 'Version',
        description: 'Text for the "select dev box image" details list source image version column',
    },
    versionColumnHeaderAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_VersionColumnHeader_AriaLabel',
        defaultMessage: 'Version',
        description: 'Aria label for the "select dev box image" details list source image version column',
    },
    lastImageUpdateColumnHeaderText: {
        id: 'SelectDevBoxImageDetailsList_LastImageUpdateColumnHeader_Text',
        defaultMessage: 'Last image update',
        description: 'Text for the select dev box image details list last image update column',
    },
    lastImageUpdateColumnHeaderAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_LastImageUpdateColumnHeader_AriaLabel',
        defaultMessage: 'Last image update',
        description: 'Aria Label for the select dev box image details list last image update column',
    },
    sortAscendingOrderAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_SortAscendingOrder_AriaLabel',
        defaultMessage: 'Sorted from A to Z',
        description: 'Aria Label for the select dev box image details list sorting ascending order',
    },
    sortDescendingOrderAriaLabel: {
        id: 'SelectDevBoxImageDetailsList_SortDescendingOrder_AriaLabel',
        defaultMessage: 'Sorted from Z to A',
        description: 'Aria Label for the select dev box image details list sorting descending order',
    },
});
