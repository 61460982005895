import { DevBoxFailureReason } from '../constants/dev-box';
import { DevBoxContract, DevBoxSnapshotContract, RemoteConnectionContract } from '../data/contracts/dev-box';
import {
    DevBoxOperationContract,
    DevBoxOperationKindContract,
    DevBoxRepairOperationContract,
    DevBoxRepairOperationResultContract,
    DevBoxRestoreOperationContract,
} from '../data/contracts/dev-box-operation';
import { Failure } from '../models/common';
import { UnionMap } from '../types/union-map';

/**
 * Application models
 */

export type DevBox = DevBoxContract;

export type DevBoxSnapshot = DevBoxSnapshotContract & { uri: string };

export type DevBoxOperation = DevBoxOperationContract;

export type DevBoxOperationKind = DevBoxOperationKindContract;

export const DevBoxOperationKind: UnionMap<DevBoxOperationKind> = {
    ...DevBoxOperationKindContract,
};

export type DevBoxRepairOperation = DevBoxRepairOperationContract;
export type DevBoxRepairOperationResult = DevBoxRepairOperationResultContract;

export type DevBoxRestoreOperation = DevBoxRestoreOperationContract;

export interface FailureOnDevBox {
    failure: Failure;
    reason: DevBoxFailureReason;
}

export type RemoteConnection = RemoteConnectionContract;

/**
 * Type guards
 */

export const isDevBoxRepairOperation = (operation: DevBoxOperation): operation is DevBoxRepairOperation =>
    operation.kind === DevBoxOperationKind.Repair;
