import { Link } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Banner from '../../banner';

const surveyLink = 'https://aka.ms/devbox/devportal-survey';

export type PortalSurveyBannerProps = {
    isDismissed: boolean;
    isExpired: boolean;
    onDismiss: () => void;
};

const surveyMessageValue = () => ({
    Link: (chunks: string) => (
        <Link href={surveyLink} target="_blank">
            {chunks}
        </Link>
    ),
});

export const PortalSurveyBanner: React.FC<PortalSurveyBannerProps> = React.memo((props: PortalSurveyBannerProps) => {
    const { isDismissed, isExpired, onDismiss } = props;

    // User has closed the banner we will not reshow the banner
    if (isDismissed) {
        return <></>;
    }

    // Message is now past the expiration date
    if (isExpired) {
        return <></>;
    }

    return (
        <Banner intent="info" onDismiss={onDismiss}>
            <FormattedMessage
                id="PortalSurveyBanner_Message_Text"
                defaultMessage="Please share your Dev Box and Azure Deployment Environments feedback to help the product team improve your experience. <Link>Share Feedback</Link>"
                description="Message asking user to fill out survey, <Link></Link> tags are not to be localized."
                values={surveyMessageValue()}
            />
        </Banner>
    );
});

export default PortalSurveyBanner;
