import { MessageBarButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';

export interface ErrorBannerActionProps {
    title: string;
    onClick: () => void;
}

interface ErrorBannerActionsProps {
    onRetry?: () => void;
    onSeeDetails?: () => void;
    retryable?: boolean;
    additionalActions?: ErrorBannerActionProps[];
}

const messages = defineMessages({
    retryButtonAriaLabel: {
        id: 'ErrorBannerActions_RetryButton_AriaLabel',
        defaultMessage: 'Retry',
        description: 'Aria label of the "Retry" button in the error banner',
    },
    seeDetailsButtonAriaLabel: {
        id: 'ErrorBannerActions_SeeDetailsButton_AriaLabel',
        defaultMessage: 'See details',
        description: 'Aria label of the "See details" button in the error banner',
    },
});

/**
 * Styles
 */

const useErrorStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * End Styles
 */

export const ErrorBannerActions: React.FC<ErrorBannerActionsProps> = React.memo((props: ErrorBannerActionsProps) => {
    const { onRetry, onSeeDetails, retryable, additionalActions } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    //Style hooks
    const horizontalStackStyles = useHorizontalStackStyles();
    const errorStyles = useErrorStyles();

    const showSeeDetailsButton = !!onSeeDetails;
    const showRetryButton = retryable && onRetry;

    // Render nothing rather than an empty stack if no buttons would end up being rendered
    if (!showSeeDetailsButton && !showRetryButton && !additionalActions) {
        return <></>;
    }

    return (
        <div className={mergeClasses(horizontalStackStyles.root, errorStyles.root)}>
            {onSeeDetails && (
                <div className={horizontalStackStyles.item}>
                    <MessageBarButton
                        ariaLabel={formatMessage(messages.seeDetailsButtonAriaLabel)}
                        onClick={onSeeDetails}
                    >
                        <FormattedMessage
                            id="ErrorBannerActions_SeeDetailsButton_Text"
                            defaultMessage="See details"
                            description="Text of the 'See details' button in the error banner"
                        />
                    </MessageBarButton>
                </div>
            )}

            {retryable && onRetry && (
                <div className={horizontalStackStyles.item}>
                    <MessageBarButton ariaLabel={formatMessage(messages.retryButtonAriaLabel)} onClick={onRetry}>
                        <FormattedMessage
                            id="ErrorBannerActions_RetryButton_Text"
                            defaultMessage="Retry"
                            description="Text of the 'Retry' button in the error banner"
                        />
                    </MessageBarButton>
                </div>
            )}

            {additionalActions &&
                additionalActions.map((action, index) => (
                    <div className={horizontalStackStyles.item} key={`additional-action-${index}`}>
                        <MessageBarButton ariaLabel={action.title} onClick={action.onClick}>
                            {action.title}
                        </MessageBarButton>
                    </div>
                ))}
        </div>
    );
});

export default ErrorBannerActions;
