import { Label, makeStyles, mergeClasses } from '@fluentui/react-components';
import {
    formatDateToTimeString,
    TimePicker,
    TimeSelectionData,
    TimeSelectionEvents,
} from '@fluentui/react-timepicker-compat';
import React from 'react';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { getIs12HourTimeFormat, getTimeStringFromDate } from '../../../utilities/time';

export interface Props {
    value?: Date;
    locale: string;
    ariaLabel?: string;
    onChange: (value: Date | undefined) => void;
    placeholder: string;
    disabled?: boolean;
    label: string;
}

/**
 * Styles
 */

const useTimePickerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const pickerStyle: React.CSSProperties = {
    width: '100%',
};

/**
 * END Styles
 */

export const ExpirationTimePicker: React.FC<Props> = (props: Props) => {
    const { locale, placeholder, onChange, disabled, value, label, ariaLabel } = props;

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const timePickerStyles = useTimePickerStyles();

    const isLocaleIn12HourFormat = React.useMemo(() => getIs12HourTimeFormat(locale), [locale]);

    const onFormatTimePicker = React.useCallback(
        (date: Date) => {
            return getTimeStringFromDate(date, locale) ?? '';
        },
        [locale]
    );

    const onChangeHandler = React.useCallback(
        (_event: TimeSelectionEvents, data: TimeSelectionData) => {
            onChange(data.selectedTime ?? undefined);
        },
        [onChange]
    );
    return (
        <div className={mergeClasses(stackStyles.root, timePickerStyles.root)}>
            <div className={stackStyles.item}>
                <Label>{label}</Label>
            </div>
            <div className={stackStyles.item}>
                <TimePicker
                    increment={15}
                    freeform={false}
                    autoComplete="on"
                    hourCycle={isLocaleIn12HourFormat ? 'h12' : 'h23'}
                    value={value ? formatDateToTimeString(value) : ''}
                    formatDateToTimeString={onFormatTimePicker}
                    onTimeChange={onChangeHandler}
                    placeholder={placeholder}
                    disabled={disabled}
                    aria-label={ariaLabel ?? label}
                    style={pickerStyle}
                />
            </div>
        </div>
    );
};

export default ExpirationTimePicker;
