import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DismissableContentName, QuickActionName } from '../../constants/app';
import { OperatingSystemFamily } from '../../constants/browser';
import { useActionCreator } from '../../hooks/action-creator';
import {
    useConfigureRemoteDesktopDialogContext,
    useConfirmationDialogContext,
    useConnectViaAppDialogContext,
    useDelayShutdownDialogContext,
    useHibernatePreviewDialogContext,
    useRepairDialogContext,
    useRestoreSnapshotDialogContext,
} from '../../hooks/context/dialogs';
import { useProjectFilterContext } from '../../hooks/context/filters';
import {
    useCustomizationDetailsPanelContext,
    useDevBoxDetailsPanelContext,
    useDevBoxSupportPanelContext,
    useErrorDetailsPanelContext,
} from '../../hooks/context/panels';
import { ExtendedIcon } from '../../icons/initialize-extended-icons';
import {
    dismissContent as dismissContentActionCreator,
    dismissQuickAction,
} from '../../redux/actions/application/application-action-creators';
import {
    delayAllDevBoxActions,
    skipAllDevBoxActions,
} from '../../redux/actions/dev-box-action/dev-box-action-action-creators';
import {
    deleteDevBox,
    hibernateDevBox,
    repairDevBox,
    restartDevBox,
    restoreSnapshot,
    resumeDevBox,
    shutdownDevBox,
    startDevBox,
} from '../../redux/actions/dev-box/dev-box-action-creators';
import { getRemoteConnection } from '../../redux/actions/remote-connection/remote-connection-action-creators';
import { getLocale } from '../../redux/selector/localization-selectors';
import { getDismissedQuickActionsForHome } from '../../redux/selector/sub-applications/home-selectors';
import { CssSelector } from '../../themes/constants/css-selectors';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { ReturnVoid } from '../../types/return-void';
import { SerializableSet } from '../../types/serializable-set';
import { operatingSystemFamily } from '../../utilities/browser';
import { ConfirmationDialogProperties } from '../common/confirmation-dialog/contexts';
import { CustomizationDetailsPanelContextProperties } from '../customization-details-panel/contexts';
import { ErrorDetailsPanelContextProperties } from '../error-details-panel/contexts';
import { OpenConnectViaAppDialogProperties } from '../user-settings/connect-via-app-dialog/contexts';
import { DelayShutdownDialogProperties } from './delay-shutdown-dialog/contexts';
import DevBoxCard from './dev-box-card/dev-box-card';
import { DevBoxDetailsPanelContextProperties } from './dev-box-details-panel/context';
import { DevBoxSupportPanelContextProperties } from './dev-box-support-panel/context';
import { HibernatePreviewDialogProperties } from './hibernate-preview-dialog/context';
import { DevBoxViewModel } from './models';
import QuickActionCard from './quick-action-card';
import { RepairDialogProperties } from './repair-dialog/context';
import { RestoreSnapshotDialogProperties } from './restore-snapshot-dialog/contexts';
import {
    getDevBoxViewModels,
    getDevBoxViewModelsInProject,
    getShowConfigureRemoteDesktopQuickAction,
    getShowQuickActionsSection,
    isHibernatePreviewDialogDismissed as isHibernatePreviewDialogDismissedSelector,
} from './selectors';

interface DevBoxesComponentProps {
    devBoxes: DevBoxViewModel[];
    selectedProjectFilter: string | undefined;
    dismissedQuickActions: SerializableSet<QuickActionName>;
    locale: string;
    onDeleteDevBoxSubmitted: ReturnVoid<typeof deleteDevBox>;
    onDismissQuickAction: ReturnVoid<typeof dismissQuickAction>;
    onGetRemoteConnectionForDevBoxSubmitted: ReturnVoid<typeof getRemoteConnection>;
    onOpenErrorPanel: (props: ErrorDetailsPanelContextProperties) => void;
    onOpenDevBoxDetailsPanel: (props: DevBoxDetailsPanelContextProperties) => void;
    onOpenDevBoxSupportPanel: (props: DevBoxSupportPanelContextProperties) => void;
    onStartDevBoxSubmitted: ReturnVoid<typeof startDevBox>;
    onDelayAllSubmitted: ReturnVoid<typeof delayAllDevBoxActions>;
    onSkipAllSubmitted: ReturnVoid<typeof skipAllDevBoxActions>;
    operatingSystemFamily: OperatingSystemFamily;
    onOpenConfigureRemoteDesktopDialog: () => void;
    onOpenDelayShutdownDialog: (properties: DelayShutdownDialogProperties) => void;
    onOpenRestoreSnapshotDialog: (properties: RestoreSnapshotDialogProperties) => void;
    onOpenConnectViaAppDialog: (properties: OpenConnectViaAppDialogProperties) => void;
    onOpenConfirmationDialog: (properties: ConfirmationDialogProperties) => void;
    onHibernateDevBoxSubmitted: ReturnVoid<typeof hibernateDevBox>;
    onShutdownDevBoxSubmitted: ReturnVoid<typeof shutdownDevBox>;
    onRestartDevBoxSubmitted: ReturnVoid<typeof restartDevBox>;
    onResumeDevBoxSubmitted: ReturnVoid<typeof resumeDevBox>;
    onRepairDevBoxSubmitted: ReturnVoid<typeof repairDevBox>;
    onRestoreSnapshotSubmitted: ReturnVoid<typeof restoreSnapshot>;
    onOpenHibernatePreviewDialog: (properties: HibernatePreviewDialogProperties) => void;
    onOpenRepairDialog: (properties: RepairDialogProperties) => void;
    onOpenCustomizationPanel: (props: CustomizationDetailsPanelContextProperties) => void;
    isHibernatePreviewDialogDismissed: boolean;
}

const messages = defineMessages({
    yourDevBoxMessage: {
        id: 'DevBoxes_YourDevBoxMessage_Text',
        defaultMessage: 'Your dev box',
        description: 'This text is informing the user that their dev box is listed on this page',
    },
    yourDevBoxesPluralMessage: {
        id: 'DevBoxes_YourDevBoxPluralMessage_Text',
        defaultMessage: 'Your dev boxes',
        description: 'This text is informing the user that their dev boxes are listed on this page',
    },
});

/**
 * Styles
 */

const useGridStyles = makeStyles({
    root: {
        width: '100%',
        flexWrap: 'wrap',
    },
});

const useGridItemStyles = makeStyles({
    root: {
        marginBottom: '24px',
        marginRight: '12px',

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },

        [CssSelector.ScreenSizeXXLarge]: {
            marginRight: '24px',
        },
    },
});

const useSectionContentStyles = makeStyles({
    root: {
        width: 'auto',

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },
    },
});

const useSectionHeaderStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
    },
    h2: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '26px',
    },
});

const useSectionStyles = makeStyles({
    root: {
        gap: '11px',
        alignItems: 'start',
    },
});

/**
 * End Styles
 */

export const DevBoxesComponent: React.FC<DevBoxesComponentProps> = React.memo((props: DevBoxesComponentProps) => {
    const {
        devBoxes,
        selectedProjectFilter,
        dismissedQuickActions,
        locale,
        onDeleteDevBoxSubmitted,
        onDismissQuickAction,
        onGetRemoteConnectionForDevBoxSubmitted,
        onOpenErrorPanel,
        onOpenDevBoxDetailsPanel,
        onStartDevBoxSubmitted,
        onDelayAllSubmitted,
        onSkipAllSubmitted,
        operatingSystemFamily,
        onOpenConfigureRemoteDesktopDialog,
        onOpenDelayShutdownDialog,
        onOpenRestoreSnapshotDialog,
        onOpenConfirmationDialog,
        onHibernateDevBoxSubmitted,
        onShutdownDevBoxSubmitted,
        onRestartDevBoxSubmitted,
        onResumeDevBoxSubmitted,
        onRepairDevBoxSubmitted,
        onRestoreSnapshotSubmitted,
        onOpenHibernatePreviewDialog,
        onOpenRepairDialog,
        onOpenCustomizationPanel,
        onOpenDevBoxSupportPanel,
        onOpenConnectViaAppDialog,
        isHibernatePreviewDialogDismissed: isHibernatePreviewMessageDismissed,
    } = props;

    // Style hooks
    const gridStyles = useGridStyles();
    const gridItemStyles = useGridItemStyles();
    const sectionContentStyles = useSectionContentStyles();
    const sectionHeaderStyles = useSectionHeaderStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();
    const sectionStyles = useSectionStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    // Action Creators
    const dismissContent = useActionCreator(dismissContentActionCreator);

    // Callback hooks
    const onConfigureRemoteDesktopActionDismissed = React.useCallback(() => {
        onDismissQuickAction({ quickAction: QuickActionName.ConfigureRemoteDesktop });
    }, [onDismissQuickAction]);

    const onDelayShutdownSubmitted = React.useCallback(
        (id: string, delayUntil: Date) => {
            onDelayAllSubmitted({ delayUntil, id });
        },
        [onDelayAllSubmitted]
    );

    const onConnectViaAppTeachBubbleDismiss = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.ConnectViaAppTeachableBubble });
    }, [dismissContent]);

    const onConnectViaAppTeachingPopoverForWelcomeTourDismiss = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.ConnectViaAppTeachingPopoverForWelcomeTour });
    }, [dismissContent]);

    const onConnectViaAppTeachingPopoverForWelcomeTourSkipped = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.ConnectViaAppTeachingPopoverForWelcomeTourSkipped });
    }, [dismissContent]);

    const onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.DevboxSecondaryActionsTeachingPopoverForWelcomeTour });
    }, [dismissContent]);

    const onDeleteSubmitted = React.useCallback(
        (id: string) => {
            onDeleteDevBoxSubmitted({ id });
        },
        [onDeleteDevBoxSubmitted]
    );

    const onGetRemoteConnectionSubmitted = React.useCallback(
        (id: string) => {
            onGetRemoteConnectionForDevBoxSubmitted({ id });
        },
        [onGetRemoteConnectionForDevBoxSubmitted]
    );

    const onHibernateSubmitted = React.useCallback(
        (id: string) => {
            onHibernateDevBoxSubmitted({ id });
        },
        [onHibernateDevBoxSubmitted]
    );

    const onRepairSubmitted = React.useCallback(
        (id: string) => {
            onRepairDevBoxSubmitted({ id });
        },
        [onRepairDevBoxSubmitted]
    );

    const onRestartSubmitted = React.useCallback(
        (id: string) => {
            onRestartDevBoxSubmitted({ id });
        },
        [onRestartDevBoxSubmitted]
    );

    const onResumeSubmitted = React.useCallback(
        (id: string) => {
            onResumeDevBoxSubmitted({ id });
        },
        [onResumeDevBoxSubmitted]
    );

    const onRestoreSubmitted = React.useCallback(
        (id: string, snapshotId: string) => {
            onRestoreSnapshotSubmitted({ id, snapshotId });
        },
        [onRestoreSnapshotSubmitted]
    );

    const onShutdownSubmitted = React.useCallback(
        (id: string) => {
            onShutdownDevBoxSubmitted({ id });
        },
        [onShutdownDevBoxSubmitted]
    );

    const onSkipShutdownSubmitted = React.useCallback(
        (id: string) => {
            onSkipAllSubmitted({ id });
        },
        [onSkipAllSubmitted]
    );

    const onStartSubmitted = React.useCallback(
        (id: string) => {
            onStartDevBoxSubmitted({ id });
        },
        [onStartDevBoxSubmitted]
    );

    // Memoized data
    const showConfigureRemoteDesktopQuickAction = React.useMemo(
        () => getShowConfigureRemoteDesktopQuickAction(dismissedQuickActions, operatingSystemFamily),
        [dismissedQuickActions, operatingSystemFamily]
    );

    const showQuickActionsSection = React.useMemo(
        () => getShowQuickActionsSection(dismissedQuickActions, operatingSystemFamily),
        [dismissedQuickActions, operatingSystemFamily]
    );

    const filteredDevBoxes = React.useMemo(
        () => (selectedProjectFilter ? getDevBoxViewModelsInProject(devBoxes, selectedProjectFilter) : devBoxes),
        [devBoxes, selectedProjectFilter]
    );

    const yourDevBoxMessage = React.useMemo(
        () =>
            filteredDevBoxes.length === 0
                ? undefined
                : filteredDevBoxes.length > 1
                ? messages.yourDevBoxesPluralMessage
                : messages.yourDevBoxMessage,
        [filteredDevBoxes, messages]
    );

    return (
        <>
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                {showQuickActionsSection && (
                    <div className={stackStyles.item}>
                        <div className={mergeClasses(stackStyles.root, sectionStyles.root)}>
                            <div className={mergeClasses(stackStyles.item, sectionHeaderStyles.root)}>
                                <FormattedMessage
                                    id="DevBoxes_QuickActionsHeader_Text"
                                    defaultMessage="Quick actions"
                                    description="Header text for the 'Quick Actions' section"
                                />
                            </div>

                            <div className={mergeClasses(stackStyles.item, sectionContentStyles.root)}>
                                <div className={mergeClasses(horizontalStackStyles.root, gridStyles.root)}>
                                    {showConfigureRemoteDesktopQuickAction && (
                                        <div className={mergeClasses(horizontalStackStyles.item, gridItemStyles.root)}>
                                            <QuickActionCard
                                                iconName={ExtendedIcon.RemoteApplication}
                                                onActionInvoked={onOpenConfigureRemoteDesktopDialog}
                                                onDismiss={onConfigureRemoteDesktopActionDismissed}
                                                subtitle={
                                                    <FormattedMessage
                                                        id="DevBoxes_ConfigureRemoteDesktopActionSubtitle_Text"
                                                        defaultMessage="Download and set up Remote Desktop to access dev boxes on your device."
                                                        description="Explanatory text for the 'Configure Remote Desktop' quick action card"
                                                    />
                                                }
                                                title={
                                                    <FormattedMessage
                                                        id="DevBoxes_ConfigureRemoteDesktopActionTitle_Text"
                                                        defaultMessage="Configure Remote Desktop"
                                                        description="Title of the 'Configure Remote Desktop' quick action card"
                                                    />
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, sectionStyles.root)}>
                        <div className={stackStyles.item}>
                            <h2 className={sectionHeaderStyles.h2}>
                                {yourDevBoxMessage && <FormattedMessage {...yourDevBoxMessage} />}
                            </h2>
                        </div>

                        <div className={mergeClasses(stackStyles.item, sectionContentStyles.root)}>
                            <div className={mergeClasses(horizontalStackStyles.root, gridStyles.root)}>
                                {filteredDevBoxes.map((devBox: DevBoxViewModel, index: number) => (
                                    <div
                                        className={mergeClasses(horizontalStackStyles.item, gridItemStyles.root)}
                                        key={devBox.key}
                                    >
                                        <DevBoxCard
                                            devBox={devBox}
                                            locale={locale}
                                            onDeleteSubmitted={onDeleteSubmitted}
                                            onGetRemoteConnectionSubmitted={onGetRemoteConnectionSubmitted}
                                            onOpenErrorPanel={onOpenErrorPanel}
                                            onOpenDevBoxDetailsPanel={onOpenDevBoxDetailsPanel}
                                            onStartSubmitted={onStartSubmitted}
                                            onDelayShutdownSubmitted={onDelayShutdownSubmitted}
                                            onSkipShutdownSubmitted={onSkipShutdownSubmitted}
                                            onOpenDelayShutdownDialog={onOpenDelayShutdownDialog}
                                            onOpenRestoreSnapshotDialog={onOpenRestoreSnapshotDialog}
                                            onRestoreSubmitted={onRestoreSubmitted}
                                            onOpenConnectViaAppDialog={onOpenConnectViaAppDialog}
                                            onOpenConfirmationDialog={onOpenConfirmationDialog}
                                            onHibernateSubmitted={onHibernateSubmitted}
                                            onShutdownSubmitted={onShutdownSubmitted}
                                            onResumeSubmitted={onResumeSubmitted}
                                            onRestartSubmitted={onRestartSubmitted}
                                            onRepairSubmitted={onRepairSubmitted}
                                            onOpenHibernatePreviewDialog={onOpenHibernatePreviewDialog}
                                            onOpenRepairDialog={onOpenRepairDialog}
                                            onOpenCustomizationPanel={onOpenCustomizationPanel}
                                            isHibernatePreviewDialogDismissed={isHibernatePreviewMessageDismissed}
                                            onOpenDevBoxSupportPanel={onOpenDevBoxSupportPanel}
                                            onConnectViaAppTeachBubbleDismiss={onConnectViaAppTeachBubbleDismiss}
                                            onConnectViaAppTeachingPopoverForWelcomeTourDismiss={
                                                onConnectViaAppTeachingPopoverForWelcomeTourDismiss
                                            }
                                            onConnectViaAppTeachingPopoverForWelcomeTourSkipped={
                                                onConnectViaAppTeachingPopoverForWelcomeTourSkipped
                                            }
                                            onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss={
                                                onDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismiss
                                            }
                                            shouldShowConnectViaAppTeachBubble={index === 0}
                                            shouldShowConnectViaAppTeachingPopoverForWelcomeTour={index === 0}
                                            shouldShowDevboxSecondaryActionsTeachingPopoverForWelcomeTour={index === 0}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export const DevBoxes: React.FC = () => {
    const { value } = useProjectFilterContext();

    // Application state hooks
    const devBoxes = useSelector(getDevBoxViewModels);
    const dismissedQuickActions = useSelector(getDismissedQuickActionsForHome);
    const locale = useSelector(getLocale);
    const isHibernatePreviewDialogDismissed = useSelector(isHibernatePreviewDialogDismissedSelector);

    // Action hooks
    const onDeleteDevBoxSubmitted = useActionCreator(deleteDevBox);
    const onDismissQuickAction = useActionCreator(dismissQuickAction);
    const onGetRemoteConnectionForDevBoxSubmitted = useActionCreator(getRemoteConnection);
    const onStartDevBoxSubmitted = useActionCreator(startDevBox);
    const onDelayAllSubmitted = useActionCreator(delayAllDevBoxActions);
    const onSkipAllSubmitted = useActionCreator(skipAllDevBoxActions);
    const onHibernateDevBoxSubmitted = useActionCreator(hibernateDevBox);
    const onShutdownDevBoxSubmitted = useActionCreator(shutdownDevBox);
    const onRestartDevBoxSubmitted = useActionCreator(restartDevBox);
    const onResumeDevBoxSubmitted = useActionCreator(resumeDevBox);
    const onRepairDevBoxSubmitted = useActionCreator(repairDevBox);
    const onRestoreSnapshotSubmitted = useActionCreator(restoreSnapshot);

    // Context hooks
    const { openSurface: onOpenErrorPanel } = useErrorDetailsPanelContext();
    const { openSurface: onOpenDevBoxDetailsPanel } = useDevBoxDetailsPanelContext();
    const { openSurface: onOpenDevBoxSupportPanel } = useDevBoxSupportPanelContext();
    const { openSurface: onOpenConfigureRemoteDesktopDialogContext } = useConfigureRemoteDesktopDialogContext();
    const { openSurface: onOpenDelayShutdownDialog } = useDelayShutdownDialogContext();
    const { openSurface: onOpenRestoreSnapshotDialog } = useRestoreSnapshotDialogContext();
    const { openSurface: onOpenConfirmationDialog } = useConfirmationDialogContext();
    const { openSurface: onOpenHibernatePreviewDialog } = useHibernatePreviewDialogContext();
    const { openSurface: onOpenRepairDialog } = useRepairDialogContext();
    const { openSurface: onOpenCustomizationPanel } = useCustomizationDetailsPanelContext();
    const { openSurface: onOpenConnectViaAppDialog } = useConnectViaAppDialogContext();

    return (
        <DevBoxesComponent
            devBoxes={devBoxes}
            selectedProjectFilter={value}
            dismissedQuickActions={dismissedQuickActions}
            locale={locale}
            onDeleteDevBoxSubmitted={onDeleteDevBoxSubmitted}
            onDismissQuickAction={onDismissQuickAction}
            onGetRemoteConnectionForDevBoxSubmitted={onGetRemoteConnectionForDevBoxSubmitted}
            onOpenErrorPanel={onOpenErrorPanel}
            onOpenDevBoxDetailsPanel={onOpenDevBoxDetailsPanel}
            onStartDevBoxSubmitted={onStartDevBoxSubmitted}
            onDelayAllSubmitted={onDelayAllSubmitted}
            onSkipAllSubmitted={onSkipAllSubmitted}
            operatingSystemFamily={operatingSystemFamily}
            onOpenConfigureRemoteDesktopDialog={onOpenConfigureRemoteDesktopDialogContext}
            onOpenConnectViaAppDialog={onOpenConnectViaAppDialog}
            onOpenDelayShutdownDialog={onOpenDelayShutdownDialog}
            onOpenRestoreSnapshotDialog={onOpenRestoreSnapshotDialog}
            onOpenConfirmationDialog={onOpenConfirmationDialog}
            onHibernateDevBoxSubmitted={onHibernateDevBoxSubmitted}
            onShutdownDevBoxSubmitted={onShutdownDevBoxSubmitted}
            onRestartDevBoxSubmitted={onRestartDevBoxSubmitted}
            onResumeDevBoxSubmitted={onResumeDevBoxSubmitted}
            onRepairDevBoxSubmitted={onRepairDevBoxSubmitted}
            onRestoreSnapshotSubmitted={onRestoreSnapshotSubmitted}
            onOpenHibernatePreviewDialog={onOpenHibernatePreviewDialog}
            onOpenRepairDialog={onOpenRepairDialog}
            onOpenCustomizationPanel={onOpenCustomizationPanel}
            isHibernatePreviewDialogDismissed={isHibernatePreviewDialogDismissed}
            onOpenDevBoxSupportPanel={onOpenDevBoxSupportPanel}
        />
    );
};

export default DevBoxes;
