import { ProjectContract, ProjectResourceContractProperties } from '../data/contracts/project';
import { SerializableMap } from '../types/serializable-map';
import { UnionMap } from '../types/union-map';
import { Resource, ResourceProvisioningState } from './resource-manager';

/**
 * Application models
 */

export type Project = ProjectContract;

export type ProjectResourceProperties = Omit<ProjectResourceContractProperties, 'provisioningState'> & {
    provisioningState: ResourceProvisioningState;
};

export type ProjectResource = Resource<ProjectResourceProperties>;

/**
 * Map types
 */

export type ProjectDataPlaneIdMap = SerializableMap<string>;
export type ProjectResourceMap = SerializableMap<ProjectResource>;
export type ProjectToDevCenterNameMap = SerializableMap<string>;

// TODO: add arguments to function a la serializable-map if they're ever needed
export const ProjectDataPlaneIdMap = (): ProjectDataPlaneIdMap => ({});
export const ProjectResourceMap = (): ProjectResourceMap => ({});
export const ProjectToDevCenterNameMap = (): ProjectToDevCenterNameMap => ({});

/**
 * Type guards
 */

export const isProjectResourceProperties = (value: unknown): value is ProjectResourceProperties => {
    const properties = value as ProjectResourceProperties;

    return (
        properties.description !== undefined &&
        properties.devCenterId !== undefined &&
        properties.devCenterUri !== undefined &&
        properties.provisioningState !== undefined
    );
};

export type ProjectFromDiscoveryService = {
    abilitiesAsAdmin: ProjectAbilityAsAdmin[];
    abilitiesAsDeveloper: ProjectAbilityAsDeveloper[];
    description: string;
    devCenterUri: string;
    displayName: string;
    name: string;
    uri: string;
    maxDevBoxesPerUser?: number;
};

type ProjectAbilityAsDeveloper =
    | 'CustomizeDevBoxesAsDeveloper'
    | 'DeleteDevBoxesAsDeveloper'
    | 'DeleteEnvironmentsAsDeveloper'
    | 'ManageDevBoxActionsAsDeveloper'
    | 'ManageEnvironmentActionsAsDeveloper'
    | 'ReadDevBoxActionsAsDeveloper'
    | 'ReadDevBoxesAsDeveloper'
    | 'ReadEnvironmentActionsAsDeveloper'
    | 'ReadEnvironmentOutputsAsDeveloper'
    | 'ReadEnvironmentsAsDeveloper'
    | 'ReadRemoteConnectionsAsDeveloper'
    | 'StartDevBoxesAsDeveloper'
    | 'StopDevBoxesAsDeveloper'
    | 'WriteDevBoxesAsDeveloper'
    | 'WriteEnvironmentsAsDeveloper';

type ProjectAbilityAsAdmin =
    | 'DeleteDevBoxesAsAdmin'
    | 'DeleteEnvironmentsAsAdmin'
    | 'ManageEnvironmentActionsAsAdmin'
    | 'ReadDevBoxesAsAdmin'
    | 'ReadEnvironmentActionsAsAdmin'
    | 'ReadEnvironmentOutputsAsAdmin'
    | 'ReadEnvironmentsAsAdmin'
    | 'StartDevBoxesAsAdmin'
    | 'StopDevBoxesAsAdmin'
    | 'WriteDevBoxesAsAdmin'
    | 'WriteEnvironmentsAsAdmin';

export const ProjectAbilityAsDeveloper: UnionMap<ProjectAbilityAsDeveloper> = {
    CustomizeDevBoxesAsDeveloper: 'CustomizeDevBoxesAsDeveloper',
    DeleteDevBoxesAsDeveloper: 'DeleteDevBoxesAsDeveloper',
    DeleteEnvironmentsAsDeveloper: 'DeleteEnvironmentsAsDeveloper',
    ManageDevBoxActionsAsDeveloper: 'ManageDevBoxActionsAsDeveloper',
    ManageEnvironmentActionsAsDeveloper: 'ManageEnvironmentActionsAsDeveloper',
    ReadDevBoxActionsAsDeveloper: 'ReadDevBoxActionsAsDeveloper',
    ReadDevBoxesAsDeveloper: 'ReadDevBoxesAsDeveloper',
    ReadEnvironmentActionsAsDeveloper: 'ReadEnvironmentActionsAsDeveloper',
    ReadEnvironmentOutputsAsDeveloper: 'ReadEnvironmentOutputsAsDeveloper',
    ReadEnvironmentsAsDeveloper: 'ReadEnvironmentsAsDeveloper',
    ReadRemoteConnectionsAsDeveloper: 'ReadRemoteConnectionsAsDeveloper',
    StartDevBoxesAsDeveloper: 'StartDevBoxesAsDeveloper',
    StopDevBoxesAsDeveloper: 'StopDevBoxesAsDeveloper',
    WriteDevBoxesAsDeveloper: 'WriteDevBoxesAsDeveloper',
    WriteEnvironmentsAsDeveloper: 'WriteEnvironmentsAsDeveloper',
};

export const ProjectAbilityAsAdmin: UnionMap<ProjectAbilityAsAdmin> = {
    DeleteDevBoxesAsAdmin: 'DeleteDevBoxesAsAdmin',
    DeleteEnvironmentsAsAdmin: 'DeleteEnvironmentsAsAdmin',
    ManageEnvironmentActionsAsAdmin: 'ManageEnvironmentActionsAsAdmin',
    ReadDevBoxesAsAdmin: 'ReadDevBoxesAsAdmin',
    ReadEnvironmentActionsAsAdmin: 'ReadEnvironmentActionsAsAdmin',
    ReadEnvironmentOutputsAsAdmin: 'ReadEnvironmentOutputsAsAdmin',
    ReadEnvironmentsAsAdmin: 'ReadEnvironmentsAsAdmin',
    StartDevBoxesAsAdmin: 'StartDevBoxesAsAdmin',
    StopDevBoxesAsAdmin: 'StopDevBoxesAsAdmin',
    WriteDevBoxesAsAdmin: 'WriteDevBoxesAsAdmin',
    WriteEnvironmentsAsAdmin: 'WriteEnvironmentsAsAdmin',
};
