import { UnionMap, UnionValueMap } from '../../../types/union-map';
import { MockScenarioConfiguration } from '../../types';
import {
    DiscoveryServiceProjectScenarioName,
    DiscoveryServiceProjectScenarios,
} from './projects/discovery-service-project-scenarios';
import {
    DiscoveryServiceRegionRecommendationScenarioName,
    DiscoveryServiceRegionRecommendationScenarios,
} from './region-recommendations/discovery-service-region-recommendation-scenarios';

export type DiscoveryServiceScenarioName =
    | DiscoveryServiceProjectScenarioName
    | DiscoveryServiceRegionRecommendationScenarioName;

export const DiscoveryServiceScenarioName: UnionMap<DiscoveryServiceScenarioName> = {
    ...DiscoveryServiceProjectScenarioName,
    ...DiscoveryServiceRegionRecommendationScenarioName,
};

export const DiscoveryServiceScenarios: UnionValueMap<DiscoveryServiceScenarioName, MockScenarioConfiguration> = {
    ...DiscoveryServiceProjectScenarios,
    ...DiscoveryServiceRegionRecommendationScenarios,
};
