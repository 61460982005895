import {
    makeStyles,
    mergeClasses,
    OptionOnSelectData,
    SelectionEvents,
    ToggleButton,
    Tooltip,
} from '@fluentui/react-components';
import { Globe20Regular } from '@fluentui/react-icons';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import { TimeZoneDropdown } from '../../../common/form/dropdown/data-controls/timezone-dropdown';

interface AddOrUpdateExpirationFormTimeZoneControlProps {
    label?: string;
    value?: string;
    onChange: (_event: SelectionEvents, data: OptionOnSelectData) => void;
    isSubmitting: boolean;
}

/**
 * Styles
 */

const useTimeZoneContainerStyles = makeStyles({
    root: {
        gap: '8px',
        marginTop: '8px',
    },
});

/**
 * END Styles
 */

const messages = defineMessages({
    selectTimezone: {
        id: 'AddOrUpdateExpirationFormTimezoneControl_Label',
        defaultMessage: 'Time zone',
        description: 'Label for the timezone picker',
    },
});

export const AddOrUpdateExpirationFormTimeZoneControl: React.FC<AddOrUpdateExpirationFormTimeZoneControlProps> = (
    props: AddOrUpdateExpirationFormTimeZoneControlProps
) => {
    const { value, onChange, isSubmitting } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const stackStyles = useStackWithFullWidthItemStyles();
    const timeZoneContainerStyles = useTimeZoneContainerStyles();

    const [isTimeZoneClicked, setIsTimeZoneClicked] = React.useState<boolean>(false);

    // Intl hooks

    const onButtonClick = React.useCallback(
        (_ev: unknown): void => {
            setIsTimeZoneClicked(!isTimeZoneClicked);
        },
        [isTimeZoneClicked]
    );

    return (
        <div className={mergeClasses(stackStyles.root, timeZoneContainerStyles.root)}>
            {!!isTimeZoneClicked && (
                <div className={stackStyles.item}>
                    <TimeZoneDropdown
                        label={formatMessage(messages.selectTimezone)}
                        disabled={isSubmitting}
                        value={value}
                        onOptionSelect={onChange}
                    />
                </div>
            )}
            <div className={stackStyles.item}>
                <Tooltip content={formatMessage(messages.selectTimezone)} relationship="label" positioning="below">
                    <ToggleButton
                        icon={<Globe20Regular />}
                        checked={!isTimeZoneClicked}
                        onClick={onButtonClick}
                        aria-label={formatMessage(messages.selectTimezone)}
                        appearance="transparent"
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default AddOrUpdateExpirationFormTimeZoneControl;
