import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ListSnapshotsResponse, listSnapshots } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    listSnapshots as listSnapshotsActionCreator,
    listSnapshotsError,
    listSnapshotsFailed,
    listSnapshotsSuccess,
} from '../../actions/snapshot/snapshot-action-creators';
import { ListSnapshotsAction } from '../../actions/snapshot/snapshot-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listSnapshotsSaga(action: ListSnapshotsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListSnapshotsResponse = yield call(listSnapshots, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(listSnapshotsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data: snapshots } = response;

        yield put(listSnapshotsSuccess({ id, result: snapshots }, meta));
        yield resolveAction(action, { data: snapshots, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListSnapshots);
        yield put(listSnapshotsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listSnapshotsListenerSaga(): SagaIterator {
    yield takeEvery(listSnapshotsActionCreator, listSnapshotsSaga);
}
