import { Button, DialogActions, DialogTrigger } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

type DialogActionButtonsProps = {
    onContinueClicked: () => void;
};

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialogFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "cancel" button in the "Initialize connect via app dialog"',
    },
    continueButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialogFooter_ContinueButton_AriaLabel',
        defaultMessage: 'Continue',
        description: 'Aria label for the "continue" button in the "Initialize connect via app dialog"',
    },
});

export const DialogActionButtons: React.FC<DialogActionButtonsProps> = (props) => {
    const { formatMessage } = useIntl();
    const { onContinueClicked } = props;

    return (
        <DialogActions>
            <Button
                appearance="primary"
                aria-label={formatMessage(messages.continueButtonAriaLabel)}
                onClick={onContinueClicked}
            >
                <FormattedMessage
                    id="InitializeConnectViaAppDialogFooter_ContinueButton_Text"
                    defaultMessage="Continue"
                    description='Text for the "continue" button in the "Initialize connect via app dialog"'
                />
            </Button>
            <DialogTrigger>
                <Button appearance="secondary" aria-label={formatMessage(messages.cancelButtonAriaLabel)}>
                    <FormattedMessage
                        id="InitializeConnectViaAppDialogFooter_CancelButton_Text"
                        defaultMessage="Cancel"
                        description='Text for the "cancel" button in the "Initialize connect via app dialog"'
                    />
                </Button>
            </DialogTrigger>
        </DialogActions>
    );
};
