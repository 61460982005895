import * as React from 'react';
import { getTimeZoneOptions, TimeZoneData } from '../../../../utilities/time';
import { Dropdown, DropdownProps, DropdownValueOption } from '../../../common/form/dropdown/dropdown';

const getTimeZoneDropdownOptions = (): TimeZoneDropdownOption[] => {
    const rawOptions = getTimeZoneOptions();
    const timeZoneOptions: TimeZoneDropdownOption[] = rawOptions.map((timeZone: TimeZoneData) => {
        const { name, displayText } = timeZone;
        return {
            key: name,
            text: displayText,
            value: name,
        };
    });

    return timeZoneOptions;
};

type TimeZoneDropdownOption = Omit<DropdownValueOption<string>, 'data'>;

export interface TimeZoneDropdownProps extends Omit<DropdownProps<string>, 'options' | 'selectedKey'> {
    value: string | undefined;
}

export const TimeZoneDropdown: React.FC<TimeZoneDropdownProps> = (props) => {
    const { value } = props;

    return <Dropdown<string> {...props} selectedKey={value} options={getTimeZoneDropdownOptions()} />;
};
