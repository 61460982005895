import { CapitalizedUnionMap } from '../../../types/union-map';

export interface DelayShutdownFormData {
    delayShutdownUntil?: DelayShutdownSpinButtonOption;
}

export type DelayShutdownFormFieldName = 'delayShutdownUntil';

export const DelayShutdownFormFieldName: CapitalizedUnionMap<DelayShutdownFormFieldName> = {
    DelayShutdownUntil: 'delayShutdownUntil',
};

export interface DelayShutdownSpinButtonOption {
    dateValue: Date;
    locale: string;
    isSkip: boolean;
    isSkipNextDay: boolean;
    offsetInHours: number;
}
