import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../../types/serializable-map';
import FailureMessage from '../../common/failure-message/failure-message';
import { FailureMessageSeverity, FailureMessageSize } from '../../common/failure-message/models';
import DevBoxProjectDropdown from '../../common/form/dropdown/data-controls/dev-box-project-dropdown';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import Label from '../../common/form/label';
import { AddDevBoxFormProjectViewModel } from '../models';
import { getAvailableUnusedProjects, getLastUsedProjects, getUnavailableProjects } from './selectors';

interface AddDevBoxFormProjectControlsProps {
    projects: AddDevBoxFormProjectViewModel[];
    disabled: boolean;
    selectedProject: AddDevBoxFormProjectViewModel | undefined;
    errorMessage: string;
    onChange: (value: AddDevBoxFormProjectViewModel | undefined) => void;
    showFailedToLoadWarning: boolean;
    recentlyUsedProjects: SerializableMap<Date>;
}

const messages = defineMessages({
    projectDropdownText: {
        id: 'AddDevBoxFormProjectControls_Dropdown_Text',
        defaultMessage: 'Project',
        description: 'Text for the project dropdown in the add dev box panel label',
    },
    projectDropdownAriaLabel: {
        id: 'AddDevBoxFormProjectControls_Dropdown_AriaLabel',
        defaultMessage: 'Project for your dev box',
        description: 'Aria label for the project dropdown in the add dev box panel',
    },
    projectDropdownPlaceholder: {
        id: 'AddDevBoxFormProjectControls_Dropdown_Placeholder',
        defaultMessage: 'Select a project',
        description: 'Placeholder text for the project dropdown in the add dev box panel',
    },
    projectDropdownToolTipText: {
        id: 'AddDevBoxFormProjectControls_Dropdown_ToolTipText',
        defaultMessage: 'Projects are configured by admins, and enable resource creation by developers on a team.',
        description: 'Tooltip text for the project dropdown',
    },
});

/**
 * Styles
 */

const useProjectDropdownStackStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * End Styles
 */

export const AddDevBoxFormProjectControls: React.FC<AddDevBoxFormProjectControlsProps> = (
    props: AddDevBoxFormProjectControlsProps
) => {
    const {
        projects,
        disabled,
        selectedProject,
        errorMessage,
        onChange,
        showFailedToLoadWarning,
        recentlyUsedProjects,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const projectDropdownStackStyles = useProjectDropdownStackStyles();

    const onRenderLabel = React.useCallback((): JSX.Element => {
        return (
            <Label tooltipValue={formatMessage(messages.projectDropdownToolTipText)}>
                <FormattedMessage
                    id="AddDevBoxFormProjectControls_Dropdown_Text"
                    defaultMessage="Project"
                    description="Text for the project dropdown in the add dev box panel label"
                />
            </Label>
        );
    }, [formatMessage]);

    const projectsOptions: AddDevBoxFormProjectViewModel[] = React.useMemo(
        () => getLastUsedProjects(projects, recentlyUsedProjects),
        [projects, recentlyUsedProjects]
    );

    const unavailableProjects: AddDevBoxFormProjectViewModel[] = React.useMemo(
        () => getUnavailableProjects(projects),
        [projects]
    );

    const availableUnusedProjects: AddDevBoxFormProjectViewModel[] = React.useMemo(
        () => getAvailableUnusedProjects(projectsOptions),
        [projectsOptions]
    );

    return (
        <div className={mergeClasses(stackStyles.root, projectDropdownStackStyles.root)}>
            <div className={stackStyles.item}>
                <DevBoxProjectDropdown
                    options={projectsOptions}
                    value={selectedProject}
                    label={formatMessage(messages.projectDropdownText)}
                    ariaLabel={formatMessage(messages.projectDropdownAriaLabel)}
                    placeholder={formatMessage(messages.projectDropdownPlaceholder)}
                    onChange={onChange}
                    onRenderLabel={onRenderLabel}
                    unavailableProjects={unavailableProjects}
                    availableUnusedProjects={availableUnusedProjects}
                    disabled={disabled}
                    errorMessage={errorMessage}
                    required
                    autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                />
            </div>
            {showFailedToLoadWarning && (
                <div className={stackStyles.item}>
                    <FailureMessage severity={FailureMessageSeverity.Warning} size={FailureMessageSize.Compact}>
                        <FormattedMessage
                            id="AddDevBoxForm_ProjectControl_FailedToLoad"
                            defaultMessage="We encountered an error loading some projects, so they may not appear here. Please retry, or contact your admin if this problem persists."
                            description="Text informing users that some projects failed to load resources and cannot be used for dev box creation."
                        />
                    </FailureMessage>
                </div>
            )}
        </div>
    );
};

export default AddDevBoxFormProjectControls;
