import { CustomizationGroupStatus } from '../models/customization';
import { UnionMap, UnionValueMap } from '../types/union-map';

export type CustomizationOperationName = 'Create';

export const CustomizationOperationName: UnionMap<CustomizationOperationName> = {
    Create: 'Create',
};

export const failedCustomizationGroupStatuses: CustomizationGroupStatus[] = [
    CustomizationGroupStatus.Failed,
    CustomizationGroupStatus.ValidationFailed,
];

export const workloadYamlName = 'workload.yaml';
export const devboxYamlName = 'devbox.yaml';
export const wingetConfigurationExtension = '.dsc.yaml';

export type IntrinsicTaskHintDefaultValue = 'DevBoxName';

export const IntrinsicTaskHintDefaultValue: UnionValueMap<IntrinsicTaskHintDefaultValue, string> = {
    DevBoxName: '{DevBoxName}',
};

export type IntrinsicTask = 'UpdatePcName' | 'EnableTeamsVdiOptimization';

export const IntrinsicTask: UnionMap<IntrinsicTask> = {
    UpdatePcName: 'UpdatePcName',
    EnableTeamsVdiOptimization: 'EnableTeamsVdiOptimization',
};

export type IntrinsicTaskName = `__INTRINSIC_${IntrinsicTask}__`;

export const IntrinsicTaskName: UnionValueMap<IntrinsicTask, IntrinsicTaskName> = {
    UpdatePcName: '__INTRINSIC_UpdatePcName__',
    EnableTeamsVdiOptimization: '__INTRINSIC_EnableTeamsVdiOptimization__',
};

export const CustomizationFailureRetainDays = 7;
