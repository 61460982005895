import { DevBoxSnapshotContract } from '../../../data/contracts/dev-box';
import { ListSnapshotsResponse } from '../../../data/services/data-plane-api/dev-box';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, ResultPayload } from '../core-actions';
import { SnapshotActionType } from './snapshot-actions';

export const listSnapshots = createActionCreator<IndexedPayload, ListSnapshotsResponse>(
    SnapshotActionType.ListSnapshots,
    {
        async: true,
        startsActivity: true,
    }
);

export const listSnapshotsError = createActionCreator<ErrorPayload & IndexedPayload>(
    SnapshotActionType.ListSnapshotsError
);
export const listSnapshotsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    SnapshotActionType.ListSnapshotsFailed
);
export const listSnapshotsSuccess = createActionCreator<IndexedPayload & ResultPayload<DevBoxSnapshotContract[]>>(
    SnapshotActionType.ListSnapshotsSuccess
);

export const loadSnapshots = createActionCreatorWithoutPayload<AggregatedResult>(SnapshotActionType.LoadSnapshots, {
    async: true,
    startsActivity: true,
});

export const loadSnapshotsError = createActionCreator<ErrorPayload>(SnapshotActionType.LoadSnapshotsError);

export const loadSnapshotsFailed = createActionCreator<FailedPayload>(SnapshotActionType.LoadSnapshotsFailed);

export const loadSnapshotsSuccess = createActionCreatorWithoutPayload(SnapshotActionType.LoadSnapshotsSuccess);
