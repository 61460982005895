import { Checkbox, FontWeights, TooltipHost } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { IntrinsicTask } from '../../../constants/customization';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { getIntrinsicTasks } from '../../../utilities/customization-task';
import Label from '../../common/form/label';
import { IntrinsicTaskWithDescription } from './models';
import { getIntrinsicTaskItems } from './selectors';

export interface CustomizationSettingsComponentProps {
    intrinsicTasksOnChange: (value: IntrinsicTask[] | undefined) => void;
    devBoxName: string;
    selectedIntrinsicTasks: IntrinsicTask[] | undefined;
}

const messages = defineMessages({
    customizationsPreviewTag: {
        id: 'CustomizationSettingsComponent_PreviewTag_TagContent',
        defaultMessage: 'preview',
        description:
            'A tag displayed to the right of the intrinsic task description indicating that this customization task is a preview feature.',
    },
});

/**
 * Styles
 */

const useHeaderTextStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
        marginBottom: '10px',
    },
});

const useLabelStyles = makeStyles({
    root: {
        fontWeight: FontWeights.regular,
        paddingLeft: '4px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '8px',
        marginTop: '12px',
    },
});

/**
 * END Styles
 */

interface IntrinsicTaskCheckboxProps {
    item: IntrinsicTaskWithDescription;
    isChecked: boolean;
    onChange: (task: IntrinsicTask, checked?: boolean) => void;
}

const IntrinsicTaskCheckbox: React.FC<IntrinsicTaskCheckboxProps> = (props) => {
    const { item, onChange, isChecked } = props;

    // Style hooks
    const stackStyles = useStackStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const labelStyles = useLabelStyles();

    const onCheckboxChange = React.useCallback(
        (_event: unknown, checked?: boolean) => {
            onChange(item.key, checked);
        },
        [onChange, item]
    );

    const onRenderLabel = React.useCallback(() => {
        return (
            <Label tagContent={formatMessage(messages.customizationsPreviewTag)} styles={labelStyles}>
                <FormattedMessage {...item.description} />
            </Label>
        );
    }, [item, labelStyles, formatMessage]);

    return (
        <div className={stackStyles.item} key={item.key}>
            <TooltipHost content={formatMessage(item.hint, item.hintParameters)}>
                <Checkbox onChange={onCheckboxChange} checked={isChecked} onRenderLabel={onRenderLabel} />
            </TooltipHost>
        </div>
    );
};

export const CustomizationSettingsComponent: React.FC<CustomizationSettingsComponentProps> = (props) => {
    const { intrinsicTasksOnChange, devBoxName, selectedIntrinsicTasks } = props;

    // Style hooks
    const headerTextStyles = useHeaderTextStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    const intrinsicTasks = React.useMemo(() => getIntrinsicTasks(), []);

    const intrinsicTaskItems = React.useMemo(
        () => getIntrinsicTaskItems(intrinsicTasks, devBoxName),
        [devBoxName, intrinsicTasks]
    );

    const onCheckboxChange = React.useCallback(
        (key: IntrinsicTask, checked?: boolean) => {
            // Update the customization data when the checked items change
            const previousSelectedIntrinsicTasks = selectedIntrinsicTasks ?? [];

            const updatedSelectedIntrinsicTasks =
                checked ?? false
                    ? [...previousSelectedIntrinsicTasks, key]
                    : previousSelectedIntrinsicTasks.filter((task) => task !== key);

            intrinsicTasksOnChange(
                updatedSelectedIntrinsicTasks.length > 0 ? updatedSelectedIntrinsicTasks : undefined
            );
        },
        [selectedIntrinsicTasks, intrinsicTasksOnChange]
    );

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={mergeClasses(stackStyles.item, headerTextStyles.root)}>
                <FormattedMessage
                    id="AddDevBoxFormCustomizationSettingsControls_Text_IntrinsicTasksHeaderText"
                    defaultMessage="Customization settings"
                    description="Text for the header of the intrinsic tasks section"
                />
            </div>
            {intrinsicTaskItems.map((item: IntrinsicTaskWithDescription) => (
                <IntrinsicTaskCheckbox
                    item={item}
                    onChange={onCheckboxChange}
                    key={item.key}
                    isChecked={selectedIntrinsicTasks?.includes(item.key) ?? false}
                />
            ))}
        </div>
    );
};

export default CustomizationSettingsComponent;
