import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { CssSelector } from '../themes/constants/css-selectors';
import { StackStyle, useHorizontalStackStyles } from '../themes/styles/flexbox-styles';
import { PortalLayout, PortalLayoutProps } from './portal-layout';

interface Props extends PortalLayoutProps {
    customStyles?: { [key in StackStyle]: string };
    hideActionBar?: boolean;
    isScrolled?: boolean;
}

export type LandingPagePortalLayoutProps = Props;

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        justifyContent: 'center',
        // We need to set this to avoid horizontal scrollbar due to background image sticking to right side of the page.
        overflowX: 'hidden',
    },
});

const useDefaultContentStyles = makeStyles({
    root: {
        height: 'fit-content',
        textAlign: 'start',
        // Note: ensure that width for ScreenSizeSmallAndBelow is kept in sync with this padding value.
        paddingLeft: '40px',
        paddingBottom: '40px',

        [CssSelector.ScreenSizeMediumAndBelow]: {
            // At screen sizes small and below, we switch from fixed width + variable gutters mode to variable
            // width + fixed gutters mode. To give a defined width to descendant elements, we calculate the width
            // to be the total viewport width minus the total width of the gutters. That total gutter width should
            // be kept in sync with the padding defined for this element.
            width: 'calc(100vw - 40px)',
        },
        [CssSelector.ScreenSizeXXLargeAndAbove]: {
            maxWidth: '1300px',
        },
        [CssSelector.ScreenSizeBetweenLargeAndXLarge]: {
            maxWidth: '700px',
        },
    },
});

/**
 * End Styles
 */

export const LandingPageLayout: React.FC<Props> = (props) => {
    const { id, showFooter, children, hideActionBar, customStyles, isScrolled } = props;

    const containerStyles = useContainerStyles();
    const defaultContentStyles = useDefaultContentStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <PortalLayout id={id} showFooter={showFooter} hideActionBar={hideActionBar} isScrolled={isScrolled}>
            <div className={mergeClasses(horizontalStackStyles.root, containerStyles.root, customStyles?.root)}>
                <div
                    className={mergeClasses(horizontalStackStyles.item, defaultContentStyles.root, customStyles?.item)}
                >
                    {children}
                </div>
            </div>
        </PortalLayout>
    );
};

export default LandingPageLayout;
