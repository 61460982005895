import { createSelector } from '@reduxjs/toolkit';
import { DismissableContentName } from '../../constants/app';
import { getDismissedMessages } from '../../redux/selector/application-selectors';
import { StoreStateSelector } from '../../redux/selector/common';
import { has } from '../../utilities/serializable-set';

export const getIsCreateNewTeachingPopoverForWelcomeTourDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.CreateNewTeachingPopoverForWelcomeTour)
);

export const getIsCreateNewTeachingPopoverForWelcomeTourSkipped: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.CreateNewTeachingPopoverForWelcomeTourSkipped)
);

export const getIsSettingsMenuTeachingPopoverForWelcomeTourDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.SettingsMenuTeachingPopoverForWelcomeTour)
);

export const getIsSettingsMenuTeachingPopoverForWelcomeTourSkipped: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) =>
        has(dismissedMessages, DismissableContentName.SettingsMenuTeachingPopoverForWelcomeTourSkipped)
);

export const getIsHelpMenuTeachingPopoverForWelcomeTourDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.HelpMenuTeachingPopoverForWelcomeTour)
);

export const getIsWelcomeTourSkippedWithNoShowAgainDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.WelcomeTourSkippedWithNoShowAgain)
);
