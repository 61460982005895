import { Link } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { compact, groupIntoMap } from '../../../utilities/array';
import { get } from '../../../utilities/serializable-map';
import RegionDropdown from '../../common/form/dropdown/data-controls/region/region-dropdown';
import RegionDropdownItem from '../../common/form/dropdown/data-controls/region/region-dropdown-item';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import Label from '../../common/form/label';
import ReadOnlyControl from '../../common/form/read-only-control';
import {
    ImageViewModel,
    ImageViewModelMap,
    RegionDropdownOption,
    RegionViewModel,
    RegionViewModelMap,
} from '../models';
import SelectDevBoxRegionDialog from '../select-dev-box-region-dialog/select-dev-box-region-dialog';
import {
    getHasOptimalRegions,
    getRegionOptions,
    getRegionsToRegionRecommendations,
    removeRegionDuplicates,
} from './selectors';

interface AddDevBoxFormRegionControlProps {
    disabled: boolean;
    imagesToPools: ImageViewModelMap;
    regionRecommendations: RegionViewModelMap;
    selectedImageId: string | undefined;
    selectedProjectId: string | undefined;
    selectedRegion: RegionDropdownOption | undefined;
    errorMessage: string;
    onChange: (event: RegionDropdownOption | undefined) => void;
}

const messages = defineMessages({
    addDevBoxFormRegionFieldDropdownText: {
        id: 'AddDevBoxFormRegionControl_Dropdown_Text',
        defaultMessage: 'Region',
        description: 'Text for the region dropdown in the add dev box panel label',
    },
    addDevBoxFormRegionFieldDropdownToolTipText: {
        id: 'AddDevBoxFormRegionControl_Dropdown_ToolTipText',
        defaultMessage:
            'Choosing a region with low latency will provide the most responsive experience with your dev box.',
        description: 'Tooltip text for the region dropdown in the add dev box panel label',
    },
    addDevBoxFormRegionFieldDropdownAriaLabel: {
        id: 'AddDevBoxFormRegionControl_Dropdown_AriaLabel',
        defaultMessage: 'Region for your dev box',
        description: 'Aria label for the region dropdown in the add dev box panel',
    },
    addDevBoxFormRegionFieldDropdownPlaceholder: {
        id: 'AddDevBoxFormRegionControl_Dropdown_Placeholder',
        defaultMessage: 'Select region',
        description: 'Placeholder text for the region dropdown in the add dev box panel',
    },
    addDevBoxFormRegionFieldNoOptimalRegionsText: {
        id: 'AddDevBoxFormRegionControl_NoOptimalRegions_Text',
        defaultMessage:
            'No optimal regions are available for this image in your location. Contact your admin to add a region, or select another image for improved latency.',
        description: 'Text to tell the user that there are no optimal regions available for this image.',
    },
});

/**
 * Styles
 */

const useRegionReadOnlyOptionContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

/**
 * END Styles
 */

export const AddDevBoxFormRegionControl: React.FC<AddDevBoxFormRegionControlProps> = (
    props: AddDevBoxFormRegionControlProps
) => {
    const {
        disabled,
        selectedRegion,
        selectedImageId,
        selectedProjectId,
        imagesToPools,
        errorMessage,
        regionRecommendations,
        onChange,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const regionReadOnlyOptionContainerStyles = useRegionReadOnlyOptionContainerStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // State hooks
    const [showSelectDevBoxRegionDialog, setShowSelectDevBoxRegionDialog] = React.useState(false);

    // Memo hooks
    const imagesMap: ImageViewModel[][] = React.useMemo(
        () => (!!selectedProjectId ? get(imagesToPools, selectedProjectId) ?? [] : []),
        [imagesToPools, selectedProjectId]
    );

    const imagesToRegions = React.useMemo(
        () => imagesMap.map((images) => groupIntoMap(images, (image) => image.imageName)),
        [imagesMap]
    );

    const filteredImages: ImageViewModel[] = React.useMemo(
        () => compact(imagesToRegions.flatMap((region) => get(region, selectedImageId))),
        [imagesToRegions, selectedImageId]
    );

    const regionsWithDuplicates: RegionViewModel[] = React.useMemo(
        () => getRegionsToRegionRecommendations(filteredImages, regionRecommendations),
        [filteredImages, regionRecommendations]
    );

    const regions: RegionViewModel[] = React.useMemo(
        () => removeRegionDuplicates(regionsWithDuplicates),
        [regionsWithDuplicates]
    );

    const hasOptimalRegions = React.useMemo(() => getHasOptimalRegions(regions), [regions]);

    const hasMultipleRegions = regions.length > 1;
    const hasNoRegions = regions.length < 1;

    // NOTE: intentionally did not put `selectedRegion` in dependencies because we don't want to rerender this when selectedRegion changes
    const regionsWithShowAll: RegionDropdownOption[] = React.useMemo(
        () => getRegionOptions(regions, hasOptimalRegions, hasMultipleRegions, selectedRegion),
        [regions, hasMultipleRegions, hasOptimalRegions]
    );

    const selectDevBoxRegionModalOnDismiss = React.useCallback(() => setShowSelectDevBoxRegionDialog(false), []);
    const onSeeAllRegionsButtonClick = React.useCallback(() => setShowSelectDevBoxRegionDialog(true), []);

    // pre-populate with the most highly recommended region
    React.useEffect(() => {
        onChange(regionsWithShowAll[0]);
    }, [regionsWithShowAll, onChange]);

    // NOTE: `selectedImageId` is in dependencies because we want this to happen only when selectedImageId changes
    React.useEffect(() => {
        if (!hasOptimalRegions) {
            onChange(undefined);
        }
    }, [selectedImageId]);

    const onRenderLabel = React.useCallback((): JSX.Element => {
        return (
            <Label tooltipValue={formatMessage(messages.addDevBoxFormRegionFieldDropdownToolTipText)}>
                <FormattedMessage
                    id="AddDevBoxFormRegionControl_Dropdown_Text"
                    defaultMessage="Region"
                    description="Text for the region dropdown in the add dev box panel label"
                />
            </Label>
        );
    }, [formatMessage]);

    const onChangeRegion = React.useCallback(
        (value: RegionDropdownOption | undefined) => {
            if (value?.id === 'show-all') {
                // Setting onChange to be first value in regions array if 'show all' is chosen first
                if (selectedRegion === undefined) {
                    onChange(regionsWithShowAll[0]);
                }
                onSeeAllRegionsButtonClick();
                return;
            }

            onChange(value);
        },
        [onChange, onSeeAllRegionsButtonClick, selectedRegion, regionsWithShowAll]
    );

    if (!hasOptimalRegions) {
        return (
            <>
                {selectedRegion ? (
                    <div className={stackStyles.root}>
                        <div className={stackStyles.item}>
                            <RegionDropdown
                                value={selectedRegion}
                                label={formatMessage(messages.addDevBoxFormRegionFieldDropdownText)}
                                ariaLabel={formatMessage(messages.addDevBoxFormRegionFieldDropdownAriaLabel)}
                                placeholder={formatMessage(messages.addDevBoxFormRegionFieldDropdownPlaceholder)}
                                options={regionsWithShowAll}
                                onChange={onChangeRegion}
                                onRenderLabel={onRenderLabel}
                                disabled={disabled}
                                errorMessage={errorMessage}
                                hasOptimalRegions={hasOptimalRegions}
                                autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                                required
                            />
                        </div>
                    </div>
                ) : (
                    <div className={stackStyles.root}>
                        <div className={stackStyles.item}>
                            <ReadOnlyControl
                                label={formatMessage(messages.addDevBoxFormRegionFieldDropdownText)}
                                value={formatMessage(messages.addDevBoxFormRegionFieldNoOptimalRegionsText)}
                                tooltipText={formatMessage(messages.addDevBoxFormRegionFieldDropdownToolTipText)}
                                disabled={disabled}
                            />
                        </div>
                        <div className={stackStyles.item}>
                            <Link onClick={onSeeAllRegionsButtonClick} disabled={disabled}>
                                <FormattedMessage
                                    id="AddDevBoxFormRegionControls_SeeAllRegionsLink_Text"
                                    defaultMessage="Show all"
                                    description="Text for the see all regions link for the add dev box region form field"
                                />
                            </Link>
                        </div>
                    </div>
                )}
                <SelectDevBoxRegionDialog
                    regions={regions}
                    onDismiss={selectDevBoxRegionModalOnDismiss}
                    showDialog={showSelectDevBoxRegionDialog}
                    onChange={onChange}
                    selectedRegion={selectedRegion}
                />
            </>
        );
    }

    if (hasMultipleRegions) {
        return (
            <div className={stackStyles.root}>
                <div className={stackStyles.item}>
                    <RegionDropdown
                        value={selectedRegion}
                        label={formatMessage(messages.addDevBoxFormRegionFieldDropdownText)}
                        ariaLabel={formatMessage(messages.addDevBoxFormRegionFieldDropdownAriaLabel)}
                        placeholder={formatMessage(messages.addDevBoxFormRegionFieldDropdownPlaceholder)}
                        options={regionsWithShowAll}
                        onChange={onChangeRegion}
                        onRenderLabel={onRenderLabel}
                        disabled={disabled}
                        errorMessage={errorMessage}
                        hasOptimalRegions={hasOptimalRegions}
                        autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                        required
                    />
                </div>
                <div className={stackStyles.item}>
                    <SelectDevBoxRegionDialog
                        regions={regions}
                        onDismiss={selectDevBoxRegionModalOnDismiss}
                        showDialog={showSelectDevBoxRegionDialog}
                        onChange={onChange}
                        selectedRegion={selectedRegion}
                    />
                </div>
            </div>
        );
    }

    if (!selectedRegion || hasNoRegions) {
        return <></>;
    }

    // Only one region option
    return (
        <div className={mergeClasses(stackStyles.root, regionReadOnlyOptionContainerStyles.root)}>
            <div className={stackStyles.item}>
                <Label tooltipValue={formatMessage(messages.addDevBoxFormRegionFieldDropdownToolTipText)}>
                    <FormattedMessage
                        id="AddDevBoxFormRegionControl_DropdownReadOnly_Text"
                        defaultMessage="Region"
                        description="Text for the region read only field in the add dev box panel label"
                    />
                </Label>
            </div>
            <div className={mergeClasses(stackStyles.item)}>
                <RegionDropdownItem region={selectedRegion} />
            </div>
        </div>
    );
};

export default AddDevBoxFormRegionControl;
