import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import listProjectsMultipleWorkingWithDevBoxAbilities from './list-projects-multiple-dev-box-abilities.json';
import listProjectsMultipleWorking from './list-projects-multiple-working.json';
import listProjectsNextLinkWorking from './list-projects-next-link-working.json';
import listProjectsOneFailing from './list-projects-one-failing.json';
import listProjectsOneWorking from './list-projects-one-working.json';

export type DiscoveryServiceProjectScenarioName =
    | 'ListProjectsOneWorking'
    | 'ListProjectsMultipleWorking'
    | 'ListProjectsOneFailing'
    | 'ListProjectsNextLinkWorking'
    | 'ListProjectsMultipleWorkingWithDevBoxAbilities';

export const DiscoveryServiceProjectScenarioName: UnionMap<DiscoveryServiceProjectScenarioName> = {
    ListProjectsOneWorking: 'ListProjectsOneWorking',
    ListProjectsMultipleWorking: 'ListProjectsMultipleWorking',
    ListProjectsOneFailing: 'ListProjectsOneFailing',
    ListProjectsNextLinkWorking: 'ListProjectsNextLinkWorking',
    ListProjectsMultipleWorkingWithDevBoxAbilities: 'ListProjectsMultipleWorkingWithDevBoxAbilities',
};

export const DiscoveryServiceProjectScenarios: UnionValueMap<
    DiscoveryServiceProjectScenarioName,
    MockScenarioConfiguration
> = {
    ListProjectsOneWorking: listProjectsOneWorking as MockScenarioConfiguration,
    ListProjectsMultipleWorking: listProjectsMultipleWorking as MockScenarioConfiguration,
    ListProjectsOneFailing: listProjectsOneFailing as MockScenarioConfiguration,
    ListProjectsNextLinkWorking: listProjectsNextLinkWorking as MockScenarioConfiguration,
    ListProjectsMultipleWorkingWithDevBoxAbilities:
        listProjectsMultipleWorkingWithDevBoxAbilities as MockScenarioConfiguration,
};
