import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { Field } from 'react-final-form';
import { useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import { FieldError } from '../../../common/form/form-field-error';
import { AddOrUpdateExpirationFormDateControlContainer } from '../add-or-update-expiration-form-field-group/add-or-update-expiration-form-date-control';
import AddOrUpdateExpirationFormTimeControlContainer from '../add-or-update-expiration-form-field-group/add-or-update-expiration-form-time-control';
import {
    AddOrUpdateExpirationFormFieldName,
    EnvironmentExpirationViewModel,
} from '../add-or-update-expiration-form-field-group/models';
import { areExpirationDatesEqual } from '../add-or-update-expiration-form-field-group/selectors';
import LegacyAddOrUpdateExpirationFormEnableControl from './legacy-add-or-update-expiration-form-enable-control';
import LegacyAddOrUpdateExpirationFormTimeZoneControl from './legacy-add-or-update-expiration-form-timezone-control';

interface AddOrUpdateExpirationFormFieldGroupProps {
    isSubmitting: boolean;
    enableExpiration: boolean;
}

/**
 * Styles
 */

const useFieldGroupStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * END Styles
 */

export const LegacyAddOrUpdateExpirationFormFieldGroup: React.FC<AddOrUpdateExpirationFormFieldGroupProps> = (
    props
) => {
    const { isSubmitting, enableExpiration } = props;

    // Style hooks
    const fieldGroupStyles = useFieldGroupStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    return (
        <div className={mergeClasses(stackStyles.root, fieldGroupStyles.root)}>
            <div className={stackStyles.item}>
                <Field<boolean> name={AddOrUpdateExpirationFormFieldName.EnableExpiration}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange, value } = input;

                        return (
                            <LegacyAddOrUpdateExpirationFormEnableControl
                                expirationEnabled={value}
                                onChange={onChange}
                                isSubmitting={isSubmitting}
                            />
                        );
                    }}
                </Field>
            </div>
            <div className={stackStyles.item}>
                <Field<EnvironmentExpirationViewModel>
                    name={AddOrUpdateExpirationFormFieldName.EnvironmentExpiration}
                    isEqual={areExpirationDatesEqual}
                >
                    {(fieldProps) => {
                        const { input, meta } = fieldProps;
                        const { onChange, value: fieldValue } = input;
                        const { expirationCalendarDate, expirationTimeZone, expirationTime } = fieldValue;

                        const onExpirationTimeChange = React.useCallback(
                            (value) => {
                                onChange({
                                    ...fieldValue,
                                    expirationTime: value,
                                });
                            },
                            [onChange, fieldValue]
                        );

                        const onExpirationDateChange = React.useCallback(
                            (value) => {
                                onChange({
                                    ...fieldValue,
                                    expirationCalendarDate: value,
                                });
                            },
                            [onChange, fieldValue]
                        );

                        const onExpirationTimeZoneChange = React.useCallback(
                            (value) => {
                                onChange({
                                    ...fieldValue,
                                    expirationTimeZone: value,
                                });
                            },
                            [onChange, fieldValue]
                        );

                        return (
                            <>
                                {!!enableExpiration && (
                                    <div className={mergeClasses(stackStyles.root, fieldGroupStyles.root)}>
                                        <div className={stackStyles.item}>
                                            <AddOrUpdateExpirationFormDateControlContainer
                                                value={expirationCalendarDate}
                                                onChange={onExpirationDateChange}
                                                isSubmitting={isSubmitting}
                                                selectedTimeZone={expirationTimeZone}
                                                isLegacy={true}
                                            />
                                        </div>
                                        <div className={stackStyles.item}>
                                            <AddOrUpdateExpirationFormTimeControlContainer
                                                value={expirationTime}
                                                onChange={onExpirationTimeChange}
                                                isSubmitting={isSubmitting}
                                                isLegacy={true}
                                            />
                                            <LegacyAddOrUpdateExpirationFormTimeZoneControl
                                                value={expirationTimeZone}
                                                onChange={onExpirationTimeZoneChange}
                                                isSubmitting={isSubmitting}
                                            />
                                            <FieldError meta={meta} />
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    }}
                </Field>
            </div>
        </div>
    );
};

export default AddOrUpdateExpirationFormFieldGroupProps;
