import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagName } from '../../../constants/features';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { Status } from '../../../models/common';
import { SerializableMap } from '../../../types/serializable-map';
import { numberOfMatchingElements, unique } from '../../../utilities/array';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { every, get, keys, size } from '../../../utilities/serializable-map';
import { StoreStateSelector, isStatusSuccessful } from '../common';
import { getStatusesForListDevBoxActionsByDevBoxId } from '../dev-box-action-selectors';
import {
    getDevBoxes,
    getIsDevBoxInUsableProvisioningState,
    getStatusesForListDevBoxesByProjectId,
} from '../dev-box-selectors';
import {
    getStatusesForWarmDevCenterNameRecord,
    getUniqueDevCentersFromProjectDataPlaneIds,
} from '../dev-center-selectors';
import { getStatusesForListEnvironmentDefinitions } from '../environment-definition-selectors';
import { getProjectIdsFromEnvironments, getStatusesForListEnvironments } from '../environment-selectors';
import { getStatusesForGetGraphDirectoryObject } from '../graph-selectors';
import {
    getStatusForDiscoverProjectEnvironmentTypePermissions,
    getStatusForDiscoverProjectPermissions,
} from '../permission-selectors';
import { getStatusesForListPools } from '../pool-selectors';
import {
    getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject,
    getStatusForDiscoverProjectEnvironmentTypesAbilitiesFromDataplane,
    getStatusForDiscoverProjectEnvironmentTypesFromDataplane,
} from '../project-environment-type-from-dataplane-selectors';
import {
    getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject,
    getStatusForDiscoverProjectEnvironmentTypes,
} from '../project-environment-type-selectors';
import {
    getProjectsByDiscoveryServiceURI,
    getProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
    getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead,
    getProjectsFromDiscoveryServiceAuthorizedForEnvironmentRead,
    getProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
    getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization,
    getProjectsFromDiscoveryServiceWithAtLeastOneProjectEnvironmentType,
    getStatusForListProjectsFromDiscoveryService,
} from '../project-from-discovery-service-selectors';
import {
    getCountOfProjects,
    getProjectsAuthorizedForDevBoxCreateByDataPlaneId,
    getProjectsAuthorizedForDevBoxReadByDataPlaneId,
    getProjectsAuthorizedForEnvironmentReadByDataPlaneId,
    getProjectsAuthorizedForEnvironmentWriteByDataPlaneId,
    getProjectsByDataPlaneId,
    getProjectsWithAnyDevResourceAuthorizationByDataPlaneId,
    getProjectsWithAtLeastOneProjectEnvironmentType,
} from '../project-selectors';
import { getStatusesForGetRemoteConnectionByDevBoxId } from '../remote-connection-selectors';
import { getStatusForDiscoverSchedulesForPools } from '../schedule-selectors';
import { getStatusForLoadControlPlaneResources } from '../sub-applications/home-selectors';
import { getStatusForDiscoverLocations } from '../subscription-selectors';

const numberOfSuccessfulStatuses = (ids: string[], statuses: SerializableMap<Status>) =>
    numberOfMatchingElements(ids, (id) => isStatusSuccessful(get(statuses, id)));

/**
 * Composed selectors
 */

const getProjectIdsForEnvironmentsAndProjects: StoreStateSelector<string[]> = createSelector(
    [
        getProjectsByDataPlaneId,
        getProjectsByDiscoveryServiceURI,
        getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject,
        getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject,
        getProjectIdsFromEnvironments,
    ],
    (
        projects,
        projectsFromDiscoveryService,
        projectEnvironmentTypesAuthorizedForEnvironmentCreate,
        projectEnvironmentTypesAuthorizedForEnvironmentCreateFromDataPlane,
        projectIdsFromEnvironments
    ) => {
        const isDiscoveryFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        let idsOfProjectsWithAuthorizedProjectEnvironmentTypes: string[] = [];

        if (isDiscoveryFeatureFlagEnabled) {
            idsOfProjectsWithAuthorizedProjectEnvironmentTypes = keys(
                getProjectsFromDiscoveryServiceWithAtLeastOneProjectEnvironmentType(
                    projectsFromDiscoveryService,
                    projectEnvironmentTypesAuthorizedForEnvironmentCreateFromDataPlane
                )
            );
        } else {
            idsOfProjectsWithAuthorizedProjectEnvironmentTypes = keys(
                getProjectsWithAtLeastOneProjectEnvironmentType(
                    projects,
                    projectEnvironmentTypesAuthorizedForEnvironmentCreate
                )
            );
        }
        return unique([...idsOfProjectsWithAuthorizedProjectEnvironmentTypes, ...projectIdsFromEnvironments]);
    }
);

export const getAreAllGraphDirectoryObjectsSucceeded: StoreStateSelector<boolean> = createSelector(
    [getStatusesForGetGraphDirectoryObject],
    (statuses) => size(statuses) > 0 && every(statuses, isStatusSuccessful)
);

export const getNumberOfAllListDevBoxActionsRequests: StoreStateSelector<number> = createSelector(
    [getDevBoxes],
    (devBoxes) => devBoxes.filter(getIsDevBoxInUsableProvisioningState).length
);

export const getNumberOfAllListDevBoxesRequests: StoreStateSelector<number> = createSelector(
    [
        getStatusForDiscoverProjectPermissions,
        getProjectsAuthorizedForDevBoxReadByDataPlaneId,
        getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead,
        getCountOfProjects,
    ],
    (
        statusForDiscoverProjectPermissions,
        projectsAuthorizedForDevBoxRead,
        projectsFromDiscoveryServiceAuthorizedForDevBoxRead,
        projectsTotal
    ) => {
        const isDiscoveryServiceFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        // Until permissions are known, assume we're going to need to load dev boxes for every project.
        // (Overcounting in this way prevents regress in the loading bar)
        if (isDiscoveryServiceFeatureFlagEnabled) {
            return size(projectsFromDiscoveryServiceAuthorizedForDevBoxRead);
        } else if (isStatusSuccessful(statusForDiscoverProjectPermissions)) {
            return size(projectsAuthorizedForDevBoxRead);
        } else {
            return projectsTotal;
        }
    }
);

export const getNumberOfAllListEnvironmentDefinitionsRequests: StoreStateSelector<number> = createSelector(
    [
        getProjectIdsForEnvironmentsAndProjects,
        getProjectsAuthorizedForEnvironmentWriteByDataPlaneId,
        getStatusForDiscoverProjectEnvironmentTypePermissions,
        getStatusForDiscoverProjectEnvironmentTypes,
        getProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
        getStatusForDiscoverProjectEnvironmentTypesFromDataplane,
        getStatusForDiscoverProjectEnvironmentTypesAbilitiesFromDataplane,
    ],
    (
        projectIds,
        projectsAuthorizedForEnvironmentWrite,
        statusForDiscoverProjectEnvironmentTypePermissions,
        statusForDiscoverProjectEnvironmentTypes,
        projectsAuthorizedForEnvironmentWriteFromDiscoveryService,
        statusForDiscoverProjectEnvironmentTypesFromDataplane,
        statusForDiscoverProjectEnvironmentTypesAbilitiesFromDataplane
    ) => {
        const isDiscoveryFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        if (isDiscoveryFeatureFlagEnabled) {
            if (
                isStatusSuccessful(statusForDiscoverProjectEnvironmentTypesFromDataplane) &&
                isStatusSuccessful(statusForDiscoverProjectEnvironmentTypesAbilitiesFromDataplane)
            ) {
                return projectIds.length;
            }

            const devCenters = unique(
                keys(projectsAuthorizedForEnvironmentWriteFromDiscoveryService).map((id) => {
                    const { devCenter } = getTokensFromProjectDataPlaneUri(id);
                    return devCenter;
                })
            );

            return devCenters.length;
        }
        // If we already know exactly which projects require environment definitions, count those
        if (
            isStatusSuccessful(statusForDiscoverProjectEnvironmentTypePermissions) &&
            isStatusSuccessful(statusForDiscoverProjectEnvironmentTypes)
        ) {
            return projectIds.length;
        }

        // Otherwise, assume we'll need to cover all projects. Count the number of dev centers.
        // (While we list environment definitions at a project level, catalogs are currently dev center-level)
        const devCenters = unique(
            keys(projectsAuthorizedForEnvironmentWrite).map((id) => {
                const { devCenter } = getTokensFromProjectDataPlaneUri(id);
                return devCenter;
            })
        );

        return devCenters.length;
    }
);

export const getNumberOfAllListEnvironmentsRequests: StoreStateSelector<number> = createSelector(
    [
        getStatusForDiscoverProjectPermissions,
        getProjectsAuthorizedForEnvironmentReadByDataPlaneId,
        getCountOfProjects,
        getProjectsFromDiscoveryServiceAuthorizedForEnvironmentRead,
    ],
    (
        statusForDiscoverProjectPermissions,
        projectsAuthorizedForEnvironmentRead,
        projectsTotal,
        projectsFromDiscoveryServiceAuthorizedForEnvironmentRead
    ) => {
        const isDiscoveryServiceFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);
        if (isDiscoveryServiceFeatureFlagEnabled) {
            return size(projectsFromDiscoveryServiceAuthorizedForEnvironmentRead);
        } else if (isStatusSuccessful(statusForDiscoverProjectPermissions)) {
            return size(projectsAuthorizedForEnvironmentRead);
        } else {
            return projectsTotal;
        }
    }
);

export const getNumberOfAllListPoolsRequests: StoreStateSelector<number> = createSelector(
    [
        getStatusForDiscoverProjectPermissions,
        getProjectsAuthorizedForDevBoxCreateByDataPlaneId,
        getProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        getCountOfProjects,
    ],
    (
        statusForDiscoverProjectPermissions,
        projectsAuthorizedForDevBoxCreate,
        projectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        projectsTotal
    ) => {
        const isDiscoveryServiceFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        if (isDiscoveryServiceFeatureFlagEnabled) {
            return size(projectsFromDiscoveryServiceAuthorizedForDevBoxCreate);
        } else if (isStatusSuccessful(statusForDiscoverProjectPermissions)) {
            return size(projectsAuthorizedForDevBoxCreate);
        }

        // Until permissions are known, assume we're going to need to load pools for every project.
        // (Overcounting in this way prevents regress in the loading bar)
        return projectsTotal;
    }
);

export const getNumberOfAllRemoteConnectionRequests: StoreStateSelector<number> = createSelector(
    [getDevBoxes],
    (devBoxes) => devBoxes.filter(getIsDevBoxInUsableProvisioningState).length
);

export const getNumberOfAllWarmDevCenterNameRecordRequests: StoreStateSelector<number> = createSelector(
    [
        getStatusForDiscoverProjectPermissions,
        getProjectsWithAnyDevResourceAuthorizationByDataPlaneId,
        getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization,
    ],
    (
        statusForDiscoverProjectPermissions,
        projectsWithAnyDevResourceAuthorization,
        projectsFromDiscoveryServiceWithAnyDevResourceAuthorization
    ) => {
        const isDiscoveryServiceFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);
        const projectsToUse = isDiscoveryServiceFeatureFlagEnabled
            ? projectsFromDiscoveryServiceWithAnyDevResourceAuthorization
            : projectsWithAnyDevResourceAuthorization;
        const devCenters = getUniqueDevCentersFromProjectDataPlaneIds(keys(projectsToUse));

        if (isDiscoveryServiceFeatureFlagEnabled) {
            return devCenters.length;
        }
        return isStatusSuccessful(statusForDiscoverProjectPermissions) ? devCenters.length : 1;
    }
);

export const getNumberOfSuccessfulGetProjectEnvironmentTypePermissionsRequests: StoreStateSelector<number> =
    createSelector([getStatusForDiscoverProjectEnvironmentTypePermissions], (status) =>
        isStatusSuccessful(status) ? 1 : 0
    );

export const getNumberOfSuccessfulGetProjectPermissionsRequests: StoreStateSelector<number> = createSelector(
    [getStatusForDiscoverProjectPermissions],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);

export const getNumberOfSuccessfulGetScheduleRequests: StoreStateSelector<number> = createSelector(
    [getStatusForDiscoverSchedulesForPools],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);

export const getNumberOfSuccessfulListDevBoxActionRequests: StoreStateSelector<number> = createSelector(
    [getDevBoxes, getStatusesForListDevBoxActionsByDevBoxId],
    (devBoxes, statuses) =>
        numberOfSuccessfulStatuses(
            devBoxes.filter(getIsDevBoxInUsableProvisioningState).map((devBox) => devBox.uri),
            statuses
        )
);

export const getNumberOfSuccessfulListDevBoxesRequests: StoreStateSelector<number> = createSelector(
    [getProjectsByDataPlaneId, getProjectsByDiscoveryServiceURI, getStatusesForListDevBoxesByProjectId],
    (projects, projectsFromDiscoveryService, statuses) => {
        const projectsToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? projectsFromDiscoveryService
            : projects;
        return numberOfSuccessfulStatuses(keys(projectsToUse), statuses);
    }
);

export const getNumberOfSuccessfulListEnvironmentDefinitionsRequests: StoreStateSelector<number> = createSelector(
    [getProjectIdsForEnvironmentsAndProjects, getStatusesForListEnvironmentDefinitions],
    (ids, statuses) => numberOfSuccessfulStatuses(ids, statuses)
);

export const getNumberOfSuccessfulListEnvironmentsRequests: StoreStateSelector<number> = createSelector(
    [getProjectsByDataPlaneId, getProjectsByDiscoveryServiceURI, getStatusesForListEnvironments],
    (projects, projectsFromDiscoveryService, statuses) => {
        const projectsToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? projectsFromDiscoveryService
            : projects;
        return numberOfSuccessfulStatuses(keys(projectsToUse), statuses);
    }
);

export const getNumberOfSuccessfulListLocationsRequests: StoreStateSelector<number> = createSelector(
    [getStatusForDiscoverLocations],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);

export const getNumberOfSuccessfulListPoolsRequests: StoreStateSelector<number> = createSelector(
    [
        getProjectsAuthorizedForDevBoxCreateByDataPlaneId,
        getProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        getStatusesForListPools,
    ],
    (projects, projectsFromDiscoveryService, statuses) => {
        const numberOfSuccessfulListPoolsRequests = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? numberOfSuccessfulStatuses(keys(projectsFromDiscoveryService), statuses)
            : numberOfSuccessfulStatuses(keys(projects), statuses);
        return numberOfSuccessfulListPoolsRequests;
    }
);

export const getNumberOfSuccessfulListProjectEnvironmentTypesRequests: StoreStateSelector<number> = createSelector(
    [getStatusForDiscoverProjectEnvironmentTypes],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);

export const getNumberOfSuccessfulLoadControlPlaneResourcesRequests: StoreStateSelector<number> = createSelector(
    [getStatusForLoadControlPlaneResources],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);

export const getNumberOfSuccessfulRemoteConnectionRequests: StoreStateSelector<number> = createSelector(
    [getDevBoxes, getStatusesForGetRemoteConnectionByDevBoxId],
    (devBoxes, statuses) =>
        numberOfSuccessfulStatuses(
            devBoxes.filter(getIsDevBoxInUsableProvisioningState).map((devBox) => devBox.uri),
            statuses
        )
);

export const getNumberOfSuccessfulWarmDevCenterNameRecordRequests: StoreStateSelector<number> = createSelector(
    [
        getProjectsWithAnyDevResourceAuthorizationByDataPlaneId,
        getStatusesForWarmDevCenterNameRecord,
        getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization,
    ],
    (projects, statuses, projectsFromDiscoveryService) => {
        if (isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)) {
            return numberOfSuccessfulStatuses(
                getUniqueDevCentersFromProjectDataPlaneIds(keys(projectsFromDiscoveryService)),
                statuses
            );
        }

        return numberOfSuccessfulStatuses(getUniqueDevCentersFromProjectDataPlaneIds(keys(projects)), statuses);
    }
);

export const getNumberOfSuccessfulProjectsFromDiscoveryServiceRequests: StoreStateSelector<number> = createSelector(
    [getStatusForListProjectsFromDiscoveryService],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);

export const getNumberOfSuccessfulProjectEnvironmentTypesFromDataplaneRequests: StoreStateSelector<number> =
    createSelector([getStatusForDiscoverProjectEnvironmentTypesFromDataplane], (status) =>
        isStatusSuccessful(status) ? 1 : 0
    );

export const getNumberOfSuccessfulProjectEnvironmentTypeAbilitiesRequests: StoreStateSelector<number> = createSelector(
    [getStatusForDiscoverProjectEnvironmentTypesAbilitiesFromDataplane],
    (status) => (isStatusSuccessful(status) ? 1 : 0)
);
