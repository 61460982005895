import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { getTokensFromDevBoxDataPlaneUri } from '../../ids/dev-box';
import { createSnapshotDataPlaneUri } from '../../ids/snapshot';
import { Entity } from '../../models/common';
import {
    listSnapshots,
    listSnapshotsError,
    listSnapshotsFailed,
    listSnapshotsSuccess,
} from '../actions/snapshot/snapshot-action-creators';
import { snapshotAdapter } from '../adapters/snapshot-adapters';
import { SnapshotDataStore, SnapshotStatusStore, SnapshotStore } from '../store/snapshot-store';
import { getPayload } from '../utilities/payload-action';
import { createStatusReducer } from './status-reducer';

export const snapshotReducer = combineReducers<SnapshotStore>({
    data: createReducer(SnapshotDataStore(), (builder) => {
        builder.addCase(listSnapshotsSuccess, (store, action) => {
            const { result, id } = getPayload(action);
            const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

            snapshotAdapter.setMany(
                store.snapshots,
                result.map((data) => {
                    // TODO: create a uri for the snapshot contract model so that we won't have to create the uri by ourselves.
                    const uri = createSnapshotDataPlaneUri({
                        snapshotId: data.snapshotId,
                        devBoxName,
                        devCenter,
                        projectName,
                        user,
                    });

                    return Entity(uri, { ...data, uri });
                })
            );
        });
    }),

    status: combineReducers<SnapshotStatusStore>({
        listSnapshots: createStatusReducer({
            inProgress: listSnapshots,
            error: listSnapshotsError,
            failed: listSnapshotsFailed,
            success: listSnapshotsSuccess,
        }),
    }),
});
