import {
    Badge,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    FluentProvider,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { DismissableContentName } from '../../../constants/app';
import { useActionCreator } from '../../../hooks/action-creator';
import { useHibernatePreviewDialogContext } from '../../../hooks/context/dialogs';
import { useOnChangeCallbackForCheckbox } from '../../../hooks/forms';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { dismissContent as dismissContentActionCreator } from '../../../redux/actions/application/application-action-creators';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';

export interface HibernatePreviewDialogProps {
    onDismiss: () => void;
    devBoxName: string;
    onHibernateSubmitted: () => void;
}

const messages = defineMessages({
    dontShowAgainCheckboxLabel: {
        id: 'HibernateDialog_DontShowAgain',
        defaultMessage: "Don't show this again",
        description:
            'Label for a checkbox on the hibernate preview dialog - when checked, submitting this form will stop us from showing this user the hibernate preview dialog again.',
    },
    hibernateButtonLabel: {
        id: 'HibernateDialog_HibernateButton_Label',
        defaultMessage: 'Hibernate {devBoxName}',
        description: 'Label for the submit button on the hibernate dialog. Do not localize {devBoxName}.',
    },
    cancelButtonLabel: {
        id: 'HibernateDialog_CancelButton_Label',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button on the hibernate dialog.',
    },
    closeButtonAriaLabel: {
        id: 'HibernateDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the "x" button in the top corner of the hibernate dialog',
    },
});

/**
 * Styles
 */

const dialogWidth = '569px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

const useDialogStackStyles = makeStyles({
    root: {
        gap: '18px',
        padding: '0px 0px 30px 0px',
    },
});

const useTitleStyles = makeStyles({
    root: {
        gap: '10px',
    },
});

/**
 * END Styles
 */

const HibernateDialogTitle: React.FC = () => {
    const horizontalStackStyles = useHorizontalStackStyles();
    const titleStyles = useTitleStyles();

    return (
        <div className={mergeClasses(horizontalStackStyles.root, titleStyles.root)}>
            <div className={horizontalStackStyles.item}>
                <FormattedMessage
                    id="HibernateDialog_Title_Hibernate"
                    defaultMessage="Hibernate"
                    description="The title for the dialog warning users that hibernate is a preview feature."
                />
            </div>
            <Badge appearance="outline" color="informative" size="large">
                <FormattedMessage
                    id="HibernateDialog_Preview_Badge"
                    defaultMessage="Preview"
                    description="Tag used to indicate that a feature is in preview"
                />
            </Badge>
        </div>
    );
};

const HibernatePreviewDialogComponent: React.FC<HibernatePreviewDialogProps> = (props: HibernatePreviewDialogProps) => {
    const { onDismiss, devBoxName, onHibernateSubmitted } = props;

    // Action creator hooks
    const dismissContent = useActionCreator(dismissContentActionCreator);

    // State hooks
    const [dontShowAgainChecked, setDontShowAgainChecked] = React.useState(false);

    // Intl hooks
    const { formatMessage } = useIntl();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Style hooks
    const stackStyles = useStackStyles();
    const dialogStackStyles = useDialogStackStyles();

    // Callback hooks
    const onCheckboxChange = useOnChangeCallbackForCheckbox(setDontShowAgainChecked);

    const onSubmit = React.useCallback(() => {
        if (dontShowAgainChecked) {
            dismissContent({ content: DismissableContentName.HibernatePreviewDialog });
        }

        onHibernateSubmitted();
        onDismiss();
    }, [dontShowAgainChecked, onHibernateSubmitted, onDismiss, dismissContent]);

    const hibernateButtonTextValues = React.useMemo(() => ({ devBoxName }), [devBoxName]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={true} onOpenChange={onDismiss}>
                <DialogSurface style={dialogStyle}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <HibernateDialogTitle />
                        </DialogTitle>
                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, dialogStackStyles.root)}>
                                <div className={stackStyles.item}>
                                    <FormattedMessage
                                        id="HibernateDialog_Description"
                                        defaultMessage="The hibernate feature is in preview, and in rare cases resuming hibernated machines might not work as expected. Should issues occur, they can be resolved by restarting the dev box, but any unsaved work will be lost."
                                        description="The description for the hibernate preview dialog"
                                    />
                                </div>
                                <Divider />
                                <div className={stackStyles.item}>
                                    <Checkbox
                                        label={formatMessage(messages.dontShowAgainCheckboxLabel)}
                                        onChange={onCheckboxChange}
                                        checked={dontShowAgainChecked}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="primary" onClick={onSubmit}>
                                <FormattedMessage
                                    id="HibernateDialog_HibernateButton_Label"
                                    defaultMessage="Hibernate {devBoxName}"
                                    description="Label for the submit button on the hibernate dialog. Do not localize {devBoxName}."
                                    values={hibernateButtonTextValues}
                                />
                            </Button>
                            <DialogTrigger>
                                <Button appearance="secondary">
                                    <FormattedMessage
                                        id="HibernateDialog_CancelButton_Label"
                                        defaultMessage="Cancel"
                                        description="Label for the cancel button on the hibernate dialog."
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

const HibernatePreviewDialog: React.FC = () => {
    // Context hooks
    const { closeSurface: closeDialog, properties } = useHibernatePreviewDialogContext();

    return <HibernatePreviewDialogComponent {...properties} onDismiss={closeDialog} />;
};

export const HibernatePreviewDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useHibernatePreviewDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <HibernatePreviewDialog />;
};
