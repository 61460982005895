import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import favicon from './content/images/favicon.ico';
import { getLocale } from './redux/selector/localization-selectors';
import { isDevPortalEnvironment } from './utilities/app';

// Note: copied from the react-helmet typedef because its not exported
interface OtherElementAttributes {
    [key: string]: string | number | boolean | null | undefined;
}

type HtmlProps = JSX.IntrinsicElements['html'] & OtherElementAttributes;

interface Props {
    locale: string;
}

export type HeadComponentProps = Props;

const messages = defineMessages({
    devBoxTitle: {
        id: 'Head_Title_Text',
        defaultMessage: 'Microsoft Dev Box',
        description: 'The website title.',
    },
    devPortalTitle: {
        id: 'Head_Title_DevPortal_Text',
        defaultMessage: 'Microsoft developer portal',
        description: 'The website title for devportal prefixed hosts.',
    },
});

// TODO: Task 2286541: Show only Microsoft logo before sign-in
const titleMessage = isDevPortalEnvironment ? messages.devPortalTitle : messages.devBoxTitle;

export const HeadComponent: React.FC<Props> = (props) => {
    const { formatMessage } = useIntl();
    const { locale } = props;

    // title must be a raw string so have to resolve the message
    const title = formatMessage(titleMessage);

    // Note: We will need to determine rtl / ltr when we support rtl languages
    const htmlAttributes: HtmlProps = React.useMemo(() => ({ lang: locale, dir: 'ltr' }), [locale]);

    return (
        <Helmet htmlAttributes={htmlAttributes}>
            <link rel="icon" href={favicon} />
            <title>{title}</title>
        </Helmet>
    );
};

export const HeadContainer: React.FC = () => {
    const locale = useSelector(getLocale);

    return <HeadComponent locale={locale} />;
};

export const Head = HeadContainer;
export default Head;
