import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LatencyIcon } from '../../../icons/latency-icon';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';

interface LatencyBandItemProps {
    latencyBand: string;
}

/**
 * Styles
 */

const useDropdownOptionContainerStyles = makeStyles({
    root: {
        gap: '6px',
    },
});

/**
 * End Styles
 */

export const LatencyBandItem: React.FC<LatencyBandItemProps> = (props: LatencyBandItemProps) => {
    const { latencyBand } = props;

    // Style hooks
    const dropdownOptionContainerStyles = useDropdownOptionContainerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    const latencyValues = React.useMemo(() => ({ latencyBand }), [latencyBand]);

    return (
        <div className={mergeClasses(horizontalStackStyles.root, dropdownOptionContainerStyles.root)}>
            <div className={mergeClasses(horizontalStackStyles.item)}>
                <LatencyIcon latencyBand={latencyBand} />
            </div>
            <div className={mergeClasses(horizontalStackStyles.item)}>
                <FormattedMessage
                    id="SelectDevBoxRegionDetailsList_LatencyBand_Text"
                    defaultMessage="{latencyBand}"
                    description="Dropdown option text to show latency band."
                    values={latencyValues}
                />
            </div>
        </div>
    );
};

export default LatencyBandItem;
