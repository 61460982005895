import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useRestoreSnapshotDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { DevBoxSnapshot } from '../../../models/dev-box';
import { RestoreFormData } from './model';
import RestoreSnapshotDialogForm from './restore-snapshot-dialog-form';
import { createInitialValues } from './selectors';

export interface RestoreSnapshotDialogComponentProps {
    devBoxId: string;
    snapshots?: DevBoxSnapshot[];
    open?: boolean;
    locale: string;
    onDismiss: () => void;
    onRestoreSubmitted: (devBoxId: string, snapshotId: string) => void;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'RestoreSnapshotDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for "close" button in the "Snapshot Restoration" dialog',
    },
});

/**
 * Styles
 */

const dialogWidth = '480px';

const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

/**
 * END Styles
 */

const RestoreSnapshotDialog: React.FC<RestoreSnapshotDialogComponentProps> = (
    props: RestoreSnapshotDialogComponentProps
) => {
    const { open, devBoxId, locale, snapshots, onDismiss, onRestoreSubmitted } = props;

    const { devBoxName } = getTokensFromDevBoxDataPlaneUri(devBoxId);

    // Intl hooks
    const { formatMessage } = useIntl();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Memoized data
    const values = React.useMemo(() => ({ devBoxName }), [devBoxName]);

    const initialValues: RestoreFormData = React.useMemo(() => createInitialValues(), []);

    return (
        <FluentProvider theme={theme}>
            <Dialog open={open} onOpenChange={onDismiss}>
                <DialogSurface style={dialogStyle}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="RestoreSnapshotDialog_Header_Text"
                                defaultMessage="Restore {devBoxName}?"
                                description="Title of the 'Snapshot Restoration' dialog. {devBoxName} should not be localized."
                                values={values}
                            />
                        </DialogTitle>
                        <RestoreSnapshotDialogForm
                            devBoxId={devBoxId}
                            snapshots={snapshots}
                            locale={locale}
                            initialValues={initialValues}
                            onRestoreSubmitted={onRestoreSubmitted}
                            onDismiss={onDismiss}
                        />
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const RestoreSnapshotDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useRestoreSnapshotDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <RestoreSnapshotDialog {...properties} open={isOpen} onDismiss={closeSurface} />;
};

export default RestoreSnapshotDialogContextWrapper;
