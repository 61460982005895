import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import ActionBarContainer from '../components/action-bar/action-bar';
import { ProjectFilterContextProvider } from '../components/action-bar/project-picker/contexts';
import { ResourceFilterContextProvider } from '../components/action-bar/resource-picker/contexts';
import AddDevBoxPanel from '../components/add-dev-box-panel/add-dev-box-panel';
import { AddDevBoxPanelContextProvider } from '../components/add-dev-box-panel/contexts';
import AddEnvironmentPanel from '../components/add-environment-panel/add-environment-panel';
import { AddEnvironmentPanelContextProvider } from '../components/add-environment-panel/contexts';
import ConfirmationDialogContextWrapper from '../components/common/confirmation-dialog/confirmation-dialog';
import { ConfirmationDialogContextProvider } from '../components/common/confirmation-dialog/contexts';
import Footer from '../components/common/footer';
import { CustomizationDetailsPanelContextProvider } from '../components/customization-details-panel/contexts';
import CustomizationDetailsPanel from '../components/customization-details-panel/customization-details-panel';
import { ConfigureRemoteDesktopDialogContextWrapper } from '../components/dev-box/configure-remote-desktop-dialog/configure-remote-desktop-dialog';
import { ConfigureRemoteDesktopDialogContextProvider } from '../components/dev-box/configure-remote-desktop-dialog/contexts';
import { DelayShutdownDialogContextProvider } from '../components/dev-box/delay-shutdown-dialog/contexts';
import DelayShutdownDialogContextWrapper from '../components/dev-box/delay-shutdown-dialog/delay-shutdown-dialog';
import { DevBoxDetailsPanelContextProvider } from '../components/dev-box/dev-box-details-panel/context';
import { DevBoxDetailsPanelContainer } from '../components/dev-box/dev-box-details-panel/dev-box-details-panel';
import { DevBoxSupportPanelContextProvider } from '../components/dev-box/dev-box-support-panel/context';
import { DevBoxSupportPanelContextWrapper } from '../components/dev-box/dev-box-support-panel/dev-box-support-panel';
import { HibernatePreviewDialogContextProvider } from '../components/dev-box/hibernate-preview-dialog/context';
import { HibernatePreviewDialogContextWrapper } from '../components/dev-box/hibernate-preview-dialog/hibernate-preview-dialog';
import { RepairDialogContextProvider } from '../components/dev-box/repair-dialog/context';
import { RepairDialogContextWrapper } from '../components/dev-box/repair-dialog/repair-dialog';
import { RestoreSnapshotDialogContextProvider } from '../components/dev-box/restore-snapshot-dialog/contexts';
import RestoreSnapshotDialogContextWrapper from '../components/dev-box/restore-snapshot-dialog/restore-snapshot-dialog';
import { DevMenuPanelContextProvider } from '../components/dev-menu-panel/contexts';
import DevMenuPanel from '../components/dev-menu-panel/dev-menu-panel';
import { ChangeEnvironmentExpirationDialogContextWrapper } from '../components/environment/change-expiration-dialog/change-environment-expiration-dialog';
import { ChangeEnvironmentExpirationDialogContextProvider } from '../components/environment/change-expiration-dialog/contexts';
import { EnvironmentDetailsPanelContextProvider } from '../components/environment/environment-details-panel/context';
import EnvironmentDetailsPanelContainer from '../components/environment/environment-details-panel/environment-details-panel';
import { RedeployEnvironmentDialogContextProvider } from '../components/environment/redeploy-environment-dialog/contexts';
import { RedeployEnvironmentDialogContextWrapper } from '../components/environment/redeploy-environment-dialog/redeploy-environment-dialog';
import { ErrorDetailsPanelContextProvider } from '../components/error-details-panel/contexts';
import ErrorDetailsPanel from '../components/error-details-panel/error-details-panel';
import { HelpMenuPanelContextProvider } from '../components/help-menu-panel/contexts';
import HelpMenuPanel from '../components/help-menu-panel/help-menu-panel';
import WhenSignedIn from '../components/identity/when-signed-in';
import Information from '../components/information/information';
import { RedeployEnvironmentPanelContextProvider } from '../components/redeploy-environment-panel/contexts';
import RedeployEnvironmentPanel from '../components/redeploy-environment-panel/redeploy-environment-panel';
import TitleBar from '../components/title-bar/title-bar';
import ConnectViaAppDialogContextWrapper from '../components/user-settings/connect-via-app-dialog/connect-via-app-dialog';
import { ConnectViaAppDialogContextProvider } from '../components/user-settings/connect-via-app-dialog/contexts';
import { UserSettingsPanelContextProvider } from '../components/user-settings/user-settings-menu-panel/contexts';
import UserSettingsPanel from '../components/user-settings/user-settings-menu-panel/user-settings-panel';
import { useStackStyles } from '../themes/styles/flexbox-styles';
import { PropsWithChildren } from '../types/props-with-children';

type Props = PropsWithChildren<{
    id: string;
    showFooter?: boolean;
    hideActionBar?: boolean;
    isScrolled?: boolean;
}>;

export type PortalLayoutProps = Props;

/**
 * Styles
 */

const useLayoutStyles = makeStyles({
    container: {
        position: 'relative',
        top: 0,
        bottom: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        colorScheme: 'dark',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    header: {
        height: 'fit-content',
        width: '100vw',
    },
    body: {
        position: 'relative',
        top: 0,
        bottom: 0,
        left: 0,
        overflowY: 'auto',
        overflowX: 'auto',
        flexGrow: 1,
        flexBasis: 0,
        minHeight: 0,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    },
    main: {
        minWidth: '100%',
        alignSelf: 'start',
        flexGrow: '1',
    },
    footer: {
        minWidth: '100vw',
        alignSelf: 'end',
    },
});

/**
 * End Styles
 */

const PanelsContextProvider: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    return (
        <AddDevBoxPanelContextProvider>
            <AddEnvironmentPanelContextProvider>
                <DevMenuPanelContextProvider>
                    <UserSettingsPanelContextProvider>
                        <ErrorDetailsPanelContextProvider>
                            <HelpMenuPanelContextProvider>
                                <DevBoxDetailsPanelContextProvider>
                                    <RedeployEnvironmentPanelContextProvider>
                                        <CustomizationDetailsPanelContextProvider>
                                            <EnvironmentDetailsPanelContextProvider>
                                                <DevBoxSupportPanelContextProvider>
                                                    {children}
                                                </DevBoxSupportPanelContextProvider>
                                            </EnvironmentDetailsPanelContextProvider>
                                        </CustomizationDetailsPanelContextProvider>
                                    </RedeployEnvironmentPanelContextProvider>
                                </DevBoxDetailsPanelContextProvider>
                            </HelpMenuPanelContextProvider>
                        </ErrorDetailsPanelContextProvider>
                    </UserSettingsPanelContextProvider>
                </DevMenuPanelContextProvider>
            </AddEnvironmentPanelContextProvider>
        </AddDevBoxPanelContextProvider>
    );
};

const DialogsContextProvider: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    return (
        <ConnectViaAppDialogContextProvider>
            <ConfigureRemoteDesktopDialogContextProvider>
                <DelayShutdownDialogContextProvider>
                    <ConfirmationDialogContextProvider>
                        <RedeployEnvironmentDialogContextProvider>
                            <RepairDialogContextProvider>
                                <HibernatePreviewDialogContextProvider>
                                    <ChangeEnvironmentExpirationDialogContextProvider>
                                        <RestoreSnapshotDialogContextProvider>
                                            {children}
                                        </RestoreSnapshotDialogContextProvider>
                                    </ChangeEnvironmentExpirationDialogContextProvider>
                                </HibernatePreviewDialogContextProvider>
                            </RepairDialogContextProvider>
                        </RedeployEnvironmentDialogContextProvider>
                    </ConfirmationDialogContextProvider>
                </DelayShutdownDialogContextProvider>
            </ConfigureRemoteDesktopDialogContextProvider>
        </ConnectViaAppDialogContextProvider>
    );
};

const SurfacesProvider: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    return (
        <PanelsContextProvider>
            <DialogsContextProvider>
                <AddDevBoxPanel />
                <AddEnvironmentPanel />
                <DevMenuPanel />
                <UserSettingsPanel />
                <ErrorDetailsPanel />
                <CustomizationDetailsPanel />
                <HelpMenuPanel />
                <DevBoxDetailsPanelContainer />
                <DevBoxSupportPanelContextWrapper />
                <RedeployEnvironmentPanel />
                <DelayShutdownDialogContextWrapper />
                <ConfigureRemoteDesktopDialogContextWrapper />
                <ConfirmationDialogContextWrapper />
                <RedeployEnvironmentDialogContextWrapper />
                <ChangeEnvironmentExpirationDialogContextWrapper />
                <HibernatePreviewDialogContextWrapper />
                <RepairDialogContextWrapper />
                <EnvironmentDetailsPanelContainer />
                <ConnectViaAppDialogContextWrapper />
                <RestoreSnapshotDialogContextWrapper />
                {children}
            </DialogsContextProvider>
        </PanelsContextProvider>
    );
};

const FiltersContextProvider: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    return (
        <ResourceFilterContextProvider>
            <ProjectFilterContextProvider>{children}</ProjectFilterContextProvider>
        </ResourceFilterContextProvider>
    );
};

const FiltersProvider: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    return <FiltersContextProvider>{children}</FiltersContextProvider>;
};

export const PortalLayout: React.FC<Props> = (props) => {
    const { children, id, showFooter, hideActionBar, isScrolled } = props;

    // Style hooks
    const styles = useLayoutStyles();
    const stackStyles = useStackStyles();

    return (
        <SurfacesProvider>
            <FiltersProvider>
                <div id="portal-layout-container" className={styles.container}>
                    <div id="portal-layout-content" className={styles.content}>
                        <div id="portal-layout-header" className={styles.header}>
                            <TitleBar isScrolled={isScrolled} />

                            <WhenSignedIn>
                                {!hideActionBar && (
                                    <>
                                        <ActionBarContainer />
                                        <Information />
                                    </>
                                )}
                            </WhenSignedIn>
                        </div>
                        <div id="portal-layout-body" className={mergeClasses(stackStyles.root, styles.body)}>
                            <div id={id} role="main" className={mergeClasses(stackStyles.item, styles.main)}>
                                {children}
                            </div>
                            {showFooter && (
                                <div className={mergeClasses(stackStyles.item, styles.footer)}>
                                    <Footer />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </FiltersProvider>
        </SurfacesProvider>
    );
};
