import {
    Button,
    DialogActions,
    DialogContent,
    DialogTrigger,
    makeStyles,
    mergeClasses,
    Spinner,
} from '@fluentui/react-components';
import * as React from 'react';
import { Form } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Status } from '../../../models/common';
import { Environment, EnvironmentUpdateProperties } from '../../../models/environment';
import { isStatusTerminal } from '../../../redux/selector/common';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { convertDateToTimeZoneDate, getDefaultTimeZone } from '../../../utilities/time';
import { EnvironmentExpirationFieldErrorType } from '../../add-environment-panel/add-environment-form-field-group/models';
import { getEnvironmentExpirationFieldErrorType } from '../../add-environment-panel/add-environment-form-field-group/selectors';
import { getExpirationDate } from '../../add-environment-panel/selectors';
import { AddOrUpdateExpirationFormFieldGroup } from '../expiration-date/add-or-update-expiration-form-field-group/add-or-update-expiration-form-field-group';
import {
    AddOrUpdateExpirationFormData as ChangeEnvironmentExpirationFormData,
    EnvironmentExpirationViewModel,
    AddOrUpdateExpirationFormErrorsByField as ManageExpirationDateFormErrorsByField,
} from '../expiration-date/add-or-update-expiration-form-field-group/models';

interface ChangeEnvironmentExpirationDialogFormProps {
    environment: Environment;
    onDismiss: () => void;
    onSubmit: (id: string, properties: EnvironmentUpdateProperties) => void;
    status: Status;
}

const messages = defineMessages({
    expirationDateControlInvalidError: {
        id: 'AddEnvironmentPanel_ExpirationDateControl_InvalidError',
        defaultMessage: 'The expiration date must be in the future',
        description: 'Error text indicating that the expiration date control in the add environment panel is invalid',
    },
    submitButtonAriaLabel: {
        id: 'ChangeEnvironmentExpirationForm_ChangeButton_AriaLabel',
        defaultMessage: 'Change',
        description: 'Aria label for the "Change" button',
    },
    cancelButtonAriaLabel: {
        id: 'ChangeEnvironmentExpirationForm_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "Cancel" button',
    },
});

/**
 * Styles
 */

const useDialogContentStyles = makeStyles({
    root: {
        paddingLeft: '6px',
    },
});

/**
 * END Styles
 */

export const ChangeEnvironmentExpirationDialogForm: React.FC<ChangeEnvironmentExpirationDialogFormProps> = (
    props: ChangeEnvironmentExpirationDialogFormProps
) => {
    const { environment, onDismiss, onSubmit, status } = props;
    const { expirationDate: inputExpirationDate, uri } = environment;
    const defaultTimeZone = getDefaultTimeZone();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const dialogContentStyles = useDialogContentStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    const initialExpirationEnabled = !!inputExpirationDate;

    const initialExpiration = React.useMemo(
        () => (initialExpirationEnabled ? convertDateToTimeZoneDate(inputExpirationDate, defaultTimeZone) : undefined),
        [initialExpirationEnabled, inputExpirationDate, defaultTimeZone]
    );

    const initialValues: ChangeEnvironmentExpirationFormData = React.useMemo(
        () => ({
            enableExpiration: initialExpirationEnabled,
            environmentExpiration: {
                expirationCalendarDate: initialExpiration,
                expirationTimeZone: defaultTimeZone,
                expirationTime: initialExpiration,
            },
        }),
        [initialExpirationEnabled, defaultTimeZone, initialExpiration]
    );

    // Callbacks
    const onFormSubmit = React.useCallback(
        (data: ChangeEnvironmentExpirationFormData) => {
            const { enableExpiration, environmentExpiration } = data;
            const isExpirationEnabled = !!enableExpiration;

            if (!isExpirationEnabled) {
                onSubmit(uri, { expirationDate: null });
            } else {
                const environmentExpirationDate = getExpirationDate(environmentExpiration);
                onSubmit(uri, { expirationDate: environmentExpirationDate });
            }
        },
        [onSubmit, uri]
    );

    const isEnvironmentExpirationValid = React.useCallback(
        (
            environmentExpiration: EnvironmentExpirationViewModel | undefined,
            enableExpiration: boolean
        ): string | undefined | boolean => {
            const environmentExpirationErrorType = getEnvironmentExpirationFieldErrorType(
                environmentExpiration,
                enableExpiration
            );

            if (
                environmentExpirationErrorType === EnvironmentExpirationFieldErrorType.DateRequired ||
                environmentExpirationErrorType === EnvironmentExpirationFieldErrorType.TimeRequired
            ) {
                return true;
            }

            return environmentExpirationErrorType === EnvironmentExpirationFieldErrorType.Invalid
                ? formatMessage(messages.expirationDateControlInvalidError)
                : undefined;
        },
        [formatMessage]
    );

    const validateChangeEnvironmentExpirationFormFieldGroup = React.useCallback(
        (values: ChangeEnvironmentExpirationFormData) => {
            const errors: ManageExpirationDateFormErrorsByField = {};

            const environmentExpirationValidationErrors = isEnvironmentExpirationValid(
                values.environmentExpiration,
                values.enableExpiration
            );

            if (environmentExpirationValidationErrors !== undefined) {
                errors.environmentExpiration = environmentExpirationValidationErrors;
            }

            return errors;
        },
        [isEnvironmentExpirationValid]
    );

    return (
        <Form<ChangeEnvironmentExpirationFormData>
            onSubmit={onFormSubmit}
            validate={validateChangeEnvironmentExpirationFormFieldGroup}
            initialValues={initialValues}
        >
            {(formProps) => {
                const { form, valid, submitting, values, pristine } = formProps;
                const { enableExpiration } = values;

                const { reset, submit } = form;

                const isInTerminalState = isStatusTerminal(status);

                const isSubmitting = !isInTerminalState && submitting;

                const submitButtonDisabled = React.useMemo(
                    () => isSubmitting || !valid || pristine,
                    [isSubmitting, valid, pristine]
                );

                const onDismissForm = React.useCallback(() => {
                    reset();
                    onDismiss();
                }, [reset, onDismiss]);

                const onSubmitClicked = React.useCallback(() => {
                    if (valid) {
                        submit();
                    }

                    if (!isSubmitting) {
                        onDismissForm();
                    }
                }, [valid, isSubmitting, submit, onDismissForm]);

                return (
                    <>
                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, dialogContentStyles.root)}>
                                <div className={stackStyles.item}>
                                    <AddOrUpdateExpirationFormFieldGroup
                                        enableExpiration={enableExpiration}
                                        isSubmitting={isSubmitting}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={formatMessage(messages.submitButtonAriaLabel)}
                                onClick={onSubmitClicked}
                                disabled={submitButtonDisabled}
                            >
                                {isSubmitting && <Spinner size="tiny" />}
                                {!isSubmitting && (
                                    <FormattedMessage
                                        id="ChangeEnvironmentExpiration_ChangeButton_Text"
                                        defaultMessage="Change"
                                        description='Text for the "Change" button'
                                    />
                                )}
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                >
                                    <FormattedMessage
                                        id="ChangeEnvironmentExpirationForm_CancelButton_Text"
                                        defaultMessage="Cancel"
                                        description='Text for the "Cancel" button'
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </>
                );
            }}
        </Form>
    );
};

export default ChangeEnvironmentExpirationDialogForm;
