import { AsyncState } from '../../../redux/store/common-state';
import { addHours, getListOfTimesWithInterval, isDayAfterNextDay } from '../../../utilities/time';
import { DelayShutdownSpinButtonOption } from './model';

const inTerminalState = (state: AsyncState): boolean => {
    switch (state) {
        case AsyncState.Error:
        case AsyncState.Failed:
        case AsyncState.Success:
            return true;
        default:
            return false;
    }
};

export const getInitialShutdownValue = (scheduledShutdown?: Date): Date => {
    const currentTime = scheduledShutdown?.getTime() ?? 0;
    const updatedTime = new Date(currentTime + 60 * 60 * 1000);
    return updatedTime;
};

export const skipOrDelayInTerminalState = (skipState: AsyncState, delayState: AsyncState): boolean =>
    inTerminalState(skipState) || inTerminalState(delayState);

export const getSpinButtonOptions = (
    originalScheduledShutdownDate: Date,
    scheduledShutdown: Date | undefined,
    locale: string,
    baselineTime: Date,
    startOffsetInHours: number,
    endOffsetInHours: number,
    skipOptionOffsetInHours: number
): DelayShutdownSpinButtonOption[] => {
    if (!originalScheduledShutdownDate || !scheduledShutdown) {
        return [];
    }

    const startTime = addHours(scheduledShutdown, startOffsetInHours);

    const endTime = addHours(originalScheduledShutdownDate, endOffsetInHours);

    const delayValues = getListOfTimesWithInterval(startTime, endTime, 1, startOffsetInHours);

    const mappedDelayedValues = delayValues.map((dateOffsetInHours) => {
        const { date, offsetInHours } = dateOffsetInHours;
        return {
            dateValue: date,
            locale,
            isSkip: false,
            isSkipNextDay: false,
            offsetInHours,
        };
    });

    const skipOptionTime = addHours(originalScheduledShutdownDate, skipOptionOffsetInHours);

    const isSkipNextDay = isDayAfterNextDay(baselineTime, skipOptionTime);

    mappedDelayedValues.push({
        dateValue: skipOptionTime,
        locale,
        isSkip: true,
        isSkipNextDay,
        offsetInHours: skipOptionOffsetInHours,
    });

    return mappedDelayedValues;
};
