import { FontSizes, FontWeights, Link } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import errorImage from '../content/images/error.svg';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import CenteredSimpleLayout from '../layouts/centered-simple-layout';
import { useStackStyles } from '../themes/styles/flexbox-styles';
import { getSessionID } from '../utilities/telemetry/channel';

interface ErrorPageProps {
    disableLocalizedContent?: boolean;
}

const messages = defineMessages({
    errorImageAlt: {
        id: 'ErrorPage_ErrorImage_Alt',
        defaultMessage: 'An error occurred',
        description: 'Alt text for "error" image',
    },
});

/**
 * Styles
 */

const useMessageContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '344px',
        paddingTop: '6px',
    },
});

const useSessionIdStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '344px',
        paddingTop: '6px',
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        maxWidth: '344px',
        paddingTop: '6px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

/**
 * END Styles
 */

// Note: NOT dispatching action to do this, as Redux may be unavailable at this point.
const onRefreshClicked = () => location.reload();

export const ErrorPageLocalizedComponent: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    const errorMessageValues = React.useMemo(
        () => ({
            Link: (chunks: string) => <Link onClick={onRefreshClicked}>{chunks}</Link>,
        }),
        [onRefreshClicked]
    );

    const sessionIdValues = React.useMemo(() => ({ sessionId: getSessionID() }), []);

    // Style hooks
    const messageContainerStyles = useMessageContainerStyles();
    const sessionIdStyles = useSessionIdStyles();
    const titleContainerStyles = useTitleContainerStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    return (
        <CenteredPortalLayout id="error">
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={stackStyles.item}>
                    <img alt={formatMessage(messages.errorImageAlt)} src={errorImage} />
                </div>

                <div className={mergeClasses(stackStyles.item, titleContainerStyles.root)}>
                    <FormattedMessage
                        id="ErrorPage_ErrorTitle_Text"
                        defaultMessage="Oops, something went wrong."
                        description="Text alerting the user that an error has occurred and they have to reload the page."
                    />
                </div>

                <div className={mergeClasses(stackStyles.item, messageContainerStyles.root)}>
                    <FormattedMessage
                        id="ErrorPage_ErrorMessage_Text"
                        defaultMessage="Please <Link>refresh</Link>, or contact your administrator if this problem continues."
                        description="Message prompting the user to refresh the page. <Link> and </Link> should not be localized."
                        values={errorMessageValues}
                    />
                </div>

                <div className={mergeClasses(stackStyles.item, sessionIdStyles.root)}>
                    <FormattedMessage
                        id="ErrorPage_SessionId_Text"
                        defaultMessage="Session ID: {sessionId}"
                        description="Message containing session ID for debugging purposes. Do not localize {sessionId}."
                        values={sessionIdValues}
                    />
                </div>
            </div>
        </CenteredPortalLayout>
    );
};

export const ErrorPageNonLocalizedComponent: React.FC = () => {
    // Style hooks
    const messageContainerStyles = useMessageContainerStyles();
    const sessionIdStyles = useSessionIdStyles();
    const titleContainerStyles = useTitleContainerStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    return (
        <CenteredSimpleLayout id="error-non-localized">
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={stackStyles.item}>
                    <img alt="An error occurred" src={errorImage} />
                </div>

                <div className={mergeClasses(stackStyles.root, titleContainerStyles.root)}>
                    Oops, something went wrong.
                </div>

                <div className={mergeClasses(stackStyles.item, messageContainerStyles.root)}>
                    Please <Link onClick={onRefreshClicked}>refresh</Link>, or contact your administrator if this
                    problem continues.
                </div>

                <div className={mergeClasses(stackStyles.item, sessionIdStyles.root)}>Session ID: {getSessionID()}</div>
            </div>
        </CenteredSimpleLayout>
    );
};

export const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
    const { disableLocalizedContent } = props;

    return disableLocalizedContent ? <ErrorPageNonLocalizedComponent /> : <ErrorPageLocalizedComponent />;
};

export default ErrorPage;
