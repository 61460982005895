import { Theme as Fluent1Theme } from '@fluentui/react';
import { Theme as Fluent2Theme, webDarkTheme, webLightTheme } from '@fluentui/react-components';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getThemeModeSelector } from '../redux/selector/user-settings-selector';
import { CustomThemes, ThemeMode } from '../themes/themes';

// Allows users to reference the theme being used by "classic" Fluent v1 components.
// TODO #2085494: converge the two hooks into a single `useCurrentFluentTheme` hook once we fully migrate to v2.
export const useCurrentFluent1Theme = (): Fluent1Theme => {
    const currentThemeMode = useSelector(getThemeModeSelector);

    // This checks if the state is changed before returning the theme.
    return useMemo(() => {
        switch (currentThemeMode) {
            case ThemeMode.LightMode:
                return CustomThemes.LightMode;
            case ThemeMode.DarkMode:
            default:
                return CustomThemes.DarkMode; // this is if the dark theme is not the default.
        }
    }, [currentThemeMode]);
};

// Allows users to reference the theme being used by new Fluent v2 components.
// TODO #2085494: converge the two hooks into a single `useCurrentFluentTheme` hook once we fully migrate to v2.
export const useCurrentFluent2Theme = (): Fluent2Theme => {
    const currentThemeMode = useSelector(getThemeModeSelector);

    return useMemo(() => {
        switch (currentThemeMode) {
            case ThemeMode.LightMode:
                return webLightTheme;
            case ThemeMode.DarkMode:
            default:
                return webDarkTheme;
        }
    }, [currentThemeMode]);
};

export const useDynamicMakeStyles = <TFunc extends (...args: never[]) => unknown>(
    styleFunction: TFunc,
    ...args: Parameters<TFunc>
): ReturnType<TFunc> => {
    return React.useMemo(() => {
        return styleFunction(...args) as ReturnType<TFunc>;
    }, [styleFunction, ...args]);
};
