import { KeyValuePair } from '../types/key-value-pair';
import { SerializableMap } from '../types/serializable-map';
import { entries, get, map, set } from './serializable-map';

export const compact = <TElement>(collection: (TElement | undefined)[]): TElement[] =>
    collection.filter((element) => element !== undefined) as TElement[];

export const first = <TElement>(collection: TElement[]): TElement | undefined =>
    collection.length > 0 ? collection[0] : undefined;

export const groupAndReduceBy = <TElement, TReduced>(
    collection: TElement[],
    key: (element: TElement, index: number) => string,
    reducer: (previousValue: TReduced, currentValue: TElement, index: number) => TReduced,
    initialValue: TReduced
): KeyValuePair<string, TReduced>[] => entries(groupAndReduceIntoMap(collection, key, reducer, initialValue));

export const groupAndReduceIntoMap = <TElement, TReduced>(
    collection: TElement[],
    key: (element: TElement, index: number) => string,
    reducer: (previousValue: TReduced, currentValue: TElement, index: number) => TReduced,
    initialValue: TReduced
): SerializableMap<TReduced> => groupAndSelectIntoMap(collection, key, (group) => group.reduce(reducer, initialValue));

export const groupAndSelectBy = <TElement, TSelected>(
    collection: TElement[],
    key: (element: TElement, index: number) => string,
    selector: (group: TElement[], key: string) => TSelected
): KeyValuePair<string, TSelected>[] => entries(groupAndSelectIntoMap(collection, key, selector));

export const groupAndSelectIntoMap = <TElement, TSelected>(
    collection: TElement[],
    key: (element: TElement, index: number) => string,
    selector: (group: TElement[], key: string) => TSelected
): SerializableMap<TSelected> => {
    const groupedMap = groupIntoMap(collection, key);

    return map(groupedMap, selector);
};

export const groupBy = <TElement>(
    collection: TElement[],
    key: (element: TElement, index: number) => string
): KeyValuePair<string, TElement[]>[] => entries(groupIntoMap(collection, key));

export const groupIntoMap = <TElement>(
    collection: TElement[],
    key: (element: TElement, index: number) => string
): SerializableMap<TElement[]> => {
    let map = SerializableMap<TElement[]>();

    collection.forEach((element, index) => {
        const keyValue = key(element, index);
        const group = get(map, keyValue) ?? [];
        map = set(map, keyValue, [...group, element]);
    });

    return map;
};

export const last = <TElement>(collection: TElement[]): TElement | undefined =>
    collection.length > 0 ? collection[collection.length - 1] : undefined;

export const mapWhen = <TElement, TMapped>(
    collection: TElement[],
    mapper: (element: TElement) => TMapped,
    predicate: (element: TElement) => boolean
): TMapped[] => compact(collection.map((element) => (predicate(element) ? mapper(element) : undefined)));

export const numberOfMatchingElements = <TElement>(
    collection: TElement[],
    predicate: (item: TElement) => boolean
): number => collection.filter(predicate).length;

const defaultCompare = <TSort>(a: TSort, b: TSort): number => {
    if (a < b) {
        return -1;
    }

    if (a > b) {
        return 1;
    }

    return 0;
};

const reverseCompare = <TSort>(a: TSort, b: TSort): number => defaultCompare(a, b) * -1;

export const sortByInPlace = <TElement, TSort>(
    items: TElement[],
    selector: (item: TElement) => TSort,
    compare?: (a: TSort, b: TSort) => number,
    isDescending?: boolean
): TElement[] =>
    items.sort((itemA, itemB) => {
        const a = selector(itemA);
        const b = selector(itemB);

        // Handle undefined and null
        if ((a === undefined || a === null) && (b === undefined || b === null)) {
            return 0;
        }

        if (a === undefined || a === null) {
            return -1;
        }

        if (b === undefined || b === null) {
            return 1;
        }

        return compare === undefined ? (isDescending ? reverseCompare(a, b) : defaultCompare(a, b)) : compare(a, b);
    });

export const sortBy = <TElement, TSort>(
    collection: TElement[],
    selector: (item: TElement) => TSort,
    compare?: (a: TSort, b: TSort) => number,
    isDescending?: boolean
): TElement[] => sortByInPlace([...collection], selector, compare, isDescending);

export const unique = <TElement>(collection: TElement[]): TElement[] => [...new Set(collection)];
