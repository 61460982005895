import { FluentProvider, Menu, MenuButton, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FeatureFlagName } from '../../../constants/features';
import { useProjectFilterContext } from '../../../hooks/context/filters';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { ProjectFromDiscoveryService, ProjectResourceMap } from '../../../models/project';
import { SerializableMap } from '../../../types/serializable-map';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { map, size } from '../../../utilities/serializable-map';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    getProjectsFromDiscoveryServiceWithAtLeastOneResource,
    getProjectsWithAtLeastOneResource,
    getSelectedProjectFromDiscoveryServiceName,
    getSelectedProjectName,
    ProjectPickerMenuItem,
} from './selectors';

interface ProjectPickerComponentProps {
    ariaLabel: string;
    projects: ProjectResourceMap;
    projectsFromDiscoveryService: SerializableMap<ProjectFromDiscoveryService>;
    selectedProject: string | undefined;
    isDiscoveryServiceEnabled: boolean;
    onChange: (project: string | undefined) => void;
}

const messages = defineMessages({
    projectPickerAllProjectsAriaLabel: {
        id: 'ProjectPicker_AllProjects_AriaLabel',
        defaultMessage: 'All projects',
        description: 'Aria label used for "All projects" option in project picker.',
    },
});

export const ProjectPickerComponent: React.FC<ProjectPickerComponentProps> = React.memo(
    (props: ProjectPickerComponentProps) => {
        const {
            projects,
            projectsFromDiscoveryService,
            ariaLabel,
            onChange,
            selectedProject,
            isDiscoveryServiceEnabled,
        } = props;

        const theme = useCurrentFluent2Theme();

        // Intl hooks
        const { formatMessage } = useIntl();

        // Memoized data
        const selectedProjectName = React.useMemo(() => {
            if (isDiscoveryServiceEnabled) {
                return isNotUndefinedOrWhiteSpace(selectedProject)
                    ? getSelectedProjectFromDiscoveryServiceName(projectsFromDiscoveryService, selectedProject)
                    : undefined;
            }
            return isNotUndefinedOrWhiteSpace(selectedProject)
                ? getSelectedProjectName(projects, selectedProject)
                : undefined;
        }, [projects, selectedProject, projectsFromDiscoveryService, isDiscoveryServiceEnabled]);

        const selectedProjectText = selectedProjectName ?? (
            <FormattedMessage
                id="ProjectPicker_Button_DefaultText"
                defaultMessage="All projects"
                description="Button text used when all projects is currently selected."
            />
        );

        const menuList = React.useMemo<JSX.Element>(() => {
            const menuItems: JSX.Element[] = [
                <ProjectPickerMenuItem
                    ariaLabel={formatMessage(messages.projectPickerAllProjectsAriaLabel)}
                    key="allProjects"
                    onSelected={onChange}
                >
                    <FormattedMessage
                        id="ProjectPicker_AllProjects_Text"
                        defaultMessage="All projects"
                        description='Text used for "All projects" option in project picker.'
                    />
                </ProjectPickerMenuItem>,
            ];

            if (isDiscoveryServiceEnabled) {
                map(projectsFromDiscoveryService, (project, id) => {
                    menuItems.push(
                        <ProjectPickerMenuItem ariaLabel={project.name} key={id} onSelected={onChange} project={id}>
                            {
                                project.displayName ??
                                    project.name /* TODO: TASK 1835522 Disambiguation for project name collisions across dev centers*/
                            }
                        </ProjectPickerMenuItem>
                    );
                });
            } else {
                map(projects, (project, id) => {
                    menuItems.push(
                        <ProjectPickerMenuItem ariaLabel={project.name} key={id} onSelected={onChange} project={id}>
                            {
                                project.properties.displayName ??
                                    project.name /* TODO: TASK 1835522 Disambiguation for project name collisions across dev centers*/
                            }
                        </ProjectPickerMenuItem>
                    );
                });
            }

            return <MenuList>{menuItems}</MenuList>;
        }, [onChange, projects, projectsFromDiscoveryService, isDiscoveryServiceEnabled]);

        return (
            <FluentProvider theme={theme}>
                <Menu>
                    <MenuTrigger>
                        <MenuButton aria-label={ariaLabel} appearance="subtle">
                            {selectedProjectText}
                        </MenuButton>
                    </MenuTrigger>

                    <MenuPopover>{menuList}</MenuPopover>
                </Menu>
            </FluentProvider>
        );
    }
);

interface ProjectPickerContainerProps {
    ariaLabel: string;
}

export const ProjectPickerContainer: React.FC<ProjectPickerContainerProps> = (props: ProjectPickerContainerProps) => {
    const { ariaLabel } = props;

    const { value, onChange } = useProjectFilterContext();

    // Application state hooks
    const projects = useSelector(getProjectsWithAtLeastOneResource);
    const projectsFromDiscoveryService = useSelector(getProjectsFromDiscoveryServiceWithAtLeastOneResource);
    const isDiscoveryServiceEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

    const projectsToUse = isDiscoveryServiceEnabled ? projectsFromDiscoveryService : projects;

    // If a user has only one project or less with resources, project filter should not show up
    if (size(projectsToUse) <= 1) {
        return <></>;
    }

    return (
        <ProjectPickerComponent
            projects={projects}
            projectsFromDiscoveryService={projectsFromDiscoveryService}
            ariaLabel={ariaLabel}
            selectedProject={value}
            isDiscoveryServiceEnabled={isDiscoveryServiceEnabled}
            onChange={onChange}
        />
    );
};

export default ProjectPickerContainer;
