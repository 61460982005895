import {
    FluentProvider,
    makeStyles,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ResourceFilterType } from '../../../constants/filters';
import { useResourceFilterContext } from '../../../hooks/context/filters';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { getHasDevBoxes } from '../../../redux/selector/dev-box-selectors';
import { getHasEnvironments } from '../../../redux/selector/environment-selectors';

interface ResourcePickerComponentProps {
    ariaLabel: string;
    selectedResource: ResourceFilterType | undefined;
    onChange: (resource: ResourceFilterType | undefined) => void;
}

const messages = defineMessages({
    allResourcesButtonText: {
        id: 'ResourcePicker_AllResourcesButton_Text',
        defaultMessage: 'All resources',
        description: 'Text for the "all resources" button.',
    },
    allResourcesButtonAriaLabel: {
        id: 'ResourcePicker_AllResourcesButton_AriaLabel',
        defaultMessage: 'All resources',
        description: 'Aria label for the "all resources" button.',
    },
    devBoxesButtonText: {
        id: 'ResourcePicker_DevBoxesButton_Text',
        defaultMessage: 'Dev boxes',
        description: 'Text for the "dev boxes" button.',
    },
    devBoxesButtonAriaLabel: {
        id: 'ResourcePicker_DevBoxesButton_AriaLabel',
        defaultMessage: 'Dev boxes',
        description: 'Aria label for the "dev boxes" button.',
    },
    deploymentEnvironmentsButtonText: {
        id: 'ResourcePicker_DeploymentEnvironmentsButton_Text',
        defaultMessage: 'Deployment environments',
        description: 'Text for the "deployment environments" button.',
    },
    deploymentEnvironmentsButtonAriaLabel: {
        id: 'ResourcePicker_DeploymentEnvironmentsButton_AriaLabel',
        defaultMessage: 'Deployment environments',
        description: 'Aria label for the "deployment environments" button.',
    },
});

/**
 * Styles
 */
const useResourcePickerStyles = makeStyles({
    root: {
        padding: '0 13px',
    },
});
/**
 * END Styles
 */

export const ResourcePickerComponent: React.FC<ResourcePickerComponentProps> = React.memo(
    (props: ResourcePickerComponentProps) => {
        const { ariaLabel, selectedResource, onChange } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        const theme = useCurrentFluent2Theme();

        // Styles
        const resourcePickerStyles = useResourcePickerStyles();

        // Memoized data

        const selectedResourceText = React.useMemo(() => {
            switch (selectedResource) {
                case ResourceFilterType.DevBoxes:
                    return formatMessage(messages.devBoxesButtonText);
                case ResourceFilterType.DeploymentEnvironments:
                    return formatMessage(messages.deploymentEnvironmentsButtonText);
                default:
                    return formatMessage(messages.allResourcesButtonText);
            }
        }, [selectedResource, formatMessage]);

        return (
            <div className={resourcePickerStyles.root}>
                <FluentProvider theme={theme}>
                    <Menu>
                        <MenuTrigger>
                            <MenuButton aria-label={ariaLabel} appearance="subtle">
                                {selectedResourceText}
                            </MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    aria-label={formatMessage(messages.allResourcesButtonAriaLabel)}
                                    onClick={(_ev?: unknown) => {
                                        onChange(undefined);
                                    }}
                                    key="allResources"
                                >
                                    <FormattedMessage
                                        id="ResourcePicker_AllResourcesButton_Text"
                                        defaultMessage="All resources"
                                        description='Text for the "all resources" button.'
                                    />
                                </MenuItem>
                                <MenuItem
                                    aria-label={formatMessage(messages.devBoxesButtonAriaLabel)}
                                    onClick={(_ev?: unknown) => {
                                        onChange(ResourceFilterType.DevBoxes);
                                    }}
                                    key="devBoxes"
                                >
                                    <FormattedMessage
                                        id="ResourcePicker_DevBoxesButton_Text"
                                        defaultMessage="Dev boxes"
                                        description='Text for the "dev boxes" button.'
                                    />
                                </MenuItem>
                                <MenuItem
                                    aria-label={formatMessage(messages.devBoxesButtonAriaLabel)}
                                    onClick={(_ev?: unknown) => {
                                        onChange(ResourceFilterType.DeploymentEnvironments);
                                    }}
                                    key="environments"
                                >
                                    <FormattedMessage
                                        id="ResourcePicker_DeploymentEnvironmentsButton_Text"
                                        defaultMessage="Deployment environments"
                                        description='Text for the "deployment environments" button.'
                                    />
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </FluentProvider>
            </div>
        );
    }
);

interface ResourcePickerContainerProps {
    ariaLabel: string;
}

export const ResourcePickerContainer: React.FC<ResourcePickerContainerProps> = (
    props: ResourcePickerContainerProps
) => {
    const { ariaLabel } = props;

    const { value, onChange } = useResourceFilterContext();

    // Application state hooks
    const hasDevBoxes = useSelector(getHasDevBoxes);
    const hasEnvironments = useSelector(getHasEnvironments);

    const hasBothResources = hasDevBoxes && hasEnvironments;

    if (!hasBothResources) {
        return <></>;
    }

    return <ResourcePickerComponent ariaLabel={ariaLabel} selectedResource={value} onChange={onChange} />;
};

export default ResourcePickerContainer;
