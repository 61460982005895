import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { getTokensFromDevBoxOperationDataPlaneUri } from '../../../ids/dev-box-operation';
import { DataResponse, FailureOperation } from '../../../models/common';
import { DevBoxOperationContract } from '../../contracts/dev-box-operation';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetDevBoxOperationResponse = DataResponse<DevBoxOperationContract>;
export type ListDevBoxOperationsResponse = DataResponse<DevBoxOperationContract[]>;

export const getDevBoxOperation = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetDevBoxOperationResponse> => {
    const { devBoxName, devBoxOperationId, devCenter, projectName, user } =
        getTokensFromDevBoxOperationDataPlaneUri(id);

    const result = await sendRequest<DevBoxOperationContract>(devCenter, {
        operation: FailureOperation.GetDevBoxOperation,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getOperation(
                projectName,
                user,
                devBoxName,
                devBoxOperationId,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.getOperation(
                projectName,
                user,
                devBoxName,
                devBoxOperationId,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listDevBoxOperations = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListDevBoxOperationsResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendIterableRequest<DevBoxOperationContract>(devCenter, {
        operation: FailureOperation.ListDevBoxOperations,

        whenUsingBetaClient: (client) =>
            client.devBoxes.listOperations(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.listOperations(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};
