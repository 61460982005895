import * as React from 'react';
import { LatencyBand } from '../components/add-dev-box-panel/models';
import badLatency from '../content/images/Latency_bad.svg';
import excellentLatency from '../content/images/Latency_excellent.svg';
import goodLatency from '../content/images/Latency_good.svg';

interface LatencyIconProps {
    latencyBand: string;
}

export const LatencyIcon: React.FC<LatencyIconProps> = (props) => {
    const { latencyBand } = props;

    switch (latencyBand) {
        case LatencyBand.Excellent:
            return <img src={excellentLatency} />;
        case LatencyBand.Good:
            return <img src={goodLatency} />;
        default:
            return <img src={badLatency} />;
    }
};
