import { CapitalizedUnionMap, UnionMap } from '../types/union-map';

export type StorageErrorCode = 'InvalidUsername' | 'NotSignedIn';

export const StorageErrorCode: UnionMap<StorageErrorCode> = {
    InvalidUsername: 'InvalidUsername',
    NotSignedIn: 'NotSignedIn',
};

export type StorageKey = 'dismissedMessages' | 'dismissedQuickActions' | 'userSettings' | 'seenWelcomeTour';

export const StorageKey: CapitalizedUnionMap<StorageKey> = {
    DismissedMessages: 'dismissedMessages',
    DismissedQuickActions: 'dismissedQuickActions',
    UserSettings: 'userSettings',
    SeenWelcomeTour: 'seenWelcomeTour',
};

export type StorageType = 'localStorage' | 'sessionStorage';

export const StorageType: CapitalizedUnionMap<StorageType> = {
    LocalStorage: 'localStorage',
    SessionStorage: 'sessionStorage',
};
