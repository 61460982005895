import { EntityState, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { DevBoxFailureReason, DevBoxProvisioningState } from '../../constants/dev-box';
import { Entity } from '../../models/common';
import { DevBox, FailureOnDevBox } from '../../models/dev-box';
import { compact } from '../../utilities/array';
import { getFailureOnDevBoxFromDevBox } from '../../utilities/dev-box';
import { ErrorOrFailedPayload, IndexedPayload, OptionalResultPayload, PayloadAction } from '../actions/core-actions';
import {
    addDevBox,
    addDevBoxAccepted,
    addDevBoxError,
    addDevBoxFailed,
    addDevBoxOperationError,
    addDevBoxOperationFailed,
    addDevBoxOperationSuccess,
    clearAddDevBoxFailure,
    deleteDevBox,
    deleteDevBoxAccepted,
    deleteDevBoxError,
    deleteDevBoxFailed,
    deleteDevBoxSuccess,
    discoverDevBoxesInTenant,
    discoverDevBoxesInTenantError,
    discoverDevBoxesInTenantFailed,
    discoverDevBoxesInTenantSuccess,
    hibernateDevBox,
    hibernateDevBoxError,
    hibernateDevBoxFailed,
    hibernateDevBoxSuccess,
    listDevBoxes,
    listDevBoxesError,
    listDevBoxesFailed,
    listDevBoxesSuccess,
    pollNonTerminalDevBoxComplete,
    repairDevBox,
    repairDevBoxError,
    repairDevBoxFailed,
    repairDevBoxSuccess,
    restartDevBox,
    restartDevBoxError,
    restartDevBoxFailed,
    restartDevBoxSuccess,
    restoreSnapshot,
    restoreSnapshotError,
    restoreSnapshotFailed,
    restoreSnapshotSuccess,
    resumeDevBox,
    resumeDevBoxError,
    resumeDevBoxFailed,
    resumeDevBoxSuccess,
    shutdownDevBox,
    shutdownDevBoxError,
    shutdownDevBoxFailed,
    shutdownDevBoxSuccess,
    startDevBox,
    startDevBoxError,
    startDevBoxFailed,
    startDevBoxSuccess,
} from '../actions/dev-box/dev-box-action-creators';
import {
    getRemoteConnectionError,
    getRemoteConnectionFailed,
} from '../actions/remote-connection/remote-connection-action-creators';
import { devBoxAdapter, devBoxStateAdapter, failureOnDevBoxAdapter } from '../adapters/dev-box-adapters';
import { DevBoxState } from '../store/dev-box-state';
import { DevBoxDataStore, DevBoxStatusStore, DevBoxStore } from '../store/dev-box-store';
import { getFailure } from '../utilities/error-or-failed-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

/**
 * Helper reducers
 */

const clearPendingStateReducer = (store: EntityState<Entity<DevBoxState>>, action: PayloadAction<IndexedPayload>) => {
    const { id } = getPayload(action);
    devBoxStateAdapter.removeOne(store, id);
};

const createSetFailureOnDevBoxForReasonReducer =
    (reason: DevBoxFailureReason) =>
    (store: EntityState<Entity<FailureOnDevBox>>, action: PayloadAction<ErrorOrFailedPayload & IndexedPayload>) => {
        const { id } = getPayload(action);
        const failure = getFailure(action);
        failureOnDevBoxAdapter.setOne(store, Entity(id, { failure, reason }));
    };

const createSetPendingStateReducer =
    (pendingState: DevBoxState) => (store: EntityState<Entity<DevBoxState>>, action: PayloadAction<IndexedPayload>) => {
        const { id } = getPayload(action);
        devBoxStateAdapter.setOne(store, Entity(id, pendingState));
    };

const removeFailureOnDevBoxReducer = (
    store: EntityState<Entity<FailureOnDevBox>>,
    action: PayloadAction<IndexedPayload>
) => {
    const { id } = getPayload(action);
    failureOnDevBoxAdapter.removeOne(store, id);
};

const setDevBoxReducer = (
    store: EntityState<Entity<DevBox>>,
    action: PayloadAction<IndexedPayload & OptionalResultPayload<DevBox>>
) => {
    const { id, result } = getPayload(action);

    if (result) {
        devBoxAdapter.setOne(store, Entity(id, result));
    }
};

const setFailureOnDevBoxFromCreateOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.CreateFailed
);

const setFailureOnDevBoxFromDeleteOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.DeleteFailed
);

const setFailureOnDevBoxFromDevBoxReducer = (
    store: EntityState<Entity<FailureOnDevBox>>,
    action: PayloadAction<IndexedPayload & OptionalResultPayload<DevBox>>
) => {
    const { id, result } = getPayload(action);
    const failureOnDevBox = result ? getFailureOnDevBoxFromDevBox(result) : undefined;

    failureOnDevBox
        ? failureOnDevBoxAdapter.setOne(store, Entity(id, failureOnDevBox))
        : failureOnDevBoxAdapter.removeOne(store, id.toLowerCase());
};

const setFailureOnDevBoxFromGetRemoteConnectionOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.GetRemoteConnectionFailed
);

const setFailureOnDevBoxFromHibernateOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.HibernateFailed
);

const setFailureOnDevBoxFromRepairOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.RepairFailed
);

const setFailureOnDevBoxFromRestoreOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.RestoreFailed
);

const setFailureOnDevBoxFromRestartOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.RestartFailed
);

const setFailureOnDevBoxFromResumeOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.ResumeFailed
);

const setFailureOnDevBoxFromShutdownOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.ShutdownFailed
);

const setFailureOnDevBoxFromStartOperationReducer = createSetFailureOnDevBoxForReasonReducer(
    DevBoxFailureReason.StartFailed
);

const setPendingStateToDeletingReducer = createSetPendingStateReducer(DevBoxState.Deleting);
const setPendingStateToHibernatingReducer = createSetPendingStateReducer(DevBoxState.Hibernating);
const setPendingStateToRepairingReducer = createSetPendingStateReducer(DevBoxState.Repairing);
const setPendingStateToRestoringReducer = createSetPendingStateReducer(DevBoxState.Restoring);
const setPendingStateToRestartingReducer = createSetPendingStateReducer(DevBoxState.Restarting);
const setPendingStateToResumingReducer = createSetPendingStateReducer(DevBoxState.Resuming);
const setPendingStateToStartingReducer = createSetPendingStateReducer(DevBoxState.Starting);
const setPendingStateToStoppingReducer = createSetPendingStateReducer(DevBoxState.Stopping);

/**
 * Data reducers
 */

const devBoxesReducer = createReducer(DevBoxDataStore().devBoxes, (builder) => {
    builder
        .addCase(addDevBoxAccepted, setDevBoxReducer)
        .addCase(addDevBoxOperationFailed, setDevBoxReducer)
        .addCase(addDevBoxOperationSuccess, setDevBoxReducer)
        .addCase(deleteDevBoxFailed, setDevBoxReducer)
        .addCase(hibernateDevBoxFailed, setDevBoxReducer)
        .addCase(hibernateDevBoxSuccess, setDevBoxReducer)
        .addCase(pollNonTerminalDevBoxComplete, setDevBoxReducer)
        .addCase(repairDevBoxFailed, setDevBoxReducer)
        .addCase(repairDevBoxSuccess, setDevBoxReducer)
        .addCase(restartDevBoxFailed, setDevBoxReducer)
        .addCase(restartDevBoxSuccess, setDevBoxReducer)
        .addCase(restoreSnapshotFailed, setDevBoxReducer)
        .addCase(restoreSnapshotSuccess, setDevBoxReducer)
        .addCase(shutdownDevBoxFailed, setDevBoxReducer)
        .addCase(shutdownDevBoxSuccess, setDevBoxReducer)
        .addCase(startDevBoxFailed, setDevBoxReducer)
        .addCase(startDevBoxSuccess, setDevBoxReducer);

    builder.addCase(deleteDevBoxAccepted, (store, action) => {
        const { id } = getPayload(action);

        // Manually set state to deleting, as dev box doesn't come back on the delete accepted response
        const entity = store.entities[id];

        if (entity) {
            const { data } = entity;

            devBoxAdapter.upsertOne(
                store,
                Entity(id, { ...data, provisioningState: DevBoxProvisioningState.Deleting })
            );
        }
    });

    builder.addCase(deleteDevBoxSuccess, (store, action) => {
        const { id } = getPayload(action);
        devBoxAdapter.removeOne(store, id);
    });

    builder.addCase(listDevBoxesSuccess, (store, action) => {
        const { result } = getPayload(action);

        devBoxAdapter.setMany(
            store,
            result.map((devBox) => Entity(devBox.uri, devBox))
        );
    });
});

const failuresFromOperationsReducer = createReducer(DevBoxDataStore().failuresFromOperations, (builder) => {
    builder
        .addCase(addDevBoxOperationError, setFailureOnDevBoxFromCreateOperationReducer)
        .addCase(addDevBoxOperationFailed, setFailureOnDevBoxFromCreateOperationReducer)
        .addCase(deleteDevBox, removeFailureOnDevBoxReducer)
        .addCase(deleteDevBoxError, setFailureOnDevBoxFromDeleteOperationReducer)
        .addCase(deleteDevBoxFailed, setFailureOnDevBoxFromDeleteOperationReducer)
        .addCase(getRemoteConnectionError, setFailureOnDevBoxFromGetRemoteConnectionOperationReducer)
        .addCase(getRemoteConnectionFailed, setFailureOnDevBoxFromGetRemoteConnectionOperationReducer)
        .addCase(hibernateDevBox, removeFailureOnDevBoxReducer)
        .addCase(hibernateDevBoxError, setFailureOnDevBoxFromHibernateOperationReducer)
        .addCase(hibernateDevBoxFailed, setFailureOnDevBoxFromHibernateOperationReducer)
        .addCase(repairDevBox, removeFailureOnDevBoxReducer)
        .addCase(repairDevBoxError, setFailureOnDevBoxFromRepairOperationReducer)
        .addCase(repairDevBoxFailed, setFailureOnDevBoxFromRepairOperationReducer)
        .addCase(restoreSnapshot, removeFailureOnDevBoxReducer)
        .addCase(restoreSnapshotError, setFailureOnDevBoxFromRestoreOperationReducer)
        .addCase(restoreSnapshotFailed, setFailureOnDevBoxFromRestoreOperationReducer)
        .addCase(restartDevBox, removeFailureOnDevBoxReducer)
        .addCase(restartDevBoxError, setFailureOnDevBoxFromRestartOperationReducer)
        .addCase(restartDevBoxFailed, setFailureOnDevBoxFromRestartOperationReducer)
        .addCase(resumeDevBox, removeFailureOnDevBoxReducer)
        .addCase(resumeDevBoxError, setFailureOnDevBoxFromResumeOperationReducer)
        .addCase(resumeDevBoxFailed, setFailureOnDevBoxFromResumeOperationReducer)
        .addCase(shutdownDevBox, removeFailureOnDevBoxReducer)
        .addCase(shutdownDevBoxError, setFailureOnDevBoxFromShutdownOperationReducer)
        .addCase(shutdownDevBoxFailed, setFailureOnDevBoxFromShutdownOperationReducer)
        .addCase(startDevBox, removeFailureOnDevBoxReducer)
        .addCase(startDevBoxError, setFailureOnDevBoxFromStartOperationReducer)
        .addCase(startDevBoxFailed, setFailureOnDevBoxFromStartOperationReducer);
});

const failuresFromResourcesReducer = createReducer(DevBoxDataStore().failuresFromResources, (builder) => {
    builder
        .addCase(addDevBoxAccepted, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(addDevBoxOperationFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(addDevBoxOperationSuccess, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(deleteDevBoxFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(hibernateDevBoxFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(hibernateDevBoxSuccess, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(pollNonTerminalDevBoxComplete, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(repairDevBoxFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(repairDevBoxSuccess, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(restoreSnapshotFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(restoreSnapshotSuccess, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(restartDevBoxFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(restartDevBoxSuccess, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(shutdownDevBoxFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(shutdownDevBoxSuccess, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(startDevBoxFailed, setFailureOnDevBoxFromDevBoxReducer)
        .addCase(startDevBoxSuccess, setFailureOnDevBoxFromDevBoxReducer);

    builder.addCase(listDevBoxesSuccess, (store, action) => {
        const { result } = getPayload(action);

        const failuresOnDevBoxes = compact(
            result.map((devBox) => {
                const { uri } = devBox;
                const failureOnEnvironment = getFailureOnDevBoxFromDevBox(devBox);

                if (!failureOnEnvironment) {
                    return undefined;
                }

                return Entity(uri, failureOnEnvironment);
            })
        );

        if (failuresOnDevBoxes.length > 0) {
            failureOnDevBoxAdapter.setMany(store, failuresOnDevBoxes);
        }
    });
});

const pendingStatesReducer = createReducer(DevBoxDataStore().pendingStates, (builder) => {
    builder
        .addCase(deleteDevBox, setPendingStateToDeletingReducer)
        .addCase(deleteDevBoxAccepted, clearPendingStateReducer)
        .addCase(deleteDevBoxError, clearPendingStateReducer)
        .addCase(deleteDevBoxFailed, clearPendingStateReducer)
        .addCase(deleteDevBoxSuccess, clearPendingStateReducer)
        .addCase(hibernateDevBox, setPendingStateToHibernatingReducer)
        .addCase(hibernateDevBoxError, clearPendingStateReducer)
        .addCase(hibernateDevBoxFailed, clearPendingStateReducer)
        .addCase(hibernateDevBoxSuccess, clearPendingStateReducer)
        .addCase(repairDevBox, setPendingStateToRepairingReducer)
        .addCase(repairDevBoxError, clearPendingStateReducer)
        .addCase(repairDevBoxFailed, clearPendingStateReducer)
        .addCase(repairDevBoxSuccess, clearPendingStateReducer)
        .addCase(restartDevBox, setPendingStateToRestartingReducer)
        .addCase(restartDevBoxError, clearPendingStateReducer)
        .addCase(restartDevBoxFailed, clearPendingStateReducer)
        .addCase(restartDevBoxSuccess, clearPendingStateReducer)
        .addCase(restoreSnapshot, setPendingStateToRestoringReducer)
        .addCase(restoreSnapshotError, clearPendingStateReducer)
        .addCase(restoreSnapshotFailed, clearPendingStateReducer)
        .addCase(restoreSnapshotSuccess, clearPendingStateReducer)
        .addCase(resumeDevBox, setPendingStateToResumingReducer)
        .addCase(resumeDevBoxError, clearPendingStateReducer)
        .addCase(resumeDevBoxFailed, clearPendingStateReducer)
        .addCase(resumeDevBoxSuccess, clearPendingStateReducer)
        .addCase(shutdownDevBox, setPendingStateToStoppingReducer)
        .addCase(shutdownDevBoxError, clearPendingStateReducer)
        .addCase(shutdownDevBoxFailed, clearPendingStateReducer)
        .addCase(shutdownDevBoxSuccess, clearPendingStateReducer)
        .addCase(startDevBox, setPendingStateToStartingReducer)
        .addCase(startDevBoxError, clearPendingStateReducer)
        .addCase(startDevBoxFailed, clearPendingStateReducer)
        .addCase(startDevBoxSuccess, clearPendingStateReducer);
});

/**
 * Core reducer
 */

export const devBoxReducer = combineReducers<DevBoxStore>({
    data: combineReducers<DevBoxDataStore>({
        devBoxes: devBoxesReducer,
        failuresFromOperations: failuresFromOperationsReducer,
        failuresFromResources: failuresFromResourcesReducer,
        pendingStates: pendingStatesReducer,
    }),

    status: combineReducers<DevBoxStatusStore>({
        addDevBox: createStatusReducer({
            inProgress: addDevBox,
            error: addDevBoxError,
            failed: addDevBoxFailed,
            success: addDevBoxAccepted,
            reset: clearAddDevBoxFailure,
        }),

        discoverDevBoxesInTenant: createStatusReducer({
            inProgress: discoverDevBoxesInTenant,
            error: discoverDevBoxesInTenantError,
            failed: discoverDevBoxesInTenantFailed,
            success: discoverDevBoxesInTenantSuccess,
        }),

        listDevBoxes: createIndexedStatusReducer({
            inProgress: listDevBoxes,
            error: listDevBoxesError,
            failed: listDevBoxesFailed,
            success: listDevBoxesSuccess,
        }),
    }),
});
