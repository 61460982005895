import { ChoiceGroup, IChoiceGroupOption, Link, makeStyles, Toggle } from '@fluentui/react';
import * as React from 'react';
import { Field } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { FeatureFlagName } from '../../../constants/features';
import { Metric, Property } from '../../../constants/telemetry';
import { WindowsStoreLinks } from '../../../constants/windows-store-links';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { ThemeMode } from '../../../themes/themes';
import { RemoteAppOption, UserSettings } from '../../../types/user-settings';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { trackMetric } from '../../../utilities/telemetry/channel';
import { UserSettingsFormFieldName } from '../models';

const messages = defineMessages({
    themeModeCheckboxAriaLabel: {
        id: 'SettingsFormFieldGroup_ThemeModeCheckbox_AriaLabel',
        defaultMessage: 'Change theme mode',
        description: 'Aria label for "change theme mode" checkbox',
    },
    themeModeCheckboxLabel: {
        id: 'SettingsFormFieldGroup_ThemeModeCheckbox_Label',
        defaultMessage: 'Dark mode',
        description: 'Label for "theme mode checkbox" checkbox',
    },

    multiMonitorCheckboxAriaLabel: {
        id: 'SettingsFormFieldGroup_MultiMonitorCheckbox_AriaLabel',
        defaultMessage: 'Enable Multiple Monitor Support',
        description: 'Aria label for "Enable Multi Monitor Support" checkbox',
    },

    multiMonitorCheckboxLabel: {
        id: 'SettingsFormFieldGroup_MultiMonitorPlaneCheckbox_Label',
        defaultMessage: 'Enable multi monitor support',
        description: 'Label for "Enable multi monitor support" checkbox',
    },

    useWindowsAppCheckboxAriaLabel: {
        id: 'SettingsFormFieldGroup_UseWindowsAppCheckbox_AriaLabel',
        defaultMessage: 'Enable use windows Application for remote connection',
        description: 'Aria label for "use windows app" checkbox',
    },
    useWindowsAppCheckboxLabel: {
        id: 'SettingsFormFieldGroup_UseWindowsAppCheckbox_Label',
        defaultMessage: 'Enable Use Windows application for remote connection',
        description: 'Label for "Use Windows app " checkbox',
    },
    connectViaWindowsAppButtonLabelText: {
        id: 'SettingsFormFieldGroup_ConnectViaWindowsAppButtonLabel_Text',
        defaultMessage: 'Connect via {appName}',
        description: '{appName} should not be localized',
    },
    connectViaRemoteDesktopLegacyButtonLabelText: {
        id: 'SettingsFormFieldGroup_ConnectViaRemoteDesktopLegacyButtonLabel_Text',
        defaultMessage: 'Connect via {appName} (legacy)',
        description: '{appName} should not be localized',
    },
    useSingleMonitorText: {
        id: 'SettingsFormFieldGroup_UseSingleMonitor_Text',
        defaultMessage: 'Use a single monitor',
        description: 'Label for choice group option "Use a single monitor"',
    },
    useMultiMonitorText: {
        id: 'SettingsFormFieldGroup_UseMultiMonitor_Text',
        defaultMessage: 'Use multiple monitors',
        description: 'Label for choice group option "Use a multiple monitors"',
    },
    windowsAppNameText: {
        id: 'SettingsFormFieldGroup_WindowsAppName_Text',
        defaultMessage: 'Windows App',
        description: 'Label for choice group option "Windows App"',
    },
    remoteDesktopAppNameText: {
        id: 'SettingsFormFieldGroup_RemoteDesktopAppName_Text',
        defaultMessage: 'Remote Desktop',
        description: 'Label for choice group option "Remote Desktop"',
    },
});

/**
 * Styles
 */

const useCheckboxStyles = makeStyles({
    root: {
        marginBottom: 8,
    },
});

const useToggleStyles = makeStyles({
    root: {
        marginBottom: 16,
    },
});

/**
 * END Styles
 */

type UserSettingsPanelComponentProps = {
    currentUserSettings?: UserSettings;
};

const handleRemoteDesktopLinkClick = () => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsStoreLinks.RemoteDesktop,
        },
    });
};

const handleWindowsAppLinkClick = () => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsStoreLinks.WindowsApp,
        },
    });
};

export const UserSettingsFormFieldGroup: React.FC<UserSettingsPanelComponentProps> = (
    props: UserSettingsPanelComponentProps
) => {
    const { currentUserSettings } = props;

    // Styles
    const checkboxStyles = useCheckboxStyles();
    const toggleStyles = useToggleStyles();
    const stackStyles = useStackStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    const remoteDesktopLinkedAppTitle = React.useMemo(
        () =>
            formatMessage(messages.connectViaRemoteDesktopLegacyButtonLabelText, {
                appName: (
                    <Link href={WindowsStoreLinks.RemoteDesktop} target="_blank" onClick={handleRemoteDesktopLinkClick}>
                        {formatMessage(messages.remoteDesktopAppNameText)}
                    </Link>
                ),
            }),
        [formatMessage]
    );

    const windowsAppLinkedAppTitle = React.useMemo(
        () =>
            formatMessage(messages.connectViaWindowsAppButtonLabelText, {
                appName: (
                    <Link href={WindowsStoreLinks.WindowsApp} target="_blank" onClick={handleWindowsAppLinkClick}>
                        {formatMessage(messages.windowsAppNameText)}
                    </Link>
                ),
            }),
        [formatMessage]
    );

    const connectionOptions: IChoiceGroupOption[] = React.useMemo(
        () => [
            { key: RemoteAppOption.useWindowsApp, text: windowsAppLinkedAppTitle as string },
            {
                key: RemoteAppOption.useAvd,
                text: remoteDesktopLinkedAppTitle as string,
            },
        ],
        [windowsAppLinkedAppTitle, remoteDesktopLinkedAppTitle]
    );

    const monitorOptions: IChoiceGroupOption[] = React.useMemo(
        () => [
            { key: 'false', text: formatMessage(messages.useSingleMonitorText) },
            { key: 'true', text: formatMessage(messages.useMultiMonitorText) },
        ],
        [formatMessage]
    );

    return (
        <div className={stackStyles.root}>
            {isFeatureFlagEnabled(FeatureFlagName.EnableThemeModeToggle) && (
                <div className={stackStyles.item}>
                    <Field<boolean> name={UserSettingsFormFieldName.themeMode}>
                        {(fieldProps) => {
                            const { input } = fieldProps;
                            const { onChange } = input;

                            const onThemeModeChange = React.useCallback(
                                (_event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    onChange(checked ? ThemeMode.DarkMode : ThemeMode.LightMode);
                                },
                                [onChange]
                            );

                            return (
                                <Toggle
                                    label={formatMessage(messages.themeModeCheckboxLabel)}
                                    ariaLabel={formatMessage(messages.themeModeCheckboxAriaLabel)}
                                    styles={toggleStyles}
                                    role="checkbox"
                                    onChange={onThemeModeChange}
                                    defaultChecked={currentUserSettings?.themeMode === ThemeMode.DarkMode}
                                />
                            );
                        }}
                    </Field>
                </div>
            )}
            <div className={stackStyles.item}>
                <Field name={UserSettingsFormFieldName.useMultiMonitor}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange } = input;
                        const defaultValue = currentUserSettings?.useMultiMonitor ?? false;

                        const onMultiMonitorChange = React.useCallback(
                            (_event: React.FormEvent<HTMLElement> | undefined, option?: IChoiceGroupOption) => {
                                const selectedKey = option?.key === 'true';
                                onChange(selectedKey);
                            },
                            [onChange]
                        );

                        return (
                            <ChoiceGroup
                                label={formatMessage(messages.multiMonitorCheckboxLabel)}
                                aria-label={formatMessage(messages.multiMonitorCheckboxAriaLabel)}
                                options={monitorOptions}
                                onChange={onMultiMonitorChange}
                                styles={checkboxStyles}
                                defaultSelectedKey={defaultValue.toString()}
                            />
                        );
                    }}
                </Field>
            </div>
            <div className={stackStyles.item}>
                <Field name={UserSettingsFormFieldName.preferredRemoteApp}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange } = input;
                        const defaultValue = currentUserSettings?.preferredRemoteApp ?? RemoteAppOption.useWindowsApp;

                        const onPreferredRemoteAppChange = React.useCallback(
                            (_event: React.FormEvent<HTMLElement> | undefined, option?: IChoiceGroupOption) => {
                                onChange(option?.key);
                            },
                            [onChange]
                        );

                        return (
                            <ChoiceGroup
                                label={formatMessage(messages.useWindowsAppCheckboxLabel)}
                                aria-label={formatMessage(messages.useWindowsAppCheckboxAriaLabel)}
                                options={connectionOptions}
                                onChange={onPreferredRemoteAppChange}
                                styles={checkboxStyles}
                                defaultSelectedKey={defaultValue}
                            />
                        );
                    }}
                </Field>
            </div>
        </div>
    );
};
