import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { RestoreSnapshotDialogComponentProps } from './restore-snapshot-dialog';

export type RestoreSnapshotDialogProperties = Omit<RestoreSnapshotDialogComponentProps, 'hidden' | 'onDismiss'>;

export const RestoreSnapshotDialogContext = React.createContext<
    SurfaceContextWithProperties<RestoreSnapshotDialogProperties>
>({
    isOpen: false,
});

export const RestoreSnapshotDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<RestoreSnapshotDialogProperties>(RestoreSnapshotDialogContext);
