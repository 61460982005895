import { Label } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { get } from '../../../utilities/serializable-map';
import { SizeDropdown } from '../../common/form/dropdown/data-controls/size/size-dropdown';
import SizeDropdownItem from '../../common/form/dropdown/data-controls/size/size-dropdown-item';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { ProjectToSizeViewModelMap, SizeViewModel } from '../models';
import { getSizeOptions } from './selectors';

interface AddDevBoxFormSizeControlProps {
    projectToSizesMap: ProjectToSizeViewModelMap;
    disabled: boolean;
    selectedProjectId: string | undefined;
    selectedImageId: string | undefined;
    selectedRegionId: string | undefined;
    selectedSize: SizeViewModel | undefined;
    errorMessage: string;
    onChange: (event: SizeViewModel | undefined) => void;
}

const messages = defineMessages({
    addDevBoxFormSizeFieldDropdownText: {
        id: 'AddDevBoxFormSizeControl_Dropdown_Text',
        defaultMessage: 'Size',
        description: 'Text for the image dropdown in the add dev box panel label',
    },
    addDevBoxFormSizeFieldDropdownToolTipText: {
        id: 'AddDevBoxFormSizeControl_Dropdown_ToolTipText',
        defaultMessage: 'Choose an image that contains the tools and code needed for your development tasks.',
        description: 'Tooltip text for the image dropdown in the add dev box panel label',
    },
    addDevBoxFormSizeFieldDropdownAriaLabel: {
        id: 'AddDevBoxFormSizeControl_Dropdown_AriaLabel',
        defaultMessage: 'Size for your dev box',
        description: 'Aria label for the image dropdown in the add dev box panel',
    },
    addDevBoxFormSizeFieldDropdownPlaceholder: {
        id: 'AddDevBoxFormSizeControl_Dropdown_Placeholder',
        defaultMessage: 'Select image',
        description: 'Placeholder text for the image dropdown in the add dev box panel',
    },
});

/**
 * Styles
 */

const useSelectionContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useReadOnlyOptionStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',
        paddingRight: '8px',
    },
});

const useSizeReadOnlyOptionContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

/**
 * END Styles
 */

export const AddDevBoxFormSizeControl: React.FC<AddDevBoxFormSizeControlProps> = (
    props: AddDevBoxFormSizeControlProps
) => {
    const {
        disabled,
        projectToSizesMap,
        selectedImageId,
        selectedProjectId,
        selectedRegionId,
        selectedSize,
        errorMessage,
        onChange,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const sizeReadOnlyOptionContainerStyles = useSizeReadOnlyOptionContainerStyles();
    const readOnlyOptionStyles = useReadOnlyOptionStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const selectionContainerStyles = useSelectionContainerStyles();

    // Memo hooks
    const sizesMap: SizeViewModel[] = React.useMemo(
        () => (!!selectedProjectId ? get(projectToSizesMap, selectedProjectId) ?? [] : []),
        [projectToSizesMap, selectedProjectId]
    );

    const sizes: SizeViewModel[] = React.useMemo(() => {
        return !!selectedImageId && !!selectedRegionId
            ? getSizeOptions(
                  sizesMap.filter(
                      (size) =>
                          size.imageName.toLowerCase() === selectedImageId.toLowerCase() &&
                          size.region === selectedRegionId
                  )
              )
            : [];
    }, [selectedImageId, selectedRegionId, sizesMap]);

    const hasMultipleSizes = sizes.length > 1;
    const hasNoSizes = sizes.length < 1;

    // pre-select the first size
    React.useEffect(() => {
        onChange(sizes[0]);
    }, [sizes]);

    if (hasMultipleSizes) {
        return (
            <div className={mergeClasses(stackStyles.root, selectionContainerStyles.root)}>
                <div className={stackStyles.item}>
                    <SizeDropdown
                        value={selectedSize}
                        label={formatMessage(messages.addDevBoxFormSizeFieldDropdownText)}
                        ariaLabel={formatMessage(messages.addDevBoxFormSizeFieldDropdownAriaLabel)}
                        placeholder={formatMessage(messages.addDevBoxFormSizeFieldDropdownPlaceholder)}
                        options={sizes}
                        onChange={onChange}
                        disabled={disabled}
                        errorMessage={errorMessage}
                        autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                        required
                    />
                </div>
            </div>
        );
    }

    if (!selectedSize || hasNoSizes) {
        return <></>;
    }

    return (
        <div className={mergeClasses(stackStyles.root, sizeReadOnlyOptionContainerStyles.root)}>
            <div className={stackStyles.item}>
                <Label>
                    <FormattedMessage
                        id="AddDevBoxFormSizeControl_SizeReadOnly_Text"
                        defaultMessage="Size"
                        description="Text for the size read only field in the add dev box panel label"
                    />
                </Label>
            </div>
            <div className={mergeClasses(stackStyles.item, readOnlyOptionStyles.root)}>
                <SizeDropdownItem size={selectedSize} />
            </div>
        </div>
    );
};

export default AddDevBoxFormSizeControl;
