import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AddDevBoxFormProjectViewModel } from '../../../../add-dev-box-panel/models';
import { DropdownDividerOption } from '../divider';
import { DropdownHeaderOption } from '../header';
import { ValueDropdownOption, ValueDropdownWrapperProps } from '../value-dropdown';
import ResourceDropdown from './resource-dropdown';
import { getFinalDisplayNameForProject, getOptionKeyForIndexedModel } from './selectors';

const messages = defineMessages({
    addDevBoxFormProjectFieldDropdownLimitsName: {
        id: 'AddDevBoxFormProjectControl_Dropdown_LimitsName',
        defaultMessage: '{name} ({used}/{limit} dev boxes used)',
        description:
            'Dropdown option text to show limits on how many dev boxes per project. Do not localize {name}. Do not localize {limit}. Do not localize {used}.',
    },
    addDevBoxFormProjectFieldDropdownDuplicateDisplayName: {
        id: 'AddDevBoxFormProjectControl_Dropdown_DuplicateDisplayName',
        defaultMessage: '{displayName} ({resourceName})',
        description:
            'Dropdown option text to show duplicate display name. Do not localize {name}. Do not localize {resourceName}.',
    },
    addDevBoxFormProjectFieldDropdownRecentProjectsText: {
        id: 'AddDevBoxFormProjectControl_RecentProjects_Text',
        defaultMessage: 'Recent projects',
        description: 'Text for the recent projects header in the project dropdown in the add dev box panel label',
    },
    addDevBoxFormProjectFieldDropdownOtherProjectsText: {
        id: 'AddDevBoxFormProjectControl_OtherProjects_Text',
        defaultMessage: 'Other projects',
        description: 'Text for the other projects header in the project dropdown in the add dev box panel label',
    },
    addDevBoxFormProjectFieldDropdownUnavailableProjectsText: {
        id: 'AddDevBoxFormProjectControl_Unavailable_Text',
        defaultMessage: 'Unavailable',
        description: 'Text for the unavailable header in the project dropdown in the add dev box panel label',
    },
});

export type DevBoxProjectDropdownProps = ValueDropdownWrapperProps<AddDevBoxFormProjectViewModel> & {
    unavailableProjects: AddDevBoxFormProjectViewModel[];
    availableUnusedProjects: AddDevBoxFormProjectViewModel[];
};

export const DevBoxProjectDropdown: React.FC<DevBoxProjectDropdownProps> = (props) => {
    const { options, unavailableProjects, availableUnusedProjects } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const getOptionText = React.useCallback(
        (project: AddDevBoxFormProjectViewModel) => {
            const {
                name: resourceName,
                maxDevBoxesPerUser: limit,
                usedDevBoxes: used,
                displayName,
                isDisplayNameDuplicate,
            } = project;

            const duplicateValues = { displayName, resourceName };

            const name = getFinalDisplayNameForProject(
                displayName,
                isDisplayNameDuplicate,
                resourceName,
                formatMessage(messages.addDevBoxFormProjectFieldDropdownDuplicateDisplayName, duplicateValues)
            );

            if (!limit) {
                return name;
            }

            const values = {
                name,
                used,
                limit,
            };

            return formatMessage(messages.addDevBoxFormProjectFieldDropdownLimitsName, values);
        },
        [formatMessage]
    );

    const optionsWithDivider: ValueDropdownOption<AddDevBoxFormProjectViewModel>[] = React.useMemo(() => {
        //If there is only one option, don't insert divider.
        if (options.length > 1 && options[options.length - 1]) {
            const optionsWithDivider: ValueDropdownOption<AddDevBoxFormProjectViewModel>[] = [];

            const recentlyUsed = options.filter((option) => option.recentlyUsed === true);

            if (recentlyUsed.length > 0) {
                optionsWithDivider.push(
                    DropdownHeaderOption(
                        'recentprojects',
                        formatMessage(messages.addDevBoxFormProjectFieldDropdownRecentProjectsText)
                    ),
                    ...recentlyUsed
                );

                if (recentlyUsed.length + 1 < options.length) {
                    optionsWithDivider.push(
                        DropdownDividerOption('project-divider-1'),
                        DropdownHeaderOption(
                            'otherprojects',
                            formatMessage(messages.addDevBoxFormProjectFieldDropdownOtherProjectsText)
                        ),
                        ...availableUnusedProjects
                    );
                }
            }

            if (unavailableProjects.length > 0) {
                optionsWithDivider.push(
                    DropdownDividerOption('project-divider-2'),
                    DropdownHeaderOption(
                        'unavailable',
                        formatMessage(messages.addDevBoxFormProjectFieldDropdownUnavailableProjectsText)
                    ),
                    ...unavailableProjects
                );
            }

            return optionsWithDivider;
        }

        return options;
    }, [options, unavailableProjects, availableUnusedProjects, formatMessage]);

    return (
        <ResourceDropdown<AddDevBoxFormProjectViewModel>
            {...props}
            options={optionsWithDivider}
            getOptionKey={getOptionKeyForIndexedModel}
            getOptionText={getOptionText}
        />
    );
};

export default DevBoxProjectDropdown;
