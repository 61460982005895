import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStackStyles } from '../../themes/styles/flexbox-styles';
import { isDevPortalEnvironment } from '../../utilities/app';

/**
 * Styles
 */

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
        lineHeight: '19px',
    },
});

const useTitleRootStyles = makeStyles({
    root: {
        height: '48px',
        justifyContent: 'center',
    },
});

const useTitleHeadingClassName = makeStyles({
    heading: {
        fontSize: FontSizes.size16,
    },
});

/**
 * End Styles
 */

const titleMessage = isDevPortalEnvironment ? (
    <FormattedMessage
        id="TitleBar_Title_DevPortal_Text"
        defaultMessage="Microsoft developer portal"
        description="Title of the application, used in application header. Variant for devportal prefixed hosts."
    />
) : (
    <FormattedMessage
        id="TitleBar_Title_Text"
        defaultMessage="Microsoft Dev Box"
        description="Title of the application, used in application header."
    />
);

const Title: React.FC = React.memo(() => {
    // Style hooks
    const { heading: headingClassName } = useTitleHeadingClassName();
    const titleRootStyles = useTitleRootStyles();
    const titleStyles = useTitleStyles();
    const stackStyles = useStackStyles();

    return (
        <div className={mergeClasses(stackStyles.root, titleRootStyles.root)}>
            <div className={mergeClasses(stackStyles.item, titleStyles.root)}>
                <h1 className={headingClassName}>{titleMessage}</h1>
            </div>
        </div>
    );
});

export default Title;
