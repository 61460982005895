import { FontSizes, Icon } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { ScheduleViewModel } from '../add-dev-box-panel/models';
import { FluentIconNames } from '../common/fluent-icon-names';

export interface ScheduleReadonlyFieldProps {
    schedule: ScheduleViewModel;
}

/**
 * Styles
 */

const useScheduleMessageContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useMessageIconStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
    },
});

/**
 * End Styles
 */

const messages = defineMessages({
    calendarIconAriaLabel: {
        id: 'ScheduleReadOnlyField_CalendarIcon_AriaLabel',
        defaultMessage: 'Information icon for the user about the schedule time and frequency for dev box shut down',
        description: 'Aria label for the add dev box schedule message',
    },
});

export const ScheduleReadonlyField: React.FC<ScheduleReadonlyFieldProps> = React.memo(
    (props: ScheduleReadonlyFieldProps) => {
        const { schedule } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const messageIconStyles = useMessageIconStyles();
        const stackStyles = useStackStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const scheduleMessageContainerStyles = useScheduleMessageContainerStyles();

        const scheduleMessageValues = React.useMemo(
            () => ({
                time: schedule?.displayTime,
                frequency: schedule?.displayFrequency,
            }),
            [schedule?.displayFrequency, schedule?.displayTime]
        );

        return (
            <div className={stackStyles.root}>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(horizontalStackStyles.root, scheduleMessageContainerStyles.root)}>
                        <div className={mergeClasses(horizontalStackStyles.item, messageIconStyles.root)}>
                            <Icon
                                aria-label={formatMessage(messages.calendarIconAriaLabel)}
                                iconName={FluentIconNames.Calendar}
                            />
                        </div>
                        <div className={horizontalStackStyles.item}>
                            <FormattedMessage
                                id="ScheduleReadOnlyField_ScheduleMessage_Text"
                                defaultMessage="Schedule: shuts down at {time} {frequency}"
                                description="Text informing users of when the dev box is scheduled to shut down. Do not localize {time} or {frequency}"
                                values={scheduleMessageValues}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
