import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EnvironmentProvisioningState } from '../../../constants/environment';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { GetEnvironmentResponse, replaceEnvironment } from '../../../data/services/data-plane-api/environment';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    FailureResponse,
    isFailureResponse,
} from '../../../models/common';
import { EnvironmentResourceProvisioningState, PutEnvironmentBody } from '../../../models/environment';
import { createFailureResponseFromCloudErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    deployEnvironment,
    deployEnvironmentAccepted,
    deployEnvironmentError,
    deployEnvironmentFailed,
    deployEnvironmentOperationError,
    deployEnvironmentOperationFailed,
    deployEnvironmentSuccess,
    pollNonTerminalEnvironment,
} from '../../actions/environment/environment-action-creators';
import {
    DeployEnvironmentAcceptedAction,
    DeployEnvironmentAction,
} from '../../actions/environment/environment-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

export function* deployEnvironmentSaga(action: DeployEnvironmentAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { environment, id, parameters, showPutErrorOnResource } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const updatedEnvironment: PutEnvironmentBody = {
            ...environment,
            provisioningState: environment.provisioningState as EnvironmentResourceProvisioningState, //NOTE: need type assertion here for compatibility b/w 10-01 and 05-01
            parameters,
        };

        const response: DataResponse = yield call(replaceEnvironment, id, updatedEnvironment, accessToken, activityId);

        if (isFailureResponse(response)) {
            // Note: the data layer will call the operation CreateOrReplace, which doesn't differentiate between
            // create and redeploy. Overwriting the operation here to add that clarity.
            const failure = FailureResponse({ ...response, operation: FailureOperation.DeployEnvironment });
            yield put(deployEnvironmentFailed({ failure, id, showPutErrorOnResource }, meta));

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DeployEnvironment,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, failure.code)
            );

            return;
        }

        yield put(deployEnvironmentAccepted({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DeployEnvironment,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DeployEnvironment);
        yield put(deployEnvironmentError({ error, id, showPutErrorOnResource }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DeployEnvironment,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* deployEnvironmentAcceptedSaga(action: DeployEnvironmentAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetEnvironmentResponse = yield putAndAwait(pollNonTerminalEnvironment({ id }, meta));

        if (isFailureResponse(response)) {
            // Note: the data layer will call the operation GetEnvironment. Adjust that here to make it consistent with
            // the other failures that get reported in this saga.
            const failure = FailureResponse({ ...response, operation: FailureOperation.DeployEnvironment });
            yield put(deployEnvironmentOperationFailed({ failure, id }, meta));
            return;
        }

        const { data } = response;
        const state = data.provisioningState;

        switch (state) {
            case EnvironmentProvisioningState.Succeeded:
                yield put(deployEnvironmentSuccess({ id, result: data }, meta));
                break;
            default:
                // Unexpected state: log cases where we're falling back on the default failure message. This means we're
                // in a failed state, but there are no error details.
                if (!data.error) {
                    trackEvent(EventName.EnvironmentInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState: state,
                        },
                    });
                }

                const failure = createFailureResponseFromCloudErrorBodyOrDefault(
                    data.error,
                    FailureOperation.DeployEnvironment
                );

                yield put(deployEnvironmentOperationFailed({ failure, id, result: data }, meta));
                break;
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DeployEnvironment);
        yield put(deployEnvironmentOperationError({ error, id }, meta));
    }
}

export function* deployEnvironmentListenerSaga(): SagaIterator {
    yield takeLeading(deployEnvironment, deployEnvironmentSaga);
}

export function* deployEnvironmentAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(deployEnvironmentAccepted, deployEnvironmentAcceptedSaga);
}
