import { GetDevBoxResponse, ListDevBoxesResponse } from '../../../data/services/data-plane-api/dev-box';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, PollPayload } from '../core-actions';
import {
    AddDevBoxAcceptedPayload,
    AddDevBoxErrorPayload,
    AddDevBoxFailedPayload,
    AddDevBoxOperationFailedPayload,
    AddDevBoxOperationSuccessPayload,
    AddDevBoxPayload,
    DeleteDevBoxErrorPayload,
    DeleteDevBoxFailedPayload,
    DevBoxActionType,
    HibernateDevBoxErrorPayload,
    HibernateDevBoxFailedPayload,
    HibernateDevBoxSuccessPayload,
    ListDevBoxesErrorPayload,
    ListDevBoxesFailedPayload,
    ListDevBoxesSuccessPayload,
    PollNonTerminalDevBoxCompletePayload,
    RepairDevBoxErrorPayload,
    RepairDevBoxFailedPayload,
    RepairDevBoxSuccessPayload,
    RestartDevBoxErrorPayload,
    RestartDevBoxFailedPayload,
    RestartDevBoxSuccessPayload,
    RestoreSnapshotErrorPayload,
    RestoreSnapshotFailedPayload,
    RestoreSnapshotPayload,
    RestoreSnapshotSuccessPayload,
    ResumeDevBoxErrorPayload,
    ResumeDevBoxFailedPayload,
    ResumeDevBoxSuccessPayload,
    ShutdownDevBoxErrorPayload,
    ShutdownDevBoxFailedPayload,
    ShutdownDevBoxSuccessPayload,
    StartDevBoxErrorPayload,
    StartDevBoxFailedPayload,
    StartDevBoxSuccessPayload,
} from './dev-box-actions';

/**
 * Action creators
 */

export const addDevBox = createActionCreator<AddDevBoxPayload>(DevBoxActionType.AddDevBox, { startsActivity: true });
export const addDevBoxAccepted = createActionCreator<AddDevBoxAcceptedPayload>(DevBoxActionType.AddDevBoxAccepted);
export const addDevBoxError = createActionCreator<AddDevBoxErrorPayload>(DevBoxActionType.AddDevBoxError);
export const addDevBoxFailed = createActionCreator<AddDevBoxFailedPayload>(DevBoxActionType.AddDevBoxFailed);

export const addDevBoxOperationError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevBoxActionType.AddDevBoxOperationError
);

export const addDevBoxOperationFailed = createActionCreator<AddDevBoxOperationFailedPayload>(
    DevBoxActionType.AddDevBoxOperationFailed
);

export const addDevBoxOperationSuccess = createActionCreator<AddDevBoxOperationSuccessPayload>(
    DevBoxActionType.AddDevBoxOperationSuccess
);

export const clearAddDevBoxFailure = createActionCreatorWithoutPayload(DevBoxActionType.ClearAddDevBoxFailure);

export const deleteDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.DeleteDevBox, {
    startsActivity: true,
});

export const deleteDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.DeleteDevBoxAccepted);
export const deleteDevBoxError = createActionCreator<DeleteDevBoxErrorPayload>(DevBoxActionType.DeleteDevBoxError);
export const deleteDevBoxFailed = createActionCreator<DeleteDevBoxFailedPayload>(DevBoxActionType.DeleteDevBoxFailed);
export const deleteDevBoxSuccess = createActionCreator<IndexedPayload>(DevBoxActionType.DeleteDevBoxSuccess);

export const discoverDevBoxesInTenant = createActionCreatorWithoutPayload<AggregatedResult>(
    DevBoxActionType.DiscoverDevBoxesInTenant,
    { async: true }
);

export const discoverDevBoxesInTenantError = createActionCreator<ErrorPayload>(
    DevBoxActionType.DiscoverDevBoxesInTenantError
);

export const discoverDevBoxesInTenantFailed = createActionCreator<FailedPayload>(
    DevBoxActionType.DiscoverDevBoxesInTenantFailed
);

export const discoverDevBoxesInTenantSuccess = createActionCreatorWithoutPayload(
    DevBoxActionType.DiscoverDevBoxesInTenantSuccess
);

export const hibernateDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.HibernateDevBox, {
    startsActivity: true,
});

export const hibernateDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.HibernateDevBoxAccepted);

export const hibernateDevBoxError = createActionCreator<HibernateDevBoxErrorPayload>(
    DevBoxActionType.HibernateDevBoxError
);

export const hibernateDevBoxFailed = createActionCreator<HibernateDevBoxFailedPayload>(
    DevBoxActionType.HibernateDevBoxFailed
);

export const hibernateDevBoxSuccess = createActionCreator<HibernateDevBoxSuccessPayload>(
    DevBoxActionType.HibernateDevBoxSuccess
);

export const listDevBoxes = createActionCreator<IndexedPayload, ListDevBoxesResponse>(DevBoxActionType.ListDevBoxes, {
    async: true,
    startsActivity: true,
});

export const listDevBoxesError = createActionCreator<ListDevBoxesErrorPayload>(DevBoxActionType.ListDevBoxesError);
export const listDevBoxesFailed = createActionCreator<ListDevBoxesFailedPayload>(DevBoxActionType.ListDevBoxesFailed);

export const listDevBoxesSuccess = createActionCreator<ListDevBoxesSuccessPayload>(
    DevBoxActionType.ListDevBoxesSuccess
);

export const pollNonTerminalDevBox = createActionCreator<IndexedPayload & PollPayload, GetDevBoxResponse>(
    DevBoxActionType.PollNonTerminalDevBox,
    { async: true, startsActivity: true }
);

export const pollNonTerminalDevBoxComplete = createActionCreator<PollNonTerminalDevBoxCompletePayload>(
    DevBoxActionType.PollNonTerminalDevBoxComplete
);

export const pollNonTerminalDevBoxError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevBoxActionType.PollNonTerminalDevBoxError
);

export const repairDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.RepairDevBox, {
    startsActivity: true,
});

export const repairDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.RepairDevBoxAccepted);
export const repairDevBoxError = createActionCreator<RepairDevBoxErrorPayload>(DevBoxActionType.RepairDevBoxError);
export const repairDevBoxFailed = createActionCreator<RepairDevBoxFailedPayload>(DevBoxActionType.RepairDevBoxFailed);

export const repairDevBoxSuccess = createActionCreator<RepairDevBoxSuccessPayload>(
    DevBoxActionType.RepairDevBoxSuccess
);

export const restoreSnapshot = createActionCreator<RestoreSnapshotPayload>(DevBoxActionType.RestoreSnapshot, {
    startsActivity: true,
});

export const restoreSnapshotAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.RestoreSnapshotAccepted);
export const restoreSnapshotError = createActionCreator<RestoreSnapshotErrorPayload>(
    DevBoxActionType.RestoreSnapshotError
);
export const restoreSnapshotFailed = createActionCreator<RestoreSnapshotFailedPayload>(
    DevBoxActionType.RestoreSnapshotFailed
);

export const restoreSnapshotSuccess = createActionCreator<RestoreSnapshotSuccessPayload>(
    DevBoxActionType.RestoreSnapshotSuccess
);

export const restartDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.RestartDevBox, {
    startsActivity: true,
});

export const restartDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.RestartDevBoxAccepted);
export const restartDevBoxError = createActionCreator<RestartDevBoxErrorPayload>(DevBoxActionType.RestartDevBoxError);

export const restartDevBoxFailed = createActionCreator<RestartDevBoxFailedPayload>(
    DevBoxActionType.RestartDevBoxFailed
);

export const restartDevBoxSuccess = createActionCreator<RestartDevBoxSuccessPayload>(
    DevBoxActionType.RestartDevBoxSuccess
);

export const resumeDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.ResumeDevBox, {
    startsActivity: true,
});

export const resumeDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.ResumeDevBoxAccepted);
export const resumeDevBoxError = createActionCreator<ResumeDevBoxErrorPayload>(DevBoxActionType.ResumeDevBoxError);
export const resumeDevBoxFailed = createActionCreator<ResumeDevBoxFailedPayload>(DevBoxActionType.ResumeDevBoxFailed);

export const resumeDevBoxSuccess = createActionCreator<ResumeDevBoxSuccessPayload>(
    DevBoxActionType.ResumeDevBoxSuccess
);

export const shutdownDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.ShutdownDevBox, {
    startsActivity: true,
});

export const shutdownDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.ShutdownDevBoxAccepted);

export const shutdownDevBoxError = createActionCreator<ShutdownDevBoxErrorPayload>(
    DevBoxActionType.ShutdownDevBoxError
);

export const shutdownDevBoxFailed = createActionCreator<ShutdownDevBoxFailedPayload>(
    DevBoxActionType.ShutdownDevBoxFailed
);

export const shutdownDevBoxSuccess = createActionCreator<ShutdownDevBoxSuccessPayload>(
    DevBoxActionType.ShutdownDevBoxSuccess
);

export const startDevBox = createActionCreator<IndexedPayload>(DevBoxActionType.StartDevBox, {
    startsActivity: true,
});

export const startDevBoxAccepted = createActionCreator<IndexedPayload>(DevBoxActionType.StartDevBoxAccepted);
export const startDevBoxError = createActionCreator<StartDevBoxErrorPayload>(DevBoxActionType.StartDevBoxError);
export const startDevBoxFailed = createActionCreator<StartDevBoxFailedPayload>(DevBoxActionType.StartDevBoxFailed);
export const startDevBoxSuccess = createActionCreator<StartDevBoxSuccessPayload>(DevBoxActionType.StartDevBoxSuccess);
