/**
 * Composed selectors
 */

import { createSelector } from '@reduxjs/toolkit';
import { DevBoxProvisioningState } from '../../../constants/dev-box';
import { getDevBoxes } from '../../selector/dev-box-selectors';

/**
 * Application state selectors
 */

export const getIdsForDevBoxesHavingSnapshots = createSelector([getDevBoxes], (devBoxes) =>
    devBoxes
        .filter((devBox) => devBox.provisioningState !== DevBoxProvisioningState.Deleting)
        .map((devBox) => devBox.uri)
);
