import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useHorizontalStackStyles } from '../themes/styles/flexbox-styles';
import { SimpleLayoutProps } from './simple-layout';

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        flexGrow: '1',
    },
});

const useContentStyles = makeStyles({
    root: {
        height: 'fit-content',
        textAlign: 'center',
        width: '680px',
    },
});

/**
 * END Styles
 */

export const CenteredSimpleLayout: React.FC<SimpleLayoutProps> = (props: SimpleLayoutProps) => {
    const { children, id } = props;

    // Style hooks
    const containerStyles = useContainerStyles();
    const contentStyles = useContentStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <div id={id} className={mergeClasses(horizontalStackStyles.root, containerStyles.root)}>
            <div className={mergeClasses(horizontalStackStyles.item, contentStyles.root)}>{children}</div>
        </div>
    );
};

export default CenteredSimpleLayout;
