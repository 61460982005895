import { FontWeights } from '@fluentui/react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    FluentProvider,
    Link,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { Dismiss24Regular, Warning20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SupportSectionLinks } from '../../../constants/support-section-links';
import { useRepairDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';

export interface RepairDialogProps {
    onDismiss: () => void;
    onRepairSubmitted: () => void;
    open?: boolean;
}

const messages = defineMessages({
    yesCheckboxLabel: {
        id: 'RepairDialog_Yes_Label',
        defaultMessage: 'Yes, I want to troubleshoot this dev box',
        description:
            'Label for a checkbox on the repair dialog - when checked, the user will be able to submit and start the repair',
    },
    troubleshootButtonAriaLabel: {
        id: 'RepairDialog_TroubleshootButton_AriaLabel',
        defaultMessage: 'Troubleshoot',
        description: 'Aria label for the submit button on the repair dialog',
    },
    cancelButtonAriaLabel: {
        id: 'RepairDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the cancel button on the repair dialog.',
    },
    closeButtonAriaLabel: {
        id: 'RepairDialog_CloseButton_Text',
        defaultMessage: 'Close',
        description: 'Aria label for the "x" button in the top corner of the repair dialog',
    },
});

const getFormattedMessageValues = (href: string, target = '_blank') => {
    const values = {
        Link: (chunks: string) => (
            <Link href={href} target={target}>
                {chunks}
            </Link>
        ),
    };
    return values;
};

const troubleshootLinkValues = getFormattedMessageValues(SupportSectionLinks.TroubleshootAndRepair);

/**
 * Styles
 */
const dialogWidth = '569px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

const useTextStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
    },
});

const useDialogStackStyles = makeStyles({
    root: {
        gap: '18px',
        padding: '0px 0px 30px 0px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '10px',
        alignItems: 'start',
    },
});

/**
 * End Styles
 */

const RepairDialog: React.FC<RepairDialogProps> = (props: RepairDialogProps) => {
    const { onDismiss, onRepairSubmitted, open } = props;

    // State hooks
    const [yesChecked, setYesChecked] = React.useState(false);

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const textStyles = useTextStyles();
    const stackStyles = useStackStyles();
    const dialogStackStyles = useDialogStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const contentStyles = useContentStyles();

    // Callback hooks
    const onCheckboxChange = React.useCallback(() => setYesChecked(!yesChecked), [yesChecked]);

    const onSubmit = React.useCallback(() => {
        onRepairSubmitted();
        onDismiss();
    }, [onRepairSubmitted, onDismiss]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                <DialogSurface style={dialogStyle}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="RepairDialog_Title_Repair"
                                defaultMessage="Troubleshoot & repair connectivity"
                                description="The title for the dialog describing the troubleshoot & repair process."
                            />
                        </DialogTitle>
                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, dialogStackStyles.root)}>
                                <div className={stackStyles.item}>
                                    <FormattedMessage
                                        id="RepairDialog_Description_Text"
                                        defaultMessage="This process will review your dev box's connectivity and attempt to resolve any issues. <Link>Learn more about troubleshooting</Link>"
                                        description="Repair dialog description. <Link> and </Link> should not be localized, but text between them should."
                                        values={troubleshootLinkValues}
                                    />
                                </div>
                                <div className={mergeClasses(horizontalStackStyles.root, contentStyles.root)}>
                                    <div className={horizontalStackStyles.item}>
                                        <Warning20Regular />
                                    </div>
                                    <div className={mergeClasses(horizontalStackStyles.item, textStyles.root)}>
                                        <FormattedMessage
                                            id="RepairDialog_Warning_Text"
                                            defaultMessage="Your dev box will be unavailable during this process, and may be automatically restarted."
                                            description="The warning for the repair dialog"
                                        />
                                    </div>
                                </div>
                                <Divider />
                                <div className={stackStyles.item}>
                                    <Checkbox
                                        label={formatMessage(messages.yesCheckboxLabel)}
                                        onChange={onCheckboxChange}
                                        checked={yesChecked}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={formatMessage(messages.troubleshootButtonAriaLabel)}
                                onClick={onSubmit}
                                disabled={!yesChecked}
                            >
                                <FormattedMessage
                                    id="RepairDialog_HibernateButton_Label"
                                    defaultMessage="Troubleshoot"
                                    description="Label for the submit button on the repair dialog."
                                />
                            </Button>
                            <DialogTrigger>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                >
                                    <FormattedMessage
                                        id="RepairDialog_CancelButton_Label"
                                        defaultMessage="Cancel"
                                        description="Label for the cancel button on the repair dialog."
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const RepairDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useRepairDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <RepairDialog {...properties} open={isOpen} onDismiss={closeSurface} />;
};
