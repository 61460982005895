import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ActionAbility,
    CannotCreateDevBoxReason,
    DevBoxCreateAbilityState,
} from '../../../redux/selector/display/create-ability/models';
import Banner from '../../banner';

export interface DevBoxCreateInfoBannerProps {
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    organizationDisplayName: string;
}

export const DevBoxCreateInfoBanner: React.FC<DevBoxCreateInfoBannerProps> = React.memo(
    (props: DevBoxCreateInfoBannerProps) => {
        const { devBoxCreateAbilityState, organizationDisplayName } = props;

        // Component state hooks
        const [isMessageBarOpen, setIsMessageBarOpen] = React.useState(true);

        const onDismissMessageBar = React.useCallback(() => {
            setIsMessageBarOpen(false);
        }, []);

        const guestAccountDescriptionMessageValues = React.useMemo(
            () => ({ organizationDisplayName }),
            [organizationDisplayName]
        );

        // Render logic

        if (!isMessageBarOpen) {
            return <></>;
        }

        // If we are in an unknown state (still loading), don't display anything
        if (devBoxCreateAbilityState.createAbility === ActionAbility.Unknown) {
            return <></>;
        }

        // If we can create dev boxes, or are not a dev box user, no message to display here
        if (
            devBoxCreateAbilityState.createAbility === ActionAbility.CanPerformAction ||
            devBoxCreateAbilityState.reason === CannotCreateDevBoxReason.IsNotDevBoxUser
        ) {
            return <></>;
        }

        // Failure to load resources has blocked us from creating dev boxes, show error message bar
        if (devBoxCreateAbilityState.createAbility === ActionAbility.FailedToLoad) {
            return (
                <Banner intent="error" onDismiss={onDismissMessageBar}>
                    <FormattedMessage
                        id="DevBoxCreateInfoBanner_FailedToLoad_Text"
                        defaultMessage="We encountered an error loading your projects, and new dev boxes cannot be created at this time. Please retry, or contact your admin if this problem persists."
                        description="Message informing user that we failed to load project resources and they can't create dev boxes."
                    />
                </Banner>
            );
        }

        // User is configured to use dev boxes but is in a guest account, show info message
        if (devBoxCreateAbilityState.reason === CannotCreateDevBoxReason.GuestAccount) {
            return (
                <Banner intent="info" onDismiss={onDismissMessageBar}>
                    <FormattedMessage
                        id="DevBoxCreateInfoBanner_GuestAccount_Text"
                        defaultMessage="Your account is a guest in the {organizationDisplayName} organization. Azure guest accounts are not currently supported in Microsoft Dev Box."
                        description="Message informing user that they can't use this feature of the app using a guest account. {organizationDisplayName} should not be localized."
                        values={guestAccountDescriptionMessageValues}
                    />
                </Banner>
            );
        }

        return <></>;
    }
);

export default DevBoxCreateInfoBanner;
