import { UnionValueMap } from '../types/union-map';

// Sourced from https://microsoft.visualstudio.com/RDV/_git/rdinfra?path=%2Fsrc%2FRDPublishing%2FEV2Buildouts%2FMicrosoft.RDInfra.Ev2%2Fappsettings.json&_a=contents&version=GBdevelop
type RegionShorthand =
    | 'eus'
    | 'eus2'
    | 'scus'
    | 'p1br'
    | 'wus2'
    | 'wus3'
    | 'aue'
    | 'seas'
    | 'neu'
    | 'uks'
    | 'weu'
    | 'cus'
    | 'ncus'
    | 'wus'
    | 'san'
    | 'cin'
    | 'eas'
    | 'jpe'
    | 'krc'
    | 'cac'
    | 'frc'
    | 'gwc'
    | 'noe'
    | 'chno'
    | 'ilc'
    | 'uaen'
    | 'brs'
    | 'c1e2'
    | 'wcus'
    | 'saw'
    | 'auc'
    | 'auc2'
    | 'ause'
    | 'jpw'
    | 'krs'
    | 'sin'
    | 'win'
    | 'cae'
    | 'frs'
    | 'grn'
    | 'nww'
    | 'ukw'
    | 'uaec'
    | 'c2cu'
    | 'jiw'
    | 'jic'
    | 'mxc'
    | 'esc'
    | 'nzn'
    | 'twn'
    | 'twnw'
    | 'sec'
    | 'ses'
    | 'chw'
    | 'itn'
    | 'plc'
    | 'ugvi'
    | 'ugaz'
    | 'ugtx'
    | 'use'
    | 'usw'
    | 'unw'
    | 'une'
    | 'cne2'
    | 'cnn2'
    | 'cne3'
    | 'cnn3';

type Region =
    | 'eastus'
    | 'eastus2'
    | 'southcentralus'
    | 'BR-southcentralus'
    | 'westus2'
    | 'westus3'
    | 'australiaeast'
    | 'southeastasia'
    | 'northeurope'
    | 'uksouth'
    | 'westeurope'
    | 'centralus'
    | 'northcentralus'
    | 'westus'
    | 'southafricanorth'
    | 'centralindia'
    | 'eastasia'
    | 'japaneast'
    | 'koreacentral'
    | 'canadacentral'
    | 'francecentral'
    | 'germanywestcentral'
    | 'norwayeast'
    | 'switzerlandnorth'
    | 'israelcentral'
    | 'uaenorth'
    | 'brazilsouth'
    | 'eastus2euap'
    | 'westcentralus'
    | 'southafricawest'
    | 'australiacentral'
    | 'australiacentral2'
    | 'australiasoutheast'
    | 'japanwest'
    | 'koreasouth'
    | 'southindia'
    | 'westindia'
    | 'canadaeast'
    | 'francesouth'
    | 'germanynorth'
    | 'norwaywest'
    | 'ukwest'
    | 'uaecentral'
    | 'centraluseuap'
    | 'jioindiawest'
    | 'jioindiacentral'
    | 'mexicocentral'
    | 'spaincentral'
    | 'newzealandnorth'
    | 'taiwannorth'
    | 'taiwannorthwest'
    | 'swedencentral'
    | 'swedensouth'
    | 'switzerlandwest'
    | 'italynorth'
    | 'polandcentral'
    | 'usgovvirginia'
    | 'usgovarizona'
    | 'usgovtexas'
    | 'usseceast'
    | 'ussecwest'
    | 'usnatwest'
    | 'usnateast'
    | 'chinaeast2'
    | 'chinanorth2'
    | 'chinaeast3'
    | 'chinanorth3';

const RegionNameMap: UnionValueMap<RegionShorthand, Region> = {
    eus: 'eastus',
    eus2: 'eastus2',
    scus: 'southcentralus',
    p1br: 'BR-southcentralus',
    wus2: 'westus2',
    wus3: 'westus3',
    aue: 'australiaeast',
    seas: 'southeastasia',
    neu: 'northeurope',
    uks: 'uksouth',
    weu: 'westeurope',
    cus: 'centralus',
    ncus: 'northcentralus',
    wus: 'westus',
    san: 'southafricanorth',
    cin: 'centralindia',
    eas: 'eastasia',
    jpe: 'japaneast',
    krc: 'koreacentral',
    cac: 'canadacentral',
    frc: 'francecentral',
    gwc: 'germanywestcentral',
    noe: 'norwayeast',
    chno: 'switzerlandnorth',
    ilc: 'israelcentral',
    uaen: 'uaenorth',
    brs: 'brazilsouth',
    c1e2: 'eastus2euap',
    wcus: 'westcentralus',
    saw: 'southafricawest',
    auc: 'australiacentral',
    auc2: 'australiacentral2',
    ause: 'australiasoutheast',
    jpw: 'japanwest',
    krs: 'koreasouth',
    sin: 'southindia',
    win: 'westindia',
    cae: 'canadaeast',
    frs: 'francesouth',
    grn: 'germanynorth',
    nww: 'norwaywest',
    ukw: 'ukwest',
    uaec: 'uaecentral',
    c2cu: 'centraluseuap',
    jiw: 'jioindiawest',
    jic: 'jioindiacentral',
    mxc: 'mexicocentral',
    esc: 'spaincentral',
    nzn: 'newzealandnorth',
    twn: 'taiwannorth',
    twnw: 'taiwannorthwest',
    sec: 'swedencentral',
    ses: 'swedensouth',
    chw: 'switzerlandwest',
    itn: 'italynorth',
    plc: 'polandcentral',
    ugvi: 'usgovvirginia',
    ugaz: 'usgovarizona',
    ugtx: 'usgovtexas',
    use: 'usseceast',
    usw: 'ussecwest',
    unw: 'usnatwest',
    une: 'usnateast',
    cne2: 'chinaeast2',
    cnn2: 'chinanorth2',
    cne3: 'chinaeast3',
    cnn3: 'chinanorth3',
};

const mapRegionShorthandToFull = (regionShorthand: string): string | undefined => {
    return RegionNameMap[regionShorthand as RegionShorthand] ?? undefined;
};

export const getRegionFromRDGatewayUrl = (rdGatewayUrl: string): string | undefined => {
    const url = new URL(rdGatewayUrl);

    // The region url is shaped like 'https://rdgateway-{regionShorthand}-r1.wvd.microsoft.com/'.
    // This line splits this hostname by '.', pulls the first part ('rdgateway-{regionShorthand}-r1'),
    // splits that by '-', and pulls the second part ('{regionShorthand}').
    const regionPart = url.hostname.split('.')[0].split('-')[1];

    return mapRegionShorthandToFull(regionPart);
};

// NOTE: This mapping is to get the closest region for one of the CPC supported regions: https://devdiv.visualstudio.com/OnlineServices/_git/azure-devcenter?path=/src/Dependencies/src/CloudPc/Constants/CloudPcRegions.cs
// We can't map it to itself, because there is no data for one region to itself
// To keep this mapping up to date, make sure all regions from here are accounted for: https://microsoft.visualstudio.com/RDV/_git/rdinfra?path=%2Fsrc%2FRDPublishing%2FEV2Buildouts%2FMicrosoft.RDInfra.Ev2%2Fappsettings.json&_a=contents&version=GBdevelop
export const getClosestRegion = (region: string): string => {
    switch (region) {
        case RegionNameMap.eus:
        case RegionNameMap.c1e2:
        case RegionNameMap.ugvi:
        case RegionNameMap.une:
            return RegionNameMap.eus2; // eastus2
        case RegionNameMap.eus2:
            return RegionNameMap.eus; // eastus
        case RegionNameMap.scus:
        case RegionNameMap.c2cu:
        case RegionNameMap.ncus:
        case RegionNameMap.cac:
        case RegionNameMap.wcus:
            return RegionNameMap.cus; // centralus
        case RegionNameMap.wus2:
        case RegionNameMap.wus:
        case RegionNameMap.unw:
            return RegionNameMap.wus3; // westus3
        case RegionNameMap.wus3:
            return RegionNameMap.wus2; // westus2
        case RegionNameMap.aue:
        case RegionNameMap.eas:
            return RegionNameMap.seas; // southeastasia
        case RegionNameMap.seas:
        case RegionNameMap.twn:
        case RegionNameMap.twnw:
        case RegionNameMap.cne2:
        case RegionNameMap.cnn2:
        case RegionNameMap.cne2:
        case RegionNameMap.cnn3:
            return RegionNameMap.eas; // eastasia
        case RegionNameMap.neu:
        case RegionNameMap.weu:
        case RegionNameMap.ukw:
            return RegionNameMap.uks; // uksouth
        case RegionNameMap.uks:
            return RegionNameMap.neu; // northeurope
        case RegionNameMap.cus:
        case RegionNameMap.brs:
        case RegionNameMap.p1br:
        case RegionNameMap.ugtx:
        case RegionNameMap.ugaz:
            return RegionNameMap.scus; // southcentralus
        case RegionNameMap.san:
        case RegionNameMap.sin:
        case RegionNameMap.win:
        case RegionNameMap.jiw:
        case RegionNameMap.jic:
            return RegionNameMap.cin; // centralindia
        case RegionNameMap.jpe:
        case RegionNameMap.krs:
            return RegionNameMap.krc; // koreacentral
        case RegionNameMap.krc:
        case RegionNameMap.jpw:
            return RegionNameMap.jpe; // japaneast
        case RegionNameMap.itn:
        case RegionNameMap.grn:
        case RegionNameMap.sec:
        case RegionNameMap.plc:
            return RegionNameMap.gwc; //germanywestcentral
        case RegionNameMap.ilc:
        case RegionNameMap.uaen:
        case RegionNameMap.saw:
        case RegionNameMap.uaec:
            return RegionNameMap.san; // southafricanorth
        case RegionNameMap.auc:
        case RegionNameMap.auc2:
        case RegionNameMap.ause:
        case RegionNameMap.nzn:
            return RegionNameMap.aue; // australiaeast
        case RegionNameMap.cae:
            return RegionNameMap.cac; // canadacentral
        case RegionNameMap.noe:
        case RegionNameMap.nww:
        case RegionNameMap.ses:
            return RegionNameMap.sec; // swedencentral
        case RegionNameMap.gwc:
        case RegionNameMap.frs:
        case RegionNameMap.frc:
        case RegionNameMap.esc:
        case RegionNameMap.chno:
            return RegionNameMap.itn; // italynorth
        case RegionNameMap.mxc:
            return RegionNameMap.brs; // brazilsouth
        case RegionNameMap.chw:
            return RegionNameMap.chno; // switzerlandnorth
        default:
            return '';
    }
};
