import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { addDevBoxAcceptedListenerSaga, addDevBoxListenerSaga } from './add-dev-box';
import { deleteDevBoxAcceptedListenerSaga, deleteDevBoxListenerSaga } from './delete-dev-box';
import { discoverDevBoxesInTenantListenerSaga } from './discover-dev-boxes-in-tenant';
import { listDevBoxesListenerSaga } from './list-dev-boxes';
import { pollNonTerminalDevBoxListenerSaga } from './poll-non-terminal-dev-box';
import { repairDevBoxAcceptedListenerSaga, repairDevBoxListenerSaga } from './repair-dev-box';
import { restartDevBoxAcceptedListenerSaga, restartDevBoxListenerSaga } from './restart-dev-box';
import { restoreSnapshotAcceptedListenerSaga, restoreSnapshotListenerSaga } from './restore-snapshot';
import {
    shutdownOrHibernateDevBoxAcceptedListenerSaga,
    shutdownOrHibernateDevBoxListenerSaga,
} from './shutdown-or-hibernate-dev-box';
import { startOrResumeDevBoxAcceptedListenerSaga, startOrResumeDevBoxListenerSaga } from './start-or-resume-dev-box';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverDevBoxesInTenantListenerSaga),
        fork(listDevBoxesListenerSaga),
        fork(addDevBoxListenerSaga),
        fork(addDevBoxAcceptedListenerSaga),
        fork(startOrResumeDevBoxListenerSaga),
        fork(startOrResumeDevBoxAcceptedListenerSaga),
        fork(restartDevBoxListenerSaga),
        fork(restartDevBoxAcceptedListenerSaga),
        fork(repairDevBoxListenerSaga),
        fork(repairDevBoxAcceptedListenerSaga),
        fork(deleteDevBoxListenerSaga),
        fork(deleteDevBoxAcceptedListenerSaga),
        fork(pollNonTerminalDevBoxListenerSaga),
        fork(shutdownOrHibernateDevBoxListenerSaga),
        fork(shutdownOrHibernateDevBoxAcceptedListenerSaga),
        fork(restoreSnapshotListenerSaga),
        fork(restoreSnapshotAcceptedListenerSaga),
    ]);
}
