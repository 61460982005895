import {
    AnimationStyles,
    CommandBar,
    ICommandBarItemProps,
    makeStyles as legacyMakeStyles,
    ProgressIndicator,
} from '@fluentui/react';
import {
    Button,
    FluentProvider,
    makeStyles,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
} from '@fluentui/react-components';
import { Add20Regular, Desktop20Regular, GlobeSurface20Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAddDevBoxPanelContext, useAddEnvironmentPanelContext } from '../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../hooks/styling';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { useStackWithFullWidthItemStyles } from '../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../utilities/styles';
import { ActionBarState } from './models';
import ProjectPickerContainer from './project-picker/project-picker';
import ResourcePickerContainer from './resource-picker/resource-picker';
import { getActionBarState, getLoadingProgress } from './selectors';

interface ActionBarComponentProps {
    onAddDevBoxButtonClicked: () => void;
    onAddEnvironmentButtonClicked: () => void;
    actionBarState: ActionBarState;
    loadingProgress: number;
}

export const messages = defineMessages({
    newButtonAriaLabel: {
        id: 'ActionBar_NewButton_AriaLabel',
        defaultMessage: 'New',
        description: 'Aria label for "new" button',
    },
    addDevBoxButtonAriaLabel: {
        id: 'ActionBar_AddDevBoxButton_AriaLabel',
        defaultMessage: 'New dev box',
        description: 'Aria label for the "new dev box" button',
    },
    addEnvironmentButtonAriaLabel: {
        id: 'ActionBar_AddEnvironmentButton_AriaLabel',
        defaultMessage: 'New environment',
        description: 'Aria label for the "new environment" button',
    },
    progressIndicatorAriaLabel: {
        id: 'ActionBar_ProgressIndicator_AriaLabel',
        defaultMessage: 'Loading progress',
        description: 'Aria label for the progress indicator',
    },
    resourcePickerButtonAriaLabel: {
        id: 'ActionBar_ResourcePickerButton_AriaLabel',
        defaultMessage: 'Resource picker',
        description: 'Aria label for the resource picker',
    },
    projectPickerButtonAriaLabel: {
        id: 'ActionBar_ProjectPickerButton_AriaLabel',
        defaultMessage: 'Project picker',
        description: 'Aria label for the project picker',
    },
});

/**
 * Styles
 */

const rootStylesFactory = (isLoading: boolean) =>
    legacyMakeStyles((theme) => ({
        root: {
            borderBottom: isLoading ? 0 : `1px solid ${getSemanticColor(theme, 'actionBarBorder')}`,
            height: isLoading ? '2px' : '47px',
            padding: isLoading ? 0 : '0 16px',
            userSelect: 'none',
            zIndex: 1000,

            // This slide-down animation is applied to the action bar container once loading is complete. Gives the
            // appearance of the progress bar disappearing and the action bar sliding in.
            ...(isLoading
                ? {}
                : {
                      ...AnimationStyles.slideDownIn10,
                      animationDuration: '0.3s',
                  }),
        },
    }));

const useProgressBarContainerStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const useProgressBarStyles = makeStyles({
    itemProgress: {
        padding: 0,
    },
});

const useCommandBarStyles = legacyMakeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
        height: '100%',
        padding: 0,
    },
}));

const useButtonContainerStyles = makeStyles({
    root: {
        gap: '8px',
        justifyContent: 'center',
        height: '100%',
    },
});

/**
 * END Styles
 */

export const ActionBarComponent: React.FC<ActionBarComponentProps> = React.memo((props: ActionBarComponentProps) => {
    const { onAddDevBoxButtonClicked, onAddEnvironmentButtonClicked, actionBarState, loadingProgress } = props;

    // Intl hooks
    const { formatMessage, formatNumber } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const isLoading = actionBarState === ActionBarState.Loading;
    const useRootStyles = rootStylesFactory(isLoading);
    const { root } = useRootStyles();
    const progressBarContainerStyles = useProgressBarContainerStyles();
    const progressBarStyles = useProgressBarStyles();
    const commandBarStyles = useCommandBarStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const buttonContainerStyles = useButtonContainerStyles();

    const menuList = React.useMemo(
        () => (
            <MenuList>
                <MenuItem
                    key="addDevBox"
                    icon={<Desktop20Regular />}
                    aria-label={formatMessage(messages.addDevBoxButtonAriaLabel)}
                    onClick={onAddDevBoxButtonClicked}
                >
                    <FormattedMessage
                        id="ActionBar_AddDevBoxButton_Text"
                        defaultMessage="New dev box"
                        description='Text for the "new dev box" button'
                    />
                </MenuItem>
                <MenuItem
                    key="addEnvironment"
                    icon={<GlobeSurface20Regular />}
                    aria-label={formatMessage(messages.addEnvironmentButtonAriaLabel)}
                    onClick={onAddEnvironmentButtonClicked}
                >
                    <FormattedMessage
                        id="ActionBar_AddEnvironmentButton_Text"
                        defaultMessage="New environment"
                        description='Text for the "new environment" button'
                    />
                </MenuItem>
            </MenuList>
        ),
        [formatMessage, onAddDevBoxButtonClicked, onAddEnvironmentButtonClicked]
    );

    const addDevBoxAndAddEnvironmentButtonProps = React.useMemo(
        () => (
            <Menu>
                <MenuTrigger>
                    <MenuButton
                        aria-label={formatMessage(messages.newButtonAriaLabel)}
                        appearance="primary"
                        icon={<Add20Regular />}
                    >
                        <FormattedMessage
                            id="ActionBar_NewButton_Text"
                            defaultMessage="New"
                            description='Text for the "new" button'
                        />
                    </MenuButton>
                </MenuTrigger>

                <MenuPopover>{menuList}</MenuPopover>
            </Menu>
        ),
        [menuList, formatMessage]
    );

    const buttonProps = React.useMemo(() => {
        switch (actionBarState) {
            case ActionBarState.CanCreateDevBoxesAndEnvironments:
                return addDevBoxAndAddEnvironmentButtonProps;
            case ActionBarState.CanCreateOnlyDevBoxes:
                return (
                    <Button
                        aria-label={formatMessage(messages.addDevBoxButtonAriaLabel)}
                        icon={<Add20Regular />}
                        onClick={onAddDevBoxButtonClicked}
                        appearance="primary"
                    >
                        <FormattedMessage
                            id="ActionBar_AddDevBoxButton_Text"
                            defaultMessage="New dev box"
                            description='Text for the "new dev box" button'
                        />
                    </Button>
                );
            case ActionBarState.CanCreateOnlyEnvironments:
                return (
                    <Button
                        aria-label={formatMessage(messages.addEnvironmentButtonAriaLabel)}
                        icon={<Add20Regular />}
                        onClick={onAddEnvironmentButtonClicked}
                        appearance="primary"
                    >
                        <FormattedMessage
                            id="ActionBar_AddEnvironmentButton_Text"
                            defaultMessage="New environment"
                            description='Text for the "new environment" button'
                        />
                    </Button>
                );
            default:
                return undefined;
        }
    }, [
        actionBarState,
        onAddEnvironmentButtonClicked,
        onAddDevBoxButtonClicked,
        formatMessage,
        addDevBoxAndAddEnvironmentButtonProps,
    ]);

    const actionBarItems: ICommandBarItemProps[] = React.useMemo(() => {
        return [
            {
                key: 'newButton',
                onRender: () => <FluentProvider theme={theme}> {buttonProps}</FluentProvider>,
            },
        ];
    }, [buttonProps, theme]);

    const farItems: ICommandBarItemProps[] = React.useMemo(() => {
        return [
            {
                key: 'resourcePicker',
                onRender: () => (
                    <ResourcePickerContainer ariaLabel={formatMessage(messages.resourcePickerButtonAriaLabel)} />
                ),
            },
            {
                key: 'projectPicker',
                onRender: () => (
                    <ProjectPickerContainer ariaLabel={formatMessage(messages.projectPickerButtonAriaLabel)} />
                ),
            },
        ];
    }, []);

    if (
        actionBarState === ActionBarState.NotStarted ||
        actionBarState === ActionBarState.CannotCreateDevBoxesOrEnvironments
    ) {
        return <></>;
    }

    return (
        <div className={root}>
            <div className={mergeClasses(stackStyles.root, buttonContainerStyles.root)}>
                {isLoading ? (
                    <div className={mergeClasses(stackStyles.item, progressBarContainerStyles.root)}>
                        <ProgressIndicator
                            ariaLabel={formatMessage(messages.progressIndicatorAriaLabel)}
                            ariaValueText={formatNumber(loadingProgress, {
                                maximumFractionDigits: 0,
                                style: 'percent',
                            })}
                            percentComplete={loadingProgress}
                            styles={progressBarStyles}
                        />
                    </div>
                ) : (
                    <div className={stackStyles.item}>
                        <CommandBar items={actionBarItems} farItems={farItems} styles={commandBarStyles} />
                    </div>
                )}
            </div>
        </div>
    );
});

export const ActionBarContainer: React.FC = () => {
    // Application state hooks
    const actionBarState = useSelector(getActionBarState);
    const loadingProgress = useSelector(getLoadingProgress);

    // Context hooks
    const { openSurface: onAddDevBoxButtonClicked } = useAddDevBoxPanelContext();
    const { openSurface: onAddEnvironmentButtonClicked } = useAddEnvironmentPanelContext();

    return (
        <ActionBarComponent
            onAddDevBoxButtonClicked={onAddDevBoxButtonClicked}
            onAddEnvironmentButtonClicked={onAddEnvironmentButtonClicked}
            actionBarState={actionBarState}
            loadingProgress={loadingProgress}
        />
    );
};

export default ActionBarContainer;
