import { FontSizes, FontWeights, PrimaryButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import signInExpiredImage from '../content/images/SignedOut.svg';
import { useActionCreator } from '../hooks/action-creator';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { signIn } from '../redux/actions/identity/identity-action-creators';
import { Index } from '../routes';
import { useStackStyles } from '../themes/styles/flexbox-styles';
import { ReturnVoid } from '../types/return-void';
interface SessionExpiredPageContainerProps {
    hasIdentityError: boolean;
}

interface SessionExpiredPageComponentProps {
    hasIdentityError: boolean;
    onSignInSubmitted: ReturnVoid<typeof signIn>;
}

const messages = defineMessages({
    expiredImageAlt: {
        id: 'SessionExpiredPage_ExpiredImage_Alt',
        defaultMessage: 'Sign in expired',
        description: 'Alt text for "sign in expired" image',
    },
    signInButtonLabel: {
        id: 'SessionExpiredPage_SignInButton_Label',
        defaultMessage: 'Sign in',
        description: 'Aria label for sign in button',
    },
    signInButtonText: {
        id: 'SessionExpiredPage_SignInButton_Text',
        defaultMessage: 'Sign in',
        description: 'Text for sign in button',
    },
});

/**
 * Styles
 */

const useSignInButtonContainerStyles = makeStyles({
    // Note: using paddingTop to space StackItems as Stack will override margins
    root: {
        lineHeight: '20px',
        paddingTop: '20px',
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        maxWidth: '344px',
        paddingTop: '6px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

/**
 * End Styles
 */

export const SessionExpiredPageComponent: React.FC<SessionExpiredPageComponentProps> = (
    props: SessionExpiredPageComponentProps
) => {
    const { hasIdentityError, onSignInSubmitted } = props;

    // Callback hooks
    // Ensure that we don't redirect to this page on sign-in, which will be a 404
    const onSignInButtonClickedWithoutError = React.useCallback(
        () => onSignInSubmitted({ postSignInPath: `https://${window.location.host}/` }),
        [onSignInSubmitted]
    );

    // Navigate back to the index if users are here due to an IdentityError.
    const onSignInButtonClickedWithError = React.useCallback(() => {
        window.location.assign(`https://${window.location.host}${Index}`);
    }, [window.location]);

    const onSignInClicked = React.useCallback(
        () => (hasIdentityError ? onSignInButtonClickedWithError() : onSignInButtonClickedWithoutError()),
        [hasIdentityError, onSignInButtonClickedWithError, onSignInButtonClickedWithoutError]
    );

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const signInButtonContainerStyles = useSignInButtonContainerStyles();
    const titleContainerStyles = useTitleContainerStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    return (
        <CenteredPortalLayout id="session-expired" showFooter>
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={stackStyles.item}>
                    <img alt={formatMessage(messages.expiredImageAlt)} src={signInExpiredImage} />
                </div>

                <div className={mergeClasses(stackStyles.item, titleContainerStyles.root)}>
                    {hasIdentityError ? (
                        <FormattedMessage
                            id="SessionExpiredPage_UnexpectedErrorTitle_Text"
                            defaultMessage="Your session has ended unexpectedly. Please sign back in to access your dev boxes."
                            description="Message indicating to the user that their session has expired due to an unexpected error and they must sign in again."
                        />
                    ) : (
                        <FormattedMessage
                            id="SessionExpiredPage_Title_Text"
                            defaultMessage="You have been logged out because your session has expired."
                            description="Message indicating to the user that their session has expired and they must sign in again."
                        />
                    )}
                </div>

                <div className={mergeClasses(stackStyles.item, signInButtonContainerStyles.root)}>
                    <PrimaryButton
                        ariaLabel={formatMessage(messages.signInButtonLabel)}
                        onClick={onSignInClicked}
                        text={formatMessage(messages.signInButtonText)}
                    />
                </div>
            </div>
        </CenteredPortalLayout>
    );
};

export const SessionExpiredPageContainer: React.FC<SessionExpiredPageContainerProps> = (
    props: SessionExpiredPageContainerProps
) => {
    const { hasIdentityError } = props;

    // Action hooks
    const onSignInSubmitted = useActionCreator(signIn);

    return <SessionExpiredPageComponent hasIdentityError={hasIdentityError} onSignInSubmitted={onSignInSubmitted} />;
};

export default SessionExpiredPageContainer;
