import { IColumn } from '@fluentui/react';
import { IntlFormatters, MessageDescriptor } from 'react-intl';
import { sortBy } from '../../../utilities/array';
import { compareDates } from '../../../utilities/date';
import { compareStrings, isSubstringOfTextCaseInsensitive, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { getRelativeTimeComponents } from '../../../utilities/time';
import { ImageKey, ImageViewModel } from '../models';
import { selectDevBoxImageDetailsListMessages } from './messages';
import { ImageDetailsListItem } from './models';

const compareImageViewModels = (a: ImageViewModel, b: ImageViewModel, columnKey: ImageKey): number => {
    switch (columnKey) {
        case ImageKey.LastUpdated:
            return compareDates(a.lastUpdated, b.lastUpdated);

        // For everything else, do a case-insensitive string comparison
        default:
            return compareStrings(`${a[columnKey]}`, `${b[columnKey]}`, true);
    }
};

const createImageDetailsListColumn = (
    ariaLabel: string,
    isSortedDescending: boolean,
    key: ImageKey,
    keyOfSortedColumn: ImageKey,
    name: string,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRender: (item: ImageDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null,
    sortAscendingAriaLabel: string,
    sortDescendingAriaLabel: string,
    minWidth: number,
    maxWidth?: number,
    isPadded = true,
    isRowHeader = false
): IColumn => ({
    ariaLabel,
    isPadded,
    isResizable: minWidth !== maxWidth,
    isRowHeader,
    isSorted: key === keyOfSortedColumn,
    ...(key === keyOfSortedColumn ? { isSortedDescending } : {}),
    key,
    maxWidth,
    minWidth,
    name,
    onColumnClick,
    onRender,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
});

export const getImageViewModelKey = (value: ImageViewModel): string => value.name;

const createImageDetailsListItem = (
    formatLastImageUpdate: (value: Date | undefined) => string,
    imageViewModel: ImageViewModel
): ImageDetailsListItem => {
    const { name, imageName, version, lastUpdated } = imageViewModel;

    return {
        key: getImageViewModelKey(imageViewModel),
        columns: {
            imageName: imageName ?? '',
            version: version ?? '',
            lastUpdated: formatLastImageUpdate(lastUpdated),
            name,
        },
        value: imageViewModel,
    };
};

const doesImageMatchSearchText = (searchText: string, pool: ImageDetailsListItem) => {
    const { columns } = pool;
    const { name, imageName, version, lastUpdated } = columns;

    return (
        isSubstringOfTextCaseInsensitive(name, searchText) ||
        isSubstringOfTextCaseInsensitive(imageName, searchText) ||
        isSubstringOfTextCaseInsensitive(version, searchText) ||
        isSubstringOfTextCaseInsensitive(lastUpdated, searchText)
    );
};

const filterImageDetailsListItems = (filterText: string, items: ImageDetailsListItem[]): ImageDetailsListItem[] =>
    items.filter((item) => doesImageMatchSearchText(filterText, item));

export const formatLastImageUpdate = (lastUpdatedDate: Date | undefined, formatters: IntlFormatters): string => {
    if (lastUpdatedDate === undefined) {
        return '';
    }

    const { formatRelativeTime } = formatters;
    const { unit, value } = getRelativeTimeComponents(lastUpdatedDate, new Date());

    // Note: converting value to negative to get "x days ago" rather than "in x days"
    return formatRelativeTime(value * -1, unit);
};

export const getImageDetailsListColumns = (
    descending: boolean,
    formatMessage: (message: MessageDescriptor) => string,
    keyOfSortedColumn: ImageKey,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRenderItem: (item: ImageDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null
): IColumn[] => {
    const sortAscendingAriaLabel = formatMessage(selectDevBoxImageDetailsListMessages.sortAscendingOrderAriaLabel);
    const sortDescendingAriaLabel = formatMessage(selectDevBoxImageDetailsListMessages.sortDescendingOrderAriaLabel);

    return [
        createImageDetailsListColumn(
            formatMessage(selectDevBoxImageDetailsListMessages.nameColumnHeaderAriaLabel),
            descending,
            ImageKey.Name,
            keyOfSortedColumn,
            formatMessage(selectDevBoxImageDetailsListMessages.nameColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            254,
            undefined,
            false,
            true
        ),
        createImageDetailsListColumn(
            formatMessage(selectDevBoxImageDetailsListMessages.sourceImageColumnHeaderAriaLabel),
            descending,
            ImageKey.ImageName,
            keyOfSortedColumn,
            formatMessage(selectDevBoxImageDetailsListMessages.sourceImageColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            525
        ),
        createImageDetailsListColumn(
            formatMessage(selectDevBoxImageDetailsListMessages.versionColumnHeaderAriaLabel),
            descending,
            ImageKey.Version,
            keyOfSortedColumn,
            formatMessage(selectDevBoxImageDetailsListMessages.versionColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            72,
            72
        ),
        createImageDetailsListColumn(
            formatMessage(selectDevBoxImageDetailsListMessages.lastImageUpdateColumnHeaderAriaLabel),
            descending,
            ImageKey.LastUpdated,
            keyOfSortedColumn,
            formatMessage(selectDevBoxImageDetailsListMessages.lastImageUpdateColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            118,
            118
        ),
    ];
};

export const getImageDetailsListItems = (
    columnKey: ImageKey,
    descending: boolean,
    filterText: string,
    formatLastImageUpdate: (value: Date | undefined) => string,
    imageViewModels: ImageViewModel[]
): ImageDetailsListItem[] => {
    // Sort items by their current given key
    const orderedItems = sortBy(
        imageViewModels,
        (item) => item,
        (a, b) => compareImageViewModels(a, b, columnKey)
    );
    const sortedItems = descending ? orderedItems.reverse() : orderedItems;

    // Convert view models into formatted items
    const formattedItems = sortedItems.map((item) => createImageDetailsListItem(formatLastImageUpdate, item));

    // Apply filter
    return isUndefinedOrWhiteSpace(filterText)
        ? formattedItems
        : filterImageDetailsListItems(filterText, formattedItems);
};
