import {
    CheckboxVisibility,
    DetailsList,
    DetailsListLayoutMode,
    ICalloutProps,
    IColumn,
    IDetailsHeaderProps,
    IObjectWithKey,
    ISelection,
    Selection,
    SelectionMode,
    TextField,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useFormatter } from '../../../hooks/localization';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { RegionKey, RegionViewModel } from '../models';
import LatencyBandItem from './latency-band-item';
import { selectDevBoxRegionDetailsListMessages } from './messages';
import { RegionDetailsListItem } from './models';
import {
    formatRoundTripTime,
    getRegionDetailsListColumns,
    getRegionDetailsListItems,
    getRegionViewModelKey,
} from './selectors';

interface SelectDevBoxRegionDetailsListProps {
    regions: RegionViewModel[];
    selectedRegion: RegionViewModel | undefined;
    setSelectedRegion: (item: RegionViewModel | undefined) => void;
}

/**
 * Styles
 */

const useFilterTextFieldStyles = makeStyles({
    root: {
        width: '320px',
    },
});

const useDetailsListContainerStyles = makeStyles({
    root: {
        minHeight: '509px',
        maxHeight: '509px',
        overflowY: 'auto',
    },
});

const useDetailsListHeaderStyles = makeStyles({
    root: {
        paddingTop: 0,
    },
});

const useTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '51px',
    },
});

/**
 * End Styles
 */

const tooltipCalloutProps: ICalloutProps = { gapSpace: 0 };

export const SelectDevBoxRegionDetailsList: React.FC<SelectDevBoxRegionDetailsListProps> = (
    props: SelectDevBoxRegionDetailsListProps
) => {
    const { regions, selectedRegion, setSelectedRegion } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const detailsListContainerStyles = useDetailsListContainerStyles();
    const filterTextFieldStyles = useFilterTextFieldStyles();
    const headerStyles = useDetailsListHeaderStyles();
    const tooltipHostStyles = useTooltipHostStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    // State hooks
    const [filterText, setFilterText] = React.useState<string>('');
    const [isSortedDescending, setIsSortedDescending] = React.useState<boolean>(false);
    const [sortKey, setSortKey] = React.useState<RegionKey>(RegionKey.Name);

    // Formatter hooks
    const roundTripTimeFormatter = useFormatter(formatRoundTripTime);

    // Callback hooks
    const onRenderItem = React.useCallback(
        (item: RegionDetailsListItem, _index?: number, column?: IColumn): JSX.Element | null => {
            if (!column) {
                return null;
            }

            const { key } = column;
            const { columns } = item;
            const value = columns[key as RegionKey];

            if (isUndefinedOrWhiteSpace(value)) {
                return <span>--</span>;
            }

            switch (key) {
                // Wrap longer text fields in tooltips
                case RegionKey.Name:
                    return (
                        <TooltipHost
                            calloutProps={tooltipCalloutProps}
                            content={value}
                            overflowMode={TooltipOverflowMode.Self}
                            styles={tooltipHostStyles}
                        >
                            <span>{value}</span>
                        </TooltipHost>
                    );
                case RegionKey.LatencyBand:
                    return <LatencyBandItem latencyBand={value} />;
                default:
                    return <span>{value}</span>;
            }
        },
        [tooltipHostStyles]
    );

    const onFilterRegionsChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
            setFilterText(newValue ?? '');
        },
        []
    );

    const onColumnClick = React.useCallback(
        (_ev: unknown, column: IColumn): void => {
            const { key: clickedColumnKey } = column;

            // If sorted column is changing, ensure it's initially sorted ascending
            setIsSortedDescending(clickedColumnKey === sortKey ? !isSortedDescending : false);
            setSortKey(clickedColumnKey as RegionKey);
        },
        [sortKey, isSortedDescending]
    );

    const onSelectedRegionChange = React.useCallback(
        (selectedItems: RegionDetailsListItem[]): void => {
            setSelectedRegion(selectedItems[0]?.value);
        },
        [setSelectedRegion]
    );

    const onRenderDetailsHeader = React.useCallback(
        (
            headerProps?: IDetailsHeaderProps,
            defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
        ): JSX.Element | null => {
            if (!headerProps || !defaultRender) {
                return null;
            }

            return defaultRender({
                ...headerProps,
                styles: headerStyles,
            });
        },
        [headerStyles]
    );

    // Memo hooks
    const columns: IColumn[] = React.useMemo(
        () => getRegionDetailsListColumns(isSortedDescending, formatMessage, sortKey, onColumnClick, onRenderItem),
        [sortKey, formatMessage, isSortedDescending, onColumnClick, onRenderItem]
    );

    const items: RegionDetailsListItem[] = React.useMemo(
        () => getRegionDetailsListItems(sortKey, isSortedDescending, filterText, roundTripTimeFormatter, regions),
        [filterText, isSortedDescending, roundTripTimeFormatter, regions, sortKey]
    );

    const selection: ISelection<RegionDetailsListItem> = React.useMemo(() => {
        return new Selection<RegionDetailsListItem>({
            onSelectionChanged: () => {
                onSelectedRegionChange(selection.getSelection());
            },
        });
    }, [onSelectedRegionChange]);

    // keeping the selection state in sync with our form state on first load
    React.useEffect(() => {
        if (selectedRegion !== undefined) {
            selection.setKeySelected(getRegionViewModelKey(selectedRegion), true, false);
        }
    }, []);

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <TextField
                    placeholder={formatMessage(selectDevBoxRegionDetailsListMessages.filterInputPlaceholder)}
                    ariaLabel={formatMessage(selectDevBoxRegionDetailsListMessages.filterInputAriaLabel)}
                    autoFocus
                    onChange={onFilterRegionsChange}
                    styles={filterTextFieldStyles}
                />
            </div>
            <div className={mergeClasses(stackStyles.root, detailsListContainerStyles.root)}>
                <DetailsList
                    items={items}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    setKey="single"
                    selectionPreservedOnEmptyClick={true}
                    // Need this type coercion because DetailsLists don't support generics and while a RegionDetailsListItem is an IObjectWithKey an IObjectWithKey is not an RegionDetailsListItem
                    selection={selection as any as ISelection<IObjectWithKey>} // eslint-disable-line @typescript-eslint/no-explicit-any
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                />
            </div>
        </div>
    );
};

export default SelectDevBoxRegionDetailsList;
