import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Banner from '../../banner';
import { CombinedInfoMessageKind } from '../models';

interface CombinedInfoBannerProps {
    kind: CombinedInfoMessageKind;
}

const CombinedInfoMessage: React.FC<CombinedInfoBannerProps> = React.memo((props: CombinedInfoBannerProps) => {
    const { kind } = props;

    switch (kind) {
        case CombinedInfoMessageKind.NoCreateResourcesForDevBoxesOrEnvironmentsMessage:
            return (
                <FormattedMessage
                    id="CombinedInfoBanner_NoWriteResources_Text"
                    defaultMessage="Your account has access to create dev boxes and environments on one or more projects, but those projects are missing resources."
                    description="Message informing user that they don't have the necessary resources to create environments or dev boxes"
                />
            );
        case CombinedInfoMessageKind.NoEnvironmentOrDevBoxWritePermissionsMessage:
            return (
                <FormattedMessage
                    id="CombinedInfoBanner_NoWritePermission_Text"
                    defaultMessage="Your account does not have access to create dev boxes or environments in any of the projects in this organization."
                    description="Message informing user that they don't have permissions to create dev boxes or environments."
                />
            );
        default:
            return <></>;
    }
});

export const CombinedInfoBanner: React.FC<CombinedInfoBannerProps> = React.memo((props: CombinedInfoBannerProps) => {
    const { kind } = props;

    // Component state hooks
    const [isMessageBarOpen, setIsMessageBarOpen] = React.useState(true);

    const onDismissMessageBar = React.useCallback(() => {
        setIsMessageBarOpen(false);
    }, []);

    if (!isMessageBarOpen) {
        return <></>;
    }

    return (
        <Banner intent="info" onDismiss={onDismissMessageBar}>
            <CombinedInfoMessage kind={kind} />
        </Banner>
    );
});

export default CombinedInfoBanner;
