// See https://uifabricicons.azurewebsites.net/ for a full list of available icons

export const FluentIconNames = {
    Play: 'Play',
    Info: 'Info',
    Clock: 'Clock',
    Next: 'Next',
    Stop: 'Stop',
    OpenInNewTab: 'OpenInNewTab',
    Delete: 'Delete',
    PowerButton: 'PowerButton',
    ErrorBadge: 'ErrorBadge',
    WarningSolid: 'WarningSolid',
    Refresh: 'Refresh',
    Repair: 'Repair',
    CheckList: 'CheckList',
    Accept: 'Accept',
    Blocked: 'Blocked',
    Download: 'Download',
    Warning: 'Warning',
    CircleRing: 'CircleRing',
    Cancel: 'Cancel',
    WorldClock: 'WorldClock',
    Calendar: 'Calendar',
    ChevronDown: 'ChevronDown',
    Clear: 'Clear',
    Headset: 'Headset',
    ConnectVirtualMachine: 'ConnectVirtualMachine',
    History: 'History',
};
