import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { RoleContract } from '../../../data/contracts/resource-manager';
import { GetPermissionsResponse } from '../../../data/services/resource-manager/permission';
import { processProjectEnvironmentTypePermissions } from '../../../data/services/web-worker/permission-worker-pool';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    FailureResponse,
    SuccessResponse,
    isFailureResponse,
    isSuccessResponse,
} from '../../../models/common';
import { ProjectEnvironmentTypePermissionRecord } from '../../../models/permission';
import { KeyValuePair } from '../../../types/key-value-pair';
import { SerializableMap } from '../../../types/serializable-map';
import { getKey, getValue } from '../../../utilities/key-value-pair';
import { get } from '../../../utilities/serializable-map';
import {
    getProjectEnvironmentTypePermissions,
    getProjectEnvironmentTypePermissionsError,
    getProjectEnvironmentTypePermissionsFailed,
    getProjectEnvironmentTypePermissionsSuccess,
} from '../../actions/permission/permission-action-creators';
import { GetProjectEnvironmentTypePermissionsAction } from '../../actions/permission/permission-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getActionsInGroup } from '../../utilities/groupable-action';
import { getPermissions, getSingleResourcePermission } from './get-permissions';

export function* getProjectEnvironmentTypePermissionsSaga(
    action: GetProjectEnvironmentTypePermissionsAction
): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};
    const actions = getActionsInGroup(action);
    const ids = actions.map((action) => action.payload.id);

    try {
        // Request data from permissions API
        let projectEnvironmentTypePermissions: KeyValuePair<string, GetPermissionsResponse>[] = yield call(
            getPermissions,
            ids,
            activityId,
            FailureOperation.GetProjectEnvironmentTypePermissions
        );

        const failedResponses = projectEnvironmentTypePermissions.filter((pair) => isFailureResponse(getValue(pair)));
        const succeededResponses = projectEnvironmentTypePermissions.filter((pair) =>
            isSuccessResponse(getValue(pair))
        );

        // ⚠️ - Sometimes the permissions API will return an error when batching requests because one of the records might violate
        // the batch size limit. In this case, we need to issue individual requests for each record that failed. This is a rare
        // occurrence, but it does happen in the wild and it could only *potentially* happen for environment type permissions
        if (failedResponses.length > 0) {
            const nonBatchedResponses: [string, GetPermissionsResponse][] = yield all(
                failedResponses.map((pair) =>
                    call(function* () {
                        const response: GetPermissionsResponse = yield call(
                            getSingleResourcePermission,
                            pair[0],
                            activityId,
                            FailureOperation.GetProjectEnvironmentTypePermissions
                        );
                        return [pair[0], response] as [string, GetPermissionsResponse];
                    })
                )
            );

            // Combine the results of the successful batched and non-batched requests for total permissions request responses
            projectEnvironmentTypePermissions = [...succeededResponses, ...nonBatchedResponses];

            const failedNonBatchedResponses = nonBatchedResponses.filter(([, response]) => isFailureResponse(response));

            // Issue failed action if any of the get permissions calls failed
            if (failedNonBatchedResponses.length > 0) {
                const projectEnvironmentTypeFailures = failedNonBatchedResponses.map(([resourceId, response]) => {
                    const failure = response as FailureResponse;
                    return { failure, id: resourceId };
                });

                yield put(getProjectEnvironmentTypePermissionsFailed(projectEnvironmentTypeFailures, meta));
            }

            // Issue success action for non-batched requests that succeeded post individual calls
            const succeedNonBatchedResponses = nonBatchedResponses.filter(([, response]) =>
                isSuccessResponse(response)
            );
            succeededResponses.push(...succeedNonBatchedResponses);
        }

        let processedResponses: SerializableMap<ProjectEnvironmentTypePermissionRecord> = SerializableMap();

        // Process permissions for succeeded records and issue success action
        if (succeededResponses.length > 0) {
            const roles = succeededResponses.map((pair) => {
                const { data } = getValue(pair) as SuccessResponse<RoleContract[]>;
                return data;
            });

            const permissions: ProjectEnvironmentTypePermissionRecord[] = yield call(
                processProjectEnvironmentTypePermissions,
                roles
            );

            const records = permissions.map((record, index) => {
                const id = getKey(succeededResponses[index]);
                return KeyValuePair(id, record);
            });

            // Keep result map up-to-date for easy map-back in promise resolution
            processedResponses = SerializableMap(records);

            const payloads = records.map((record) => ({ id: getKey(record), result: getValue(record) }));
            yield put(getProjectEnvironmentTypePermissionsSuccess(payloads, meta));
        }

        // When resolving the action, send back the processed records rather than the raw roles
        const result = projectEnvironmentTypePermissions.map<DataResponse<ProjectEnvironmentTypePermissionRecord>>(
            (pair) => {
                const [id, response] = pair;

                if (isFailureResponse(response)) {
                    return response;
                }

                const data = get(processedResponses, id) as ProjectEnvironmentTypePermissionRecord;

                return { data, succeeded: true };
            }
        );

        yield resolveAction(action, result);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetProjectEnvironmentTypePermissions);
        const payloads = ids.map((id) => ({ error, id }));
        yield put(getProjectEnvironmentTypePermissionsError(payloads, meta));
        yield rejectAction(action, error);
    }
}

export function* getProjectEnvironmentTypePermissionsListenerSaga(): SagaIterator {
    yield takeEvery(getProjectEnvironmentTypePermissions, getProjectEnvironmentTypePermissionsSaga);
}
