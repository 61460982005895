import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    Label,
    makeStyles,
    mergeClasses,
    OptionOnSelectData,
    SelectionEvents,
} from '@fluentui/react-components';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { DevBoxSnapshot } from '../../../models/dev-box';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import Separator from '../../common/form/separator';
import SubmitButton from '../../common/submit-button';
import { RestoreFormData, RestoreFormFieldName, RestoreSnapshotDropdownOption } from './model';
import RestoreSnapshotDropdown from './restore-snapshot-dropdown';

interface RestoreSnapshotDialogFormProps {
    devBoxId: string;
    snapshots?: DevBoxSnapshot[];
    locale: string;
    initialValues: RestoreFormData;
    onDismiss: () => void;
    onRestoreSubmitted: (devBoxId: string, snapshotId: string) => void;
}

const messages = defineMessages({
    yesCheckboxLabel: {
        id: 'RestoreSnapshotDialog_Yes_Label',
        defaultMessage: 'Yes, I want to restore this dev box',
        description:
            'Label for a checkbox on the restore dialog - when checked, the user will be able to submit and start the restore',
    },
    restoreButtonAriaLabel: {
        id: 'RestoreSnapshotDialogForm_RestoreButton_AriaLabel',
        defaultMessage: 'Restore',
        description: 'Aria label for the "Restore" button',
    },
    cancelButtonAriaLabel: {
        id: 'RestoreSnapshotDialogForm_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "Cancel" button',
    },
});

/**
 * Styles
 */

const useDialogContentStyles = makeStyles({
    root: {
        paddingTop: '12px',
    },
});

const useLabelStyles = makeStyles({
    root: {
        paddingBottom: '10px',
        marginTop: '15px',
    },
});

const useStyles = makeStyles({
    skipLabel: {
        paddingTop: '7px',
    },
    dialogFooter: {
        marginTop: '55px',
    },
});

const useCheckBoxStyles = makeStyles({
    root: {
        padding: '30px 0px 0px 0px',
    },
});

const useSectionContentStyles = makeStyles({
    headerText: { fontSize: '16px', marginBottom: '10px' },
    upperText: { paddingLeft: '10px' },
    lowerText: { paddingLeft: '10px', marginBottom: '10px' },
    footerText: { marginBottom: '20px' },
});

/**
 * END Styles
 */

export const RestoreSnapshotDialogForm: React.FC<RestoreSnapshotDialogFormProps> = (
    props: RestoreSnapshotDialogFormProps
) => {
    const { devBoxId, locale, snapshots, initialValues, onDismiss, onRestoreSubmitted } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const styles = useStyles();
    const dialogContentStyles = useDialogContentStyles();
    const labelStyles = useLabelStyles();
    const checkboxStyles = useCheckBoxStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const sectionContentStyles = useSectionContentStyles();

    // State hooks
    const [yesChecked, setYesChecked] = React.useState(false);

    // Callback hooks
    const onCheckboxChange = React.useCallback(() => setYesChecked(!yesChecked), [yesChecked]);

    const dropdownOptions: RestoreSnapshotDropdownOption[] = React.useMemo(
        () =>
            snapshots?.map((snapshot) => ({
                createdTime: snapshot.createdTime,
                locale,
                snapshotId: snapshot.snapshotId,
            })) ?? [],
        [snapshots, locale]
    );

    // Callbacks
    const onFormSubmit = React.useCallback(
        (data: RestoreFormData) => {
            const { restoreSnapshotId } = data;

            if (!restoreSnapshotId) {
                return;
            }
            onRestoreSubmitted(devBoxId, restoreSnapshotId);
        },
        [devBoxId, onRestoreSubmitted]
    );

    return (
        <Form<RestoreFormData> initialValues={initialValues} onSubmit={onFormSubmit}>
            {(formProps) => {
                const { form, valid, submitting, values } = formProps;

                const { reset, submit, change } = form;

                const { restoreSnapshotId } = values;

                const isSubmitting = React.useMemo(() => submitting, [submitting]);

                const onDismissForm = React.useCallback(() => {
                    reset();
                    onDismiss();
                }, [reset, onDismiss]);

                const onSubmitClicked = React.useCallback(() => {
                    if (valid) {
                        submit();
                    }

                    if (!isSubmitting) {
                        onDismissForm();
                    }
                }, [valid, isSubmitting, submit, onDismissForm]);

                const dropdownOnChange = React.useCallback(
                    (_event: SelectionEvents, data: OptionOnSelectData) => {
                        change(RestoreFormFieldName.RestoreSnapshotId, data.optionValue);
                    },
                    [change]
                );

                return (
                    <>
                        <DialogContent>
                            <div className={mergeClasses(stackStyles.root, dialogContentStyles.root)}>
                                <div className={stackStyles.item}>
                                    <div className={sectionContentStyles.headerText}>
                                        <FormattedMessage
                                            id="RestoreSnapshotDialogForm_Header_Text"
                                            defaultMessage="Restoring this dev box will: "
                                            description="Text explaining how restoration works"
                                        />
                                    </div>
                                    <div className={sectionContentStyles.upperText}>
                                        <FormattedMessage
                                            id="RestoreSnapshotDialogForm_Header_Text2"
                                            defaultMessage="• Permanently delete all data saved to your dev box between the specfied time and now"
                                            description="Text explaining how restoration works"
                                        />
                                    </div>
                                </div>
                                <div className={stackStyles.item}>
                                    <div className={sectionContentStyles.lowerText}>
                                        <FormattedMessage
                                            id="RestoreSnapshotDialogForm_Header_Text3"
                                            defaultMessage="• Remove any apps installed between the specified time and now"
                                            description="Text explaining how restoration works"
                                        />
                                    </div>
                                </div>
                                <div className={stackStyles.item}>
                                    <div className={sectionContentStyles.footerText}>
                                        <FormattedMessage
                                            id="RestoreSnapshotDialogForm_Header_Text4"
                                            defaultMessage="Restoration can take up to a few hours. This action can't be reversed."
                                            description="Text explaining how restoration works"
                                        />
                                    </div>
                                </div>
                                <div className={stackStyles.item}>
                                    <Separator />
                                </div>
                                <div className={mergeClasses(stackStyles.item, checkboxStyles.root)}>
                                    <Checkbox
                                        label={formatMessage(messages.yesCheckboxLabel)}
                                        onChange={onCheckboxChange}
                                        checked={yesChecked}
                                    />
                                </div>
                                <div className={mergeClasses(stackStyles.item, labelStyles.root)}>
                                    <Label>
                                        <FormattedMessage
                                            id="RestoreSnapshotDialogForm_Input_Text"
                                            defaultMessage="Restore to"
                                            description='Text for the "please choose the snapshot to restore" input text'
                                        />
                                    </Label>
                                </div>
                                <div className={stackStyles.item}>
                                    <Field<RestoreSnapshotDropdownOption> name={RestoreFormFieldName.RestoreSnapshotId}>
                                        {() => (
                                            <RestoreSnapshotDropdown
                                                options={dropdownOptions}
                                                onOptionSelect={dropdownOnChange}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions className={styles.dialogFooter}>
                            <SubmitButton
                                ariaLabel={formatMessage(messages.restoreButtonAriaLabel)}
                                onClick={onSubmitClicked}
                                isSubmitting={submitting}
                                disabled={!yesChecked || !restoreSnapshotId}
                            >
                                <FormattedMessage
                                    id="RestoreSnapshotDialogForm_RestoreButton_Text"
                                    defaultMessage="Restore"
                                    description="Text for the Restore button"
                                />
                            </SubmitButton>
                            <Button aria-label={formatMessage(messages.cancelButtonAriaLabel)} onClick={onDismissForm}>
                                <FormattedMessage
                                    id="RestoreSnapshotDialogForm_CancelButton_Text"
                                    defaultMessage="Cancel"
                                    description='Text for the "Cancel" button'
                                />
                            </Button>
                        </DialogActions>
                    </>
                );
            }}
        </Form>
    );
};

export default RestoreSnapshotDialogForm;
