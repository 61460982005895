import { Dialog, DialogSurface, FluentProvider } from '@fluentui/react-components';
import * as React from 'react';
import { useDelayShutdownDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { AsyncState } from '../../../redux/store/common-state';
import DelayShutdownDialogForm from './delay-shutdown-dialog-form';

export interface DelayShutdownDialogComponentProps {
    devBoxId: string;
    open?: boolean;
    locale: string;
    onDismiss: () => void;
    onDelaySubmitted: (devBoxId: string, delayUntil: Date) => void;
    onSkipSubmitted: (devBoxId: string) => void;
    hasHibernateSupport: boolean;
    originalScheduledTime: Date;
    nextScheduledTime: Date;
    skipState: AsyncState;
    delayState: AsyncState;
}

/**
 * Styles
 */

const dialogWidth = '480px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

/**
 * END Styles
 */

const DelayShutdownDialog: React.FC<DelayShutdownDialogComponentProps> = (props: DelayShutdownDialogComponentProps) => {
    const {
        open,
        devBoxId,
        locale,
        onDismiss,
        onSkipSubmitted,
        onDelaySubmitted,
        hasHibernateSupport,
        nextScheduledTime,
        originalScheduledTime,
        skipState,
        delayState,
    } = props;

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Memoized data

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                <DialogSurface style={dialogStyle}>
                    <DelayShutdownDialogForm
                        devBoxId={devBoxId}
                        locale={locale}
                        originalScheduledTime={originalScheduledTime}
                        nextScheduledTime={nextScheduledTime}
                        onDelaySubmitted={onDelaySubmitted}
                        onSkipSubmitted={onSkipSubmitted}
                        onDismiss={onDismiss}
                        skipState={skipState}
                        delayState={delayState}
                        hasHibernateSupport={hasHibernateSupport}
                    />
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const DelayShutdownDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useDelayShutdownDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <DelayShutdownDialog {...properties} open={isOpen} onDismiss={closeSurface} />;
};

export default DelayShutdownDialogContextWrapper;
