import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { Form } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Metric, Property, Severity } from '../../../constants/telemetry';
import { useActionCreator } from '../../../hooks/action-creator';
import { useConnectViaAppDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { setPartialUserSettings } from '../../../redux/actions/user-settings/user-settings-action-creator';
import { SetPartialUserSettingsPayload } from '../../../redux/actions/user-settings/user-settings-actions';
import { RemoteAppOption, UserSettings } from '../../../types/user-settings';
import { openInSameWindow } from '../../../utilities/browser';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackMetric, trackTrace } from '../../../utilities/telemetry/channel';
import { tryAddMultiMonitorAndSourceParameters } from '../../../utilities/url';
import { UserSettingsFormData } from '../models';
import { getUserSettingsSetFromFormData } from '../selectors';
import { DialogActionButtons } from './connect-via-app-dialog-footer';
import { ConnectViaAppFormFieldGroup } from './connect-via-app-form-field-group';
import { OpenConnectViaAppDialogProperties } from './contexts';

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialog_CloseButton_Text',
        defaultMessage: 'Close',
        description: 'Aria label for the "x" button in the top corner of the "Initialize connect via app dialog"',
    },
});
/**
 * Styles
 */

const dialogWidth = '569px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

/**
 * END Styles
 */

type ConnectViaAppDialogProps = {
    open?: boolean;
    onDismiss: () => void;
    onSetPartialUserSettings: (payload: SetPartialUserSettingsPayload) => void;
    properties: OpenConnectViaAppDialogProperties;
};

const conditionConnectionUri = (
    connectionStrings: OpenConnectViaAppDialogProperties,
    userSettings: UserSettings
): string => {
    // Incoming from devbox back-end passed through the dialog context
    const { remoteDesktopUrl, cloudPcConnectionUrl } = connectionStrings;

    // Use the settings we just saved to determine the connection string
    const { preferredRemoteApp, useMultiMonitor } = userSettings;

    const useWindowsApp = preferredRemoteApp ? preferredRemoteApp === RemoteAppOption.useWindowsApp : false;
    const remoteDesktopConnection = useWindowsApp ? cloudPcConnectionUrl : remoteDesktopUrl;

    if (isUndefinedOrWhiteSpace(remoteDesktopConnection)) {
        return '';
    }

    return tryAddMultiMonitorAndSourceParameters(remoteDesktopConnection, useMultiMonitor) ?? '';
};

const ConnectViaAppDialogComponent: React.FC<ConnectViaAppDialogProps> = (props: ConnectViaAppDialogProps) => {
    const { open, onDismiss, onSetPartialUserSettings, properties } = props;

    const { formatMessage } = useIntl();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    const onSubmitClicked = React.useCallback(
        (data: UserSettingsFormData) => {
            const userSettings: UserSettings = getUserSettingsSetFromFormData(data);
            const { preferredRemoteApp, useMultiMonitor } = userSettings;
            // Store the users settings in  Browser Storage
            onSetPartialUserSettings({ preferredRemoteApp, useMultiMonitor });

            const connectionUri = conditionConnectionUri(properties, userSettings);
            if (connectionUri) {
                openInSameWindow(connectionUri);
                trackMetric(Metric.DirectLaunchStarted, 1, {
                    properties: {
                        [Property.PreferredRemoteApp]: preferredRemoteApp,
                        [Property.MultiMonitor]: useMultiMonitor,
                    },
                });
            } else {
                // If the connection URI is not defined, log a warning and close the dialog
                trackTrace('Connection URI is not defined. Unable to open connection', {
                    severity: Severity.Warning,
                });
            }

            onDismiss();
        },
        [properties, onSetPartialUserSettings]
    );

    return (
        <Form<UserSettingsFormData> onSubmit={onSubmitClicked}>
            {(formProps) => {
                const { form } = formProps;
                const { submit } = form;

                return (
                    <FluentProvider theme={theme}>
                        <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                            <DialogSurface style={dialogStyle}>
                                <DialogBody>
                                    <DialogTitle
                                        action={
                                            <DialogTrigger action="close">
                                                <Button
                                                    appearance="subtle"
                                                    aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                                    icon={<Dismiss24Regular />}
                                                />
                                            </DialogTrigger>
                                        }
                                    >
                                        <FormattedMessage
                                            id="InitializeConnectViaAppDialog_Header_Text"
                                            defaultMessage="Connect via app"
                                            description="Title of the 'Initialize connect via app dialog'"
                                        />
                                    </DialogTitle>
                                    <DialogContent>
                                        <ConnectViaAppFormFieldGroup />
                                    </DialogContent>
                                    <DialogActionButtons onContinueClicked={submit} />
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>
                    </FluentProvider>
                );
            }}
        </Form>
    );
};

export const ConnectViaAppDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useConnectViaAppDialogContext();

    const onSetPartialUserSettings = useActionCreator(setPartialUserSettings);

    if (!isOpen) {
        return <></>;
    }

    return (
        <ConnectViaAppDialogComponent
            onDismiss={closeSurface}
            onSetPartialUserSettings={onSetPartialUserSettings}
            open={isOpen}
            properties={properties}
        />
    );
};

export default ConnectViaAppDialogContextWrapper;
