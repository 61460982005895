import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    getScreenSelector,
    IDialogContentProps,
    IModalProps,
    makeStyles as legacyMakeStyles,
    PrimaryButton,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';
import { LatencyBand, RegionViewModel } from '../models';
import SelectDevBoxRegionDetailsList from './select-dev-box-region-details-list';
import SuboptimalRegionDialog from './suboptimal-region-dialog';

interface SelectDevBoxRegionDialogProps {
    onDismiss: () => void;
    onChange: (selectedRegion: RegionViewModel | undefined) => void;
    showDialog: boolean;
    regions: RegionViewModel[];
    selectedRegion: RegionViewModel | undefined;
}

const messages = defineMessages({
    selectDevBoxRegionDialogHeaderText: {
        id: 'SelectDevBoxRegionDialog_Header_Text',
        defaultMessage: 'Select region',
        description: 'Text for the select dev box region dialog title',
    },
    selectDevBoxRegionDialogSelectButtonText: {
        id: 'SelectDevBoxRegionDialog_SelectButton_Text',
        defaultMessage: 'Select',
        description: 'Text for the select dev box region dialog select button',
    },
    selectDevBoxRegionDialogSelectButtonAriaLabel: {
        id: 'SelectDevBoxRegionDialog_SelectButton_AriaLabel',
        defaultMessage: 'Select the dev box region',
        description: 'Aria label for the select dev box region dialog select button',
    },
    selectDevBoxRegionDialogCancelButtonText: {
        id: 'SelectDevBoxRegionDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the select dev box region dialog cancel button',
    },
    selectDevBoxRegionDialogCancelButtonAriaLabel: {
        id: 'SelectDevBoxRegionDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel button for the dev box region dialog',
        description: 'Aria label for the select dev box region dialog cancel button',
    },
    selectDevBoxRegionDialogCloseButtonAriaLabel: {
        id: 'SelectDevBoxRegionDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close button for the dev box region dialog',
        description: 'Aria label for the select dev box region dialog close button icon',
    },
});

/**
 * Styles
 */

const dialogModalMainMaxWidth = 'unset !important';
const useSelectDevBoxTypeDialogModalStyles = legacyMakeStyles((theme) => ({
    main: {
        backgroundColor: getSemanticColor(theme, 'selectDevBoxTypeDialogBackground'),

        [getScreenSelector(1440, undefined)]: {
            maxWidth: 'calc(100vw - 64px)',
        },

        [getScreenSelector(undefined, 1440)]: {
            maxWidth: dialogModalMainMaxWidth,
            width: 'calc(100vw - 64px)',
        },
    },
}));

const useSelectDevBoxTypeDialogFooterStyles = makeStyles({
    actions: {
        lineHeight: 0,
    },
});

const useButtonsContainerStyles = makeStyles({
    root: {
        gap: '8px',
        justifyContent: 'end',
    },
});

/**
 * End Styles
 */

const SelectDevBoxRegionDialogComponent: React.FC<SelectDevBoxRegionDialogProps> = (
    props: SelectDevBoxRegionDialogProps
) => {
    const { onDismiss, onChange, regions, selectedRegion: initialSelectedRegion } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const selectDevBoxTypeDialogModalStyles = useSelectDevBoxTypeDialogModalStyles();
    const selectDevBoxTypeDialogFooterStyles = useSelectDevBoxTypeDialogFooterStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const buttonsContainerStyles = useButtonsContainerStyles();

    // State hooks
    const [selectedRegion, setSelectedRegion] = React.useState<RegionViewModel | undefined>(initialSelectedRegion);
    const [showSuboptimalRegionDialog, setShowSuboptimalRegionDialog] = React.useState(false);

    const suboptimalRegionModalOnDismiss = React.useCallback(() => setShowSuboptimalRegionDialog(false), []);
    const onSuboptimalRegionSelected = React.useCallback(() => setShowSuboptimalRegionDialog(true), []);

    // Callback hooks
    const onDevBoxRegionSelectButtonClick = React.useCallback((): void => {
        if (selectedRegion?.latencyBand === LatencyBand.Poor) {
            onSuboptimalRegionSelected();
        } else {
            onChange(selectedRegion);
            onDismiss();
        }
    }, [selectedRegion, onChange, onDismiss, onSuboptimalRegionSelected]);

    // Memo hooks
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            closeButtonAriaLabel: formatMessage(messages.selectDevBoxRegionDialogCloseButtonAriaLabel),
        }),
        [formatMessage]
    );

    const dialogContentProps: IDialogContentProps = React.useMemo(
        () => ({
            type: DialogType.normal,
            title: formatMessage(messages.selectDevBoxRegionDialogHeaderText),
        }),
        [formatMessage]
    );

    return (
        <>
            <Dialog
                hidden={false}
                onDismiss={onDismiss}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                styles={selectDevBoxTypeDialogModalStyles}
            >
                <SelectDevBoxRegionDetailsList
                    setSelectedRegion={setSelectedRegion}
                    regions={regions}
                    selectedRegion={selectedRegion}
                />
                <DialogFooter styles={selectDevBoxTypeDialogFooterStyles}>
                    <div className={mergeClasses(horizontalStackStyles.root, buttonsContainerStyles.root)}>
                        <div className={horizontalStackStyles.item}>
                            <PrimaryButton
                                text={formatMessage(messages.selectDevBoxRegionDialogSelectButtonText)}
                                ariaLabel={formatMessage(messages.selectDevBoxRegionDialogSelectButtonAriaLabel)}
                                onClick={onDevBoxRegionSelectButtonClick}
                            />
                        </div>
                        <div className={horizontalStackStyles.item}>
                            <DefaultButton
                                text={formatMessage(messages.selectDevBoxRegionDialogCancelButtonText)}
                                ariaLabel={formatMessage(messages.selectDevBoxRegionDialogCancelButtonAriaLabel)}
                                onClick={onDismiss}
                            />
                        </div>
                    </div>
                </DialogFooter>
            </Dialog>
            <SuboptimalRegionDialog
                onDismiss={suboptimalRegionModalOnDismiss}
                showDialog={showSuboptimalRegionDialog}
                onSelectedRegionSubmitted={onDismiss}
                onChange={onChange}
                selectedRegion={selectedRegion}
            />
        </>
    );
};

const SelectDevBoxRegionDialogWrapper: React.FC<SelectDevBoxRegionDialogProps> = (
    props: SelectDevBoxRegionDialogProps
) => {
    const { showDialog } = props;

    if (!showDialog) {
        return <></>;
    }

    return <SelectDevBoxRegionDialogComponent {...props} />;
};

export const SelectDevBoxRegionDialog = SelectDevBoxRegionDialogWrapper;
export default SelectDevBoxRegionDialog;
