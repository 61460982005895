import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { DismissableContentName } from '../../constants/app';
import { useActionCreator } from '../../hooks/action-creator';
import { dismissContent } from '../../redux/actions/application/application-action-creators';
import {
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/models';
import {
    getDevBoxCreateAbilityState,
    getEnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/selectors';
import { DisplayState, getDisplayState } from '../../redux/selector/display/display-state';
import { ResourceUserState } from '../../redux/selector/display/user-state/models';
import { getDevBoxUserState, getEnvironmentUserState } from '../../redux/selector/display/user-state/selectors';
import { getOrganizationDisplayName } from '../../redux/selector/graph-selectors';
import { ReturnVoid } from '../../types/return-void';
import { isDevPortalEnvironment } from '../../utilities/app';
import CombinedInfoBanner from './banners/combined-info-banner';
import DevBoxCreateInfoBanner from './banners/dev-box-create-info-banner';
import EnvironmentCreateInfoBanner from './banners/environment-create-info-banner';
import PlannedMaintenanceBanner from './banners/planned-maintenance-banner';
import PortalSurveyBanner from './banners/portal-survey-banner';
import { CombinedInfoMessageKind } from './models';
import {
    getCombinedInfoMessageKind,
    getIsPlannedMaintenanceBannerDismissed,
    getIsPortalSurveyBannerDismissed,
    shouldHideInfoMessageBar,
} from './selectors';

interface InformationProps {
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    devBoxUserState: ResourceUserState;
    displayState: DisplayState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    environmentUserState: ResourceUserState;
    isPlannedMaintenanceBannerDismissed: boolean;
    isPortalSurveyBannerDismissed: boolean;
    onDismiss: ReturnVoid<typeof dismissContent>;
    organizationDisplayName: string;
}

const plannedMaintenanceDate = DateTime.fromObject(
    {
        year: 2024,
        month: 10,
        day: 11,
        hour: 19,
        // Maintenance will occur at 7 PM on devportal.microsoft.com, 7:30 on devbox.microsoft.com
        minute: isDevPortalEnvironment ? 0 : 30,
    },
    { zone: 'America/Los_Angeles' }
).toJSDate();

const plannedMaintenanceExpirationDate = DateTime.fromObject(
    {
        year: 2024,
        month: 10,
        day: 11,
        hour: 21,
    },
    { zone: 'America/Los_Angeles' }
).toJSDate();

const surveyExpirationDate = DateTime.fromObject(
    {
        year: 2024,
        month: 2,
        day: 19,
        hour: 17,
    },
    { zone: 'America/Los_Angeles' }
).toJSDate();

// Purposefully detecting expiration outside of a component so that it's static per page load
const isPlannedMaintenancePastExpirationDate = new Date() >= plannedMaintenanceExpirationDate;
const isPortalSurveyPastExpirationDate = new Date() >= surveyExpirationDate;

const InformationComponent: React.FC<InformationProps> = React.memo((props: InformationProps) => {
    const {
        devBoxCreateAbilityState,
        devBoxUserState,
        displayState,
        environmentCreateAbilityState,
        environmentUserState,
        isPlannedMaintenanceBannerDismissed,
        isPortalSurveyBannerDismissed,
        onDismiss,
        organizationDisplayName,
    } = props;

    const hideInformation = React.useMemo(() => shouldHideInfoMessageBar(displayState), [displayState]);

    const combinedInfoMessageKind = React.useMemo(
        () =>
            getCombinedInfoMessageKind(
                devBoxCreateAbilityState,
                devBoxUserState,
                environmentCreateAbilityState,
                environmentUserState
            ),
        [devBoxCreateAbilityState, devBoxUserState, environmentCreateAbilityState, environmentUserState]
    );

    const onDismissPlannedMaintenanceBanner = React.useCallback(() => {
        onDismiss({ content: DismissableContentName.PlannedMaintenance20241004Banner });
    }, [onDismiss]);

    const onDismissPortalSurveyBanner = React.useCallback(() => {
        onDismiss({ content: DismissableContentName.PortalSurveyInfoBanner });
    }, [onDismiss]);

    const useCombinedMessage = combinedInfoMessageKind !== CombinedInfoMessageKind.None;

    if (hideInformation) {
        return <></>;
    }

    // If we have a combined message to use, display that. Otherwise, use the individual resource type message bars (which will return empty if no misconfiguration is found)
    return (
        <>
            {useCombinedMessage && <CombinedInfoBanner kind={combinedInfoMessageKind} />}

            {!useCombinedMessage && (
                <>
                    <DevBoxCreateInfoBanner
                        devBoxCreateAbilityState={devBoxCreateAbilityState}
                        organizationDisplayName={organizationDisplayName}
                    />
                    <EnvironmentCreateInfoBanner environmentCreateAbilityState={environmentCreateAbilityState} />
                </>
            )}

            <PortalSurveyBanner
                isDismissed={isPortalSurveyBannerDismissed}
                isExpired={isPortalSurveyPastExpirationDate}
                onDismiss={onDismissPortalSurveyBanner}
            />
            <PlannedMaintenanceBanner
                dateTime={plannedMaintenanceDate}
                isDismissed={isPlannedMaintenanceBannerDismissed}
                isExpired={isPlannedMaintenancePastExpirationDate}
                onDismiss={onDismissPlannedMaintenanceBanner}
                timeSpanInMinutes={15}
            />
        </>
    );
});

export const Information: React.FC = () => {
    // Application state hooks
    const devBoxCreateAbilityState = useSelector(getDevBoxCreateAbilityState);
    const devBoxUserState = useSelector(getDevBoxUserState);
    const displayState = useSelector(getDisplayState);
    const environmentCreateAbilityState = useSelector(getEnvironmentCreateAbilityState);
    const environmentUserState = useSelector(getEnvironmentUserState);
    const isPlannedMaintenanceBannerDismissed = useSelector(getIsPlannedMaintenanceBannerDismissed);
    const isPortalSurveyBannerDismissed = useSelector(getIsPortalSurveyBannerDismissed);
    const organizationDisplayName = useSelector(getOrganizationDisplayName);

    // Action creator hooks
    const onDismiss = useActionCreator(dismissContent);

    return (
        <InformationComponent
            devBoxCreateAbilityState={devBoxCreateAbilityState}
            devBoxUserState={devBoxUserState}
            displayState={displayState}
            environmentCreateAbilityState={environmentCreateAbilityState}
            environmentUserState={environmentUserState}
            isPlannedMaintenanceBannerDismissed={isPlannedMaintenanceBannerDismissed}
            isPortalSurveyBannerDismissed={isPortalSurveyBannerDismissed}
            onDismiss={onDismiss}
            organizationDisplayName={organizationDisplayName}
        />
    );
};

export default Information;
