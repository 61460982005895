import { Option } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getNumericDateStringFromDate, getNumericTimeStringFromDate } from '../../../utilities/time';
import { RestoreSnapshotDropdownOption } from './model';

interface RestoreSnapshotDropdownItemProps {
    snapshot: RestoreSnapshotDropdownOption;
}

const messages = defineMessages({
    restoreOptionText: {
        id: 'RestoreSnapshotDropdown_TimeString_Text',
        defaultMessage: '{dateString} at {timeString}',
        description: 'Text for date and time. Do not localize {dateString} and {timeString}',
    },
});

export const RestoreSnapshotDropdownItem: React.FC<RestoreSnapshotDropdownItemProps> = (
    props: RestoreSnapshotDropdownItemProps
) => {
    const { snapshot } = props;

    const { createdTime, locale, snapshotId } = snapshot;

    // Intl hooks
    const { formatMessage } = useIntl();

    const dateString = React.useMemo(() => getNumericDateStringFromDate(createdTime, locale), [createdTime, locale]);

    const timeString = React.useMemo(() => getNumericTimeStringFromDate(createdTime, locale), [createdTime, locale]);

    const optionText = React.useMemo(() => {
        const values = { dateString, timeString };
        return formatMessage(messages.restoreOptionText, values);
    }, [dateString, timeString, formatMessage]);

    return (
        <Option key={snapshotId} value={snapshotId}>
            {optionText}
        </Option>
    );
};

export default RestoreSnapshotDropdownItem;
