import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    setWelcomeTourStatus,
    setWelcomeTourStatusError,
    setWelcomeTourStatusFailed,
    setWelcomeTourStatusSuccess,
} from '../../actions/application/application-action-creators';
import { SetWelcomeTourStatusAction } from '../../actions/application/application-actions';
import { setStorageValue } from '../../actions/storage/storage-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* setWelcomeTourStatusSaga(action: SetWelcomeTourStatusAction): SagaIterator {
    const { seenOrSkippedWelcomeTour } = getPayload(action);

    try {
        const storageType = yield select(getStorageType);
        const setStorageValueResponse: DataResponse = yield putAndAwait(
            setStorageValue({
                key: StorageKey.SeenWelcomeTour,
                setForSignedInUser: true,
                storageType,
                value: JSON.stringify(seenOrSkippedWelcomeTour),
            })
        );

        if (isFailureResponse(setStorageValueResponse)) {
            yield put(setWelcomeTourStatusFailed({ failure: setStorageValueResponse }));
            return;
        }

        yield put(setWelcomeTourStatusSuccess());
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SetWelcomeTourStatus);
        yield put(setWelcomeTourStatusError({ error }));
    }
}

export function* setWelcomeTourStatusListenerSaga(): SagaIterator {
    yield takeEvery(setWelcomeTourStatus, setWelcomeTourStatusSaga);
}
