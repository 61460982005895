import { Status } from '../../models/common';
import { devBoxRegionRecommendationAdapter } from '../adapters/dev-box-region-recommendation-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector } from './common';

/**
 * Basic selectors
 */

export const getStatusForListRegionRecommendations: StoreStateSelector<Status> = (store) =>
    store.devBoxRegionRecommendationStore.status.listDevBoxRegionRecommendations;

/**
 * Entity state selectors
 */

const regionRecommendationsSelectors = devBoxRegionRecommendationAdapter.getSelectors<StoreState>(
    (store) => store.devBoxRegionRecommendationStore.data.regionRecommendations
);

/**
 * Composed selectors
 */

export const getRegionRecommendations = createMapFromEntitiesSelector(regionRecommendationsSelectors.selectAll);
