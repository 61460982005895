import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import getRegionRecommendations from './get-region-recommendations.json';

export type DiscoveryServiceRegionRecommendationScenarioName = 'GetRegionRecommendations';

export const DiscoveryServiceRegionRecommendationScenarioName: UnionMap<DiscoveryServiceRegionRecommendationScenarioName> =
    {
        GetRegionRecommendations: 'GetRegionRecommendations',
    };

export const DiscoveryServiceRegionRecommendationScenarios: UnionValueMap<
    DiscoveryServiceRegionRecommendationScenarioName,
    MockScenarioConfiguration
> = {
    GetRegionRecommendations: getRegionRecommendations as MockScenarioConfiguration,
};
