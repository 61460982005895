import { FontSizes, FontWeights, Link } from '@fluentui/react';
import { Button, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SupportSectionLinks } from '../constants/support-section-links';
import { WindowsAppLink } from '../constants/windows-store-links';
import heroImage from '../content/images/hero_full.webp';
import illustration from '../content/images/illustration.webp';
import { useActionCreator } from '../hooks/action-creator';
import { useCurrentFluent2Theme } from '../hooks/styling';
import LandingPageLayout from '../layouts/landing-page-portal-layout';
import { signIn } from '../redux/actions/identity/identity-action-creators';
import { CssSelector } from '../themes/constants/css-selectors';
import { useHorizontalStackStyles, useStackStyles } from '../themes/styles/flexbox-styles';
import { ReturnVoid } from '../types/return-void';
import { isElementInViewport } from '../utilities/element';

interface UnauthenticatedPageComponentProps {
    onSignInSubmitted: ReturnVoid<typeof signIn>;
}

const messages = defineMessages({
    signedOutImageAlt: {
        id: 'UnauthenticatedLandingPage_SignedOutImage_Alt',
        defaultMessage: 'Not signed in',
        description: 'Alt text for "not signed in" image',
    },
    signInButtonLabel: {
        id: 'UnauthenticatedLandingPage_SignInButton_Label',
        defaultMessage: 'Sign in',
        description: 'Aria label for sign in button',
    },
    signInButtonText: {
        id: 'UnauthenticatedLandingPage_SignInButton_Text',
        defaultMessage: 'Sign in',
        description: 'Text for sign in button',
    },
});

/**
 * Styles
 */

/**
 * Section One Styles
 */

const useSignInButtonContainerStyles = makeStyles({
    // Note: using paddingTop to space StackItems as Stack will override margins
    root: {
        lineHeight: '20px',
        paddingTop: '20px',
    },
});

const useSectionOneBackgroundImageStyles = makeStyles({
    root: {
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'contain',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        backgroundPosition: 'top right',
    },
});

const useSectionOneTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size42,
        fontWeight: FontWeights.semibold,
        width: '500px',
        [CssSelector.ScreenSizeMediumAndBelow]: {
            // For small devices, we need to text to wrap below
            width: '280px',
        },
        [CssSelector.ScreenSizeBetweenMediumAndXLarge]: {
            width: '350px',
        },
        lineHeight: '40px',
        marginTop: '10px',
    },
});

const useSectionOneTextStyles = makeStyles({
    root: {
        marginTop: '20px',
        lineHeight: '25px',
        width: '400px',
        [CssSelector.ScreenSizeMediumAndBelow]: {
            // For small devices, we need to text to wrap below
            width: '250px',
        },
    },
});

// TODO: Task 2279552: Replace hard-coded color values with tokens
const useHeaderTextStyles = makeStyles({
    root: {
        color: '#2886DE',
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        marginTop: '150px',
    },
});

/**
 * Section Two Styles
 */

const useSectionTwoTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size32,
        fontWeight: FontWeights.semibold,
        width: '330px',
        lineHeight: '32px',
        [CssSelector.ScreenSizeMediumAndBelow]: {
            width: '200px',
        },
    },
});

const useImageStyles = makeStyles({
    root: {
        width: '700px',
        [CssSelector.ScreenSizeMediumAndBelow]: {
            // For smaller devices, we need a smaller image
            width: '200px',
        },
    },
});

const useSectionTwoGridStyles = makeStyles({
    root: { flexWrap: 'wrap', gap: '100px', marginTop: '70px' },
});

/**
 * Section Three Styles
 */

const useSectionThreeTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size32,
        fontWeight: FontWeights.semibold,
        width: '400px',
        lineHeight: '32px',
        [CssSelector.ScreenSizeMediumAndBelow]: {
            // For smaller devices, we need to text to wrap.
            width: '200px',
        },
    },
});

const useSectionThreeGridStyles = makeStyles({
    root: {
        flexWrap: 'wrap',
        gap: '200px',
        [CssSelector.ScreenSizeBetweenMediumAndXLarge]: {
            gap: '50px',
        },
    },
});

/**
 * Section Four Styles
 */
const useSectionFourGridStyles = makeStyles({
    root: {
        flexWrap: 'wrap',
        gap: '30px',
        marginTop: '70px',
    },
});

const useSectionFourTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.semibold,
        width: '400px',
        lineHeight: '32px',
    },
});

const useSectionFourSubTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        width: '400px',
        lineHeight: '32px',
        marginTop: '10px',
    },
});

const useSectionFourGridItemStyles = makeStyles({
    root: {
        [CssSelector.ScreenSizeMediumAndBelow]: {
            // For smaller devices, we need to text to wrap below
            width: '250px',
        },
    },
});

/**
 * End Styles
 */

export const UnauthenticatedLandingPageComponent: React.FC<UnauthenticatedPageComponentProps> = (
    props: UnauthenticatedPageComponentProps
) => {
    const { onSignInSubmitted } = props;

    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    const headerTextStyles = useHeaderTextStyles();

    const sectionOneTitleStyles = useSectionOneTitleStyles();
    const sectionOneBackgroundImageStyles = useSectionOneBackgroundImageStyles();

    const textStyles = useSectionOneTextStyles();
    const signInButtonContainerStyles = useSignInButtonContainerStyles();
    const imageStylesForTablet = useImageStyles();
    const sectionTwoGridStyles = useSectionTwoGridStyles();

    const sectionTwoTitleStyles = useSectionTwoTitleStyles();
    const sectionThreeTitleStyles = useSectionThreeTitleStyles();
    const sectionThreeGridStyles = useSectionThreeGridStyles();

    const sectionFourTitleStyles = useSectionFourTitleStyles();
    const sectionFourGridStyles = useSectionFourGridStyles();
    const sectionFourSubTitleStyles = useSectionFourSubTitleStyles();
    const sectionFourGridItemStyles = useSectionFourGridItemStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Callback hooks
    // Note: this useCallback prevents onClick event args from being sent to redux, which will cause errors
    const onSignInButtonClicked = React.useCallback(() => onSignInSubmitted({}), [onSignInSubmitted]);

    const [isScrolled, setIsScrolled] = React.useState<boolean>(false);

    const handleScroll = React.useCallback(() => {
        // Get the sign in button div
        const section = document.getElementById('sign-in-button');

        // Check if the sign in button is currently in view
        if (isElementInViewport(section as HTMLElement)) {
            setIsScrolled(false);
        } else {
            setIsScrolled(true);
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    return (
        <>
            <FluentProvider theme={theme}>
                <LandingPageLayout id="unauthenticatedLandingPage" showFooter isScrolled={isScrolled}>
                    <div className={stackStyles.root}>
                        <div className={sectionOneBackgroundImageStyles.root}>
                            <div className={mergeClasses(stackStyles.item, headerTextStyles.root)}>
                                <FormattedMessage
                                    id="UnauthenticatedLandingPage_DevelopInCloud_Header_Text"
                                    defaultMessage="MICROSOFT DEVELOPER PORTAL"
                                    description="Header Text for the develop and deploy in the cloud section of the layout page"
                                />
                            </div>
                            <div className={mergeClasses(stackStyles.item, sectionOneTitleStyles.root)}>
                                <FormattedMessage
                                    id="UnauthenticatedLandingPage_DevelopInCloud_Title_Text"
                                    defaultMessage="Create and manage dev boxes and deployment environments"
                                    description="Title Text for the develop and deploy in the cloud section of the layout page"
                                />
                            </div>
                            <div className={mergeClasses(stackStyles.item, textStyles.root)}>
                                <FormattedMessage
                                    id="UnauthenticatedLandingPage_DevelopInCloud_Text"
                                    defaultMessage="If your project administrator has already added to you to a team, sign in to begin."
                                    description="Text for the develop and deploy in the cloud section of the layout page"
                                />
                            </div>
                            <div
                                id="sign-in-button"
                                className={mergeClasses(stackStyles.item, signInButtonContainerStyles.root)}
                            >
                                <Button
                                    aria-label={formatMessage(messages.signInButtonLabel)}
                                    id="UnauthenticatedLandingPage_SignInButton"
                                    onClick={onSignInButtonClicked}
                                    appearance="primary"
                                >
                                    {formatMessage(messages.signInButtonText)}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={stackStyles.root}>
                        <div className={mergeClasses(horizontalStackStyles.root, sectionTwoGridStyles.root)}>
                            <div className={horizontalStackStyles.item}>
                                <div className={mergeClasses(stackStyles.item, sectionTwoTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_DeployInCloud_Title_Text"
                                        defaultMessage="Develop and deploy in the cloud"
                                        description="Title Text for the develop and deploy in the cloud section of the layout page"
                                    />
                                </div>
                                <div className={mergeClasses(stackStyles.item, textStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_DeployInCloud_Text"
                                        defaultMessage="The developer portal provides project-based access to your development resources. Depending on how your project is configured, these can include dev boxes, deployment environments, or both."
                                        description="Text for the develop and deploy in the cloud section of the layout page"
                                    />
                                </div>
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <div className={stackStyles.item}>
                                    {/*alt set to empty string sets it to a decorative image*/}
                                    <img src={illustration} className={imageStylesForTablet.root} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={stackStyles.root}>
                        <div className={mergeClasses(horizontalStackStyles.root, sectionThreeGridStyles.root)}>
                            <div className={horizontalStackStyles.item}>
                                <div className={mergeClasses(stackStyles.item, sectionThreeTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionThree_1_Title_Text"
                                        defaultMessage="Deployment environments on demand"
                                        description="Title Text for the Deployment Environments section of the layout page"
                                    />
                                </div>
                                <div className={mergeClasses(stackStyles.item, textStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionThree_1_Text"
                                        defaultMessage="Azure Deployment Environments empowers you to create secure and environments for code deployment, when and where you need them. Pre-approved templates ensure every environment is fully compliant."
                                        description="Text for the Deployment Environments section of the layout page"
                                    />
                                </div>
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <div className={mergeClasses(stackStyles.item, sectionThreeTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionThree_2_Title_Text"
                                        defaultMessage="Manage your own dev boxes"
                                        description="Title Text for the Manage your own dev boxes section of the layout page"
                                    />
                                </div>
                                <div className={mergeClasses(stackStyles.item, textStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectinThree_2_Text"
                                        defaultMessage="Microsoft Dev Box puts the power to create, access, and manage powerful development environments in your hands."
                                        description="Text for the  Manage your own dev boxes section of the layout page"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={stackStyles.root}>
                        <div className={mergeClasses(horizontalStackStyles.root, sectionFourGridStyles.root)}>
                            <div className={horizontalStackStyles.item}>
                                <div className={mergeClasses(stackStyles.item, sectionFourTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionFour_1_Title_Text"
                                        defaultMessage="Microsoft Dev Box"
                                        description="Title Text for the Microsoft Dev Box Links section of the layout page"
                                    />
                                </div>
                                <div className={mergeClasses(stackStyles.item, sectionFourSubTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionFour_1_SubText"
                                        defaultMessage="For developers"
                                        description="Subtext for the developers link section of the layout page"
                                    />
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={SupportSectionLinks.QuickstartDevBox} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_SectionFour_OnboardingGuide_Link"
                                            defaultMessage="Dev Box quickstart"
                                            description="Link to getting the onboarding guide."
                                        />
                                    </Link>
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={WindowsAppLink} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_WindowsApp_Link"
                                            defaultMessage="Download Windows app"
                                            description="Link to getting the Windows App."
                                        />
                                    </Link>
                                </div>
                                <div className={mergeClasses(stackStyles.item, sectionFourSubTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_Devbox_Admins"
                                        defaultMessage="For admins and project leads"
                                        description="Text for admins and project leads for dev box"
                                    />
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={SupportSectionLinks.ConfigureDevBox} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_Configure_DevBox_Link"
                                            defaultMessage="Configuring Microsoft Dev Box for your team"
                                            description="Link to configuring dev box information."
                                        />
                                    </Link>
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={SupportSectionLinks.GettingStarted} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_All_Documentation_DevBox_Link"
                                            defaultMessage="All documentation"
                                            description="Link to getting all documentation for dev box."
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className={mergeClasses(horizontalStackStyles.item, sectionFourGridItemStyles.root)}>
                                <div className={mergeClasses(stackStyles.item, sectionFourTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionFour_Title_Text"
                                        defaultMessage="Azure Deployment Environments"
                                        description="Title Text for the deployment environment section of the layout page"
                                    />
                                </div>
                                <div className={mergeClasses(stackStyles.item, sectionFourSubTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_SectionFour_Sub_Text"
                                        defaultMessage="For developers"
                                        description="Sub text for the deployment environment section of the layout page"
                                    />
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={SupportSectionLinks.QuickstartADE} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_Onboarding_Guide_ADE_Link"
                                            defaultMessage="Deployment Environments quickstart"
                                            description="Link to getting ADE information."
                                        />
                                    </Link>
                                </div>
                                <div className={mergeClasses(stackStyles.item, sectionFourSubTitleStyles.root)}>
                                    <FormattedMessage
                                        id="UnauthenticatedLandingPage_ADE_Admins"
                                        defaultMessage="For admins and project leads"
                                        description="Text for admins and project leads for ADE"
                                    />
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={SupportSectionLinks.ConfigureADE} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_Configure_ADE_Link"
                                            defaultMessage="Configuring Azure Deployment Environments for your team"
                                            description="Link to configuring ADE information."
                                        />
                                    </Link>
                                </div>
                                <div className={stackStyles.item}>
                                    <Link href={SupportSectionLinks.GettingStartedADE} target="_blank">
                                        <FormattedMessage
                                            id="UnauthenticatedLandingPage_All_Documentation_ADE_Link"
                                            defaultMessage="All documentation"
                                            description="Link to getting ADE all documentation."
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </LandingPageLayout>
            </FluentProvider>
        </>
    );
};

export const UnauthenticatedLandingPageContainer: React.FC = () => {
    // Action hooks
    const onSignInSubmitted = useActionCreator(signIn);

    return <UnauthenticatedLandingPageComponent onSignInSubmitted={onSignInSubmitted} />;
};

export default UnauthenticatedLandingPageContainer;
