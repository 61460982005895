import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import offlineImage from '../content/images/Error_Disconnected.svg';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { useStackStyles } from '../themes/styles/flexbox-styles';

const messages = defineMessages({
    offlineImageAlt: {
        id: 'OfflinePage_OfflineImage_Alt',
        defaultMessage: 'No connection',
        description: 'Alt text for "offline" image',
    },
});

/**
 * Styles
 */

const useMessageContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '344px',
        paddingTop: '6px',
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        maxWidth: '344px',
        paddingTop: '6px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

/**
 * End Styles
 */

export const OfflinePage: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const messageContainerStyles = useMessageContainerStyles();
    const titleContainerStyles = useTitleContainerStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    return (
        <CenteredPortalLayout id="offline" hideActionBar>
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={stackStyles.item}>
                    <img alt={formatMessage(messages.offlineImageAlt)} src={offlineImage} />
                </div>

                <div className={mergeClasses(stackStyles.item, titleContainerStyles.root)}>
                    <FormattedMessage
                        id="OfflinePage_OfflineTitle_Text"
                        defaultMessage="You're offline"
                        description="Text alerting the user that they are offline."
                    />
                </div>

                <div className={mergeClasses(stackStyles.item, messageContainerStyles.root)}>
                    <FormattedMessage
                        id="OfflinePage_OfflineMessage_Text"
                        defaultMessage="The internet connection has been lost. Please reconnect to continue using the developer portal."
                        description="Message informing the user that they do not have an internet connection."
                    />
                </div>
            </div>
        </CenteredPortalLayout>
    );
};

export default OfflinePage;
