import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import {
    GetPermissionsResponse,
    getManyPermissions,
    getPermissions as getSinglePermission,
} from '../../../data/services/resource-manager/permission';
import { FailureOperation } from '../../../models/common';
import { KeyValuePair } from '../../../types/key-value-pair';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureResourceManagerPayload } from '../../actions/identity/identity-actions';
import { putAndAwait } from '../../effects/put-and-await';

export function* getPermissions(
    resourceIds: string[],
    activityId?: string,
    operation = FailureOperation.GetPermissions
): SagaIterator<KeyValuePair<string, GetPermissionsResponse>[]> {
    const meta = activityId ? { activityId } : undefined;
    const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForAzureResourceManagerPayload(), meta));

    const responses: KeyValuePair<string, GetPermissionsResponse>[] = yield call(
        getManyPermissions,
        resourceIds,
        accessToken,
        activityId,
        operation
    );

    return responses;
}

export function* getSingleResourcePermission(
    resourceId: string,
    activityId?: string,
    operation = FailureOperation.GetPermissions
): SagaIterator<GetPermissionsResponse> {
    const meta = activityId ? { activityId } : undefined;
    const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForAzureResourceManagerPayload(), meta));
    return yield call(getSinglePermission, resourceId, accessToken, activityId, operation);
}
