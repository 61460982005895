import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    getScreenSelector,
    IDialogContentProps,
    IModalProps,
    makeStyles as legacyMakeStyles,
    PrimaryButton,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';
import { ImageViewModel } from '../models';
import SelectDevBoxImageDetailsList from './select-dev-box-image-details-list';

interface SelectDevBoxImageDialogProps {
    onDismiss: () => void;
    onChange: (selectedImage: ImageViewModel | undefined) => void;
    showDialog: boolean;
    images: ImageViewModel[];
    selectedImage: ImageViewModel | undefined;
}

const messages = defineMessages({
    selectDevBoxImageDialogHeaderText: {
        id: 'SelectDevBoxImageDialog_Header_Text',
        defaultMessage: 'Select image',
        description: 'Text for the select dev box image dialog title',
    },
    selectDevBoxImageDialogSelectButtonText: {
        id: 'SelectDevBoxImageDialog_SelectButton_Text',
        defaultMessage: 'Select',
        description: 'Text for the select dev box image dialog select button',
    },
    selectDevBoxImageDialogSelectButtonAriaLabel: {
        id: 'SelectDevBoxImageDialog_SelectButton_AriaLabel',
        defaultMessage: 'Select the dev box image',
        description: 'Aria label for the select dev box image dialog select button',
    },
    selectDevBoxImageDialogCancelButtonText: {
        id: 'SelectDevBoxImageDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the select dev box image dialog cancel button',
    },
    selectDevBoxImageDialogCancelButtonAriaLabel: {
        id: 'SelectDevBoxImageDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel button for the dev box image dialog',
        description: 'Aria label for the select dev box image dialog cancel button',
    },
    selectDevBoxImageDialogCloseButtonAriaLabel: {
        id: 'SelectDevBoxImageDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close button for the dev box image dialog',
        description: 'Aria label for the select dev box image dialog close button icon',
    },
});

/**
 * Styles
 */

const dialogModalMainMaxWidth = 'unset !important';
const useSelectDevBoxTypeDialogModalStyles = legacyMakeStyles((theme) => ({
    main: {
        backgroundColor: getSemanticColor(theme, 'selectDevBoxTypeDialogBackground'),

        [getScreenSelector(1440, undefined)]: {
            maxWidth: 'calc(100vw - 64px)',
        },

        [getScreenSelector(undefined, 1440)]: {
            maxWidth: dialogModalMainMaxWidth,
            width: 'calc(100vw - 64px)',
        },
    },
}));

const useSelectDevBoxTypeDialogFooterStyles = makeStyles({
    actions: {
        lineHeight: 0,
    },
});

const useButtonsContainerStyles = makeStyles({
    root: {
        gap: '8px',
        justifyContent: 'end',
    },
});

/**
 * End Styles
 */

const SelectDevBoxImageDialogComponent: React.FC<SelectDevBoxImageDialogProps> = (
    props: SelectDevBoxImageDialogProps
) => {
    const { onDismiss, onChange, images, selectedImage: initialSelectedImage } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const selectDevBoxTypeDialogModalStyles = useSelectDevBoxTypeDialogModalStyles();
    const selectDevBoxTypeDialogFooterStyles = useSelectDevBoxTypeDialogFooterStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const buttonsContainerStyles = useButtonsContainerStyles();

    // State hooks
    const [selectedImage, setSelectedImage] = React.useState<ImageViewModel | undefined>(initialSelectedImage);

    // Callback hooks
    const onDevBoxImageSelectButtonClick = React.useCallback((): void => {
        onChange(selectedImage);
        onDismiss();
    }, [selectedImage, onChange, onDismiss]);

    // Memo hooks
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            closeButtonAriaLabel: formatMessage(messages.selectDevBoxImageDialogCloseButtonAriaLabel),
        }),
        [formatMessage]
    );

    const dialogContentProps: IDialogContentProps = React.useMemo(
        () => ({
            type: DialogType.normal,
            title: formatMessage(messages.selectDevBoxImageDialogHeaderText),
        }),
        [formatMessage]
    );

    return (
        <Dialog
            hidden={false}
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            styles={selectDevBoxTypeDialogModalStyles}
        >
            <SelectDevBoxImageDetailsList
                setSelectedImage={setSelectedImage}
                images={images}
                selectedImage={selectedImage}
            />
            <DialogFooter styles={selectDevBoxTypeDialogFooterStyles}>
                <div className={mergeClasses(horizontalStackStyles.root, buttonsContainerStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <PrimaryButton
                            text={formatMessage(messages.selectDevBoxImageDialogSelectButtonText)}
                            ariaLabel={formatMessage(messages.selectDevBoxImageDialogSelectButtonAriaLabel)}
                            onClick={onDevBoxImageSelectButtonClick}
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <DefaultButton
                            text={formatMessage(messages.selectDevBoxImageDialogCancelButtonText)}
                            ariaLabel={formatMessage(messages.selectDevBoxImageDialogCancelButtonAriaLabel)}
                            onClick={onDismiss}
                        />
                    </div>
                </div>
            </DialogFooter>
        </Dialog>
    );
};

const SelectDevBoxImageDialogWrapper: React.FC<SelectDevBoxImageDialogProps> = (
    props: SelectDevBoxImageDialogProps
) => {
    const { showDialog } = props;

    if (!showDialog) {
        return <></>;
    }

    return <SelectDevBoxImageDialogComponent {...props} />;
};

export const SelectDevBoxImageDialog = SelectDevBoxImageDialogWrapper;
export default SelectDevBoxImageDialog;
