import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { SerializableSet } from '../../types/serializable-set';
import { add } from '../../utilities/serializable-set';
import {
    dismissContentSuccess,
    fatalError,
    initializeApplication,
    initializeApplicationError,
    initializeApplicationFailed,
    initializeApplicationSuccess,
    setWelcomeTourStatus,
    syncDismissedContentSuccess,
    syncWelcomeTourStatusSuccess,
} from '../actions/application/application-action-creators';
import { ApplicationDataStore, ApplicationStore } from '../store/application-store';
import { InitializationStatusStore } from '../store/initialization-status-store';
import { getPayload } from '../utilities/payload-action';
import { createInitializationStatusReducer } from './initialization-status-reducer';

export const applicationReducer = combineReducers<ApplicationStore>({
    data: createReducer(ApplicationDataStore(), (builder) => {
        builder
            .addCase(dismissContentSuccess, (store, action) => {
                const { content } = getPayload(action);
                store.dismissedContent = add(store.dismissedContent, content);
            })
            .addCase(fatalError, (store, action) => {
                const { failure } = getPayload(action);
                store.fatalError = failure;
            })
            .addCase(syncDismissedContentSuccess, (store, action) => {
                const { result } = getPayload(action);
                store.dismissedContent = SerializableSet(result);
            })
            .addCase(setWelcomeTourStatus, (store, action) => {
                const { seenOrSkippedWelcomeTour } = getPayload(action);
                store.seenOrSkippedWelcomeTour = seenOrSkippedWelcomeTour;
            })
            .addCase(syncWelcomeTourStatusSuccess, (store, action) => {
                const { seenOrSkippedWelcomeTour } = getPayload(action);

                if (!seenOrSkippedWelcomeTour) {
                    store.seenOrSkippedWelcomeTour = false; // For net new users where we haven't set the value yet, set it to false
                    return;
                }
                store.seenOrSkippedWelcomeTour = seenOrSkippedWelcomeTour;
            });
    }),

    status: combineReducers<InitializationStatusStore>({
        initialization: createInitializationStatusReducer({
            initializing: initializeApplication,
            error: initializeApplicationError,
            failed: initializeApplicationFailed,
            success: initializeApplicationSuccess,
        }),
    }),
});
