import { MenuItem } from '@fluentui/react-components';
import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { ProjectFromDiscoveryService, ProjectResource, ProjectResourceMap } from '../../../models/project';
import { StoreStateSelector } from '../../../redux/selector/common';
import { getDevBoxCountByProject } from '../../../redux/selector/dev-box-selectors';
import { getEnvironmentCountByProject } from '../../../redux/selector/environment-selectors';
import { getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization } from '../../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsWithAnyDevResourceAuthorizationByDataPlaneId } from '../../../redux/selector/project-selectors';
import { SerializableMap } from '../../../types/serializable-map';
import { filter, get } from '../../../utilities/serializable-map';

/**
 * Application state selectors
 */

export const getProjectsWithAtLeastOneResource: StoreStateSelector<SerializableMap<ProjectResource>> = createSelector(
    [getProjectsWithAnyDevResourceAuthorizationByDataPlaneId, getDevBoxCountByProject, getEnvironmentCountByProject],
    (projects, devBoxCountsByProject, environmentCountsByProject) =>
        filter(projects, (_project, id) => {
            const devBoxCount = get(devBoxCountsByProject, id) ?? 0;
            const environmentCount = get(environmentCountsByProject, id) ?? 0;

            return devBoxCount > 0 || environmentCount > 0;
        })
);

export const getProjectsFromDiscoveryServiceWithAtLeastOneResource: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector(
    [
        getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization,
        getDevBoxCountByProject,
        getEnvironmentCountByProject,
    ],
    (projects, devBoxCountsByProject, environmentCountsByProject) =>
        filter(projects, (_project, id) => {
            const devBoxCount = get(devBoxCountsByProject, id) ?? 0;
            const environmentCount = get(environmentCountsByProject, id) ?? 0;

            return devBoxCount > 0 || environmentCount > 0;
        })
);

/**
 * Other selectors
 */

type ProjectPickerMenuItemProps = React.PropsWithChildren<{
    ariaLabel: string;
    onSelected: (project?: string) => void;
    project?: string;
}>;

export const ProjectPickerMenuItem: React.FC<ProjectPickerMenuItemProps> = (props) => {
    const { ariaLabel, children, onSelected, project } = props;

    const onClick = React.useCallback(() => {
        onSelected(project);
    }, [onSelected, project]);

    return (
        <MenuItem aria-label={ariaLabel} onClick={onClick}>
            {children}
        </MenuItem>
    );
};

export const getSelectedProjectName = (projects: ProjectResourceMap, selectedProjectId: string): string | undefined =>
    get(projects, selectedProjectId)?.properties.displayName ?? get(projects, selectedProjectId)?.name;

export const getSelectedProjectFromDiscoveryServiceName = (
    projects: SerializableMap<ProjectFromDiscoveryService>,
    selectedProjectId: string
): string | undefined => get(projects, selectedProjectId)?.displayName ?? get(projects, selectedProjectId)?.name;
