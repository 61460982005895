import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { FeatureFlagName } from '../../../../constants/features';
import { EventName } from '../../../../constants/telemetry';
import { terminatePermissionWorkerPool } from '../../../../data/services/web-worker/permission-worker-pool';
import { isFeatureFlagEnabled } from '../../../../utilities/features';
import { trackEvent } from '../../../../utilities/telemetry/channel';
import { updateDisplayTime } from '../../../actions/time/time-action-creators';
import { getHasProjectEnvironmentTypesAuthorizedForEnvironmentWrite } from '../../../selector/project-environment-type-selectors';
import {
    getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
    getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
} from '../../../selector/project-from-discovery-service-selectors';
import {
    getHasProjectsAuthorizedForDevBoxCreate,
    getHasProjectsAuthorizedForEnvironmentWrite,
} from '../../../selector/project-selectors';

export function* cleanUpDiscovery(): SagaIterator {
    const isDiscoveryServiceEnabled: boolean = yield call(isFeatureFlagEnabled, FeatureFlagName.EnableDiscoveryService);

    // Terminate permission worker pool now that discovery has completed.
    if (!isDiscoveryServiceEnabled) {
        yield call(terminatePermissionWorkerPool);
    }

    // Log telemetry if the user does not have create permissions on either dev boxes or environments
    const userHasDevBoxCreatePermissions: boolean = isDiscoveryServiceEnabled
        ? yield select(getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate)
        : yield select(getHasProjectsAuthorizedForDevBoxCreate);

    const hasProjectsAuthorizedForEnvironmentWrite: boolean = isDiscoveryServiceEnabled
        ? yield select(getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite)
        : yield select(getHasProjectsAuthorizedForEnvironmentWrite);

    const hasProjectEnvironmentTypesAuthorizedForEnvironmentWrite: boolean = isDiscoveryServiceEnabled
        ? yield select(getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite)
        : yield select(getHasProjectEnvironmentTypesAuthorizedForEnvironmentWrite);

    const userHasEnvironmentCreatePermissions =
        hasProjectsAuthorizedForEnvironmentWrite || hasProjectEnvironmentTypesAuthorizedForEnvironmentWrite;

    if (!userHasDevBoxCreatePermissions) {
        yield call(trackEvent, EventName.NoDevBoxCreatePermissions);
    }

    if (!userHasEnvironmentCreatePermissions) {
        yield call(trackEvent, EventName.NoEnvironmentCreatePermissions);
    }

    // Start tracking and updating display time
    const time = new Date();
    yield put(updateDisplayTime({ time }));
}
