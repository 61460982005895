import { SerializableSet } from '../../types/serializable-set';
import { StoreStateSelector, createIsInitializedSelector, createIsInitializingSelector } from './common';

/**
 * Basic selectors
 */

export const getDismissedMessages: StoreStateSelector<SerializableSet<string>> = (store) =>
    store.applicationStore.data.dismissedContent;

export const getHasFatalError: StoreStateSelector<boolean> = (store) =>
    store.applicationStore.data.fatalError !== undefined;

export const getWelcomeTourSeenStatus: StoreStateSelector<boolean> = (store) =>
    store.applicationStore.data.seenOrSkippedWelcomeTour;

/**
 * Composed selectors
 */

export const getIsApplicationInitialized = createIsInitializedSelector(
    (store) => store.applicationStore.status.initialization
);

export const getIsApplicationInitializing = createIsInitializingSelector(
    (store) => store.applicationStore.status.initialization
);
