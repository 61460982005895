import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { FeatureFlagName } from '../../../constants/features';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { isBoolean } from '../../../utilities/boolean';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { tryOrDefault } from '../../../utilities/try-or-default';
import {
    setWelcomeTourStatus,
    syncWelcomeTourStatus,
    syncWelcomeTourStatusError,
    syncWelcomeTourStatusFailed,
    syncWelcomeTourStatusSuccess,
} from '../../actions/application/application-action-creators';
import { SyncWelcomeTourStatusAction } from '../../actions/application/application-actions';
import { getStorageValue } from '../../actions/storage/storage-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';

export const convertStringToWelcomeTourSettings = (settings: string): boolean => {
    const maybewelcomeTourSeenStatus = JSON.parse(settings);

    if (!isBoolean(maybewelcomeTourSeenStatus)) {
        throw new ClientError('String could not be parsed as welcome tour settings.');
    }

    return maybewelcomeTourSeenStatus;
};

export const tryConvertStringToWelcomeTourSettings = tryOrDefault(convertStringToWelcomeTourSettings);

export function* syncWelcomeTourStatusSaga(action: SyncWelcomeTourStatusAction): SagaIterator {
    try {
        const isWelcomeTourEnabled: boolean = yield call(isFeatureFlagEnabled, FeatureFlagName.EnableWelcomeTour);
        if (!isWelcomeTourEnabled) {
            const seenOrSkippedWelcomeTour = false;
            yield put(setWelcomeTourStatus({ seenOrSkippedWelcomeTour }));
            yield resolveAction(action, { seenOrSkippedWelcomeTour });
            return;
        }
        const storageType = yield select(getStorageType);
        const response: DataResponse<string> = yield putAndAwait(
            getStorageValue({
                getForSignedInUser: true,
                key: StorageKey.SeenWelcomeTour,
                storageType,
            })
        );

        if (isFailureResponse(response)) {
            yield put(syncWelcomeTourStatusFailed({ failure: response }));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;
        const seenOrSkippedWelcomeTour = tryConvertStringToWelcomeTourSettings(data);

        yield put(syncWelcomeTourStatusSuccess({ seenOrSkippedWelcomeTour }));
        yield resolveAction(action, { data: seenOrSkippedWelcomeTour, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SyncWelcomeTourStatus);
        yield put(syncWelcomeTourStatusError({ error }));
        yield rejectAction(action, error);
    }
}

export function* syncWelcomeTourStatusListenerSaga(): SagaIterator {
    yield takeEvery(syncWelcomeTourStatus, syncWelcomeTourStatusSaga);
}
