import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import InProgressOperationMessage from '../../common/in-progress-operation-message';
import { DevBoxCardInfoBannerMessageKind } from './models';

interface DevBoxBannerInfoMessageProps {
    kind: DevBoxCardInfoBannerMessageKind;
    ariaLabel?: string;
}

export const DevBoxBannerInfoMessage: React.FC<DevBoxBannerInfoMessageProps> = (
    props: DevBoxBannerInfoMessageProps
) => {
    const { kind, ariaLabel } = props;

    switch (kind) {
        case DevBoxCardInfoBannerMessageKind.RepairOperationInProgressMessage:
            return (
                <InProgressOperationMessage ariaLabel={ariaLabel}>
                    <FormattedMessage
                        id="DevBoxCard_RepairOperationInfo_Text"
                        defaultMessage="Troubleshooting connection. This takes 20 minutes on average, but may take up to 40 minutes."
                        description="In progress notification for repair operation"
                    />
                </InProgressOperationMessage>
            );
        case DevBoxCardInfoBannerMessageKind.RestoreOperationInProgressMessage:
            return (
                <InProgressOperationMessage ariaLabel={ariaLabel}>
                    <FormattedMessage
                        id="DevBoxCard_RestoreOperationInfo_Text"
                        defaultMessage="Restoring Dev Box"
                        description="In progress notification for restore operation"
                    />
                </InProgressOperationMessage>
            );
        case DevBoxCardInfoBannerMessageKind.NoIssuesDetectedMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_RepairOperationNoIssuesDetected_Text"
                    defaultMessage="No issues detected."
                    description="No issues detected notification after repair operation"
                />
            );
        case DevBoxCardInfoBannerMessageKind.CustomizeOperationInProgressMessage:
            return (
                <InProgressOperationMessage ariaLabel={ariaLabel}>
                    <FormattedMessage
                        id="DevBoxCard_CustomizeOperationInfo_Text"
                        defaultMessage="Creating a Dev Box with your customizations. This may take a while."
                        description="In progress notification for customization operation"
                    />
                </InProgressOperationMessage>
            );
        default:
            return <></>;
    }
};

export default DevBoxBannerInfoMessage;
