import { createSelector } from 'reselect';
import { parse } from 'yaml';
import {
    CreateDevBoxErrorCode,
    DefaultCpuCount,
    DefaultDiskSizeInGb,
    DefaultMemoryInGb,
    DefaultOSType,
} from '../../constants/dev-box';
import { ImageDisplayNames } from '../../constants/images';
import { DefaultScheduleName, ScheduleFrequency } from '../../constants/schedule';
import { getTokensFromPoolDataPlaneUri } from '../../ids/pool';
import { createScheduleDataPlaneUri } from '../../ids/schedule';
import { Failure } from '../../models/common';
import { CustomizationTaskExecutionAccount, PutCustomizationTask } from '../../models/customization';
import { HibernateSupport } from '../../models/pool';
import { ScheduleMap } from '../../models/schedule';
import { StoreStateSelector } from '../../redux/selector/common';
import { getRegionRecommendations } from '../../redux/selector/dev-box-region-recommendation-selectors';
import { getDevBoxCountByProject, getDevBoxes } from '../../redux/selector/dev-box-selectors';
import { getPoolsByProject } from '../../redux/selector/pool-selectors';
import { getProjectsByDiscoveryServiceURI } from '../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsByDataPlaneId } from '../../redux/selector/project-selectors';
import { getProjectsInSingleDevCenter } from '../../redux/selector/sub-applications/single-dev-center-selectors';
import { getLocations } from '../../redux/selector/subscription-selectors';
import { SerializableMap } from '../../types/serializable-map';
import { compact, groupIntoMap, sortByInPlace } from '../../utilities/array';
import { getIntrinsicTasks } from '../../utilities/customization-task';
import { filter, forEach, get, map, set, values } from '../../utilities/serializable-map';
import { getIsSingleDevCenterMode } from '../../utilities/single-dev-center';
import { compareStrings, isNotUndefinedOrWhiteSpace, isString, isUndefinedOrWhiteSpace } from '../../utilities/string';
import { compareDates, tryGetTimeStringFromBrowserTimeZone } from '../../utilities/time';
import { tryOrDefault } from '../../utilities/try-or-default';
import { tryParseWingetCustomizationTask } from '../common/winget-configuration/selectors';
import {
    AddDevBoxFormData,
    AddDevBoxFormProjectViewModel,
    CustomizationFileSchemaVersion,
    CustomizationTaskContract,
    ImageViewModel,
    ImageViewModelMap,
    PoolViewModel,
    ProjectToPoolViewModelMap,
    ProjectToSizeViewModelMap,
    RegionViewModel,
    RegionViewModelMap,
    ScheduleViewModel,
    SizeViewModel,
} from './models';

const getDisplayFrequency = (frequency: ScheduleFrequency) => {
    switch (frequency) {
        case ScheduleFrequency.Daily:
            return 'every day';
        default:
            return '';
    }
};

const getLocationKey = (location: string | undefined): string => location?.toLowerCase() ?? '';

// Parsing with option { schema: 'failsafe' } so the parsed value is a direct string of the value in $schema
export const tryParseSchema = tryOrDefault(
    (fileContent: string): string | undefined => parse(fileContent, { schema: 'failsafe' }).$schema
);

const tryParseWorkloadYamlV0 = tryOrDefault(
    (fileContent: string): CustomizationTaskContract[] => parse(fileContent).setupTasks
);

const tryParseWorkloadYamlV1 = tryOrDefault((fileContent: string): PutCustomizationTask[] => {
    const parsedFileContent = parse(fileContent);
    const systemTasks = Array.isArray(parsedFileContent.tasks) ? parsedFileContent.tasks : [];
    const userTasks = Array.isArray(parsedFileContent.userTasks)
        ? parsedFileContent.userTasks.map((task: PutCustomizationTask) => ({
              ...task,
              runAs: CustomizationTaskExecutionAccount.User,
          }))
        : [];
    return [...systemTasks, ...userTasks];
});

/**
 * Application state selectors
 */

export const getPoolViewModels: StoreStateSelector<ProjectToPoolViewModelMap> = createSelector(
    [getPoolsByProject, getLocations],
    (pools, locations) =>
        map(pools, (value) => {
            const poolViewModels = map(value, (pool, id) => {
                const {
                    name,
                    location,
                    hardwareProfile,
                    storageProfile,
                    imageReference,
                    osType,
                    hibernateSupport,
                    displayName,
                } = pool;
                const cpuCount = hardwareProfile?.vCPUs ?? DefaultCpuCount;
                const memoryInGb = hardwareProfile?.memoryGB ?? DefaultMemoryInGb;
                const diskSizeInGb = storageProfile?.osDisk?.diskSizeGB ?? DefaultDiskSizeInGb;
                const finalOsType = osType ?? DefaultOSType;
                const regionMetadata = get(locations, getLocationKey(location));

                const viewModel: PoolViewModel = {
                    id,
                    name: name ?? '',
                    cpuCount,
                    memoryInGb,
                    diskSizeInGb,
                    region: regionMetadata?.displayName ?? location ?? '',
                    imageName: imageReference?.name,
                    imageVersion: imageReference?.version,
                    lastUpdated: imageReference?.publishedDate ?? undefined,
                    osType: finalOsType,
                    supportsHibernate: hibernateSupport === HibernateSupport.Enabled,
                    hibernateSupport: hibernateSupport ?? HibernateSupport.Disabled,
                    displayName: displayName ?? name,
                };

                return viewModel;
            });

            return sortByInPlace(
                compact(values(poolViewModels)),
                (option) => option.displayName ?? option.name,
                (a, b) => compareStrings(a, b, true)
            );
        })
);

export const getImageViewModels: StoreStateSelector<ImageViewModelMap> = createSelector(
    [getPoolsByProject, getLocations],
    (images, locations) =>
        map(images, (value) => {
            const imageViewModels = map(value, (pool, id) => {
                const { name, location, imageReference } = pool;

                const regionMetadata = get(locations, getLocationKey(location));

                const viewModel: ImageViewModel = {
                    id,
                    name: get(ImageDisplayNames, imageReference?.name) ?? imageReference?.name ?? '',
                    region: location,
                    regionDisplayName: regionMetadata?.displayName ?? location,
                    imageName: imageReference?.name ?? '',
                    poolName: name,
                    version: imageReference?.version,
                    lastUpdated: imageReference?.publishedDate,
                };

                return viewModel;
            });

            const imageViewModelsArray = values(imageViewModels);

            const groupedImageViewModels = groupIntoMap(imageViewModelsArray, (image) => image.imageName ?? '');

            return sortByInPlace(
                compact(values(groupedImageViewModels)),
                (option) => option[0].name,
                (a, b) => compareStrings(a, b, true)
            );
        })
);

export const getRegionViewModels: StoreStateSelector<RegionViewModelMap> = createSelector(
    [getRegionRecommendations, getLocations],
    (regions, locations) =>
        map(regions, (region) => {
            const { sourceRegion, destinationRegion, latencyBand, latencyValue } = region;

            const regionMetadata = get(locations, getLocationKey(destinationRegion));

            const viewModel: RegionViewModel = {
                id: destinationRegion,
                sourceRegion,
                name: regionMetadata?.displayName ?? destinationRegion,
                region: destinationRegion,
                latencyBand,
                roundTripTime: latencyValue,
            };

            return viewModel;
        })
);

export const getSizeViewModels: StoreStateSelector<ProjectToSizeViewModelMap> = createSelector(
    [getPoolsByProject, getLocations],
    (pools, locations) =>
        map(pools, (value) => {
            const sizeViewModels = map(value, (pool) => {
                const { uri, name, location, hardwareProfile, storageProfile, hibernateSupport, imageReference } = pool;

                const cpuCount = hardwareProfile?.vCPUs ?? DefaultCpuCount;
                const memoryInGb = hardwareProfile?.memoryGB ?? DefaultMemoryInGb;
                const diskSizeInGb = storageProfile?.osDisk?.diskSizeGB ?? DefaultDiskSizeInGb;
                const regionMetadata = get(locations, getLocationKey(location));

                const viewModel: SizeViewModel = {
                    id: name,
                    poolId: uri,
                    cpuCount,
                    memoryInGb,
                    diskSizeInGb,
                    imageName: imageReference?.name ?? '',
                    region: regionMetadata?.displayName ?? location ?? '',
                    hibernateSupport: hibernateSupport ?? HibernateSupport.Disabled,
                };

                return viewModel;
            });

            return sortByInPlace(
                compact(values(sizeViewModels)),
                (option) => option.id,
                (a, b) => compareStrings(a, b, true)
            );
        })
);

const getSingleDevCenterProjectViewModels: StoreStateSelector<
    SerializableMap<AddDevBoxFormProjectViewModel | undefined>
> = createSelector(
    [getProjectsInSingleDevCenter, getPoolViewModels, getDevBoxCountByProject],
    (projects, poolViewModels, usedDevBoxesMap) =>
        map(projects, (project, id) => {
            const { name, maxDevBoxesPerUser } = project;

            // Skip this project if there are no pool options for it.
            const pools = get(poolViewModels, id);

            if (!pools || pools.length < 1) {
                return undefined;
            }

            const usedDevBoxes = get(usedDevBoxesMap, id) ?? 0;

            const viewModel: AddDevBoxFormProjectViewModel = { id, name, usedDevBoxes, maxDevBoxesPerUser };

            return viewModel;
        })
);

export const getProjectViewModels: StoreStateSelector<AddDevBoxFormProjectViewModel[]> = createSelector(
    [
        getProjectsByDataPlaneId,
        getPoolViewModels,
        getDevBoxCountByProject,
        getIsSingleDevCenterMode,
        getSingleDevCenterProjectViewModels,
    ],
    (
        projects,
        poolViewModels,
        usedDevBoxesMap,
        isSingleDevCenterMode,
        singleDevCenterProjectViewModels
    ): AddDevBoxFormProjectViewModel[] => {
        // Filter out projects that don't have any pools
        const filteredProjects = filter(projects, (_, id) => {
            const pools = get(poolViewModels, id);
            if (!pools || pools.length < 1) {
                return false;
            }
            return true;
        });

        // Create a map of project display names to determine if they are duplicates
        const projectDisplayNameIsDuplicateMap = new Map<string, boolean>();

        forEach(filteredProjects, (project) => {
            const { properties } = project;
            const { displayName } = properties;

            if (isUndefinedOrWhiteSpace(displayName)) {
                return;
            }

            if (projectDisplayNameIsDuplicateMap.has(displayName)) {
                projectDisplayNameIsDuplicateMap.set(displayName, true);
            } else {
                projectDisplayNameIsDuplicateMap.set(displayName, false);
            }
        });

        const projectViewModels = isSingleDevCenterMode
            ? singleDevCenterProjectViewModels
            : map(filteredProjects, (project, id) => {
                  const { id: resourceId, name, properties } = project;
                  const { maxDevBoxesPerUser, displayName } = properties;

                  const usedDevBoxes = get(usedDevBoxesMap, id) ?? 0;

                  const viewModel: AddDevBoxFormProjectViewModel = {
                      id,
                      name,
                      resourceId,
                      usedDevBoxes,
                      maxDevBoxesPerUser,
                      displayName,
                      isDisplayNameDuplicate: displayName
                          ? projectDisplayNameIsDuplicateMap.get(displayName)
                          : undefined,
                  };

                  return viewModel;
              });

        return sortByInPlace(
            compact(values(projectViewModels)),
            (option) => option.displayName ?? option.name,
            (a, b) => compareStrings(a, b, true)
        );
    }
);

export const getProjectFromDiscoveryServiceViewModels: StoreStateSelector<AddDevBoxFormProjectViewModel[]> =
    createSelector(
        [
            getPoolViewModels,
            getDevBoxCountByProject,
            getProjectsByDiscoveryServiceURI,
            getIsSingleDevCenterMode,
            getSingleDevCenterProjectViewModels,
        ],
        (
            poolViewModels,
            usedDevBoxesMap,
            projectsFromDiscoveryService,
            isSingleDevCenterMode,
            singleDevCenterProjectViewModels
        ): AddDevBoxFormProjectViewModel[] => {
            // Filter out projects that don't have any pools
            const filteredProjects = filter(projectsFromDiscoveryService, (_, id) => {
                const pools = get(poolViewModels, id);
                if (!pools || pools.length < 1) {
                    return false;
                }
                return true;
            });

            // Create a map of project display names to determine if they are duplicates
            const projectDisplayNameIsDuplicateMap = new Map<string, boolean>();

            forEach(filteredProjects, (project) => {
                const { displayName } = project;

                if (isUndefinedOrWhiteSpace(displayName)) {
                    return;
                }

                if (projectDisplayNameIsDuplicateMap.has(displayName)) {
                    projectDisplayNameIsDuplicateMap.set(displayName, true);
                } else {
                    projectDisplayNameIsDuplicateMap.set(displayName, false);
                }
            });

            const projectViewModels = isSingleDevCenterMode
                ? singleDevCenterProjectViewModels
                : map(filteredProjects, (project, id) => {
                      const { uri: resourceId, name, maxDevBoxesPerUser, displayName } = project;

                      const usedDevBoxes = get(usedDevBoxesMap, id) ?? 0;

                      const viewModel: AddDevBoxFormProjectViewModel = {
                          id,
                          name,
                          resourceId,
                          usedDevBoxes,
                          maxDevBoxesPerUser,
                          displayName,
                          isDisplayNameDuplicate: displayName
                              ? projectDisplayNameIsDuplicateMap.get(displayName)
                              : undefined,
                      };

                      return viewModel;
                  });

            return sortByInPlace(
                compact(values(projectViewModels)),
                (option) => option.displayName ?? option.name,
                (a, b) => compareStrings(a, b, true)
            );
        }
    );

/**
 * Other selectors
 */

export const createInitialValues = (): AddDevBoxFormData => ({
    devBoxName: '',
    selectedProject: undefined,
    selectedPool: undefined,
    selectedImage: undefined,
    selectedRegion: undefined,
    selectedSize: undefined,
    applyCustomizationsEnabled: false,
    uploadFileEnabled: false,
    fileFromRepoEnabled: false,
    fileCustomizations: undefined,
    repoCustomizations: undefined,
    selectedIntrinsicTasks: getIntrinsicTasks(),
});

export const getScheduleViewModel = (
    schedules: ScheduleMap,
    poolDataPlaneId: string,
    locale: string
): ScheduleViewModel | undefined => {
    const { devCenter, poolName, projectName } = getTokensFromPoolDataPlaneUri(poolDataPlaneId);
    const scheduleId = createScheduleDataPlaneUri({
        devCenter,
        poolName,
        projectName,
        scheduleName: DefaultScheduleName,
    });
    const schedule = get(schedules, scheduleId);

    if (!schedule) {
        return undefined;
    }

    const { frequency, time, timeZone: configuredTimeZone } = schedule;
    const displayTime = tryGetTimeStringFromBrowserTimeZone(locale, time, configuredTimeZone);
    const displayFrequency = getDisplayFrequency(frequency as ScheduleFrequency);

    return isNotUndefinedOrWhiteSpace(displayTime) && isNotUndefinedOrWhiteSpace(displayFrequency)
        ? {
              displayFrequency,
              displayTime,
          }
        : undefined;
};

export const getTaskListFromCustomizationFiles = (selectedFiles: string[]): PutCustomizationTask[] | undefined => {
    const tasks: PutCustomizationTask[] = compact([
        ...selectedFiles.flatMap((file) =>
            tryParseSchema(file) === CustomizationFileSchemaVersion.V1
                ? tryParseWorkloadYamlV1(file)
                : tryParseWorkloadYamlV0(file)?.map((task) => ({
                      name: task.task,
                      parameters: task.inputs,
                  }))
        ),
        ...selectedFiles.flatMap((file) => tryParseWingetCustomizationTask(file)),
    ]);

    if (tasks.length < 1) {
        return undefined;
    }

    return getProcessedParameterValues(tasks);
};

// Converts boolean and numbered parameter values to strings for API call
export const getProcessedParameterValues = (parameters: PutCustomizationTask[]): PutCustomizationTask[] | undefined => {
    const processedValues = parameters.map((value) => {
        if (value.parameters) {
            let stringifiedParameters = SerializableMap<string>(value.parameters, true);

            map(value.parameters, (val, key) => {
                if (!isString(val)) {
                    stringifiedParameters = set(stringifiedParameters, key, JSON.stringify(val), true);
                }
            });

            value.parameters = stringifiedParameters;
        }

        return value;
    });

    return processedValues;
};

// Used to prevent submission if a) the current failure is non-retryable, b) the form values haven't been modified since
// that failed submit.
export const getIsSubmitDisabled = (
    failure: Failure | undefined,
    hasBeenModifiedSinceLastSubmit: boolean,
    isSubmitting: boolean,
    isValid: boolean,
    isValidatingCustomizations: boolean
): boolean => {
    // Simple cases: block if not valid, if we're already submitting, or we're validating customizations
    if (!isValid || isSubmitting || isValidatingCustomizations) {
        return true;
    }

    // Nuanced case: also block if a) there's a failure, b) we deem it non-retryable, c) the form values haven't been
    // modified since that failed submit.
    if (hasBeenModifiedSinceLastSubmit) {
        return false;
    }

    const { code } = failure ?? {};

    return code === CreateDevBoxErrorCode.PoolUnhealthyState || code === CreateDevBoxErrorCode.QuotaExceeded;
};

export const getMostRecentUsageDateForImages: StoreStateSelector<SerializableMap<Date>> = createSelector(
    [getDevBoxes],
    (devBoxes) => {
        let recentlyUsedImagesMap = SerializableMap<Date>();

        devBoxes.forEach((devBox) => {
            if (devBox.imageReference?.name && devBox.createdTime) {
                const currentLatestTime = get(recentlyUsedImagesMap, devBox.imageReference?.name);
                let mostRecentUsageDate = devBox.createdTime;

                if (currentLatestTime) {
                    mostRecentUsageDate =
                        compareDates(devBox.createdTime, currentLatestTime) === 1
                            ? devBox.createdTime
                            : currentLatestTime;
                }

                recentlyUsedImagesMap = set(recentlyUsedImagesMap, devBox.imageReference?.name, mostRecentUsageDate);
            }
        });

        return recentlyUsedImagesMap;
    }
);

export const getMostRecentUsageDateForProjects: StoreStateSelector<SerializableMap<Date>> = createSelector(
    [getDevBoxes],
    (devBoxes) => {
        let recentlyUsedProjectsMap = SerializableMap<Date>();

        devBoxes.forEach((devBox) => {
            if (devBox.projectName && devBox.createdTime) {
                const currentLatestTime = get(recentlyUsedProjectsMap, devBox.projectName);
                let mostRecentUsageDate = devBox.createdTime;

                if (currentLatestTime) {
                    mostRecentUsageDate =
                        compareDates(devBox.createdTime, currentLatestTime) === 1
                            ? devBox.createdTime
                            : currentLatestTime;
                }

                recentlyUsedProjectsMap = set(recentlyUsedProjectsMap, devBox.projectName, mostRecentUsageDate);
            }
        });

        return recentlyUsedProjectsMap;
    }
);
