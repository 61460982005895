import { SpinButton, SpinButtonProps } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getDayOfTheWeek, getTimeStringFromDate, isDayAfterNextDay, isNextDay } from '../../../utilities/time';
import { DelayShutdownSpinButtonOption } from './model';

export type DelayShutdownProps = {
    options: DelayShutdownSpinButtonOption[];
    minDelay: number;
    skipOffsetInHours: number;
    onChange: (date: DelayShutdownSpinButtonOption | undefined) => void;
};

/**
 * Styles
 */

const spinButtonStyle: React.CSSProperties = {
    width: '95%',
};

/**
 * END Styles
 */

const messages = defineMessages({
    spinButtonAriaLabel: {
        id: 'DelayShutdownSpinButton_SpinButton_AriaLabel',
        defaultMessage: 'Delay shutdown until',
        description: 'Aria label for spin button in the "Delay shutdown" dialog',
    },
    twoDaysLaterAndSkipText: {
        id: 'DelayShutdownSpinButton_TwoDaysLaterAndSkip_Text',
        defaultMessage: '{timeString} {dayOfTheWeek} (skip)',
        description: 'Text for timeString format if the selection goes to the next day. Do not localize {timeString}',
    },
    twoDaysLaterText: {
        id: 'DelayShutdownSpinButton_TwoDaysLater_Text',
        defaultMessage: '{timeString} {dayOfTheWeek}',
        description: 'Text for timeString format if the selection goes to the next day. Do not localize {timeString}',
    },
    skipText: {
        id: 'DelayShutdownSpinButton_Skip_Text',
        defaultMessage: '{timeString} tomorrow (skip)',
        description: 'Text for timeString format if the selection goes to the next day. Do not localize {timeString}',
    },
    tomorrowText: {
        id: 'DelayShutdownSpinButton_Tomorrow_Text',
        defaultMessage: '{timeString} tomorrow',
        description: 'Text for timeString format if the selection goes to the next day. Do not localize {timeString}',
    },
    timeStringText: {
        id: 'DelayShutdownSpinButton_TimeString_Text',
        defaultMessage: '{timeString}',
        description: 'Text for timeString format. Do not localize {timeString}',
    },
});

export const DelayShutdownSpinButton: React.FC<DelayShutdownProps> = (props: DelayShutdownProps) => {
    // Intl hooks
    const { formatMessage } = useIntl();

    const { options, minDelay, skipOffsetInHours, onChange } = props;

    // Switching these around so that the down arrow moves forward in time
    const minValue = -options.length;
    const maxValue = -minDelay;

    const getDelayShutdownSpinButtonDisplayValue = React.useCallback(
        (date: DelayShutdownSpinButtonOption): string => {
            const { dateValue, locale, isSkip } = date;

            const currentTime = new Date();

            const dayOfTheWeek = getDayOfTheWeek(dateValue);

            const isTomorrow = isNextDay(currentTime, dateValue);
            const isTwoDaysLater = isDayAfterNextDay(currentTime, dateValue);
            const timeString = getTimeStringFromDate(dateValue, locale) ?? '';

            const valuesForTimeAndDay = { timeString, dayOfTheWeek };

            const valuesForTime = { timeString };

            if (isTwoDaysLater && isSkip) {
                return formatMessage(messages.twoDaysLaterAndSkipText, valuesForTimeAndDay);
            }
            if (isTwoDaysLater) {
                return formatMessage(messages.twoDaysLaterText, valuesForTimeAndDay);
            }
            if (isSkip) {
                return formatMessage(messages.skipText, valuesForTime);
            }
            if (isTomorrow) {
                return formatMessage(messages.tomorrowText, valuesForTime);
            }

            return formatMessage(messages.timeStringText, valuesForTime);
        },
        [formatMessage]
    );

    const getDateFromOffset = React.useCallback(
        (timeOffsetInHours: number): DelayShutdownSpinButtonOption | undefined => {
            if (timeOffsetInHours === minValue) {
                return options.find((option) => option.offsetInHours === skipOffsetInHours);
            }
            return options.find((option) => option.offsetInHours === -timeOffsetInHours);
        },
        [minValue, skipOffsetInHours, options]
    );

    const onRenderOption = React.useCallback(
        (timeOffsetInHours: number): string => {
            const date = getDateFromOffset(timeOffsetInHours);

            return date ? getDelayShutdownSpinButtonDisplayValue(date) : '--';
        },
        [getDateFromOffset, getDelayShutdownSpinButtonDisplayValue]
    );

    const getOffsetFromDisplayValue = React.useCallback(
        (displayValue: string): number | null => {
            const date = options.find((option) => {
                const optionDisplay = getDelayShutdownSpinButtonDisplayValue(option);
                return optionDisplay.toLocaleLowerCase().includes(displayValue.toLocaleLowerCase());
            });

            return date ? -date.offsetInHours : null;
        },
        [options, getDelayShutdownSpinButtonDisplayValue]
    );

    const [spinButtonValue, setSpinButtonValue] = React.useState<number | null>(maxValue);
    const [spinButtonDisplayValue, setSpinButtonDisplayValue] = React.useState(onRenderOption(maxValue));

    const onSpinButtonChange: SpinButtonProps['onChange'] = React.useCallback(
        (_ev, data) => {
            if (data.value !== undefined && data.value !== null) {
                setSpinButtonValue(data.value);
                setSpinButtonDisplayValue(onRenderOption(data.value));
                onChange(getDateFromOffset(data.value));
            } else if (data.displayValue !== undefined) {
                const newValue = getOffsetFromDisplayValue(data.displayValue);
                if (newValue) {
                    setSpinButtonValue(newValue);
                    setSpinButtonDisplayValue(onRenderOption(newValue));
                    onChange(getDateFromOffset(newValue));
                }
            }
        },
        [onRenderOption, getDateFromOffset, onChange, getOffsetFromDisplayValue]
    );

    return (
        <SpinButton
            value={spinButtonValue}
            displayValue={spinButtonDisplayValue}
            onChange={onSpinButtonChange}
            min={minValue}
            max={maxValue}
            aria-label={formatMessage(messages.spinButtonAriaLabel)}
            style={spinButtonStyle}
        />
    );
};

export default DelayShutdownSpinButton;
