import { Label } from '@fluentui/react';
import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogTitle,
    DialogTrigger,
    makeStyles,
    Spinner,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AsyncState } from '../../../redux/store/common-state';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { getDateForBrowserTimeZone } from '../../../utilities/time';
import { DelayShutdownSpinButton } from './delay-shutdown-spin-button';
import { DelayShutdownFormData, DelayShutdownFormFieldName, DelayShutdownSpinButtonOption } from './model';
import { getSpinButtonOptions, skipOrDelayInTerminalState } from './selectors';

interface DelayShutdownDialogFormProps {
    devBoxId: string;
    locale: string;
    skipState: AsyncState;
    delayState: AsyncState;
    originalScheduledTime: Date;
    nextScheduledTime: Date;
    onDismiss: () => void;
    onDelaySubmitted: (devBoxId: string, delayUntil: Date) => void;
    onSkipSubmitted: (devBoxId: string) => void;
    hasHibernateSupport: boolean;
}

interface DelayShutdownDialogFooterComponentProps {
    isSkipSelected: boolean;
    hasHibernateSupport: boolean;
    isSkipNextDay: boolean;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'DelayShutdownDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss dialog',
        description: "Aria label for the delay shutdown dialog's close button",
    },
    delayButtonText: {
        id: 'DelayShutdownDialogForm_DelayButton_Text',
        defaultMessage: 'Delay',
        description: 'Text for the "Delay" button',
    },
    delayButtonAriaLabel: {
        id: 'DelayShutdownDialogForm_DelayButton_AriaLabel',
        defaultMessage: 'Delay',
        description: 'Aria label for the "Delay" button',
    },
    skipButtonText: {
        id: 'DelayShutdownDialogForm_SkipButton_Text',
        defaultMessage: 'Skip',
        description: 'Text for the "Skip" button',
    },
    skipButtonAriaLabel: {
        id: 'DelayShutdownDialogForm_SkipButton_AriaLabel',
        defaultMessage: 'Skip',
        description: 'Aria label for the "Skip" button',
    },
    cancelButtonText: {
        id: 'DelayShutdownDialogForm_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the "Cancel" button',
    },
    cancelButtonAriaLabel: {
        id: 'DelayShutdownDialogForm_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "Cancel" button',
    },
    delayShutdownFailureMessage: {
        id: 'DelayShutdownDialogForm_DelayShutdownErrorMessage_Text',
        defaultMessage: 'Invalid time selected, please select another time.',
        description: 'Failure message text for the delay shutdown until field',
    },
});

/**
 * Styles
 */

const useLabelStyles = makeStyles({
    root: {
        paddingBottom: '10px',
        marginTop: '15px',
    },
});

const useStyles = makeStyles({
    skipLabel: {
        paddingTop: '7px',
    },
});

const footerStyle = {
    marginTop: '30px',
};

/**
 * END Styles
 */

// Constants defining the available delay and skip options
const startOffsetInHours = 1;
const endOffsetInHours = 8;
const skipOffsetInHours = 24;

export const DelayShutdownDialogFooterComponent: React.FC<DelayShutdownDialogFooterComponentProps> = (
    props: DelayShutdownDialogFooterComponentProps
) => {
    const { isSkipSelected, isSkipNextDay } = props;

    // Style hooks
    const styles = useStyles();

    if (isSkipSelected && isSkipNextDay) {
        return (
            <div className={styles.skipLabel}>
                <FormattedMessage
                    id="DelayShutdownDialogForm_SkipLabel_Tomorrow_Text"
                    defaultMessage="Tomorrow's shutdown will be skipped"
                    description="Text for the skip shutdown label for tomorrow"
                />
            </div>
        );
    }

    if (isSkipSelected) {
        return (
            <div className={styles.skipLabel}>
                <FormattedMessage
                    id="DelayShutdownDialogForm_SkipLabel_Text"
                    defaultMessage="Today's shutdown will be skipped"
                    description="Text for the skip shutdown label"
                />
            </div>
        );
    }

    return <></>;
};

export const DelayShutdownDialogForm: React.FC<DelayShutdownDialogFormProps> = (
    props: DelayShutdownDialogFormProps
) => {
    const {
        devBoxId,
        locale,
        skipState,
        delayState,
        originalScheduledTime,
        nextScheduledTime,
        onDismiss,
        onSkipSubmitted,
        onDelaySubmitted,
        hasHibernateSupport,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const labelStyles = useLabelStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // Memoized data
    const scheduledShutdown = React.useMemo(() => getDateForBrowserTimeZone(nextScheduledTime), [nextScheduledTime]);

    const spinButtonOptions: DelayShutdownSpinButtonOption[] = React.useMemo(
        () =>
            getSpinButtonOptions(
                originalScheduledTime,
                scheduledShutdown,
                locale,
                new Date(),
                startOffsetInHours,
                endOffsetInHours,
                skipOffsetInHours
            ),
        [originalScheduledTime, scheduledShutdown, locale]
    );

    const initialValues: DelayShutdownFormData = React.useMemo(
        () => ({
            delayShutdownUntil:
                spinButtonOptions.length === 1
                    ? spinButtonOptions[0]
                    : spinButtonOptions.find((value) => {
                          return value.offsetInHours === startOffsetInHours;
                      }),
        }),
        [spinButtonOptions, startOffsetInHours]
    );

    // Callbacks
    const onFormSubmit = React.useCallback(
        (data: DelayShutdownFormData) => {
            const { delayShutdownUntil } = data;
            if (!delayShutdownUntil) {
                return;
            }

            if (delayShutdownUntil.isSkip) {
                onSkipSubmitted(devBoxId);
            } else {
                onDelaySubmitted(devBoxId, delayShutdownUntil.dateValue);
            }
        },
        [devBoxId, onDelaySubmitted, onSkipSubmitted]
    );

    return (
        <Form<DelayShutdownFormData> initialValues={initialValues} onSubmit={onFormSubmit}>
            {(formProps) => {
                const { form, valid, submitting, values } = formProps;
                const { delayShutdownUntil } = values;

                const { reset, submit } = form;

                const isSkipOrDelayInTerminalState = React.useMemo(
                    () => skipOrDelayInTerminalState(skipState, delayState),
                    [skipState, delayState]
                );

                const isSubmitting = React.useMemo(
                    () => !isSkipOrDelayInTerminalState && submitting,
                    [submitting, isSkipOrDelayInTerminalState]
                );

                const submitButtonDisabled = React.useMemo(() => isSubmitting || !valid, [isSubmitting, valid]);

                const submitButtonLabel = React.useMemo(
                    () =>
                        delayShutdownUntil?.isSkip
                            ? formatMessage(messages.skipButtonAriaLabel)
                            : formatMessage(messages.delayButtonAriaLabel),
                    [formatMessage, delayShutdownUntil?.isSkip]
                );

                const onDismissForm = React.useCallback(() => {
                    reset();
                    onDismiss();
                }, [reset, onDismiss]);

                const onSubmitClicked = React.useCallback(() => {
                    if (valid) {
                        submit();
                    }

                    if (!isSubmitting) {
                        onDismissForm();
                    }
                }, [valid, isSubmitting, submit, onDismissForm]);

                const spinButtonOnChange = React.useCallback(
                    (date: DelayShutdownSpinButtonOption | undefined) => {
                        form.change(DelayShutdownFormFieldName.DelayShutdownUntil, date);
                    },
                    [form.change]
                );

                return (
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="DelayShutdownDialog_Header_Text"
                                defaultMessage="Delay shutdown"
                                description='Title of the "Delay shutdown" dialog'
                            />
                        </DialogTitle>
                        <DialogContent>
                            <div className={stackStyles.root}>
                                <div className={stackStyles.item}>
                                    <Label styles={labelStyles}>
                                        <FormattedMessage
                                            id="DelayShutdownDialogForm_Input_Text"
                                            defaultMessage="Delay shutdown until"
                                            description='Text for the "delay shutdown until" input text'
                                        />
                                    </Label>
                                </div>
                                <div className={stackStyles.item}>
                                    <Field<DelayShutdownSpinButtonOption>
                                        name={DelayShutdownFormFieldName.DelayShutdownUntil}
                                    >
                                        {() => (
                                            <>
                                                <DelayShutdownSpinButton
                                                    options={spinButtonOptions}
                                                    minDelay={startOffsetInHours}
                                                    skipOffsetInHours={skipOffsetInHours}
                                                    onChange={spinButtonOnChange}
                                                />
                                                <DelayShutdownDialogFooterComponent
                                                    isSkipSelected={delayShutdownUntil?.isSkip || false}
                                                    isSkipNextDay={delayShutdownUntil?.isSkipNextDay || false}
                                                    hasHibernateSupport={hasHibernateSupport}
                                                />
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={footerStyle}>
                            <Button
                                appearance="primary"
                                aria-label={submitButtonLabel}
                                onClick={onSubmitClicked}
                                disabled={submitButtonDisabled}
                            >
                                {submitting && <Spinner size="tiny" />}
                                {!submitting && submitButtonLabel}
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                >
                                    <FormattedMessage
                                        id="DelayShutdownDialogForm_CancelButton_Text"
                                        defaultMessage="Cancel"
                                        description='Text for the "Cancel" button'
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                );
            }}
        </Form>
    );
};

export default DelayShutdownDialogForm;
