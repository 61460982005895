import { Button, makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { signIn } from '../../redux/actions/identity/identity-action-creators';
import { CssSelector } from '../../themes/constants/css-selectors';
import { useStackStyles } from '../../themes/styles/flexbox-styles';
import { ReturnVoid } from '../../types/return-void';

interface SignInButtonComponentProps {
    onSignInSubmitted: ReturnVoid<typeof signIn>;
}

const messages = defineMessages({
    signInButtonLabel: {
        id: 'SignInButton_Label',
        defaultMessage: 'Sign in',
        description: 'Aria label for sign in button',
    },
    signInButtonText: {
        id: 'SignInButton_Text',
        defaultMessage: 'Sign in',
        description: 'Text for sign in button',
    },
});

/**
 * Styles
 */

const useTitleRootStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '22px',
        height: '48px',
        textAlign: 'start',
        [CssSelector.ScreenSizeMediumAndBelow]: {
            // At screen sizes small and below, we switch from fixed width + variable gutters mode to variable
            // width + fixed gutters mode. To give a defined width to descendant elements, we calculate the width
            // to be the total viewport width minus the total width of the gutters. That total gutter width should
            // be kept in sync with the padding defined for this element.
            paddingLeft: '40px',
            width: 'calc(100vw - 40px)',
        },
        [CssSelector.ScreenSizeXXLargeAndAbove]: {
            width: '1300px',
        },
        [CssSelector.ScreenSizeBetweenLargeAndXLarge]: {
            width: '700px',
        },
    },
});

/**
 * End Styles
 */

const SignInButton: React.FC<SignInButtonComponentProps> = React.memo((props: SignInButtonComponentProps) => {
    const { onSignInSubmitted } = props;

    // Style hooks
    const titleRootStyles = useTitleRootStyles();
    const stackStyles = useStackStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Callback hooks
    const onSignInButtonClicked = React.useCallback(() => onSignInSubmitted({}), [onSignInSubmitted]);

    return (
        <div className={mergeClasses(stackStyles.root, titleRootStyles.root)}>
            <div className={stackStyles.item}>
                <Button
                    aria-label={formatMessage(messages.signInButtonLabel)}
                    id="SignInButton"
                    onClick={onSignInButtonClicked}
                    appearance="primary"
                >
                    {formatMessage(messages.signInButtonText)}
                </Button>
            </div>
        </div>
    );
});

export default SignInButton;
