import type { DropdownProps } from '@fluentui/react-components';
import { Dropdown, Label, makeStyles, mergeClasses, Option } from '@fluentui/react-components';
import React from 'react';
import { useStackWithFullWidthItemStyles } from '../../../../../themes/styles/flexbox-styles';
import { getTimeZoneOptions } from '../../../../../utilities/time';

const getTimeZoneDropdownOptions = getTimeZoneOptions().map((timeZone) => {
    const { name, displayText } = timeZone;
    return (
        <Option key={name} value={name}>
            {displayText}
        </Option>
    );
});

/**
 * Styles
 */

const dropdownStyle: React.CSSProperties = {
    width: '100%',
};

const useTimeZoneStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * END Styles
 */

export interface TimeZoneDropdownProps extends DropdownProps {
    label?: string;
}

export const TimeZoneDropdown: React.FC<TimeZoneDropdownProps> = (props) => {
    const { label } = props;

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const timeZoneStyles = useTimeZoneStyles();

    return (
        <div className={mergeClasses(stackStyles.root, timeZoneStyles.root)}>
            <div className={stackStyles.item}>
                <Label>{label}</Label>
            </div>
            <div className={stackStyles.item}>
                <Dropdown style={dropdownStyle} {...props}>
                    {getTimeZoneDropdownOptions}
                </Dropdown>
            </div>
        </div>
    );
};
