import { FontSizes, Icon, Link } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { FeatureFlagName } from '../../constants/features';
import { SupportSectionLinks } from '../../constants/support-section-links';
import { HibernateSupport } from '../../models/pool';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { FluentIconNames } from '../common/fluent-icon-names';
import Label from '../common/form/label';

export interface HibernateReadonlyFieldProps {
    hibernateSupport: HibernateSupport;
    disabled?: boolean;
}

const hibernatePreviewMessageValues = {
    Link: (chunks: string) => (
        <Link href={SupportSectionLinks.HibernationPublicPreview} target="_blank">
            {chunks}
        </Link>
    ),
};

/**
 * Styles
 */

const useHibernateMessageContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

const useHibernateContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

const useMessageIconStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
    },
});

const useMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
    },
});

/**
 * End Styles
 */

const messages = defineMessages({
    hibernateFieldLabel: {
        id: 'HibernateReadonlyField_Label',
        defaultMessage: 'Hibernate',
        description: 'Field label for a readonly field indicating whether the selected pool has hibernate capability.',
    },
    hibernateFieldSupported: {
        id: 'HibernateReadonlyField_Supported',
        defaultMessage: 'supported',
        description: 'Readonly field value indicating that the selected pool supports hibernation.',
    },
    hibernateFieldNotSupported: {
        id: 'HibernateReadonlyField_NotSupported',
        defaultMessage: 'not supported',
        description: 'Readonly field value indicating that the selected pool does not support hibernation.',
    },
    hibernateFieldOsUnsupported: {
        id: 'HibernateReadonlyField_OsUnsupported',
        defaultMessage: 'OS not supported',
        description:
            'Readonly field value indicating that OS specific configurations have caused hibernate support to be disabled.',
    },
    hibernatePreviewTag: {
        id: 'HibernateReadonlyField_PreviewTag',
        defaultMessage: 'preview',
        description:
            'A tag displayed to the right of the "Hibernate" field of the create dev box form indicating that hibernate is a preview feature.',
    },
    hibernateFieldAriaLabel: {
        id: 'HibernateReadonlyField_AriaLabel',
        defaultMessage: 'Hibernate (preview)',
        description: 'Aria label for hibernate field with preview tag.',
    },
    hibernateInfoIconAriaLabel: {
        id: 'Hibernate_InfoIcon_AriaLabel',
        defaultMessage: 'Information icon for the user to learn more about hibernate',
        description: 'Aria label for the information icon for user to learn more about hibernate',
    },
    clockIconAriaLabel: {
        id: 'HibernateReadonlyField_ClockIcon_AriaLabel',
        defaultMessage: 'Information icon for the user about whether or not hibernate is supported',
        description: 'Aria label for whether or not hibernate is supported',
    },
});

export const HibernateReadonlyField: React.FC<HibernateReadonlyFieldProps> = React.memo(
    (props: HibernateReadonlyFieldProps) => {
        const { hibernateSupport, disabled } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const messageIconStyles = useMessageIconStyles();
        const messageStyles = useMessageStyles();
        const stackStyles = useStackStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const hibernateContainerStyles = useHibernateContainerStyles();
        const hibernateMessageContainerStyles = useHibernateMessageContainerStyles();

        const infoIconAriaLabel = formatMessage(messages.hibernateInfoIconAriaLabel);

        const isRoundTripTimeEnabled = isFeatureFlagEnabled(FeatureFlagName.RoundTripTime);

        // Memoized data
        const value = React.useMemo(() => {
            switch (hibernateSupport) {
                case HibernateSupport.Enabled:
                    return formatMessage(messages.hibernateFieldSupported);
                case HibernateSupport.OsUnsupported:
                    return formatMessage(messages.hibernateFieldOsUnsupported);
                case HibernateSupport.Disabled:
                default:
                    return formatMessage(messages.hibernateFieldNotSupported);
            }
        }, [hibernateSupport, formatMessage]);

        const hibernateSupportedOrNotValues = React.useMemo(
            () => ({
                supportedOrNot: value,
            }),
            [value]
        );

        if (isRoundTripTimeEnabled) {
            return (
                <div className={stackStyles.root}>
                    <div className={stackStyles.item}>
                        <div className={mergeClasses(horizontalStackStyles.root, hibernateMessageContainerStyles.root)}>
                            <div className={mergeClasses(horizontalStackStyles.item, messageIconStyles.root)}>
                                <Icon
                                    aria-label={formatMessage(messages.clockIconAriaLabel)}
                                    iconName={FluentIconNames.Clock}
                                />
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <FormattedMessage
                                    id="HibernateSupportedOrNot_Text"
                                    defaultMessage="Hibernate: {supportedOrNot}"
                                    description="Text showing if hibernate is supported or not for the dev box"
                                    values={hibernateSupportedOrNotValues}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={mergeClasses(stackStyles.root, hibernateContainerStyles.root)}>
                <div className={stackStyles.item}>
                    <Label
                        disabled={disabled}
                        tagContent={
                            hibernateSupport === HibernateSupport.Enabled
                                ? formatMessage(messages.hibernatePreviewTag)
                                : undefined
                        }
                        aria-label={formatMessage(messages.hibernateFieldAriaLabel)}
                    >
                        <FormattedMessage
                            id="HibernateSupportedOrNot_Text"
                            defaultMessage="Hibernate: {supportedOrNot}"
                            description="Text showing if hibernate is supported or not for the dev box"
                            values={hibernateSupportedOrNotValues}
                        />
                    </Label>
                </div>
                {hibernateSupport === HibernateSupport.Enabled && (
                    <div className={mergeClasses(horizontalStackStyles.root, hibernateMessageContainerStyles.root)}>
                        <div className={mergeClasses(horizontalStackStyles.item, messageIconStyles.root)}>
                            <Icon aria-label={infoIconAriaLabel} iconName="Info" />
                        </div>
                        <div className={mergeClasses(horizontalStackStyles.item, messageStyles.root)}>
                            <FormattedMessage
                                id="HibernateInfoMessage_Text"
                                defaultMessage="You may experience <Link> issues with provisioning and hibernate/resume operations </Link>"
                                description="Text directing to learning more about hibernate"
                                values={hibernatePreviewMessageValues}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
