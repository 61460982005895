import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';

export interface MisconfiguredStateProps {
    title: string;
    description: string;
    /* Alt text for the empty state image */
    imageAlt: string;
    imageSrc: string;
}

/**
 * Styles
 */

const useDescriptionContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '432px',
        textAlign: 'left',
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        textAlign: 'left',
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '34px',
    },
    item: {
        flexGrow: '1',
    },
});

const useMessageStyles = makeStyles({
    root: {
        justifyContent: 'center',
        height: '100%',
        gap: '6px',
    },
});

/**
 * End Styles
 */

export const MisconfiguredState: React.FC<MisconfiguredStateProps> = React.memo((props: MisconfiguredStateProps) => {
    const { title, description, imageAlt, imageSrc } = props;

    // Style hooks
    const descriptionContainerStyles = useDescriptionContainerStyles();
    const titleContainerStyles = useTitleContainerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();
    const messageStyles = useMessageStyles();

    return (
        <div className={mergeClasses(horizontalStackStyles.root)}>
            <div className={horizontalStackStyles.item}>
                <img alt={imageAlt} src={imageSrc} />
            </div>

            <div className={mergeClasses(horizontalStackStyles.item, containerStyles.item)}>
                <div className={mergeClasses(stackStyles.root, messageStyles.root)}>
                    <div className={mergeClasses(stackStyles.item, titleContainerStyles.root)}>{title}</div>

                    <div className={mergeClasses(stackStyles.item, descriptionContainerStyles.root)}>{description}</div>
                </div>
            </div>
        </div>
    );
});
