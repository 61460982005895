import { CommandBar, ICommandBarItemProps, makeStyles, TeachingBubble } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DismissableContentName } from '../../constants/app';
import { FeatureFlagName } from '../../constants/features';
import { useActionCreator } from '../../hooks/action-creator';
import {
    useDevMenuPanelContext,
    useHelpMenuPanelContext,
    useUserSettingsPanelContext,
} from '../../hooks/context/panels';
import { Status } from '../../models/common';
import { dismissContent as dismissContentActionCreator } from '../../redux/actions/application/application-action-creators';
import { signIn } from '../../redux/actions/identity/identity-action-creators';
import { getWelcomeTourSeenStatus } from '../../redux/selector/application-selectors';
import {
    ActionAbility,
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/models';
import {
    getDevBoxCreateAbilityState,
    getEnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/selectors';
import { getStatusForGetOrganization, getStatusForGetSignedInUser } from '../../redux/selector/graph-selectors';
import { getIsSignedIn } from '../../redux/selector/identity-selectors';
import { getIsHomeInitialized } from '../../redux/selector/sub-applications/home-selectors';
import { getHasMultipleTenants, getStatusForListTenants } from '../../redux/selector/tenant-selector';
import { getRemoteAppOptionsSelector } from '../../redux/selector/user-settings-selector';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { ReturnVoid } from '../../types/return-void';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { getSemanticColor } from '../../utilities/styles';
import { getIsConnectViaAppTeachBubbleDismissed } from '../dev-box/selectors';
import { getFarItems, getIsSettingsMenuTeachBubbleDismissed } from './selectors';
import SignInButton from './sign-in-button';
import Title from './title';

interface TitleBarComponentProps {
    hasMultipleTenants: boolean;
    isDevMenuPanelOpen: boolean;
    isUserSettingsPanelOpen: boolean;
    isHelpMenuPanelOpen: boolean;
    isSignedIn: boolean;
    onDevMenuButtonClicked: () => void;
    onUserSettingsButtonClicked: () => void;
    onHelpMenuButtonClicked: () => void;
    statusForGetOrganization: Status;
    statusForGetSignedInUser: Status;
    statusForListTenants: Status;
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    onDismiss: () => void;
    isHomeInitialized: boolean;
    isSettingsMenuTeachBubbleDismissed: boolean;
    isConnectViaAppTeachingBubbleDismissed: boolean;
    hasUserConnectedTheFirstTime: boolean;
    onSignInSubmitted: ReturnVoid<typeof signIn>;
    isScrolled?: boolean;
    seenOrSkippedWelcomeTour: boolean;
}

const messages = defineMessages({
    devMenuButtonAriaLabel: {
        id: 'TitleBar_DevMenuButton_AriaLabel',
        defaultMessage: 'Developer Menu',
        description: 'Aria label for dev menu button',
    },
    helpMenuButtonLabel: {
        id: 'TitleBar_HelpMenuButton_Label',
        defaultMessage: 'Help menu',
        description: 'Aria label for help menu (a.k.a "help" control) button',
    },
    meControlButtonLabel: {
        id: 'TitleBar_MeControlButton_Label',
        defaultMessage: 'User menu',
        description: 'Aria label for user menu (a.k.a "me" control) button',
    },
    buttonDefaultText: {
        id: 'TenantPicker_Button_DefaultText',
        defaultMessage: 'Select an organization',
        description: 'Button text used when no tenant (organization) is currently selected.',
    },
    userSettingsButtonAriaLabel: {
        id: 'TitleBar_UserSettingsButton_AriaLabel',
        defaultMessage: 'User settings menu',
        description: 'Aria label for user settings menu button',
    },
    userSettingsTeachBubbleTitle: {
        id: 'TitleBar_UserSettingsTeachBubble_Title',
        defaultMessage: 'Connection preferences',
        description: 'Title for the user settings teach bubble',
    },
});

/**
 * Styles
 */

const titleBarStylesFactory = (showBorder: boolean) =>
    makeStyles((theme) => ({
        root: {
            backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
            borderBottom: showBorder ? `1px solid ${theme.palette.neutralQuaternary}` : 0,
            height: '47px',
            // Disables text highlighting
            userSelect: 'none',
            width: '100%',
            zIndex: 1000,
        },
        alt: {
            backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
            borderBottom: showBorder ? `1px solid ${theme.palette.neutralQuaternary}` : 0,
            height: '47px',
            // Disables text highlighting
            userSelect: 'none',
            width: '100%',
            zIndex: 1000,
            justifyContent: 'center',
            display: 'flex',
        },
    }));

const useCommandBarStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
        height: '100%',
        // Note: using padding rather than paddingLeft to explicitly zero-out other paddings
        padding: '0 0 0 16px',
    },
}));

const useCommonCommandBarButtonStyles = makeStyles({
    root: {
        padding: '0',
        width: '48px',
    },
});

const useTeachingBubbleStyles = makeStyles({
    headline: {
        color: 'white',
    },
    subText: {
        color: 'white',
    },
});

/**
 * End Styles
 */

const titleBarItems: ICommandBarItemProps[] = [
    {
        key: 'title',
        onRender: () => <Title />,
    },
];

export const TitleBarComponent: React.FC<TitleBarComponentProps> = React.memo((props: TitleBarComponentProps) => {
    const {
        hasMultipleTenants,
        isDevMenuPanelOpen,
        isHelpMenuPanelOpen,
        isUserSettingsPanelOpen: isSettingsPanelOpen,
        isSignedIn,
        onDevMenuButtonClicked,
        onUserSettingsButtonClicked: onSettingsButtonClicked,
        onHelpMenuButtonClicked,
        statusForGetOrganization,
        statusForGetSignedInUser,
        statusForListTenants,
        devBoxCreateAbilityState,
        environmentCreateAbilityState,
        onDismiss,
        isSettingsMenuTeachBubbleDismissed,
        isConnectViaAppTeachingBubbleDismissed,
        hasUserConnectedTheFirstTime,
        isHomeInitialized,
        onSignInSubmitted,
        isScrolled,
        seenOrSkippedWelcomeTour,
    } = props;

    // Fluent hooks Creates unique ID for settings menu globally
    const settingsMenuButtonId = useId('settings-menu');

    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    // HACK: to correctly display progress bar, this non-subapp component needs awareness of subapp loading. May want to
    // think about how to detangle this in the future, but low priority for now since there's only one subapp.
    const showBorder =
        !isSignedIn ||
        (devBoxCreateAbilityState.createAbility !== ActionAbility.Unknown &&
            environmentCreateAbilityState.createAbility !== ActionAbility.Unknown);

    // Style hooks
    const commandBarButtonStyles = useCommonCommandBarButtonStyles();
    const commandBarStyles = useCommandBarStyles();
    const useTitleBarStyles = titleBarStylesFactory(showBorder);
    const titleBarStyles = useTitleBarStyles();
    const teachingBubbleStyles = useTeachingBubbleStyles();

    // Memoized data
    const farItems = React.useMemo(
        () =>
            getFarItems(
                commandBarButtonStyles,
                formatMessage(messages.devMenuButtonAriaLabel),
                hasMultipleTenants,
                formatMessage(messages.helpMenuButtonLabel),
                isDevMenuPanelOpen,
                isHelpMenuPanelOpen,
                isSettingsPanelOpen,
                isSignedIn,
                formatMessage(messages.meControlButtonLabel),
                onDevMenuButtonClicked,
                onHelpMenuButtonClicked,
                onSettingsButtonClicked,
                statusForGetSignedInUser,
                statusForGetOrganization,
                formatMessage(messages.buttonDefaultText),
                statusForListTenants,
                formatMessage(messages.userSettingsButtonAriaLabel),
                settingsMenuButtonId
            ),
        [
            commandBarButtonStyles,
            formatMessage,
            hasMultipleTenants,
            isDevMenuPanelOpen,
            isSettingsPanelOpen,
            isHelpMenuPanelOpen,
            isSignedIn,
            onDevMenuButtonClicked,
            onHelpMenuButtonClicked,
            onSettingsButtonClicked,
            statusForGetOrganization,
            statusForGetSignedInUser,
            statusForListTenants,
        ]
    );

    if (isScrolled) {
        return (
            <header className={titleBarStyles.alt} role="banner">
                <SignInButton onSignInSubmitted={onSignInSubmitted} />
            </header>
        );
    }

    let shouldShowTeachingBubble = false;

    /** Settings menu teach bubble should not be already dismissed + user must connect first time + home must be initialized + 
    connect via app teach bubble should be dismissed + welcome tour should be seen or skipped **/

    if (isFeatureFlagEnabled(FeatureFlagName.EnableWelcomeTour)) {
        shouldShowTeachingBubble =
            !isSettingsMenuTeachBubbleDismissed &&
            hasUserConnectedTheFirstTime &&
            isHomeInitialized &&
            isConnectViaAppTeachingBubbleDismissed &&
            seenOrSkippedWelcomeTour;
    } else {
        // dont consider welcome tour status for teaching bubble if welcome tour is disabled
        shouldShowTeachingBubble =
            !isSettingsMenuTeachBubbleDismissed &&
            hasUserConnectedTheFirstTime &&
            isHomeInitialized &&
            isConnectViaAppTeachingBubbleDismissed;
    }

    // TODO: Task 2292216: Migrate Teaching Bubble to Popovers
    return (
        <header className={titleBarStyles.root} role="banner">
            <CommandBar farItems={farItems} items={titleBarItems} styles={commandBarStyles} />
            {shouldShowTeachingBubble && (
                <TeachingBubble
                    target={`#${settingsMenuButtonId}`}
                    onDismiss={onDismiss}
                    headline={formatMessage(messages.userSettingsTeachBubbleTitle)}
                    hasCloseButton
                    isWide
                    styles={teachingBubbleStyles}
                >
                    <FormattedMessage
                        id="TitleBar_UserSettingsTeachBubble_Content"
                        defaultMessage="Your dev box connection preferences can be changed at any time in the settings menu."
                        description="Content for the user settings teach bubble"
                    />
                </TeachingBubble>
            )}
        </header>
    );
});

type TitleBarContainerProps = {
    isScrolled?: boolean;
};

export const TitleBarContainer: React.FC<TitleBarContainerProps> = (props) => {
    const { isScrolled } = props;

    // Application state hooks
    const hasMultipleTenants = useSelector(getHasMultipleTenants);
    const isSignedIn = useSelector(getIsSignedIn);
    const statusForGetOrganization = useSelector(getStatusForGetOrganization);
    const statusForGetSignedInUser = useSelector(getStatusForGetSignedInUser);
    const statusForListTenants = useSelector(getStatusForListTenants);
    const devBoxCreateAbilityState = useSelector(getDevBoxCreateAbilityState);
    const environmentCreateAbilityState = useSelector(getEnvironmentCreateAbilityState);
    const isSettingsMenuTeachBubbleDismissed = useSelector(getIsSettingsMenuTeachBubbleDismissed);
    const isConnectViaAppTeachBubbleDismissed = useSelector(getIsConnectViaAppTeachBubbleDismissed);
    const seenOrSkippedWelcomeTour = useSelector(getWelcomeTourSeenStatus);
    const hasUserConnectedTheFirstTime = !!useSelector(getRemoteAppOptionsSelector);
    const isHomeInitialized = useSelector(getIsHomeInitialized);

    const dismissContent = useActionCreator(dismissContentActionCreator);
    const onSignInSubmitted = useActionCreator(signIn);

    const onDismiss = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.SettingsMenuTeachableBubble });
    }, [dismissContent]);

    // Context hooks
    const { isOpen: isDevMenuPanelOpen, openSurface: onDevMenuButtonClicked } = useDevMenuPanelContext();
    const { isOpen: isHelpMenuPanelOpen, openSurface: onHelpMenuButtonClicked } = useHelpMenuPanelContext();
    const { isOpen: isUserSettingsPanelOpen, openSurface: onUserSettingsButtonClicked } = useUserSettingsPanelContext();

    return (
        <TitleBarComponent
            hasMultipleTenants={hasMultipleTenants}
            isDevMenuPanelOpen={isDevMenuPanelOpen}
            isUserSettingsPanelOpen={isUserSettingsPanelOpen}
            isHelpMenuPanelOpen={isHelpMenuPanelOpen}
            isSignedIn={isSignedIn}
            onDevMenuButtonClicked={onDevMenuButtonClicked}
            onUserSettingsButtonClicked={onUserSettingsButtonClicked}
            onHelpMenuButtonClicked={onHelpMenuButtonClicked}
            statusForGetOrganization={statusForGetOrganization}
            statusForGetSignedInUser={statusForGetSignedInUser}
            statusForListTenants={statusForListTenants}
            devBoxCreateAbilityState={devBoxCreateAbilityState}
            environmentCreateAbilityState={environmentCreateAbilityState}
            onDismiss={onDismiss}
            isHomeInitialized={isHomeInitialized}
            isSettingsMenuTeachBubbleDismissed={isSettingsMenuTeachBubbleDismissed}
            isConnectViaAppTeachingBubbleDismissed={isConnectViaAppTeachBubbleDismissed}
            hasUserConnectedTheFirstTime={hasUserConnectedTheFirstTime}
            onSignInSubmitted={onSignInSubmitted}
            isScrolled={isScrolled}
            seenOrSkippedWelcomeTour={seenOrSkippedWelcomeTour}
        />
    );
};

export default TitleBarContainer;
