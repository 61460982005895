import { Button, FluentProvider, makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';

type UserSettingsPanelFooterProps = {
    isSubmitDisabled?: boolean;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
};

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'UserSettingsFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for "Cancel" button in user settings form',
    },
    saveButtonAriaLabel: {
        id: 'UserSettingsFooter_SaveButton_AriaLabel',
        defaultMessage: 'Save',
        description: 'Aria label for "Save" button in user settings form',
    },
});

/**
 * Styles
 */

const useButtonContainerStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
        height: '100%',
    },
});

/**
 * END Styles
 */

export const UserSettingsPanelFooter: React.FC<UserSettingsPanelFooterProps> = (
    props: UserSettingsPanelFooterProps
) => {
    const { isSubmitDisabled, onCancelClicked, onSubmitClicked } = props;

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const horizontalStackStyles = useHorizontalStackStyles();
    const buttonContainerStyles = useButtonContainerStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <FluentProvider theme={theme}>
            <div className={mergeClasses(horizontalStackStyles.root, buttonContainerStyles.root)}>
                <div className={horizontalStackStyles.item}>
                    <Button
                        aria-label={formatMessage(messages.saveButtonAriaLabel)}
                        disabled={isSubmitDisabled}
                        onClick={onSubmitClicked}
                        appearance="primary"
                    >
                        <FormattedMessage
                            id="UserSettingsFooter_SaveButton_Text"
                            defaultMessage="Save"
                            description='Text for "Save" button in user settings form'
                        />
                    </Button>
                </div>
                <div className={horizontalStackStyles.item}>
                    <Button aria-label={formatMessage(messages.cancelButtonAriaLabel)} onClick={onCancelClicked}>
                        <FormattedMessage
                            id="UserSettingsFooter_CancelButton_Text"
                            defaultMessage="Cancel"
                            description='Text for "Cancel" button in user settings form'
                        />
                    </Button>
                </div>
            </div>
        </FluentProvider>
    );
};
