import {
    Dropdown as FluentDropdown,
    ICalloutProps,
    IDropdownOption,
    IDropdownProps,
    SelectableOptionMenuItemType,
    makeStyles,
    mergeStyleSets,
} from '@fluentui/react';
import * as React from 'react';
import { RequiredProperty } from '../../../../types/required-property';
import { UnionMap } from '../../../../types/union-map';
import { DefaultRenderFunction } from '../../types';
import { ReadOnlyOptionControl } from '../read-only-option-control';
import { InputShimmer } from '../shimmered-field';

/* eslint-disable @typescript-eslint/ban-types */
// Justification: Record and string map types are not generic enough
export type DropdownValue = object | string | number;
/* eslint-enable @typescript-eslint/ban-types */

export type DropdownValueRenderFunction<TValue, TData> = (value: TValue, data?: TData) => JSX.Element;

export type DropdownDividerOption = Pick<RequiredProperty<IDropdownOption, 'itemType'>, 'itemType' | 'key'> & {
    text: '';
    itemType: SelectableOptionMenuItemType.Divider;
};

export type DropdownHeaderOption = Pick<RequiredProperty<IDropdownOption, 'itemType'>, 'itemType' | 'key' | 'text'> & {
    itemType: SelectableOptionMenuItemType.Header;
};

export type DropdownValueOption<TValue extends DropdownValue, TData = undefined> = Omit<
    IDropdownOption<TData>,
    'isSelected' | 'selected'
> & {
    value: TValue;
    key: string;
    itemType?: SelectableOptionMenuItemType.Normal;
};

export type DropdownOption<TValue extends DropdownValue, TData = undefined> =
    | DropdownValueOption<TValue, TData>
    | DropdownDividerOption
    | DropdownHeaderOption;

export type AutoSelectMode = 'Always' | 'WhenOnlyHasOneOption' | 'Never';

export const AutoSelectMode: UnionMap<AutoSelectMode> = {
    Always: 'Always',
    WhenOnlyHasOneOption: 'WhenOnlyHasOneOption',
    Never: 'Never',
};

export const isDropdownDividerOption = (value: unknown): value is DropdownDividerOption => {
    const dividerOption = value as DropdownDividerOption;

    return dividerOption !== undefined && dividerOption.itemType === SelectableOptionMenuItemType.Divider;
};

export const isDropdownHeaderOption = (value: unknown): value is DropdownHeaderOption => {
    const headerOption = value as DropdownHeaderOption;

    return headerOption !== undefined && headerOption.itemType === SelectableOptionMenuItemType.Header;
};

export interface DropdownProps<TValue extends DropdownValue, TData = undefined>
    extends Omit<
        IDropdownProps,
        | 'options'
        | 'onChange'
        | 'onChanged'
        | 'onRenderOption'
        | 'onRenderTitle'
        | 'onRenderPlaceholder'
        | 'multiSelect'
        | 'defaultSelectedKeys'
        | 'defaultSelectedKey'
        | 'selectedKeys'
        | 'selectedKey'
        | 'multiSelectDelimiter'
    > {
    options: DropdownOption<TValue, TData>[];
    selectedKey: string | undefined;
    /** If set to `Always`, will auto select the first value in options. If set to `WhenOnlyHasOneOption`, will only auto select when `options.length === 1`. If set to `Never`, will never auto select (defaults to `Never`). */
    autoSelectMode?: AutoSelectMode;
    onChange: (value: TValue | undefined) => void;
    /** Optional custom renderer for normal and header options only. Use `onRenderItem` to control rendering for separators as well. */
    onRenderOption?: DropdownValueRenderFunction<TValue, TData>;
    /** Our equivalent of Fluent's onRenderTitle: Optional custom renderer for selected option displayed in input. */
    onRenderValue?: DropdownValueRenderFunction<TValue, TData>;
    onRenderPlaceholder?: (placeholder: string) => JSX.Element;
    isLoading?: boolean;
}

/**
 * Styles
 */

const useCalloutStyles = makeStyles({
    calloutMain: {
        colorScheme: 'dark',
    },
});

/**
 * END Styles
 */

/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Justification: arrow functions have limitations with the use of generics when using JSX
export function Dropdown<TValue extends DropdownValue, TData = undefined>(
    props: DropdownProps<TValue, TData>
): ReturnType<React.FC<DropdownProps<TValue, TData>>> {
    /* eslint-enable prefer-arrow/prefer-arrow-functions */
    const {
        selectedKey,
        autoSelectMode,
        options,
        label,
        onChange,
        onRenderOption,
        onRenderValue,
        onRenderPlaceholder,
        onRenderList,
        calloutProps: providedCalloutProps,
        isLoading,
    } = props;

    // Style hooks
    const calloutStyles = useCalloutStyles();

    const calloutProps = React.useMemo((): ICalloutProps => {
        const { styles: providedStyles, directionalHintFixed: providedDirectionalHintFixed } =
            providedCalloutProps ?? {};

        const styles = mergeStyleSets(calloutStyles, providedStyles);
        const directionalHintFixed = providedDirectionalHintFixed === undefined ? true : providedDirectionalHintFixed;

        return {
            ...providedCalloutProps,
            styles,
            directionalHintFixed,
        };
    }, [providedCalloutProps, calloutStyles]);

    const selectedOption = React.useMemo((): DropdownValueOption<TValue, TData> | undefined => {
        return !selectedKey
            ? undefined
            : (options.find((option) => option.key === selectedKey) as DropdownValueOption<TValue, TData>);
    }, [selectedKey, options]);

    // wrapper for fluent's weird contract to a value based contract
    const fluentOnChange = React.useCallback(
        (_event: React.FormEvent<HTMLDivElement>, fluentOption?: IDropdownOption, _index?: number) => {
            const option = fluentOption as DropdownValueOption<TValue, TData>;
            if (!option) {
                onChange(undefined);
                return;
            }

            onChange(option.value);
        },
        [onChange]
    );

    // wrapper for fluent's dropdown weirdness to return our dropdown option instead for custom value rendering
    const fluentOnRenderOption = React.useCallback(
        (props?: IDropdownOption, defaultRender?: DefaultRenderFunction<IDropdownOption>): JSX.Element | null => {
            if (!onRenderOption || isDropdownDividerOption(props) || isDropdownHeaderOption(props)) {
                return defaultRender ? defaultRender(props) : null;
            }

            if (!props) {
                return null;
            }

            const option = props as DropdownValueOption<TValue, TData>;
            const { value, data } = option;

            return onRenderOption(value, data);
        },
        [onRenderOption]
    );

    // wrapper for fluent's dropdown weirdness to return our dropdown options instead for custom title rendering
    const fluentOnRenderTitle = React.useCallback(
        (
            props?: IDropdownOption[],
            defaultRender?: (props?: IDropdownOption[]) => JSX.Element | null
        ): JSX.Element | null => {
            if (isLoading) {
                return <InputShimmer />;
            }

            if (!onRenderValue) {
                return defaultRender?.(props) ?? null;
            }

            if (!props || props.length === 0) {
                return null;
            }

            // props is a list because fluent's typedef is intended to work for single- and multi-select
            // We can take the first item in props because only one item will be selected in this single-select control
            const { value, data } = props[0] as DropdownValueOption<TValue, TData>;

            return onRenderValue(value, data);
        },
        [onRenderValue, isLoading]
    );

    // wrapper for fluent's weird contract for rendering placeholder text
    const fluentOnRenderPlaceholder = React.useCallback(
        (props?: IDropdownProps, defaultRender?: DefaultRenderFunction<IDropdownProps>): JSX.Element | null => {
            if (isLoading) {
                return <InputShimmer />;
            }

            if (!onRenderPlaceholder) {
                return defaultRender?.(props) ?? null;
            }

            if (!props) {
                return null;
            }

            const { placeholder } = props;
            if (!placeholder) {
                return null;
            }

            return onRenderPlaceholder(placeholder);
        },
        [onRenderPlaceholder, isLoading]
    );

    // Effect to select the first option if none is selected and autoSelectMode === 'Always'
    // Will re-fire when `options` changes
    React.useEffect(() => {
        if (
            !selectedOption &&
            ((autoSelectMode === AutoSelectMode.Always && options.length > 0) ||
                (autoSelectMode === AutoSelectMode.WhenOnlyHasOneOption && options.length === 1))
        ) {
            const firstValueOption = options.find(
                (option) => !isDropdownDividerOption(option) && !isDropdownHeaderOption(option)
            ) as DropdownValueOption<TValue, TData>;
            onChange(firstValueOption.value);
        }
    }, [autoSelectMode, options, selectedOption]);

    // Effect to clear out selection if the selected option is no longer available in the options list
    React.useEffect(() => {
        if (!!selectedKey && !selectedOption) {
            onChange(undefined);
        }
    }, [selectedOption, selectedKey]);

    // If dropdown has only one option which is not a divider and not a header and auto-select = true, display option as read only
    if (
        (autoSelectMode === AutoSelectMode.Always || autoSelectMode === AutoSelectMode.WhenOnlyHasOneOption) &&
        options.length === 1 &&
        selectedOption !== undefined &&
        !isDropdownDividerOption(selectedOption) &&
        !isDropdownHeaderOption(selectedOption)
    ) {
        return <ReadOnlyOptionControl label={label} selectedOption={selectedOption} onRenderOption={onRenderOption} />;
    }

    return (
        <FluentDropdown
            {...props}
            multiSelect={false}
            onChange={fluentOnChange}
            onRenderOption={fluentOnRenderOption}
            onRenderTitle={fluentOnRenderTitle}
            onRenderPlaceholder={fluentOnRenderPlaceholder}
            calloutProps={calloutProps}
            onRenderList={onRenderList}
        />
    );
}
