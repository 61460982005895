import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useChangeEnvironmentExpirationDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { Status } from '../../../models/common';
import { Environment, EnvironmentUpdateProperties } from '../../../models/environment';
import { updateEnvironment } from '../../../redux/actions/environment/environment-action-creators';
import { getStatusForUpdateEnvironment } from '../../../redux/selector/environment-selectors';
import { ReturnVoid } from '../../../types/return-void';
import ChangeEnvironmentExpirationDialogForm from './change-environment-expiration-dialog-form';

export interface ChangeEnvironmentExpirationDialogComponentProps extends ChangeEnvironmentExpirationDialogProps {
    onDismiss: () => void;
    onUpdateEnvironment: ReturnVoid<typeof updateEnvironment>;
    statusForUpdateEnvironment: Status;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'ChangeEnvironmentExpirationDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss dialog',
        description: "Aria label for the change environment expiration dialog's close button",
    },
    cancelButtonText: {
        id: 'ChangeEnvironmentExpirationDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the redeploy dialog and perform no operation',
    },
});

/**
 * Styles
 */

const dialogWidth = '569px';
const dialogStyle: React.CSSProperties = {
    width: dialogWidth,
};

/**
 * END Styles
 */

export const ChangeEnvironmentExpirationDialogComponent: React.FC<ChangeEnvironmentExpirationDialogComponentProps> = (
    props: ChangeEnvironmentExpirationDialogComponentProps
) => {
    const { environment, open, onDismiss, onUpdateEnvironment, statusForUpdateEnvironment } = props;
    const { name } = environment;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Memoized data
    const values = React.useMemo(() => ({ name }), [name]);

    // Callback hooks
    const onSubmit = React.useCallback(
        (id: string, properties: EnvironmentUpdateProperties) => {
            onUpdateEnvironment({ id, ...properties });
        },
        [onUpdateEnvironment]
    );

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                <DialogSurface style={dialogStyle}>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <FormattedMessage
                                id="ChangeEnvironmentExpirationDialog_Header_Text"
                                defaultMessage="Change {name} expiration"
                                description="Title of the change expiration dialog. {name} should not be localized."
                                values={values}
                            />
                        </DialogTitle>
                        <ChangeEnvironmentExpirationDialogForm
                            environment={environment}
                            onDismiss={onDismiss}
                            onSubmit={onSubmit}
                            status={statusForUpdateEnvironment}
                        />
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export interface ChangeEnvironmentExpirationDialogProps {
    open?: boolean;
    onDismiss: () => void;
    environment: Environment;
}

export const ChangeEnvironmentExpirationDialogContainer: React.FC<ChangeEnvironmentExpirationDialogProps> = (
    props: ChangeEnvironmentExpirationDialogProps
) => {
    //Application state hooks
    const statusForUpdateEnvironment = useSelector(getStatusForUpdateEnvironment);

    // Action hooks
    const onUpdateEnvironment = useActionCreator(updateEnvironment);

    return (
        <ChangeEnvironmentExpirationDialogComponent
            onUpdateEnvironment={onUpdateEnvironment}
            statusForUpdateEnvironment={statusForUpdateEnvironment}
            {...props}
        />
    );
};

export const ChangeEnvironmentExpirationDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useChangeEnvironmentExpirationDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <ChangeEnvironmentExpirationDialogContainer {...properties} open={isOpen} onDismiss={closeSurface} />;
};
