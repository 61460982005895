import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHorizontalStackStyles, useStackStyles } from '../../../../../../themes/styles/flexbox-styles';
import { removeSpaces } from '../../../../../../utilities/string';
import { SizeViewModel } from '../../../../../add-dev-box-panel/models';

interface SizeDropdownItemProps {
    size: SizeViewModel;
}

/**
 * Styles
 */

const useSizeMetaDataStyles = makeStyles({
    root: {
        gap: '10px',
    },
});

/**
 * End Styles
 */

export const SizeDropdownItem: React.FC<SizeDropdownItemProps> = (props: SizeDropdownItemProps) => {
    const { size } = props;
    const { cpuCount, memoryInGb, diskSizeInGb, id, isDuplicateSize } = size;

    // Intl hooks
    const { formatNumber } = useIntl();

    // Style hooks
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const poolMetadataStyles = useSizeMetaDataStyles();

    const duplicateSizeValues = React.useMemo(() => ({ poolName: id }), [id]);

    return (
        <div className={stackStyles.root}>
            <div className={stackStyles.item}>
                <div className={mergeClasses(horizontalStackStyles.root, poolMetadataStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxSizeDropdownItem_CpuCount_Text"
                            defaultMessage="{cpuCount} vCPU"
                            description="Text informing user of how many cores are assigned to a dev box. {cpuCount} should not be localized."
                            values={{ cpuCount: formatNumber(cpuCount) }}
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxSizeDropdownItem_MemoryInGb_Text"
                            defaultMessage="{memoryInGb} RAM"
                            description="Text informing user of how much memory a dev box has. {memoryInGb} should not be localized."
                            values={{
                                // Note: using removeSpaces to convert "__ GB" -> "__GB"
                                memoryInGb: removeSpaces(formatNumber(memoryInGb, { style: 'unit', unit: 'gigabyte' })),
                            }}
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxSizeDropdownItem_DiskSizeInGb_Text"
                            defaultMessage="{diskSizeInGb} Storage"
                            description="Text informing user of how much storage a dev box has. {diskSizeInGb} should not be localized."
                            values={{
                                // Note: using removeSpaces to convert "__ GB" -> "__GB"
                                diskSizeInGb: removeSpaces(
                                    formatNumber(diskSizeInGb, { style: 'unit', unit: 'gigabyte' })
                                ),
                            }}
                        />
                    </div>
                    {isDuplicateSize && (
                        <div className={horizontalStackStyles.item}>
                            <FormattedMessage
                                id="AddDevBoxSizeDropdownItem_PoolName_Text"
                                defaultMessage="({poolName})"
                                description="Text informing user what pool the size is from if there are duplicate sizes. Do not localize {poolName}."
                                values={duplicateSizeValues}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SizeDropdownItem;
