import { Dropdown, DropdownProps } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RestoreSnapshotDropdownOption } from './model';
import RestoreSnapshotDropdownItem from './restore-snapshot-dropdown-item';

export interface RestoreSnapshotDropdownProps extends DropdownProps {
    options?: RestoreSnapshotDropdownOption[];
}

const messages = defineMessages({
    dropdownAriaLabel: {
        id: 'RestoreSnapshotDialog_Dropdown_AriaLabel',
        defaultMessage: 'Please choose the snapshot to restore',
        description: 'Aria label for dropdown in the "Snapshot Restoration" dialog',
    },
    dropdownPlaceholder: {
        id: 'RestoreSnapshotDialog_Dropdown_Placeholder',
        defaultMessage: 'Select a restore point',
        description: 'Placeholder for dropdown in the "Snapshot Restoration" dialog',
    },
});

/**
 * Styles
 */

const dropdownStyle: React.CSSProperties = {
    width: '100%',
};

/**
 * END Styles
 */

export const RestoreSnapshotDropdown: React.FC<RestoreSnapshotDropdownProps> = (
    props: RestoreSnapshotDropdownProps
) => {
    // Intl hooks
    const { formatMessage } = useIntl();

    const { options } = props;

    return (
        <Dropdown
            style={dropdownStyle}
            placeholder={formatMessage(messages.dropdownPlaceholder)}
            aria-label={formatMessage(messages.dropdownAriaLabel)}
            {...props}
        >
            {options?.map((option) => (
                <RestoreSnapshotDropdownItem key={option.snapshotId} snapshot={option} />
            ))}
        </Dropdown>
    );
};

export default RestoreSnapshotDropdown;
