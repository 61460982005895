import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagName } from '../../constants/features';
import { FeatureFlagSet } from '../../models/features';
import { StoreState } from '../store/store-state';
import { StoreStateSelector } from './common';

/**
 * Basic selectors
 */

export const getEnabledFeatures: StoreStateSelector<FeatureFlagSet> = (store) =>
    store.featuresStore.data.enabledFeatures;

export const getFrozenFeatures: StoreStateSelector<FeatureFlagSet> = (store) => store.featuresStore.data.frozenFeatures;

export const getIsFeatureEnabled = (storeState: StoreState, feature: FeatureFlagName): boolean =>
    storeState.featuresStore.data.enabledFeatures[feature];

/**
 * Composed selectors
 */

export const getEnabledFeatureFlagNames: StoreStateSelector<FeatureFlagName[]> = createSelector(
    [getEnabledFeatures],
    (enabledFeatures) => {
        const featureFlagNames = Object.keys(enabledFeatures) as FeatureFlagName[];

        return featureFlagNames.filter((feature) => enabledFeatures[feature]);
    }
);
