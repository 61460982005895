import {
    Card,
    CardFooter,
    CardHeader,
    Divider,
    makeStyles,
    mergeClasses,
    Radio,
    RadioGroup,
    RadioGroupOnChangeData,
} from '@fluentui/react-components';
import * as React from 'react';
import { Field } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { RemoteAppOption } from '../../../types/user-settings';
import { UserSettingsFormFieldName } from '../models';
import ConnectViaAppDownloadButton from './connect-via-app-download-button';

const messages = defineMessages({
    connectViaWindowsAppText: {
        id: 'SettingsFormFieldGroup_ConnectViaWindowsApp_Text',
        defaultMessage: 'Connect via Windows App',
        description: 'Label for choice group option "Connect via Windows App"',
    },
    connectViaRemoteDesktopLegacyText: {
        id: 'SettingsFormFieldGroup_ConnectViaRemoteDesktopLegacy_Text',
        defaultMessage: 'Connect via Remote Desktop (legacy)',
        description: 'Label for choice group option "Connect via Remote Desktop (legacy)"',
    },
    useSingleMonitorText: {
        id: 'SettingsFormFieldGroup_UseSingleMonitor_Text',
        defaultMessage: 'Use a single monitor',
        description: 'Label for choice group option "Use a single monitor"',
    },
    useMultiMonitorText: {
        id: 'SettingsFormFieldGroup_UseMultiMonitor_Text',
        defaultMessage: 'Use multiple monitors',
        description: 'Label for choice group option "Use a multiple monitors"',
    },
});

/**
 * Styles
 */

const useFormStyles = makeStyles({
    root: {
        gap: '16px',
    },
});

/**
 * END Styles
 */

export const ConnectViaAppFormFieldGroup: React.FC = () => {
    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const formStyles = useFormStyles();

    // Intl hooks
    const { formatMessage } = useIntl();
    const [preferredRemoteApp, setPreferredRemoteApp] = React.useState(RemoteAppOption.useWindowsApp);

    return (
        <div className={mergeClasses(stackStyles.root, formStyles.root)}>
            <div className={stackStyles.item}>
                <Field name={UserSettingsFormFieldName.preferredRemoteApp}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange } = input;

                        const onPreferredRemoteAppChange = React.useCallback(
                            (_event: React.FormEvent<HTMLElement> | undefined, data: RadioGroupOnChangeData) => {
                                onChange(data.value);
                                setPreferredRemoteApp(data.value as RemoteAppOption);
                            },
                            [onChange]
                        );

                        return (
                            <RadioGroup
                                onChange={onPreferredRemoteAppChange}
                                defaultValue={RemoteAppOption.useWindowsApp}
                            >
                                <Radio
                                    value={RemoteAppOption.useWindowsApp}
                                    label={formatMessage(messages.connectViaWindowsAppText)}
                                />
                                <Radio
                                    value={RemoteAppOption.useAvd}
                                    label={formatMessage(messages.connectViaRemoteDesktopLegacyText)}
                                />
                            </RadioGroup>
                        );
                    }}
                </Field>
            </div>
            <div className={stackStyles.item}>
                <Card appearance="filled-alternative">
                    <CardHeader
                        description={
                            preferredRemoteApp === RemoteAppOption.useWindowsApp ? (
                                <FormattedMessage
                                    id="WindowsApp_DownloadCardSubtitle_Text"
                                    defaultMessage="If the Windows App is not installed, get it from the Microsoft Store."
                                    description="Subtitle for the download option within the 'connect via windows app' dialog"
                                />
                            ) : (
                                <FormattedMessage
                                    id="RemoteDesktop_DownloadCardSubtitle_Text"
                                    defaultMessage="If the Remote Desktop is not installed, get it from the Microsoft Store"
                                    description="Subtitle for the download option within the 'connect via windows app' dialog"
                                />
                            )
                        }
                    />
                    <CardFooter>
                        <ConnectViaAppDownloadButton remoteAppOption={preferredRemoteApp} />
                    </CardFooter>
                </Card>
            </div>
            <Divider />
            <div className={stackStyles.item}>
                <Field name={UserSettingsFormFieldName.useMultiMonitor}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange } = input;

                        const onMultiMonitorChange = React.useCallback(
                            (_event: React.FormEvent<HTMLElement> | undefined, data: RadioGroupOnChangeData) => {
                                const selectedKey = data.value === 'true';
                                onChange(selectedKey);
                            },
                            [onChange]
                        );

                        return (
                            <RadioGroup onChange={onMultiMonitorChange} defaultValue="false">
                                <Radio value="false" label={formatMessage(messages.useSingleMonitorText)} />
                                <Radio value="true" label={formatMessage(messages.useMultiMonitorText)} />
                            </RadioGroup>
                        );
                    }}
                </Field>
            </div>
        </div>
    );
};
