import { CheckboxOnChangeData, Checkbox as FluentCheckbox } from '@fluentui/react-components';
import React from 'react';

interface CheckboxProps {
    value: boolean;
    onChangeHandler: (value: boolean | 'mixed') => void;
    label: string;
    checkboxLabel: string;
    checkboxAriaLabel: string;
    disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const { value, onChangeHandler, checkboxLabel, checkboxAriaLabel, disabled } = props;

    const environmentExpirationEnabledOnChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLElement>, data: CheckboxOnChangeData) => {
            onChangeHandler(data.checked);
        },
        [onChangeHandler]
    );

    return (
        <FluentCheckbox
            label={checkboxLabel}
            aria-label={checkboxAriaLabel}
            onChange={environmentExpirationEnabledOnChange}
            checked={value}
            disabled={disabled}
        />
    );
};

export default Checkbox;
