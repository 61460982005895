import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { CssSelector } from '../themes/constants/css-selectors';
import { StackStyle, useHorizontalStackStyles } from '../themes/styles/flexbox-styles';
import { PortalLayout, PortalLayoutProps } from './portal-layout';

interface Props extends PortalLayoutProps {
    customStyles?: { [key in StackStyle]: string };
    hideActionBar?: boolean;
}

export type CenteredPortalLayoutProps = Props;

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexGrow: '1',
    },
});

const useDefaultContentStyles = makeStyles({
    root: {
        height: 'fit-content',
        textAlign: 'center',
        // Note: ensure that width for ScreenSizeSmallAndBelow is kept in sync with this padding value.
        padding: '0 58px',
        paddingBottom: '40px',
        paddingTop: '40px',

        [CssSelector.ScreenSizeSmallAndBelow]: {
            // At screen sizes small and below, we switch from fixed width + variable gutters mode to variable
            // width + fixed gutters mode. To give a defined width to descendant elements, we calculate the width
            // to be the total viewport width minus the total width of the gutters. That total gutter width should
            // be kept in sync with the padding defined for this element.
            width: 'calc(100vw - 116px)',
        },

        [CssSelector.ScreenSizeMedium]: {
            maxWidth: '524px',
        },

        [CssSelector.ScreenSizeBetweenLargeAndXLarge]: {
            maxWidth: '816px',
        },

        [CssSelector.ScreenSizeXXLarge]: {
            maxWidth: '864px',
        },
    },
});

/**
 * End Styles
 */

export const CenteredPortalLayout: React.FC<Props> = (props) => {
    const { id, showFooter, children, hideActionBar, customStyles } = props;

    const containerStyles = useContainerStyles();
    const defaultContentStyles = useDefaultContentStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <PortalLayout id={id} showFooter={showFooter} hideActionBar={hideActionBar}>
            <div className={mergeClasses(horizontalStackStyles.root, containerStyles.root, customStyles?.root)}>
                <div
                    className={mergeClasses(horizontalStackStyles.item, defaultContentStyles.root, customStyles?.item)}
                >
                    {children}
                </div>
            </div>
        </PortalLayout>
    );
};

export default CenteredPortalLayout;
