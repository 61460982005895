import {
    AzureCoreFoundationsError as GeneratedAzureCoreFoundationsErrorBeta,
    AzureCoreFoundationsErrorResponse as GeneratedAzureCoreFoundationsErrorResponseBeta,
} from 'devcenter-internal-beta';
import {
    CloudErrorBody as GeneratedCloudErrorBodyStable,
    CloudError as GeneratedCloudErrorStable,
    OperationStatus,
    OperationStatusError,
} from 'devcenter-internal-stable';
import { isString } from '../../utilities/string';

// Note: this type is used in both Control Plane and Data Plane contexts, even though the type originates from the Data
// Plane SDK. The types are indeed the same between the two contexts and our bet is things will stay that way. If the
// two definitions drift, we should create separate types for Control Plane and Data Plane.
export type CloudErrorContract = GeneratedCloudErrorStable;
export type CloudErrorBodyContract = GeneratedCloudErrorBodyStable;

export type AzureCoreFoundationsErrorResponseContract = GeneratedAzureCoreFoundationsErrorResponseBeta;
export type AzureCoreFoundationsErrorContract = GeneratedAzureCoreFoundationsErrorBeta;

export type OperationStatusContract = OperationStatus;
export type OperationStatusErrorContract = OperationStatusError;

interface LongRunningOperationResponseHeaders {
    operationLocation?: string;
}

export type LongRunningOperationResponseContract = OperationStatusContract & LongRunningOperationResponseHeaders;

export const isCloudErrorContract = (value: unknown): value is CloudErrorContract => {
    const cloudError = value as CloudErrorContract;

    if (!cloudError.error) {
        return false;
    }

    const error = cloudError.error as CloudErrorBodyContract;

    return isString(error.code) && isString(error.message);
};
