import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    FluentProvider,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useConfirmationDialogContext } from '../../../hooks/context/dialogs';
import { useCurrentFluent2Theme } from '../../../hooks/styling';

export interface ConfirmationDialogComponentProps {
    open?: boolean;
    onDismiss: () => void;
    onPrimaryButtonSubmitted: () => void;
    primaryButtonAriaLabel?: string;
    primaryButtonText: string;
    subText: string;
    title: string;
}

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'ConfirmationDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel the "delete" operation',
        description: 'Aria label for the cancel button to dismiss the dialog and perform no operation',
    },
    cancelButtonText: {
        id: 'ConfirmationDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the delete dialog and perform no operation',
    },
    closeButtonAriaLabel: {
        id: 'ConfirmationDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss the "Delete" dialog',
        description: "Aria label for the delete dialog's close button",
    },
});

const ConfirmationDialog: React.FC<ConfirmationDialogComponentProps> = (props: ConfirmationDialogComponentProps) => {
    const { open, onDismiss, onPrimaryButtonSubmitted, primaryButtonAriaLabel, primaryButtonText, subText, title } =
        props;

    // Theming hooks
    const theme = useCurrentFluent2Theme();

    // Intl hooks
    const { formatMessage } = useIntl();

    // Callback hooks
    const onPrimaryButtonClicked = React.useCallback(() => {
        onPrimaryButtonSubmitted();
        onDismiss();
    }, [onDismiss, onPrimaryButtonSubmitted]);

    return (
        <FluentProvider theme={theme}>
            <Dialog modalType="alert" open={open} onOpenChange={onDismiss}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={formatMessage(messages.closeButtonAriaLabel)}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            {title}
                        </DialogTitle>
                        <DialogContent>{subText}</DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                aria-label={primaryButtonAriaLabel}
                                onClick={onPrimaryButtonClicked}
                            >
                                {primaryButtonText}
                            </Button>
                            <DialogTrigger>
                                <Button
                                    appearance="secondary"
                                    aria-label={formatMessage(messages.cancelButtonAriaLabel)}
                                >
                                    <FormattedMessage
                                        id="ConfirmationDialog_CancelButton_Text"
                                        defaultMessage="Cancel"
                                        description="Cancel button to dismiss the delete dialog and perform no operation"
                                    />
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

export const ConfirmationDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useConfirmationDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <ConfirmationDialog {...properties} open={isOpen} onDismiss={closeSurface} />;
};

export default ConfirmationDialogContextWrapper;
