import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/action-creator';
import { useRedeployEnvironmentPanelContext } from '../../hooks/context/panels';
import { usePrevious } from '../../hooks/use-previous';
import { Status } from '../../models/common';
import {
    clearDeployEnvironmentFailure,
    deployEnvironment,
} from '../../redux/actions/environment/environment-action-creators';
import { isStatusInProgress, isStatusSuccessful, isStatusUnsuccessful } from '../../redux/selector/common';
import { getStatusForDeployEnvironment } from '../../redux/selector/environment-selectors';
import { useStackStyles } from '../../themes/styles/flexbox-styles';
import { ReturnVoid } from '../../types/return-void';
import { getProcessedParameterValues } from '../add-environment-panel/selectors';
import { FormPanel } from '../common/form-panel';
import { EnvironmentViewModel } from '../environment/models';
import { EnvironmentParametersPanelContent } from '../environment/parameters-field/parameters-field';
import { RedeployEnvironmentFormField, RedeployEnvironmentFormState } from './models';
import RedeployEnvironmentPanelFooter from './redeploy-environment-panel-footer';
import { getInitialParameterValuesForRedeploy } from './selectors';

export interface RedeployEnvironmentPanelProps {
    isOpen: boolean;
    onDismiss: () => void;
    onRedeployEnvironmentSubmitted: ReturnVoid<typeof deployEnvironment>;
    statusForDeployEnvironment: Status;
    environment: EnvironmentViewModel;
}

const messages = defineMessages({
    redeployEnvironmentPanelTitleText: {
        id: 'RedeployEnvironmentPanel_Title_Text',
        defaultMessage: 'Redeploy {environmentName}',
        description: 'Text for redeploy environment panel title',
    },
    redeployEnvironmentPanelCloseButtonAriaLabel: {
        id: 'RedeployEnvironmentPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close redeploy environment panel',
        description: 'Aria label for the redeploy environment panel close button label',
    },
});

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        gap: '16px',
    },
});

/**
 * End Styles
 */

export const RedeployEnvironmentPanelComponent: React.FC<RedeployEnvironmentPanelProps> = (
    props: RedeployEnvironmentPanelProps
) => {
    const { onDismiss, isOpen, onRedeployEnvironmentSubmitted, environment, statusForDeployEnvironment } = props;
    const { failure: failureForDeployEnvironment } = statusForDeployEnvironment ?? {};
    const { resource, environmentDefinition } = environment;
    const environmentDefinitionParameters = environmentDefinition?.parameters;
    const { environmentDefinitionName, parameters, uri } = resource;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const containerStyles = useContainerStyles();
    const stackStyles = useStackStyles();

    // Form
    const initialValues = React.useMemo<RedeployEnvironmentFormState>(
        () => ({
            parameterValues: getInitialParameterValuesForRedeploy(environmentDefinitionParameters, parameters),
        }),
        [environmentDefinitionParameters, parameters]
    );

    const onFormSubmit = useCallback(
        (formState: RedeployEnvironmentFormState) => {
            const { parameterValues } = formState;
            const processedParameterValues = getProcessedParameterValues(
                parameterValues,
                environmentDefinitionParameters
            );

            onRedeployEnvironmentSubmitted({
                environment: resource,
                id: uri,
                parameters: processedParameterValues,
                // Note: when using panel, any error on initial request will be shown in footer. In that case, we don't
                // want to route the error to the environment resource card.
                showPutErrorOnResource: false,
            });
        },
        [onRedeployEnvironmentSubmitted, uri, resource, environmentDefinitionParameters]
    );

    const isErrorOrFailedRedeployEnvironmentState = isStatusUnsuccessful(statusForDeployEnvironment);

    return (
        <Form<RedeployEnvironmentFormState> initialValues={initialValues} onSubmit={onFormSubmit}>
            {(formProps) => {
                const { form, valid, submitting } = formProps;
                const { submit } = form;

                const isSubmitting = submitting || isStatusInProgress(statusForDeployEnvironment);

                // detect when we should close ourselves
                const previousStatusForDeployEnvironment = usePrevious(statusForDeployEnvironment);
                React.useEffect(() => {
                    if (
                        isStatusInProgress(previousStatusForDeployEnvironment) &&
                        isStatusSuccessful(statusForDeployEnvironment)
                    ) {
                        onDismiss();
                    }
                }, [previousStatusForDeployEnvironment, statusForDeployEnvironment, onDismiss]);

                const onCancelClicked = React.useCallback(() => {
                    onDismiss();
                }, [onDismiss]);

                const onRenderFooterContent = React.useCallback(() => {
                    return (
                        <RedeployEnvironmentPanelFooter
                            failure={failureForDeployEnvironment}
                            isErrorOrFailedState={isErrorOrFailedRedeployEnvironmentState}
                            isSubmitting={isSubmitting}
                            isValid={valid}
                            onCancelClicked={onCancelClicked}
                            onSubmitClicked={submit}
                        />
                    );
                }, [
                    failureForDeployEnvironment,
                    isErrorOrFailedRedeployEnvironmentState,
                    isSubmitting,
                    valid,
                    onCancelClicked,
                    submit,
                ]);

                return (
                    <FormPanel
                        isOpen={isOpen}
                        headerText={formatMessage(messages.redeployEnvironmentPanelTitleText, {
                            environmentName: environment.resource.name,
                        })}
                        isLightDismiss={!isSubmitting}
                        onDismiss={isSubmitting ? undefined : onCancelClicked}
                        closeButtonAriaLabel={formatMessage(messages.redeployEnvironmentPanelCloseButtonAriaLabel)}
                        onRenderFooterContent={onRenderFooterContent}
                    >
                        <div className={mergeClasses(stackStyles.item, containerStyles.root)}>
                            <EnvironmentParametersPanelContent
                                name={RedeployEnvironmentFormField.ParameterValues}
                                isSubmitting={isSubmitting}
                                parameters={environmentDefinitionParameters}
                                description={environmentDefinition?.description}
                                environmentDefinitionName={environmentDefinitionName}
                            />
                        </div>
                    </FormPanel>
                );
            }}
        </Form>
    );
};

export const RedeployEnvironmentPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useRedeployEnvironmentPanelContext();

    // Application state hooks
    const statusForDeployEnvironment = useSelector(getStatusForDeployEnvironment);

    // Action hooks
    const onClearRedeployEnvironmentFailure = useActionCreator(clearDeployEnvironmentFailure);
    const onDeployEnvironment = useActionCreator(deployEnvironment);

    const onDismiss = React.useCallback(() => {
        closePanel();
        onClearRedeployEnvironmentFailure();
    }, [closePanel, onClearRedeployEnvironmentFailure]);

    return (
        <RedeployEnvironmentPanelComponent
            statusForDeployEnvironment={statusForDeployEnvironment}
            onDismiss={onDismiss}
            isOpen={isOpen}
            onRedeployEnvironmentSubmitted={onDeployEnvironment}
            {...properties}
        />
    );
};

export const RedeployEnvironmentPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useRedeployEnvironmentPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <RedeployEnvironmentPanelContainer />;
};

export default RedeployEnvironmentPanelContextWrapper;
