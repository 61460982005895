import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { DevBoxSnapshot } from '../../models/dev-box';
import { createInitializer } from '../../utilities/initializer';
import { snapshotAdapter } from '../adapters/snapshot-adapters';
import { StatusStore } from './status-store';

type StatusName = 'listSnapshots';

export interface SnapshotDataStore {
    snapshots: EntityState<Entity<DevBoxSnapshot>>;
}

export const SnapshotDataStore = createInitializer<SnapshotDataStore>({
    snapshots: snapshotAdapter.getInitialState(),
});

export type SnapshotStatusStore = StatusStore<StatusName>;

export const SnapshotStatusStore = createInitializer<SnapshotStatusStore>({
    listSnapshots: Status(),
});

export interface SnapshotStore {
    data: SnapshotDataStore;
    status: SnapshotStatusStore;
}

export const SnapshotStore = createInitializer<SnapshotStore>({
    data: SnapshotDataStore(),
    status: SnapshotStatusStore(),
});
