import { FontSizes, FontWeights, makeStyles } from '@fluentui/react';
import { Button, FluentProvider, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useCurrentFluent2Theme } from '../hooks/styling';
import { CssSelector } from '../themes/constants/css-selectors';
import { useCenteredStackStyles } from '../themes/styles/flexbox-styles';
import { getSemanticColor } from '../utilities/styles';

/**
 * Styles
 */

const useNoResourcesCreateCardContentStyles = makeStyles({
    root: {
        height: '100%',
    },
});

const useNoResourcesCreateCardStylesFactory = makeStyles((theme) => ({
    previewImage: {
        // objectFit: 'cover' downscales image without warping aspect ratio
        objectFit: 'cover',
        width: '100%',
    },
    root: {
        backgroundColor: getSemanticColor(theme, 'resourceCardBackground'),
        borderRadius: 4,
        // Using hidden overflow will ensure preview image doesn't cover rounded corners
        overflow: 'hidden',
        position: 'relative',
        height: 366,
        width: 256,
        justifyContent: 'center',

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },
    },
}));

const usePreviewStyles = makeStyles({
    root: {
        height: '134px',
        // Explicitly assigning relative positioning so status can overlay it
        position: 'relative',
        width: '100%',
    },
});

const useHeaderStyles = makeStyles({
    root: {
        fontSize: FontSizes.size20,
        fontWeight: FontWeights.semibold,
    },
});

const useBodyStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        textAlign: 'center',
        padding: '10px',
        color: '#D6D6D6',
    },
});

const useContainerStyles = makeStyles({
    root: {
        padding: '10px',
    },
});

const useButtonStyles = makeStyles({
    root: {
        padding: '5px',
    },
});

/**
 * End Styles
 */

interface NoResourceCreateCardPreviewImageProps {
    previewImageSrc: string;
}

const NoResourcesCreateCardPreviewImage: React.FC<NoResourceCreateCardPreviewImageProps> = React.memo(
    (props: NoResourceCreateCardPreviewImageProps) => {
        const { previewImageSrc } = props;

        // Style hooks
        const previewStyles = usePreviewStyles();
        const resourceCardStyles = useNoResourcesCreateCardStylesFactory();
        const stackStyles = useCenteredStackStyles();

        return (
            <div className={mergeClasses(stackStyles.item, previewStyles.root)}>
                <img alt={''} className={resourceCardStyles.previewImage} src={previewImageSrc} />
            </div>
        );
    }
);

interface NoResourceCreateCardProps {
    previewImageSrc: string;
    headerText: string;
    bodyText: string;
    buttonText: string;
    buttonAriaLabel?: string;
    onButtonClick: () => void;
}

export const NoResourcesCreateCard: React.FC<NoResourceCreateCardProps> = React.memo(
    (props: NoResourceCreateCardProps) => {
        const { previewImageSrc, headerText, bodyText, buttonText, buttonAriaLabel, onButtonClick } = props;

        // Theming hooks
        const theme = useCurrentFluent2Theme();

        // Style hooks
        const resourceCardStyles = useNoResourcesCreateCardStylesFactory();
        const resourceCardContentStyles = useNoResourcesCreateCardContentStyles();
        const centeredStackStyles = useCenteredStackStyles();
        const headerStyles = useHeaderStyles();
        const containerStyles = useContainerStyles();
        const bodyStyles = useBodyStyles();
        const buttonStyles = useButtonStyles();

        return (
            <FluentProvider theme={theme}>
                <div className={resourceCardStyles.root}>
                    <div className={mergeClasses(centeredStackStyles.root, resourceCardContentStyles.root)}>
                        <NoResourcesCreateCardPreviewImage previewImageSrc={previewImageSrc} />
                        <div className={mergeClasses(centeredStackStyles.root, containerStyles.root)}>
                            <div className={mergeClasses(centeredStackStyles.item, headerStyles.root)}>
                                {headerText}
                            </div>
                            <div className={mergeClasses(centeredStackStyles.item, bodyStyles.root)}>{bodyText}</div>
                            <div className={mergeClasses(buttonStyles.root)}>
                                <Button aria-label={buttonAriaLabel} onClick={onButtonClick}>
                                    {buttonText}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </FluentProvider>
        );
    }
);
