import { defineMessages } from 'react-intl';

export const IntrinsicTaskDescriptionMessages = defineMessages({
    UpdatePcName: {
        id: 'CustomizationSettings_IntrinsicTask_UpdatePcNameDescriptionText',
        defaultMessage: 'Append dev box name to PC name',
        description: 'Text for the description of the UpdatePcName intrinsic task',
    },
    EnableTeamsVdiOptimization: {
        id: 'CustomizationSettings_IntrinsicTask_EnableTeamsVdiOptimizationDescriptionText',
        defaultMessage: 'Apply optimizations for Microsoft Teams',
        description: 'Text for the description of the EnableTeamsVdiOptimization intrinsic task',
    },
});

export const IntrinsicTaskHintMessages = defineMessages({
    UpdatePcName: {
        id: 'CustomizationSettings_IntrinsicTask_UpdatePcNameHintText',
        defaultMessage: "The PC name will be \"MDB-'{USER}'-'{RAND}'-{devBoxName}\"", // using single quotes to escape interpolation
        description: 'Text for the hint of the UpdatePcName intrinsic task',
    },
    EnableTeamsVdiOptimization: {
        id: 'CustomizationSettings_IntrinsicTask_EnableTeamsVdiOptimizationHintText',
        defaultMessage:
            'For more information about Microsoft Teams optimizations for VDI, check out Microsoft Teams documentation',
        description: 'Text for the hint of the EnableTeamsVdiOptimization intrinsic task',
    },
});
