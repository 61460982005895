import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { listSnapshots, loadSnapshots } from './snapshot-action-creators';

/**
 * Action types
 */

type SnapshotActionName =
    | ActionName<'ListSnapshots', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadSnapshots', 'Error' | 'Failed' | 'Success'>;

export type SnapshotActionType =
    | ActionType<'LIST_SNAPSHOTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_SNAPSHOTS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const SnapshotActionType: UnionValueMap<SnapshotActionName, SnapshotActionType> = {
    ListSnapshots: 'LIST_SNAPSHOTS',
    ListSnapshotsError: 'LIST_SNAPSHOTS_ERROR',
    ListSnapshotsFailed: 'LIST_SNAPSHOTS_FAILED',
    ListSnapshotsSuccess: 'LIST_SNAPSHOTS_SUCCESS',
    LoadSnapshots: 'LOAD_SNAPSHOTS',
    LoadSnapshotsError: 'LOAD_SNAPSHOTS_ERROR',
    LoadSnapshotsFailed: 'LOAD_SNAPSHOTS_FAILED',
    LoadSnapshotsSuccess: 'LOAD_SNAPSHOTS_SUCCESS',
};

/**
 * Action shorthands
 */

export type ListSnapshotsAction = ReturnType<typeof listSnapshots>;

export type LoadSnapshotsAction = ReturnType<typeof loadSnapshots>;
