import * as React from 'react';
import { SizeViewModel } from '../../../../../add-dev-box-panel/models';
import ValueDropdown, { ValueDropdownWrapperProps } from '../../value-dropdown';
import { getOptionKeyForIndexedModel } from '../selectors';
import SizeDropdownItem from './size-dropdown-item';

export type SizeDropdownProps = ValueDropdownWrapperProps<SizeViewModel>;

const onRenderOption = (size: SizeViewModel): JSX.Element => <SizeDropdownItem size={size} />;

export const SizeDropdown: React.FC<SizeDropdownProps> = (props) => (
    <ValueDropdown<SizeViewModel>
        {...props}
        onRenderOption={onRenderOption}
        getOptionKey={getOptionKeyForIndexedModel}
    />
);
