import { CapitalizedUnionMap } from '../../../types/union-map';

export interface RestoreFormData {
    restoreSnapshotId?: string;
}

export type RestoreFormFieldName = 'restoreSnapshotId';

export const RestoreFormFieldName: CapitalizedUnionMap<RestoreFormFieldName> = {
    RestoreSnapshotId: 'restoreSnapshotId',
};

export interface RestoreSnapshotDropdownOption {
    createdTime: Date;
    locale: string;
    snapshotId: string;
}
