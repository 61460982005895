import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ActionAbility,
    CannotCreateEnvironmentReason,
    EnvironmentCreateAbilityState,
} from '../../../redux/selector/display/create-ability/models';
import Banner from '../../banner';

export interface EnvironmentCreateInfoBannerProps {
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
}

export const EnvironmentCreateInfoBanner: React.FC<EnvironmentCreateInfoBannerProps> = React.memo(
    (props: EnvironmentCreateInfoBannerProps) => {
        const { environmentCreateAbilityState } = props;

        // Component state hooks
        const [isMessageBarOpen, setIsMessageBarOpen] = React.useState(true);

        const onDismissMessageBar = React.useCallback(() => {
            setIsMessageBarOpen(false);
        }, []);

        // Render logic

        if (!isMessageBarOpen) {
            return <></>;
        }

        // If we are not an environment user / environments are not enabled, no message to display here
        if (environmentCreateAbilityState.reason === CannotCreateEnvironmentReason.IsNotEnvironmentUser) {
            return <></>;
        }

        // Failure to load resources has blocked us from creating environments, show error message bar
        if (environmentCreateAbilityState.createAbility === ActionAbility.FailedToLoad) {
            return (
                <Banner intent="error" onDismiss={onDismissMessageBar}>
                    <FormattedMessage
                        id="EnvironmentCreateInfoBanner_FailedToLoad_Text"
                        defaultMessage="We encountered an error loading your projects, and new environments cannot be created at this time. Please retry, or contact your admin if this problem persists."
                        description="Message informing user that we failed to load project resources and they can't create environments."
                    />
                </Banner>
            );
        }

        return <></>;
    }
);

export default EnvironmentCreateInfoBanner;
