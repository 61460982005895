import { UnionMap, UnionValueMap } from '../types/union-map';

export type SupportLinkName =
    | 'Privacy'
    | 'GettingStarted'
    | 'DevBoxFeedback'
    | 'AdeFeedback'
    | 'ProductPageDevBox'
    | 'OneESDevBoxFaq'
    | 'HibernationPublicPreview'
    | 'TroubleshootAndRepair'
    | 'ProductTerms'
    | 'CustomizationsLink'
    | 'MultipleMonitorsLink'
    | 'DevHomeLink'
    | 'InternalSupportLink'
    | 'ExternalSupportLink'
    | 'ConfigureDevBox'
    | 'GettingStartedADE'
    | 'ConfigureADE'
    | 'QuickstartDevBox'
    | 'QuickstartADE';

export const SupportLinkName: UnionMap<SupportLinkName> = {
    Privacy: 'Privacy',
    GettingStarted: 'GettingStarted',
    DevBoxFeedback: 'DevBoxFeedback',
    AdeFeedback: 'AdeFeedback',
    ProductPageDevBox: 'ProductPageDevBox',
    OneESDevBoxFaq: 'OneESDevBoxFaq',
    HibernationPublicPreview: 'HibernationPublicPreview',
    TroubleshootAndRepair: 'TroubleshootAndRepair',
    ProductTerms: 'ProductTerms',
    CustomizationsLink: 'CustomizationsLink',
    MultipleMonitorsLink: 'MultipleMonitorsLink',
    DevHomeLink: 'DevHomeLink',
    InternalSupportLink: 'InternalSupportLink',
    ExternalSupportLink: 'ExternalSupportLink',
    ConfigureDevBox: 'ConfigureDevBox',
    GettingStartedADE: 'GettingStartedADE',
    ConfigureADE: 'ConfigureADE',
    QuickstartDevBox: 'QuickstartDevBox',
    QuickstartADE: 'QuickstartADE',
};

// Note: If these links need to be updated, update the aka.ms url directly at https://redirectiontool.trafficmanager.net/am/redirection/home?options=host:aka.ms.
// Do not modify the links here.
export const PrivacyLink = 'https://aka.ms/devbox-privacy';
export const GettingStartedLink = 'https://aka.ms/devbox-documentation';
export const DevBoxFeedbackLink = 'https://aka.ms/devbox-feedback';
export const AdeFeedbackLink = 'https://aka.ms/ade-feedback';
export const ProductPageDevBox = 'https://aka.ms/devbox-info';
export const OneESDevBoxFaq = 'https://aka.ms/devbox/faq';
export const HibernationPublicPreview = 'https://aka.ms/DevBoxHibernationPublicPreviewDocs';
export const TroubleshootAndRepair = 'https://aka.ms/devboxtsandrepair';
export const ProductTermsLink = 'https://aka.ms/devbox-productterms';
export const CustomizationsLink = 'https://aka.ms/devbox/customizations/learn';
export const MultipleMonitorsLink = 'https://aka.ms/devbox/multi-monitors';
export const DevHomeLink = 'https://aka.ms/devhome/doc';
export const InternalSupportLink = 'https://aka.ms/AArqmfd';
export const ExternalSupportLink = 'https://aka.ms/AArpncm';
export const ConfigureDevBox = 'https://aka.ms/AAtf7va';
export const GettingStartedADE = 'https://aka.ms/ade/overview';
export const ConfigureADE = 'https://aka.ms/ade/devcenter';
export const QuickstartDevBox = 'https://aka.ms/AAtfp8p';
export const QuickstartADE = 'https://aka.ms/AAtfp9v';

export const SupportSectionLinks: UnionValueMap<SupportLinkName, string> = {
    Privacy: PrivacyLink,
    GettingStarted: GettingStartedLink,
    DevBoxFeedback: DevBoxFeedbackLink,
    AdeFeedback: AdeFeedbackLink,
    ProductPageDevBox: ProductPageDevBox,
    OneESDevBoxFaq: OneESDevBoxFaq,
    HibernationPublicPreview: HibernationPublicPreview,
    TroubleshootAndRepair: TroubleshootAndRepair,
    ProductTerms: ProductTermsLink,
    CustomizationsLink: CustomizationsLink,
    MultipleMonitorsLink: MultipleMonitorsLink,
    DevHomeLink: DevHomeLink,
    InternalSupportLink: InternalSupportLink,
    ExternalSupportLink: ExternalSupportLink,
    ConfigureDevBox: ConfigureDevBox,
    GettingStartedADE: GettingStartedADE,
    ConfigureADE: ConfigureADE,
    QuickstartDevBox: QuickstartDevBox,
    QuickstartADE: QuickstartADE,
};
