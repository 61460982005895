import { DefaultButton, Dialog, DialogFooter, IDialogContentProps, IModalProps, PrimaryButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { RegionViewModel } from '../models';

export interface SuboptimalRegionDialogProps {
    onDismiss: () => void;
    showDialog: boolean;
    onSelectedRegionSubmitted: () => void;
    selectedRegion: RegionViewModel | undefined;
    onChange: (selectedRegion: RegionViewModel | undefined) => void;
}

const messages = defineMessages({
    continueButtonLabel: {
        id: 'SuboptimalRegionDialog_ContinueButton_Label',
        defaultMessage: 'Continue',
        description: 'Label for the submit button on the suboptimal region dialog.',
    },
    cancelButtonLabel: {
        id: 'SuboptimalRegionDialog_CancelButton_Label',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button on the suboptimal region dialog.',
    },
});

const SuboptimalRegionDialogTitle: React.FC = () => {
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <div className={horizontalStackStyles.root}>
            <FormattedMessage
                id="SuboptimalRegionDialog_Title_SuboptimalRegion"
                defaultMessage="Suboptimal region"
                description="The title for the dialog warning users that the selected region is suboptimal."
            />
        </div>
    );
};

/**
 * Styles
 */

const dialogWidth = '490px';
const useModalStyles = makeStyles({
    main: {
        width: dialogWidth,
    },
});

const useDialogStyles = makeStyles({
    inner: {
        padding: '26px 24px 24px 24px',
    },
});

const useDialogStackStyles = makeStyles({
    root: {
        gap: '18px',
        padding: '0px 0px 30px 0px',
    },
});

/**
 * END Styles
 */

const SuboptimalRegionDialogComponent: React.FC<SuboptimalRegionDialogProps> = (props: SuboptimalRegionDialogProps) => {
    const { onDismiss, onSelectedRegionSubmitted, selectedRegion, onChange } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const dialogStyles = useDialogStyles();
    const modalStyles = useModalStyles();
    const stackStyles = useStackStyles();
    const dialogStackStyles = useDialogStackStyles();

    const onSubmit = React.useCallback(() => {
        onChange(selectedRegion);
        onSelectedRegionSubmitted();
        onDismiss();
    }, [onSelectedRegionSubmitted, onDismiss, onChange, selectedRegion]);

    // Memoized data
    const dialogContentProps = React.useMemo(
        (): IDialogContentProps => ({
            title: <SuboptimalRegionDialogTitle />,
            styles: dialogStyles,
        }),
        [dialogStyles]
    );

    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    return (
        <Dialog
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            hidden={false}
            modalProps={modalProps}
            maxWidth={dialogWidth}
        >
            <div className={mergeClasses(stackStyles.root, dialogStackStyles.root)}>
                <div className={stackStyles.item}>
                    <FormattedMessage
                        id="SuboptimalRegionDialog_Description"
                        defaultMessage="The selected region will have high latency to your location, and will negatively impact the responsiveness of your dev box. Contact your admin to add support for your region."
                        description="The description for the suboptimal region dialog"
                    />
                </div>
            </div>
            <DialogFooter>
                <PrimaryButton text={formatMessage(messages.continueButtonLabel)} onClick={onSubmit} />
                <DefaultButton text={formatMessage(messages.cancelButtonLabel)} onClick={onDismiss} />
            </DialogFooter>
        </Dialog>
    );
};

const SuboptimalRegionDialogWrapper: React.FC<SuboptimalRegionDialogProps> = (props: SuboptimalRegionDialogProps) => {
    const { showDialog } = props;

    if (!showDialog) {
        return <></>;
    }

    return <SuboptimalRegionDialogComponent {...props} />;
};

export const SuboptimalRegionDialog = SuboptimalRegionDialogWrapper;
export default SuboptimalRegionDialog;
