import { DatePicker, IDatePickerStrings } from '@fluentui/react';
import { Info } from 'luxon';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getWeekStartByLocale } from 'weekstart';
import { getDateStringFromDate } from '../../../../utilities/time';

export interface Props {
    value?: Date;
    label: string;
    locale: string;
    ariaLabel?: string;
    onChange: (value: Date | undefined) => void;
    minDate?: Date;
    placeholder: string;
    disabled?: boolean;
}

const messages = defineMessages({
    goToToday: {
        id: 'ExpirationDatePicker_GoToToday_Label',
        defaultMessage: 'Go to today',
        description: 'Label for button for go to today in the expiration date picker',
    },
    goToTodayAriaLabel: {
        id: 'ExpirationDatePicker_GoToToday_AriaLabel',
        defaultMessage: 'Go to today for date picker',
        description: 'Aria Label for button for go to today in the expiration date picker',
    },
    previousMonthAriaLabel: {
        id: 'ExpirationDatePicker_PreviousMonth_AriaLabel',
        defaultMessage: 'Previous month',
        description: 'Aria label for the previous month button in the expiration date picker',
    },
    nextMonthAriaLabel: {
        id: 'ExpirationDatePicker_NextMonth_AriaLabel',
        defaultMessage: 'Next month',
        description: 'Aria label for the next month button in the expiration date picker',
    },
    previousYearAriaLabel: {
        id: 'ExpirationDatePicker_PreviousYear_AriaLabel',
        defaultMessage: 'Previous year',
        description: 'Aria label for the previous year button in the expiration date picker',
    },
    nextYearAriaLabel: {
        id: 'ExpirationDatePicker_NextYear_AriaLabel',
        defaultMessage: 'Next year',
        description: 'Aria label for the next year button in the expiration date picker',
    },
    closeExpirationDatePickerAriaLabel: {
        id: 'ExpirationDatePicker_Close_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the close button in the expiration date picker',
    },
});

export const LegacyExpirationDatePicker: React.FC<Props> = (props: Props) => {
    const { value, locale, onChange, minDate, placeholder, disabled, label, ariaLabel } = props;
    // Intl hooks
    const { formatMessage } = useIntl();

    const firstDayOfWeek = React.useMemo(() => getWeekStartByLocale(locale), [locale]);

    const dateStrings: IDatePickerStrings = React.useMemo(() => {
        const days = Info.weekdays(undefined, { locale });
        const shortDays = Info.weekdays('narrow', { locale });

        // Shift the arrays to move the last day (Sunday) to the start of the array for DatePicker
        days.unshift(days.pop() ?? '');
        shortDays.unshift(shortDays.pop() ?? '');

        return {
            months: Info.months('long', { locale }),
            shortMonths: Info.months('short', { locale }),
            days: days,
            shortDays: shortDays,
            goToToday: formatMessage(messages.goToToday),
            goToTodayAriaLabel: formatMessage(messages.goToTodayAriaLabel),
            prevMonthAriaLabel: formatMessage(messages.previousMonthAriaLabel),
            nextMonthAriaLabel: formatMessage(messages.nextMonthAriaLabel),
            prevYearAriaLabel: formatMessage(messages.previousYearAriaLabel),
            nextYearAriaLabel: formatMessage(messages.nextYearAriaLabel),
            closeButtonAriaLabel: formatMessage(messages.closeExpirationDatePickerAriaLabel),
        };
    }, [locale]);

    const onFormatDate = React.useCallback(
        (date: Date | undefined) => {
            return getDateStringFromDate(date, locale) ?? '';
        },
        [locale]
    );

    const onChangeHandler = React.useCallback(
        (date: Date | null | undefined) => {
            !!date ? onChange(date) : onChange(undefined);
        },
        [onChange]
    );

    return (
        <DatePicker
            allowTextInput={false}
            value={value}
            formatDate={onFormatDate}
            onSelectDate={onChangeHandler}
            strings={dateStrings}
            placeholder={placeholder}
            disabled={disabled}
            minDate={minDate}
            firstDayOfWeek={firstDayOfWeek}
            label={label}
            ariaLabel={ariaLabel ?? label}
        />
    );
};

export default LegacyExpirationDatePicker;
