import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from '../language/messages';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { useStackStyles } from '../themes/styles/flexbox-styles';
import { isDevPortalEnvironment } from '../utilities/app';

interface LoadingPageProps {
    message?: {
        defaultMessage: string;
        description: string;
        id: string;
    };
}

/**
 * Styles
 */

const useLoadingMessageContainerStyles = makeStyles({
    // Note: using paddingTop to space StackItems as Stack will override margins
    root: {
        lineHeight: '20px',
        paddingTop: '6px',
    },
});

const useWelcomeMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: '15px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: '1',
    },
});

/**
 * End Styles
 */

const welcomeMessage = isDevPortalEnvironment
    ? commonMessages.welcomeMessageDevPortalText
    : commonMessages.welcomeMessageText;

export const LoadingPage: React.FC<LoadingPageProps> = (props: LoadingPageProps) => {
    const { message } = props;

    // Style hooks
    const loadingMessageContainerStyles = useLoadingMessageContainerStyles();
    const welcomeMessageContainerStyles = useWelcomeMessageContainerStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    return (
        <CenteredPortalLayout id="loading">
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={mergeClasses(stackStyles.item, welcomeMessageContainerStyles.root)}>
                    <FormattedMessage {...welcomeMessage} />
                </div>

                <div className={mergeClasses(stackStyles.item, loadingMessageContainerStyles.root)}>
                    {message ? (
                        <FormattedMessage {...message} />
                    ) : (
                        <FormattedMessage
                            id="LoadingPage_LoadingMessage_Text"
                            defaultMessage="Loading..."
                            description="Message indicating that the app is loading"
                        />
                    )}
                </div>
            </div>
        </CenteredPortalLayout>
    );
};

export default LoadingPage;
