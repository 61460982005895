import fetchMock from 'fetch-mock';
import { ScenarioName, Scenarios } from './scenarios/all-scenarios';
import { GlobalMockConfiguration, MockRequestConfiguration, MockResponse } from './types';

const isMockResponse = (
    response: number | string | MockResponse | fetchMock.MockResponseFunction
): response is MockResponse =>
    typeof response !== 'number' && typeof response !== 'string' && typeof response !== 'function';

const defaultGlobalConfiguration: GlobalMockConfiguration = {
    fallbackToNetwork: true,
    // Note: false means that duplicate routes are appended to an array of matching routes
    // TODO: validate behavior when duplicate route names are given
    overwriteRoutes: false,
};

export const clearMocks = (): void => {
    fetchMock.reset();
};

export const configureAllMocks = (configuration: GlobalMockConfiguration): void => {
    fetchMock.config.fallbackToNetwork = configuration.fallbackToNetwork;
    fetchMock.config.includeContentLength = configuration.includeContentLength;
    fetchMock.config.overwriteRoutes = configuration.overwriteRoutes;
    fetchMock.config.sendAsJson = configuration.sendAsJson;
    fetchMock.config.warnOnFallback = configuration.warnOnFallback;
};

export const configureMock = (mockConfiguration: MockRequestConfiguration): void => {
    // Special handling before calling mock:
    //      - response body (if object) must be serialized to string
    const { body, headers, matchPartialBody, method, name, params, query, repeat, response, url, overwriteRoutes } =
        mockConfiguration;

    const finalConfiguration = {
        body,
        headers,
        matchPartialBody,
        method,
        name,
        params,
        query,
        repeat,
        response: !isMockResponse(response)
            ? response
            : {
                  body: typeof response.body === 'string' ? response.body : JSON.stringify(response.body),
                  headers: response.headers,
                  status: response.status,
              },
        url,
        overwriteRoutes,
    };

    fetchMock.mock(finalConfiguration);
};

export const configureMocks = (mockConfigurations: MockRequestConfiguration[]): void => {
    mockConfigurations.forEach(configureMock);
};

export const configureMocksByScenarioName = (scenarios: ScenarioName[]): void => {
    scenarios.forEach((scenario) => configureMocks(Scenarios[scenario].requests));
};

// Apply default configuration to module
configureAllMocks(defaultGlobalConfiguration);
