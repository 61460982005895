import { rawTimeZones } from '@vvo/tzdb';
import { DateTime, Duration, DurationUnit, Interval } from 'luxon';
import { TimeFormat12Hour, TimeFormat24Hour } from '../constants/time';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from './string';

export const timeFormat12Hour = 'hh:mm a';
export const timeFormat24Hour = 'HH:mm';
const defaultTimeZone = 'America/Los_Angeles';

interface RelativeTimeComponents {
    unit: Intl.RelativeTimeFormatUnit;
    value: number;
}

export interface TimeZoneData {
    name: string;
    alternativeName: string;
    displayText: string;
}

const isUnitSignificantForDuration = (duration: Duration, unit: DurationUnit) => duration.as(unit) >= 1;

export const getDateFromTimeString = (
    time: string | undefined,
    is12HourFormat: boolean,
    providedTimeZone?: string
): Date | undefined => {
    if (isUndefinedOrWhiteSpace(time)) {
        return undefined;
    }

    const timeZone = isUndefinedOrWhiteSpace(providedTimeZone) ? getBrowserTimeZone() : providedTimeZone;

    if (isUndefinedOrWhiteSpace(timeZone)) {
        return undefined;
    }

    const formatPattern = is12HourFormat ? TimeFormat12Hour : TimeFormat24Hour;
    const dateTime = DateTime.fromFormat(time.trim(), formatPattern, { zone: timeZone, setZone: true });

    return dateTime.isValid ? dateTime.toJSDate() : undefined;
};

// Intl.DateTimeFormat uses browser calculations to determine the timezone.
export const getBrowserTimeZone = (): string => new Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;

// Intl.DateTimeFormat uses browser calculations to determine the timezone.
export const getDefaultTimeZone = (): string => {
    return getBrowserTimeZone() ?? defaultTimeZone;
};

const getTimeStringFromTimeZone = (date: Date, locale: string, timeZone: string): string | undefined => {
    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale) || isUndefinedOrWhiteSpace(timeZone)) {
        return undefined;
    }

    return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: '2-digit',
        timeZone,
    }).format(date);
};

const getDateStringFromTimeZone = (date: Date, locale: string, timeZone: string): string | undefined => {
    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale) || isUndefinedOrWhiteSpace(timeZone)) {
        return undefined;
    }

    return new Intl.DateTimeFormat(locale, {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        timeZone,
    }).format(date);
};

const getNumericTimeStringFromTimeZone = (date: Date, locale: string, timeZone: string): string | undefined => {
    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale) || isUndefinedOrWhiteSpace(timeZone)) {
        return undefined;
    }

    return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
    }).format(date);
};

const getNumericDateStringFromTimeZone = (date: Date, locale: string, timeZone: string): string | undefined => {
    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale) || isUndefinedOrWhiteSpace(timeZone)) {
        return undefined;
    }

    return new Intl.DateTimeFormat(locale, {
        day: 'numeric',
        year: 'numeric',
        month: 'numeric',
        timeZone,
    }).format(date);
};

export const isDateValid = (date: Date | undefined): date is Date => {
    return date !== undefined && !isNaN(date.valueOf());
};

export const getRelativeTimeComponents = (from: Date, to: Date): RelativeTimeComponents => {
    const duration = Interval.fromDateTimes(from, to).toDuration();

    if (isUnitSignificantForDuration(duration, 'year')) {
        return { unit: 'year', value: Math.ceil(duration.as('year')) };
    }

    if (isUnitSignificantForDuration(duration, 'month')) {
        return { unit: 'month', value: Math.ceil(duration.as('month')) };
    }

    if (isUnitSignificantForDuration(duration, 'week')) {
        return { unit: 'week', value: Math.ceil(duration.as('week')) };
    }

    if (isUnitSignificantForDuration(duration, 'day')) {
        return { unit: 'day', value: Math.ceil(duration.as('day')) };
    }

    if (isUnitSignificantForDuration(duration, 'hour')) {
        return { unit: 'hour', value: Math.ceil(duration.as('hour')) };
    }

    if (isUnitSignificantForDuration(duration, 'minute')) {
        return { unit: 'minute', value: Math.ceil(duration.as('minute')) };
    }

    return { unit: 'second', value: Math.ceil(duration.as('second')) };
};

export const tryGetTimeStringFromBrowserTimeZone = (
    locale: string,
    time?: string,
    configuredTimeZone?: string,
    userTimeZone?: string
): string | undefined => {
    if (
        isUndefinedOrWhiteSpace(time) ||
        isUndefinedOrWhiteSpace(locale) ||
        isUndefinedOrWhiteSpace(configuredTimeZone)
    ) {
        return undefined;
    }

    const timeZone = isNotUndefinedOrWhiteSpace(userTimeZone) ? userTimeZone : getBrowserTimeZone();

    // Note: First, get the date based on the configured timezone. Then, get the string based on the browser timezone.
    const date = getDateFromTimeString(time, false, configuredTimeZone);

    return date ? getTimeStringFromTimeZone(date, locale, timeZone) : undefined;
};

export const isNextDay = (originalDate: Date, newDate: Date): boolean => {
    if (!isDateValid(originalDate) || !isDateValid(newDate)) {
        return false;
    }

    const addOneDay = DateTime.fromJSDate(originalDate).plus({ days: 1 }).startOf('day').toISODate();
    const newDay = DateTime.fromJSDate(newDate).startOf('day').toISODate();

    return addOneDay === newDay;
};

export const isDayAfterNextDay = (originalDate: Date, newDate: Date): boolean => {
    if (!isDateValid(originalDate) || !isDateValid(newDate)) {
        return false;
    }

    const addTwoDays = DateTime.fromJSDate(originalDate).plus({ days: 2 }).startOf('day').toISODate();
    const newDay = DateTime.fromJSDate(newDate).startOf('day').toISODate();

    return addTwoDays === newDay;
};

export const getTimeStringFromDate = (date: Date | undefined, locale: string): string | undefined => {
    if (!date) {
        return undefined;
    }

    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale)) {
        return undefined;
    }

    const timeZone = getBrowserTimeZone();

    return getTimeStringFromTimeZone(date, locale, timeZone);
};

export const getDateStringFromDate = (date: Date | undefined, locale: string): string | undefined => {
    if (!date) {
        return undefined;
    }

    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale)) {
        return undefined;
    }

    const timeZone = getBrowserTimeZone();

    return getDateStringFromTimeZone(date, locale, timeZone);
};

export const getNumericTimeStringFromDate = (date: Date | undefined, locale: string): string | undefined => {
    if (!date) {
        return undefined;
    }

    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale)) {
        return undefined;
    }

    const timeZone = getBrowserTimeZone();

    return getNumericTimeStringFromTimeZone(date, locale, timeZone);
};

export const getNumericDateStringFromDate = (date: Date | undefined, locale: string): string | undefined => {
    if (!date) {
        return undefined;
    }

    if (!isDateValid(date) || isUndefinedOrWhiteSpace(locale)) {
        return undefined;
    }

    const timeZone = getBrowserTimeZone();

    return getNumericDateStringFromTimeZone(date, locale, timeZone);
};

export const getIs12HourTimeFormat = (locale: string): boolean => {
    const time = new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' });
    const formattedString = time.format(0);

    return getDateFromTimeString(formattedString, true) ? true : false;
};

export const getHoursBetweenDates = (originalDate: Date, updatedDate: Date): number | undefined => {
    if (!isDateValid(originalDate) || !isDateValid(updatedDate)) {
        return undefined;
    }
    const updatedDateValue = updatedDate.getTime();
    const originalDateValue = originalDate?.getTime();

    if (!updatedDateValue || !originalDateValue) {
        return -1;
    }

    const milliseconds = Math.abs(updatedDateValue - originalDateValue);

    return milliseconds / (60 * 60 * 1000);
};

export const getAreDatesLessThanNHoursApart = (date1: Date, date2: Date, n: number): boolean => {
    const hoursBetweenDates = getHoursBetweenDates(date1, date2);

    return hoursBetweenDates !== undefined && hoursBetweenDates < n;
};

export const getMillisecondsBetween = (startDate: Date, endDate: Date): number =>
    endDate.getTime() - startDate.getTime();

export const getDateForBrowserTimeZone = (date: Date | undefined): Date | undefined => {
    if (date === undefined) {
        return undefined;
    }

    if (!isDateValid(date)) {
        return undefined;
    }

    const zone = getBrowserTimeZone();
    const localDate = DateTime.fromJSDate(date, { zone });

    return localDate.isValid ? localDate.toJSDate() : undefined;
};

type TimesWithOffset = {
    date: Date;
    offsetInHours: number;
};

export const getListOfTimesWithInterval = (
    start: Date,
    end: Date,
    interval: number,
    startOffsetInHours: number
): TimesWithOffset[] => {
    const startTime = DateTime.fromJSDate(start);
    const endTime = DateTime.fromJSDate(end);
    const datesArray = [];
    let currentTime = startTime;
    let currentOffset = startOffsetInHours;

    while (currentTime <= endTime) {
        const currentTimeJS = currentTime.toJSDate();
        datesArray.push({ date: currentTimeJS, offsetInHours: currentOffset });
        currentTime = DateTime.fromJSDate(addHours(currentTimeJS, interval));
        currentOffset = currentOffset + interval;
    }

    return datesArray;
};

export const addHours = (date: Date, hours: number): Date => {
    const datetime = DateTime.fromJSDate(date);
    const newDate = datetime.plus({ hours: hours });
    return newDate.toJSDate();
};

export const getDayOfTheWeek = (date: Date): string => {
    return DateTime.fromJSDate(date).toFormat('EEEE');
};

export const compareDates = (a: Date, b: Date, isSortedDescending = false): number => {
    const aValue = a.valueOf();
    const bValue = b.valueOf();

    if (aValue === bValue) {
        return 0;
    } else {
        return (isSortedDescending ? aValue < bValue : aValue > bValue) ? 1 : -1;
    }
};

export const getTimeZoneOptions = (): TimeZoneData[] => {
    const timeZones: TimeZoneData[] = rawTimeZones.map((timeZone) => {
        const { rawFormat, mainCities, name, alternativeName } = timeZone;

        const splitOffset = rawFormat.split(' ');
        const timeOffset = splitOffset[0];
        const cities = mainCities.join(', ');

        const displayText = `(UTC${timeOffset}) ${cities}`;

        return {
            name,
            alternativeName,
            displayText,
        };
    });
    return timeZones;
};

export const convertDateToTimeZoneDate = (date: Date, zone: string): Date => {
    const updatedDate = DateTime.fromJSDate(date, { zone });
    return updatedDate.toJSDate();
};
